import React from 'react';

export const manifestRouteConfig = [
  {
    auth: ["Admin excluded", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/manifest',
        component: React.lazy(() => import('./Manifest')),
      },
    ],
  },
];
