import "whatwg-fetch";
import { takeLatest, put, call } from "redux-saga/effects";
import { Api, getGetParams, getPostParams } from "../../@crema/utility/Utils";
import { fetchError, fetchStart, fetchSuccess } from '../actions/Common';

import {
  fetchPrescriptionListSucceeded,
  fetchOrderListSucceeded,
  fetch_KVP_TrialForPharmacySucceeded,
  fetch_KVP_SitesByPharmacyIdSucceeded,
  fetch_KVP_SitesByTrialIdSucceeded,
  deleteDispenseSucceeded,
  fetchPrescriptionByIdSucceeded,
  fetchBulkOrderDispenseByIdSucceeded,
  fetchInventoryForKitAndBottleSucceeded,
  fetchBulkOrderKitNumbersSucceeded,
  submitManualAssignToBulkOrderSucceeded,
  fetchBulkOrderRandomisationSucceeded,
  fetchPrescriptionInventoryByIdSucceeded,
  markBulkOrderAsDispensedSucceeded,
  markPrescriptionAsDispensedSucceeded,
  printPrescriptionLabelsSucceeded,
  approvePrescriptionSucceeded,
  rejectPrescriptionSucceeded,
  approveBulkOrderSucceeded,
  rejectBulkOrderSucceeded,
  getShippingDetailsSucceeded,
  markPrescriptionAsPackedSucceeded,
  markBulkOrderAsPackedSucceeded,
  markPrescriptionAsReceivedSucceeded,
  markBulkOrderAsReceivedSucceeded,
  fetchInventoryofDispensedDrugsSucceeded,
  fetchConsignmentDetailsSucceeded,
  uploadReceivedStatusFilesSucceeded,
  fetchUsersToApproveSucceeded,
  fetchOrderListReportSucceeded,
  fetchAllOrderListReportSucceeded
 } from "../actions/Prescriptions";

const baseUrlv1 = 'api/v1';

function fetchPrescriptionListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, searchPayload } = payload;
  const url = `${baseUrlv1}/prescriptions?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getPostParams(searchPayload));
}

function fetchOrderListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, searchPayload, type } = payload;
  const url = `${baseUrlv1}/bulkorders/${type}/orderlist?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getPostParams(searchPayload));
}

function fetch_KVP_SitesByPharmacyIdApi() {
  const url = `${baseUrlv1}/sites/getkvp`;
  return Api.fetch(url, getGetParams());
}

function fetch_KVP_SitesByTrialIdApi(trialId:string) {
  const url = `${baseUrlv1}/sites/getsitekvp/${trialId}`;
  return Api.fetch(url, getGetParams());
}

function fetch_KVP_TrialForPharmacyApi() {
  const url = `${baseUrlv1}/pharmacy/trialKvp`;
  return Api.fetch(url, getGetParams());
}

function deleteDispenseApi(id:string, Type: string, reason: string) {
  const payload = {id, reason};
  const url = Type === 'prescription'
    ? `${baseUrlv1}/prescriptions/${id}/cancel`
    : `${baseUrlv1}/bulkOrders/${id}/cancel`

  return Api.fetch(url, getPostParams(payload));
}

function fetchPrescriptinByIdApi(prescriptionId:string) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}`;
  return Api.fetch(url, getGetParams());
}

function fetchBulkOrderDispenseByIdApi(id:string) {
  // const url = `${baseUrlv1}/bulkorders/dispense/${id}`;
  const url = `${baseUrlv1}/bulkorders/${id}/dispense`;
  return Api.fetch(url, getGetParams());
}

function fetchInventoryForKitAndBottleApi(payload:any) {
  const { id, inventoryId, treatmentId, bottleIdentifier, PageSize, PageNumber, SearchTerm} = payload;
  const url = bottleIdentifier === 2 
    ? `${baseUrlv1}/bulkorders/${id}/kitnumbers/${inventoryId}/${treatmentId}?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`
    : `${baseUrlv1}/bulkorders/${id}/bottlenumbers/${inventoryId}/${treatmentId}?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchBulkOrderRandomisationApi(id:string) {
  const url = `${baseUrlv1}/bulkorders/dispense/randomisation/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchBulkOrderKitNumbersApi(id:string) {
  const url = `${baseUrlv1}/bulkorders/dispense/kitnumbers/${id}`;
  return Api.fetch(url, getGetParams());
}

function submitManualAssignToBulkOrderdApi(payload:any) {
  const url = `${baseUrlv1}bulkorders/dispense/manualassignkitnumbers/${payload.bulkOrderId}`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchPrescriptinInventoryByIdApi(prescriptionId:string) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/treatments`;
  return Api.fetch(url, getGetParams());
}

function markBulkOrderAsDispensedApi(bulkOrderId:string, payload:any) {
  const url = `${baseUrlv1}/prescriptions/${bulkOrderId}/bulkOrderDispensed`;
  return Api.fetch(url, getPostParams(payload));
}

function markPrescriptionAsDispensedApi(prescriptionId:string, payload: any) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/dispensed`;
  return Api.fetch(url, getPostParams(payload));
}

function printPrescriptionLabelsApi(prescriptionId:string, payload: any) {
  const url = `${baseUrlv1}/prescriptions/print/${prescriptionId}`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchInventoryofDispensedDrugsApi(prescriptionId:string) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/dispensed`;
  return Api.fetch(url, getGetParams());
}

function approvePrescriptionApi(prescriptionId:string, payload: any, specialApproval:boolean) {
  const url = specialApproval 
    ? `${baseUrlv1}/prescriptions/${prescriptionId}/dispensed/approveWithPin`
    : `${baseUrlv1}/prescriptions/${prescriptionId}/dispensed/approve`
  return Api.fetch(url, getPostParams(payload));
}

function rejectPrescriptionApi(prescriptionId:string, payload: any, specialApproval:boolean) {
  const url = specialApproval 
    ? `${baseUrlv1}/prescriptions/${prescriptionId}/dispensed/rejectWithPin`
    : `${baseUrlv1}/prescriptions/${prescriptionId}/dispensed/reject`;
  return Api.fetch(url, getPostParams(payload));
}

function approveBulkOrderApi(bulkOrderId:string, payload:any, specialApproval:boolean) {
  const url = specialApproval 
    ? `${baseUrlv1}/bulkOrders/${bulkOrderId}/dispensed/approveWithPin`
    : `${baseUrlv1}/bulkOrders/${bulkOrderId}/dispensed/approve`;
  return Api.fetch(url, getPostParams(payload));
}
function rejectBulkOrderApi(bulkOrderId:string, payload:any, specialApproval:boolean) {
  const url = specialApproval 
    ? `${baseUrlv1}/bulkOrders/${bulkOrderId}/dispensed/rejectWithPin`
    : `${baseUrlv1}/bulkOrders/${bulkOrderId}/dispensed/reject`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchShippingDetailsByIdApi(trialId:string) {
  const url = `${baseUrlv1}/trials/${trialId}/shipping`;
  return Api.fetch(url, getGetParams());
}

function markPrescriptionAsPackedApi(prescriptionId:string, payload: any) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/markAsPacked`;
  return Api.fetch(url, getPostParams(payload));
}

function markBulkOrderAsPackedApi(bulkOrderId:string, payload: any) {
  const url = `${baseUrlv1}/bulkorders/${bulkOrderId}/markAsPacked`;
  return Api.fetch(url, getPostParams(payload));
}

function markPrescriptionAsReceivedApi(prescriptionId:string) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/markAsReceived`;
  return Api.fetch(url, getPostParams({}));
}

function markBulkOrderAsReceivedApi(bulkOrderId:string) {
  const url = `${baseUrlv1}/bulkorders/${bulkOrderId}/markAsReceived`;
  return Api.fetch(url, getPostParams({}));
}

function downloadReceivedStatusFilesApi(id:string, dispenseType:string, Type: number) {
  const url = dispenseType === 'prescription' 
    ? `${baseUrlv1}/prescriptions/${id}/ReceivedStatusFiles?type=${Type}`
    : `${baseUrlv1}/bulkorders/${id}/ReceivedStatusFiles?type=${Type}`
  return Api.fetch(url, getGetParams(), 'text');
}

function uploadReceivedStatusFilesApi(id:string, dispenseType:string, payload: any) {
  const formUpload = true;
  const url = dispenseType === 'prescription' 
    ? `${baseUrlv1}/prescriptions/${id}/uploadReceivedStatusFiles`
    : `${baseUrlv1}/bulkorders/${id}/uploadReceivedStatusFiles`
  return Api.fetch(url, getPostParams(payload, formUpload));
}

function fetchConsignmentDetailsApi(prescriptionId:string) {
  const url = `${baseUrlv1}/shipping/consignment/${prescriptionId}`;
  return Api.fetch(url, getGetParams());
}

function fetchUsersToApproveApi(Type:string, id: string) {
  const url = Type === 'prescription' 
    ? `${baseUrlv1}/prescriptions/${id}/dispensed/getUsersToApprove`
    : `${baseUrlv1}/bulkorders/${id}/dispensed/getUsersToApprove`
  return Api.fetch(url, getGetParams());
}

function fetchOrderListReportApi(payload:any) {
  const url = `${baseUrlv1}/bulkorders/sponsor/orderlistbystatus`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchAllOrderListReportApi(payload:any) {
  const url = `${baseUrlv1}/bulkorders/sponsor/downloadorderlist`;
  return Api.fetch(url, getPostParams(payload));
}

// function fetchAvailableRoutesApi(payload:any) {
//   const params = Object.keys(payload).map(keys => `${keys}=${payload[keys]}`);
//   const url = `${baseUrlv1}/shipping?${params.join('&')}`;
//   return Api.fetch(url, getGetParams());
// }


export function* fetchPrescriptionList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append, Disabled } = payload;
    const response = yield call(fetchPrescriptionListApi, payload);
    if(!response.error) {
      const pagedData = [...response.pagedData].map(list => {
        list.deletedStatus = Disabled;
        return list;
      });
      const _response = {
        ...response,
        pagedData
      }
      yield put(fetchSuccess(true));
      yield put(fetchPrescriptionListSucceeded(_response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchOrderList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append, Disabled } = payload;
    const response = yield call(fetchOrderListApi, payload);
    if(!response.error) {
      const pagedData = [...response.pagedData].map(list => {
        list.deletedStatus = Disabled;
        return list;
      });
      const _response = {
        ...response,
        pagedData
      }
      yield put(fetchSuccess(true));
      yield put(fetchOrderListSucceeded(_response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetch_KVP_SitesByPharmacyId() {
  yield put(fetchStart());
  try {
    const response  = yield call(fetch_KVP_SitesByPharmacyIdApi);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetch_KVP_SitesByPharmacyIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetch_KVP_SitesByTrialId({trialId}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetch_KVP_SitesByTrialIdApi, trialId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetch_KVP_SitesByTrialIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetch_KVP_TrialForPharmacy() {
  yield put(fetchStart());
  try {
    const response  = yield call(fetch_KVP_TrialForPharmacyApi);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetch_KVP_TrialForPharmacySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteDispense({id, Type, reason}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteDispenseApi, id, Type, reason);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteDispenseSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchPrescriptionsById({prescriptionId}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchPrescriptinByIdApi, prescriptionId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchPrescriptionByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No Prescription found"
      } else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchBulkOrderDispenseById({id}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchBulkOrderDispenseByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchBulkOrderDispenseByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No BulkOrder found"
      } else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInventoryForKitAndBottle({payload}:any) {
  yield put(fetchStart(true));
  try {
    const { bottleIdentifier,inventoryId, treatmentId, append, SearchTerm  } = payload;
    const response = yield call(fetchInventoryForKitAndBottleApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchInventoryForKitAndBottleSucceeded(bottleIdentifier, inventoryId, treatmentId, {...response, searchTerm:SearchTerm}, append));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchBulkOrderRandomisation({id}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchBulkOrderRandomisationApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchBulkOrderRandomisationSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchBulkOrderKitNumbers({id}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchBulkOrderKitNumbersApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchBulkOrderKitNumbersSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitManualAssignToBulkOrder({payload}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(submitManualAssignToBulkOrderdApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitManualAssignToBulkOrderSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchPrescriptionInventoryById({prescriptionId}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchPrescriptinInventoryByIdApi, prescriptionId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchPrescriptionInventoryByIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* markBulkOrderAsDispensed({bulkOrderId, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(markBulkOrderAsDispensedApi, bulkOrderId, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(markBulkOrderAsDispensedSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* markPrescriptionAsDispensed({prescriptionId, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(markPrescriptionAsDispensedApi, prescriptionId, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(markPrescriptionAsDispensedSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* printPrescriptionLabels({prescriptionId, payload}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(printPrescriptionLabelsApi, prescriptionId, payload);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(printPrescriptionLabelsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInventoryofDispensedDrugs({prescriptionId}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchInventoryofDispensedDrugsApi, prescriptionId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchInventoryofDispensedDrugsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* approvePrescription({prescriptionId, payload, specialApproval}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(approvePrescriptionApi, prescriptionId, payload, specialApproval);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(approvePrescriptionSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* rejectPrescription({prescriptionId, payload, specialApproval}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(rejectPrescriptionApi, prescriptionId, payload, specialApproval);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(rejectPrescriptionSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* approveBulkOrder({bulkOrderId, payload, specialApproval}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(approveBulkOrderApi, bulkOrderId, payload, specialApproval);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(approveBulkOrderSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}
export function* rejectBulkOrder({bulkOrderId, payload, specialApproval}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(rejectBulkOrderApi, bulkOrderId, payload, specialApproval);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(rejectBulkOrderSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* fetchShippingDetails({trialId}:any) {
  yield put(fetchStart());
  try {
    let response = yield call(fetchShippingDetailsByIdApi, trialId);
    
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(getShippingDetailsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* markPrescriptionAsReceived({prescriptionId}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(markPrescriptionAsReceivedApi, prescriptionId);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(markPrescriptionAsReceivedSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* markBulkOrderAsReceived({bulkOrderId}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(markBulkOrderAsReceivedApi, bulkOrderId);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(markBulkOrderAsReceivedSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* markPrescriptionAsPacked({prescriptionId, payload}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(markPrescriptionAsPackedApi, prescriptionId, payload);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(markPrescriptionAsPackedSucceeded(response))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}
export function* markBulkOrderAsPacked({bulkOrderId, payload}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(markBulkOrderAsPackedApi, bulkOrderId, payload);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(markBulkOrderAsPackedSucceeded(response))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* downloadReceivedStatusFiles({id, dispenseType, Type}:any) {
  yield put(fetchStart());
  try {
    let response = yield call(downloadReceivedStatusFilesApi, id, dispenseType, Type);
    if(!response.error) {
      yield put(fetchSuccess());
      window.open(response, "_blank");
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* uploadReceivedStatusFiles({id, dispenseType, payload}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(uploadReceivedStatusFilesApi, id, dispenseType, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(uploadReceivedStatusFilesSucceeded(response))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchConsignmentDetails({prescriptionId}:any) {
  yield put(fetchStart());
  try {
    let response = yield call(fetchConsignmentDetailsApi, prescriptionId); 
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchConsignmentDetailsSucceeded(response))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchUsersToApprove({Type, id}:any) {
  yield put(fetchStart());
  try {
    let response = yield call(fetchUsersToApproveApi, Type, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchUsersToApproveSucceeded(response))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchOrderListReport({payload}:any) {
  yield put(fetchStart());
  try {
    const { append } = payload;
    let response = yield call(fetchOrderListReportApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchOrderListReportSucceeded(response, append))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchAllOrderListReport({payload}:any) {
  yield put(fetchStart(true));
  try {
    let response = yield call(fetchAllOrderListReportApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchAllOrderListReportSucceeded(response))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

/* export function* fetchAvailableRoutes({payload}:any) {
  yield put(fetchStart());
  try {
    let response = yield call(fetchAvailableRoutesApi, payload);
    
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchAvailableRoutesSucceeded(response))
    }
    else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
} */

export default [
  takeLatest('FETCH_PRESCRIPTION_LIST' ,fetchPrescriptionList),
  takeLatest('FETCH_ORDER_LIST' ,fetchOrderList),
  takeLatest('FETCH_KVP_SITES_BY_PHARMACY_ID' ,fetch_KVP_SitesByPharmacyId),
  takeLatest('FETCH_KVP_SITES_BY_TRIAL_ID' ,fetch_KVP_SitesByTrialId),
  takeLatest('FETCH_KVP_TRIAL_FOR_PHARMACY' ,fetch_KVP_TrialForPharmacy),
  takeLatest('DELETE_DISPENSE' ,deleteDispense),
  takeLatest('FETCH_PRESCRIPTION_BY_ID', fetchPrescriptionsById),
  takeLatest('FETCH_BULKORDER_DISPENSE_BY_ID', fetchBulkOrderDispenseById),
  takeLatest('FETCH_INVENTORY_FOR_KIT_AND_BOTTLE', fetchInventoryForKitAndBottle),
  takeLatest('FETCH_BULKORDER_RANDOMISATION', fetchBulkOrderRandomisation),
  takeLatest('FETCH_BULKORDER_KIT_NUMBERS', fetchBulkOrderKitNumbers),
  takeLatest('SUBMIT_MANUAL_ASSIGN_TO_BULKORDER', submitManualAssignToBulkOrder),
  takeLatest('FETCH_PRESCRIPTION_INVENTORY_BY_ID', fetchPrescriptionInventoryById),
  takeLatest('MARK_BULKORDER_DISPENSED', markBulkOrderAsDispensed),
  takeLatest('MARK_PRESCRIPTION_DISPENSED', markPrescriptionAsDispensed),
  takeLatest('PRINT_PRESCRIPTION_LABELS', printPrescriptionLabels),
  takeLatest('FETCH_INVENTORY_OF_DISPENSED_DRUGS', fetchInventoryofDispensedDrugs),
  takeLatest('APPROVE_PRESCRIPTION', approvePrescription),
  takeLatest('REJECT_PRESCRIPTION', rejectPrescription),
  takeLatest('APPROVE_BULKORDER', approveBulkOrder),
  takeLatest('REJECT_BULKORDER', rejectBulkOrder),
  takeLatest('GET_SHIPPING_DETAILS', fetchShippingDetails),
  takeLatest('MARK_PRESCRIPTION_PACKED', markPrescriptionAsPacked),
  takeLatest('MARK_BULKORDER_PACKED', markBulkOrderAsPacked),
  takeLatest('MARK_PRESCRIPTION_RECEIVED', markPrescriptionAsReceived),
  takeLatest('MARK_BULKORDER_RECEIVED', markBulkOrderAsReceived),
  takeLatest('DOWNLOAD_RECEIVED_STATUS_FILES', downloadReceivedStatusFiles),
  takeLatest('UPLOAD_RECEIVED_STATUS_FILES', uploadReceivedStatusFiles),
  takeLatest('FETCH_CONSIGNMENT_DETAILS', fetchConsignmentDetails),
  takeLatest('FETCH_USERS_TO_APPROVE', fetchUsersToApprove),
  takeLatest('FETCH_ORDERLIST_REPORT', fetchOrderListReport),
  takeLatest('FETCH_ALL_ORDERLIST_REPORT', fetchAllOrderListReport),
  // takeLatest('FETCH_AVAILABLE_ROUTES', fetchAvailableRoutes)
];
