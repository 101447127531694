import {
  CommonActionTypes,
  FETCH_ERROR,
  FETCH_START,
  FETCH_STOP,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  RESET_PROMISE_COMPLETE,
  FETCH_KVP,
  // FETCH_KVP_SUCCEEDED,
  SET_CRUD,
  RESET_CRUD,
  RESET_ERROR
} from '../../types/actions/Common.action';

export const fetchStart = (backdropLoader:boolean=false): CommonActionTypes => ({
  type: FETCH_START,
  backdropLoader
});

export const fetchStop = (): CommonActionTypes => ({
  type: FETCH_STOP
});

export const fetchSuccess = (promiseComplete:boolean=false): CommonActionTypes => ({
  type: FETCH_SUCCESS,
  promiseComplete
});

export const hideMessage = (): CommonActionTypes => ({type: HIDE_MESSAGE});

export const resetPromiseComplete = (): CommonActionTypes => ({type: RESET_PROMISE_COMPLETE});

export const resetCRUD = (): CommonActionTypes => ({type: RESET_CRUD});

export const resetError = (): CommonActionTypes => ({type: RESET_ERROR});

export const setCRUD = (crudAction:string): CommonActionTypes => ({type: SET_CRUD, crudAction});

export const fetchError = (error: string): CommonActionTypes => ({
  type: FETCH_ERROR,
  error,
});

export const showMessage = (message: string): CommonActionTypes => ({
  type: SHOW_MESSAGE,
  message,
});

export const onToggleAppDrawer = (): CommonActionTypes => ({
  type: TOGGLE_APP_DRAWER,
});

export const fetchKVP = (typeofKVP:string, searchValue:string, component:string): CommonActionTypes => ({
  type: FETCH_KVP,
  typeofKVP,
  searchValue,
  component
});

export const fetchKVPSucceeded = (payload:any, typeofKVP:string, component:string):any => ({
  type: `FETCH_KVP_FROM_${component.toUpperCase()}_SUCCEEDED`,
  payload,
  typeofKVP,
  component
})