/* eslint-disable react-hooks/exhaustive-deps */
import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams } from "../../@crema/utility/Utils";
import { rejectQAApi, approveQAApi} from "./Trials";
import { setCRUD } from "../actions/Common"
import { 
  fetchPendingRequestListSucceeded, 
  fetchRequestHistoryListSucceeded, 
  fetchRequestByIdSucceeded,
  approveQARequestSucceeded,
  rejectQARequestSucceeded,
  fetchInventoryFromQASucceeded
} from "redux/actions/Notifications";

const baseUrlv1 = `api/v1`;

function fetchPendingRequestsApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, FilterBy  } = payload;
  const url = `${baseUrlv1}/qa?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}&FilterBy=${FilterBy}`;
  return Api.fetch(url, getGetParams());
}

function fetchRequestHistoryApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload;
  const url = `${baseUrlv1}/qa/history?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchRequestByIdApi(id:string) {
  const url = `${baseUrlv1}/qa/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchInventoryFromQAApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, id  } = payload;
  const url = `${baseUrlv1}/qa/${id}/inventoryqa?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

export function* fetchPendingRequestList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append } = payload;
    const response  = yield call(fetchPendingRequestsApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchPendingRequestListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchRequestHistoryList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append } = payload;
    const response  = yield call(fetchRequestHistoryApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchRequestHistoryListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchRequestById({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchRequestByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchRequestByIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* approveQARequest({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(approveQAApi, id);
    if(!response.error && response.approved !== null) {
      yield put(setCRUD('approve'));
      yield put(fetchSuccess(true));
      yield put(approveQARequestSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* rejectQARequest({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(rejectQAApi, payload);
    if(!response.error && response.approved !== null) {
      yield put(setCRUD('reject'));
      yield put(fetchSuccess(true));
      yield put(rejectQARequestSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInventoryFromQA({payload}:any) {
  yield put(fetchStart(true));
  try {
    const { append } = payload;
    const response  = yield call(fetchInventoryFromQAApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchInventoryFromQASucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_PENDING_REQUESTS' ,fetchPendingRequestList),
  takeLatest('FETCH_REQUEST_HISTORY' ,fetchRequestHistoryList),
  takeLatest('FETCH_REQUEST_BY_ID', fetchRequestById),
  takeLatest('APPROVE_QA_REQUEST', approveQARequest),
  takeLatest('REJECT_QA_REQUEST', rejectQARequest),
  takeLatest('FETCH_INVENTORY_FROM_QA', fetchInventoryFromQA),
]
