import React from 'react';

export const dashboardRouteConfig = [
  {
    // auth: ['Admin'],
    auth: ["Admin", "Site", "Sponsor", "Sponsor Representative"],
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() => import('./Dashboard')),
      }
    ],
  },
];
