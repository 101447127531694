import {AuthUser} from '../../types/models/AuthUser';

import {
  SIGNOUT_AUTH0_SUCCESS,
  UPDATE_AUTH0_USER,
  AuthActions
} from '../../types/actions/Auth.actions';

export const onSignOutAuth0User = () => ({
  type: SIGNOUT_AUTH0_SUCCESS
});

export const updateAuth0User = (payload:any) => ({
  type: UPDATE_AUTH0_USER,
  payload
});

export const setCurrentUser = (user: AuthUser | null): AuthActions => ({
  type: UPDATE_AUTH0_USER,
  payload: user,
});
