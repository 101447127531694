import { ConsultationList, Payload } from "../../types/models/Consultations";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_CONSULTATION_LIST,
  FETCH_CONSULTATION_BY_ID,
  SUBMIT_CONSULTATION,
  UPDATE_CONSULTATION,
  DELETE_CONSULTATION,
  FETCH_CONSULTATION_LIST_SUCCEEDED,
  FETCH_CONSULTATION_BY_ID_SUCCEEDED,
  SUBMIT_CONSULTATION_SUCCEEDED,
  UPDATE_CONSULTATION_SUCCEEDED,
  DELETE_CONSULTATION_SUCCEEDED,
  SET_SELECTED_CONSULTATION,
  REMOVE_SELECTED_CONSULTATION,
  RESET_CONSULTATION,
  FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID,
  FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID_SUCCEEDED,
  UPDATE_CONSULTATION_STOCK_STATUS,
  UPDATE_CONSULTATION_STOCK_STATUS_SUCCEEDED,
  FOLLOW_UP_AGAIN,
  FOLLOW_UP_AGAIN_SUCCEEDED,
  MARK_AS_DONE,
  MARK_AS_DONE_SUCCEEDED,
  ConsultationActionsTypes
} from "../../types/actions/Consultations.action";

export const fetchConsultationList = (payload: GetQueryParams) => ({
  type: FETCH_CONSULTATION_LIST,
  payload
});

export const submitConsultation = (payload:ConsultationList):ConsultationActionsTypes => ({
  type: SUBMIT_CONSULTATION,
  payload
});

export const updateConsultation = (id: string, payload:ConsultationList):ConsultationActionsTypes => ({
  type: UPDATE_CONSULTATION,
  id,
  payload
});

export const deleteConsultation = (id: string):ConsultationActionsTypes => ({
  type: DELETE_CONSULTATION,
  id
});

export const fetchConsultationById = (id: string, counsellingId: string):ConsultationActionsTypes => ({
  type: FETCH_CONSULTATION_BY_ID,
  id,
  counsellingId
});

export const setSelectedConsultation = (id: string) => ({
  type: SET_SELECTED_CONSULTATION,
  id
});

export const removeSelectedConsultation = () => ({
  type: REMOVE_SELECTED_CONSULTATION
});

export const resetConsultation = (): ConsultationActionsTypes => ({
  type: RESET_CONSULTATION
});

export const fetchConsultationListSucceeded = (payload:Payload, append:boolean): ConsultationActionsTypes=> ({
  type: FETCH_CONSULTATION_LIST_SUCCEEDED,
  payload,
  append
});

export const submitConsultationSucceeded = (payload:ConsultationList):ConsultationActionsTypes => ({
  type: SUBMIT_CONSULTATION_SUCCEEDED,
  payload
});

export const updateConsultationSucceeded = (id: string, payload:ConsultationList):ConsultationActionsTypes => ({
  type: UPDATE_CONSULTATION_SUCCEEDED,
  id,
  payload
});

export const deleteConsultationSucceeded = (id: string):ConsultationActionsTypes => ({
  type: DELETE_CONSULTATION_SUCCEEDED,
  id
});

export const fetchConsultationByIdSucceeded = (payload:ConsultationList):ConsultationActionsTypes => ({
  type: FETCH_CONSULTATION_BY_ID_SUCCEEDED,
  payload
});

// Fetch consultation details
export const fetchConsultationByIdAndPrescriptionId = (counsellingId: string, prescriptionId:string ):ConsultationActionsTypes => ({
  type: FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID,
  counsellingId: counsellingId,
  prescriptionId: prescriptionId
});

export const fetchConsultationByIdAndPrescriptionIdSucceeded = (payload:any):ConsultationActionsTypes => ({
  type: FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID_SUCCEEDED,
  payload
});

// Update Consultation Stock Received Status
export const updateConsultationStockStatus = (prescriptionId:string, status:boolean ):ConsultationActionsTypes => ({
  type: UPDATE_CONSULTATION_STOCK_STATUS,
  prescriptionId,
  status,
});

export const updateConsultationStockStatusSucceeded = (payload:any):ConsultationActionsTypes => ({
  type: UPDATE_CONSULTATION_STOCK_STATUS_SUCCEEDED,
  payload
});


// Update Consultation Follow Up Again Status
export const followUpAgainConsultation = (prescriptionId:string, counsellingId: string, payload: any):ConsultationActionsTypes => ({
  type: FOLLOW_UP_AGAIN,
  prescriptionId,
  counsellingId,
  payload
});

export const followUpAgainConsultationSucceeded = (payload:any):ConsultationActionsTypes => ({
  type: FOLLOW_UP_AGAIN_SUCCEEDED,
  payload
});

// Mark as done Consultation Mark as done
export const markConsultationAsDone = (prescriptionId:string, counsellingId: string):ConsultationActionsTypes => ({
  type: MARK_AS_DONE,
  prescriptionId,
  counsellingId
});

export const markConsultationAsDoneSucceeded = (payload:any):ConsultationActionsTypes => ({
  type: MARK_AS_DONE_SUCCEEDED,
  payload
});
