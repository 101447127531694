/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Hidden from '@material-ui/core/Hidden';
import HorizontalNav from '../../Navigation/HorizontalNav';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './AppHeader.style';

import MoreIcon from '@material-ui/icons/MoreVert';
import UserInfo from '../UserInfo';
import AppLogo from '../../../../shared/components/AppLogo';
import routesConfig from '../../../../modules/routesConfig';
import routes from "../../../../modules";
import { fetchPendingRequestList } from '../../../../redux/actions/Notifications';

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const authUser = localStorage.getItem('auth-user');
  // const roles = authUser ? JSON.parse(authUser).roles || [] : [];

  /* const filterRoutesConfig = routesConfig.filter((config:any) => {
    const route = routes.find((route):any => route.path === config.url);

    if(route) {
      return route.auth.includes(roles[0]);
      // if(roles.includes('Admin')) {
      //   return config;
      // } else {
      //   return route.auth.includes(roles[0]);
      // }
    }
    return false;
  }); */

  const [ mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [filterRoutesConfig, setFilterRoutesConfig] = useState([]);

  const role = useSelector((state:any) => state.auth.user?.roles[0]);
  // const currentUser = useSelector((state:any) => state.users.currentUser);
  // const isWarehouseUser = useSelector((state:any) => state.users.currentUser?.appMetadata?.isWharehouse);
  const qaPendingTotal= useSelector((state:any) => state.notifications.badgeCounter);

  useEffect(() => {
    if(role) {
      // const userType = currentUser.appMetadata.userType;
      const filterRoutesConfig:any = routesConfig.filter((config:any) => {
        const route = routes.find((route):any => route.path === config.url);
    
        if(route) {
          if(config.id === "consultations") {
            return !role.includes('Warehouse');
          } else {
            return route.auth.includes(role)
          }
          // userType = 3 //Site  "Principal Investigators", "Sponsor", "Study Coordinators"
          // return route.auth.includes(role) || (userType === 3 && (route.auth.includes('Principal Investigators') || route.auth.includes('Study Coordinators')));
        }
        return false;
      }).map((config):any => {
        if((role.includes('Warehouse') || role.includes('Admin')) && config.id === "dispensing") config.messageId = 'menu.picking'
        return config;
      })

      setFilterRoutesConfig(filterRoutesConfig);
    }
  }, [role]);

  useEffect(() => {
    if(role && filterRoutesConfig.length > 0 && role === 'Quality Associate QA') {
      const payload = {
        PageSize: 50,
        PageNumber: 0,
        SearchTerm: '',
        append: false
      }
      dispatch(fetchPendingRequestList(payload));
    }
  }, [role, filterRoutesConfig])

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      id={mobileMenuId}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 45, horizontal: 'left'}}
      keepMounted
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
      getContentAnchorEl={null}
    >
      {filterRoutesConfig.map((item:any, index:any) => (
         <MenuItem key={index} component={Link} to={item.url} onClick={handleMobileMenuClose}><IntlMessages id={item.messageId} /></MenuItem>
      ))} 
    </Menu>
  );

  return (
    <Box displayPrint="none"> 
      <AppBar position='fixed'>
        <Toolbar className={classes.headerMain}>
          <Box className={classes.headerContainer}>
            <Box className={classes.headerMainFlex}>
              <AppLogo />
              <Hidden mdUp><Box className={classes.grow} /></Hidden>
              <Box className={classes.sectionMobile}>
                <IconButton
                  aria-label='show more'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                >
                  <MoreIcon />
                </IconButton>
              </Box>
              <Hidden smDown>
                <Box className={classes.headerNav}>
                  <Box className={classes.headerContainer}>
                    <HorizontalNav filterRoutesConfig={filterRoutesConfig} qaPendingTotal={qaPendingTotal}/>
                  </Box>
                </Box>
              </Hidden>
              <UserInfo />
            </Box>
          </Box>
        </Toolbar> 
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
};
export default AppHeader;
