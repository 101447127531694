import { takeLatest, put, call } from "redux-saga/effects";import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams } from "../../@crema/utility/Utils";
import { 
  fetchBillingReportsSucceeded,
} from "../actions/Reports";

const baseUrlv1 = `api/v1`;

function fetchBillingReportApi() {
  const url = `${baseUrlv1}/billingReports/availablePeriods`;
  return Api.fetch(url, getGetParams());
}

function fetchReportUriApi(uri: string) {

  const url = `${baseUrlv1}/billingReports/download?reportUri=${uri}`;
  return Api.fetch(url, getGetParams(), 'text');
}

export function* fetchBillingReport() {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchBillingReportApi);
    if(response) {
      yield put(fetchSuccess());
      yield put(fetchBillingReportsSucceeded(response))
    } else {
      yield put(fetchError(response.message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchReportUri({uri}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchReportUriApi,uri);
    if(!response.error) {
      yield put(fetchSuccess());
      window.open(response, "_blank");
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_BILLING_REPORTS' ,fetchBillingReport),
  takeLatest('FETCH_REPORT_URL' ,fetchReportUri)
]