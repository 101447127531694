import { SiteList, Payload } from "../../types/models/Sites";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_SITE_LIST,
  FETCH_SITE_BY_ID,
  SUBMIT_SITE,
  UPDATE_SITE,
  DELETE_SITE,
  FETCH_SITE_LIST_SUCCEEDED,
  FETCH_SITE_BY_ID_SUCCEEDED,
  SUBMIT_SITE_SUCCEEDED,
  UPDATE_SITE_SUCCEEDED,
  DELETE_SITE_SUCCEEDED,
  SET_SELECTED_SITE,
  REMOVE_SELECTED_SITE,
  RESET_SITE,
  RESET_FETCHCOMPLETE,
  SiteActionsTypes
} from "../../types/actions/Sites.action";

export const fetchSiteList = (payload: GetQueryParams) => ({
  type: FETCH_SITE_LIST,
  payload
});

export const submitSite = (payload:SiteList):SiteActionsTypes => ({
  type: SUBMIT_SITE,
  payload
});

export const updateSite = (id: string, payload:SiteList):SiteActionsTypes => ({
  type: UPDATE_SITE,
  id,
  payload
});

export const deleteSite = (id: string):SiteActionsTypes => ({
  type: DELETE_SITE,
  id
});

export const fetchSiteById = (id: string) => ({
  type: FETCH_SITE_BY_ID,
  id
});

export const setSelectedSite = (id: string) => ({
  type: SET_SELECTED_SITE,
  id
});

export const removeSelectedSite = () => ({
  type: REMOVE_SELECTED_SITE
});

export const resetSite = (): SiteActionsTypes => ({
  type: RESET_SITE
});

export const fetchSiteListSucceeded = (payload:Payload, append:boolean): SiteActionsTypes=> ({
  type: FETCH_SITE_LIST_SUCCEEDED,
  payload,
  append
});

export const submitSiteSucceeded = (payload:SiteList):SiteActionsTypes => ({
  type: SUBMIT_SITE_SUCCEEDED,
  payload
});

export const updateSiteSucceeded = (id: string, payload:SiteList):SiteActionsTypes => ({
  type: UPDATE_SITE_SUCCEEDED,
  id,
  payload
});

export const deleteSiteSucceeded = (id: string):SiteActionsTypes => ({
  type: DELETE_SITE_SUCCEEDED,
  id
});

export const fetchSiteByIdSucceeded = (payload:SiteList):SiteActionsTypes => ({
  type: FETCH_SITE_BY_ID_SUCCEEDED,
  payload
});

export const resetFetchComplete = ():SiteActionsTypes => ({
  type: RESET_FETCHCOMPLETE
});