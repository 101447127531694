import { CourierList, Payload } from "../models/Couriers";
import { GetQueryParams } from "../models/Common";

export const FETCH_COURIER_LIST = "FETCH_COURIER_LIST";
export const SUBMIT_COURIER = "SUBMIT_COURIER";
export const UPDATE_COURIER = "UPDATE_COURIER";
export const DELETE_COURIER = "DELETE_COURIER";
export const FETCH_COURIER_BY_ID = "FETCH_COURIER_BY_ID";
export const SET_SELECTED_COURIER = "SET_SELECTED_COURIER"
export const REMOVE_SELECTED_COURIER = "REMOVE_SELECTED_COURIER"
export const RESET_COURIER = "RESET_COURIER";
export const FETCH_COURIER_LIST_SUCCEEDED = "FETCH_COURIER_LIST_SUCCEEDED";
export const FETCH_COURIER_BY_ID_SUCCEEDED = "FETCH_COURIER_BY_ID_SUCCEEDED";
export const SUBMIT_COURIER_SUCCEEDED = "SUBMIT_COURIER_SUCCEEDED";
export const UPDATE_COURIER_SUCCEEDED = "UPDATE_COURIER_SUCCEEDED";
export const DELETE_COURIER_SUCCEEDED = "DELETE_COURIER_SUCCEEDED";
export const FETCH_PHARMACY_ADDRESS = "FETCH_PHARMACY_ADDRESS";
export const FETCH_PHARMACY_ADDRESS_SUCCEEDED = "FETCH_PHARMACY_ADDRESS_SUCCEEDED";
export const FETCH_AVAILABLE_ROUTES = "FETCH_AVAILABLE_ROUTES";
export const FETCH_AVAILABLE_ROUTES_SUCCEEDED = "FETCH_AVAILABLE_ROUTES_SUCCEEDED";
export const FETCH_COURIER_KVP_LIST = "FETCH_COURIER_KVP_LIST";
export const FETCH_COURIER_KVP_LIST_SUCCEEDED = "FETCH_COURIER_KVP_LIST_SUCCEEDED";
export const CREATE_API_CONSIGNMENT = "CREATE_API_CONSIGNMENT";
export const CREATE_API_CONSIGNMENT_SUCCEEDED = "CREATE_API_CONSIGNMENT_SUCCEEDED";
export const SAVE_MANUAL_CONSIGNMENT = "SAVE_MANUAL_CONSIGNMENT";
export const SAVE_MANUAL_CONSIGNMENT_SUCCEEDED = "SAVE_MANUAL_CONSIGNMENT_SUCCEEDED";
export const CONSIGNMENT_COMPLETED = "CONSIGNMENT_COMPLETED";
export const RESET_MANUAL_CONSIGNMENT = "RESET_MANUAL_CONSIGNMENT";
export interface FetchCourierListActions {
  type: typeof FETCH_COURIER_LIST;
  payload: GetQueryParams;
}

export interface FetchCourierListSucceededActions {
  type: typeof FETCH_COURIER_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchCourierByIdActions {
  type: typeof FETCH_COURIER_BY_ID;
  id: string;
}

export interface SetSelectedCourierActions {
  type: typeof SET_SELECTED_COURIER;
  id: string
}


export interface FetchCourierByIdSucceededActions {
  type: typeof FETCH_COURIER_BY_ID_SUCCEEDED;
  id: string;
}

export interface RemoveSelectedCourierActions {
  type: typeof REMOVE_SELECTED_COURIER;
}

export interface ResetCourierActions {
  type: typeof RESET_COURIER;
}

export interface SubmitCourierActions {
  type: typeof SUBMIT_COURIER;
  payload: CourierList;
}

export interface SubmitCourierSucceededActions {
  type: typeof SUBMIT_COURIER_SUCCEEDED;
  payload: CourierList;
}

export interface UpdateCourierActions {
  type: typeof UPDATE_COURIER;
  id: string;
  payload: CourierList;
}

export interface UpdateCourierSucceededActions {
  type: typeof UPDATE_COURIER_SUCCEEDED;
  id: string;
  payload: CourierList;
}

export interface DeleteCourierActions {
  type: typeof DELETE_COURIER;
  id: string;
}

export interface DeleteCourierSuceededActions {
  type: typeof DELETE_COURIER_SUCCEEDED;
  id: string;
}

export interface FetchPharmacyAddressActions {
  type: typeof FETCH_PHARMACY_ADDRESS;
  payload: GetQueryParams;
}

export interface FetchPharmacyAddressSucceededActions {
  type: typeof FETCH_PHARMACY_ADDRESS_SUCCEEDED;
  payload: any;
}


export interface FetchAvailableRoutesActions {
  type: typeof FETCH_AVAILABLE_ROUTES;
  payload: any;
}

export interface FetchAvailableRoutesSucceededActions {
  type: typeof FETCH_AVAILABLE_ROUTES_SUCCEEDED;
  payload: any;
}

export interface FetchCourierKvpListActions {
  type: typeof FETCH_COURIER_KVP_LIST;
  payload: GetQueryParams;
}

export interface FetchCourierKvpListSucceededActions {
  type: typeof FETCH_COURIER_KVP_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface CreateApiConsignmentActions {
  type: typeof CREATE_API_CONSIGNMENT;
  payload: any;
}

export interface CreateApiConsignmentSucceededActions {
  type: typeof CREATE_API_CONSIGNMENT_SUCCEEDED;
  payload: any;
}

export interface SaveManualConsignmentActions {
  type: typeof SAVE_MANUAL_CONSIGNMENT;
  payload: any;
}

export interface SaveManualConsignmentSucceededActions {
  type: typeof SAVE_MANUAL_CONSIGNMENT_SUCCEEDED;
  payload: any;
}

export interface ConsignmentCompletedActions {
  type: typeof CONSIGNMENT_COMPLETED
}

export interface ResetManualConsignmentActions{
  type: typeof RESET_MANUAL_CONSIGNMENT
}

export type CourierActionsTypes =
  | FetchCourierListActions
  | SubmitCourierActions
  | UpdateCourierActions
  | DeleteCourierActions
  | FetchCourierByIdActions
  | SetSelectedCourierActions
  | RemoveSelectedCourierActions
  | ResetCourierActions
  | FetchCourierListSucceededActions
  | FetchCourierByIdSucceededActions
  | SubmitCourierSucceededActions
  | UpdateCourierSucceededActions
  | DeleteCourierSuceededActions
  | FetchPharmacyAddressActions
  | FetchPharmacyAddressSucceededActions
  | FetchAvailableRoutesActions
  | FetchAvailableRoutesSucceededActions
  | FetchCourierKvpListActions
  | FetchCourierKvpListSucceededActions
  | CreateApiConsignmentActions
  | CreateApiConsignmentSucceededActions
  | SaveManualConsignmentActions
  | SaveManualConsignmentSucceededActions
  | ConsignmentCompletedActions
  | ResetManualConsignmentActions
  ;