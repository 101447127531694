import React from 'react';

export const consultationRouteConfig = [
  {
    auth: ["Admin excluded as pharmacy only", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/counselling',
        exact: true,
        component: React.lazy(() => import('./Consultations')),
      },
      {
        path: '/counselling/:prescriptionId/:counsellingId',
        exact: true,
        component:  React.lazy(() => import('./ConsultationDetails'))
      }
    ],
  },
];

