export const FETCH_BILLING_REPORTS = "FETCH_BILLING_REPORTS";
export const FETCH_BILLING_REPORTS_SUCCEEDED = "FETCH_BILLING_REPORTS_SUCCEEDED";
export const FETCH_REPORT_URL = "FETCH_REPORT_URL";
export interface FetchBillingReportsActions {
  type: typeof FETCH_BILLING_REPORTS
}
export interface FetchBillingReportsSucceededActions {
  type: typeof FETCH_BILLING_REPORTS_SUCCEEDED,
  payload: any;
}

export interface FetchReportUrlActions {
  type: typeof FETCH_REPORT_URL;
  uri: string;
}

export type ReportActionTypes = 
  | FetchBillingReportsActions
  | FetchBillingReportsSucceededActions
  | FetchReportUrlActions
  ;