import { ConsultationList} from "../../types/models/Consultations";
import { 
  FETCH_CONSULTATION_LIST_SUCCEEDED,
  SUBMIT_CONSULTATION_SUCCEEDED,
  REMOVE_SELECTED_CONSULTATION,
  UPDATE_CONSULTATION_SUCCEEDED,
  SET_SELECTED_CONSULTATION,
  DELETE_CONSULTATION_SUCCEEDED,
  FETCH_CONSULTATION_BY_ID_SUCCEEDED,
  FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID_SUCCEEDED,
  UPDATE_CONSULTATION_STOCK_STATUS_SUCCEEDED,
  RESET_CONSULTATION,
  ConsultationActionsTypes,
} from "../../types/actions/Consultations.action";

const initialState: ConsultationList = {
  consultationList: [],
  selectedConsultation: {},
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  stockRecieved: null,
}

export default (state = initialState, action: ConsultationActionsTypes): ConsultationList => {
  switch (action.type) {
    case FETCH_CONSULTATION_LIST_SUCCEEDED: {
      const {append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const consultationList = append ? [...state.consultationList, ...lists] : lists;

      return {
        ...state,
        ...rest,
        consultationList
      }
    }
    case SUBMIT_CONSULTATION_SUCCEEDED: {
      const { payload } = action;
      const consultationList: any = [...state.consultationList, payload]
      const totalResults:number = state.totalResults + 1;
      return {
        ...state,
        consultationList,
        totalResults
      }
    }
    case UPDATE_CONSULTATION_SUCCEEDED: {
      const {id, payload } = action;
      const consultationList = [...state.consultationList].map(list => {
        if(list.id === id) {
          list = {...list, ...payload}
        }
        return list
      })
      return {
        ...state,
        consultationList,
        selectedConsultation: payload
      }
    }
    case DELETE_CONSULTATION_SUCCEEDED: {
      const { id } = action;
      const consultationList = [...state.consultationList].filter(list => list.id !== id);
      const totalResults:number = state.totalResults - 1;
      return {
        ...state,
        consultationList,
        totalResults
      }
    }
    case FETCH_CONSULTATION_BY_ID_SUCCEEDED: {
      const selectedConsultation = action.payload;
      return {
        ...state,
        selectedConsultation
      }
    }
    case FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID_SUCCEEDED: {
      const selectedConsultation = {...state.selectedConsultation, ...action.payload};
      return {
        ...state,
        selectedConsultation
      }
    }
    case UPDATE_CONSULTATION_STOCK_STATUS_SUCCEEDED: {
      const {stockReceived} = action.payload;

      return {
        ...state,
        selectedConsultation: {
          ...state.selectedConsultation,
          stockReceived
        }
      }
    }
    case SET_SELECTED_CONSULTATION: {
      const { id } =  action;
      const selectedConsultation = [...state.consultationList].find((list:any) => list.id === id) || null;
      return {
        ...state,
        selectedConsultation
      }
    }
    case REMOVE_SELECTED_CONSULTATION: {
      return {
        ...state,
        selectedConsultation: null
      }
    }
    case RESET_CONSULTATION: {
      return {
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}