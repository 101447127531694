import { 
  SUBMIT_COMMENT,
  SUBMIT_COMMENT_SUCCEEDED,
  FETCH_COMMENTS_LIST,
  FETCH_COMMENTS_LIST_SUCCEEDED,
  CommentActionsTypes
} from "../../types/actions/Comment.action";

// Add Comment in the Prescription
export const addCommentToConsultation = (prescriptionId:string, payload:any):CommentActionsTypes => ({
  type: SUBMIT_COMMENT,
  prescriptionId,
  payload
});

export const addCommentToConsultationSucceeded = (payload:any):CommentActionsTypes => ({
  type: SUBMIT_COMMENT_SUCCEEDED,
  payload
});

// List Comments in the Prescription
export const listPrescriptionComments= (prescriptionId:string, payload:any):CommentActionsTypes => ({
  type: FETCH_COMMENTS_LIST,
  prescriptionId,
  payload
});

export const listPrescriptionCommentsSucceeded = (payload:any, append:boolean):CommentActionsTypes => ({
  type: FETCH_COMMENTS_LIST_SUCCEEDED,
  payload,
  append
});