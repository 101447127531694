import {useMediaQuery, useTheme} from "@material-ui/core";
import authorization from "../../modules/authorization.json";

let token: string;
let baseUrl:string;
let role:string;
const auth = localStorage.getItem('auth-user');
role = auth ? JSON.parse(auth)?.roles[0] : '';
token = auth ? JSON.parse(auth)?.token : '';

export const loadToken = (_token:string) => {
  token = _token;
}

export const loadBaseUrl = (url:string) => {
  baseUrl = url;
};

export const loadRole = (_role: string) => {
  role = _role;
}

// export const regexNumber = /^[0-9]+$|^$|^\s$/;
export const regexNumber = /^(\d)*(\.)?([0-9]{0,2})?$/;
export const regexOnlyNumber = /^(\d)*$/;

export const useBreakPointDown = (key: "xs" | "sm" | "md" | "lg" | "xl") => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

export const createRoutes = (routeConfigs: any[]) => {
  let allRoutes: any[] = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });

  allRoutes = allRoutes.filter((route:any) => {
    if(route.auth) {
      // const auth = localStorage.getItem('auth-user');
      // console.log(auth)
      const valid = route.auth.some((ROLE:string) => ROLE === role);
      return valid;
    } else {
      return true;
    }
  }) 
  // console.log(allRoutes)
  return allRoutes;
};

export const setRoutes = (config: any) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      // let auth = route.auth
      //   ? [...new Set([...config.auth, ...route.auth])]
      //   : [...config.auth];
      let auth = route.auth ? [...route.auth] : [...config.auth]; 
      return {...route, auth};
    });
  }

  return [...routes];
};
export const getBreakPointsValue = (valueSet: any, breakpoint: string) => {
  if (typeof valueSet === "number") return valueSet;
  switch (breakpoint) {
    case "xs":
      return valueSet.xs;
    case "sm":
      return valueSet.sm || valueSet.xs;
    case "md":
      return valueSet.md || valueSet.sm || valueSet.xs;
    case "lg":
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getGetParams = () => ({
  method: "GET",
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
});

export const getPostParams = (payload: any, formUpload = false) => {
  let headers:any = {};
  if(!formUpload) {
    headers["Content-Type"] = 'application/json'
  }
  return {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    },
    body: formUpload ? payload : JSON.stringify(payload)
  }
};

export const getDeleteParams = () => ({
  method: "DELETE",
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
});

export const getPutParams = (payload:any, formUpload = false) => {
  let headers:any = {};
  if(!formUpload) {
    headers["Content-Type"] = 'application/json'
  }
  return {
    method: "PUT",
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    },
    body: formUpload ? payload : JSON.stringify(payload)
  };
};

export const Api = {
  fetch: (url:string, params:any, responseType: string = "json")=> {
    if (!url) {
      throw new Error("Uh-oh, url not found");
    }
    return fetch(`${baseUrl}${url}`, params)
      .then((response:any) => handleErrors(response, responseType))
      .catch(handleCatch);
  },
  fetchWithoutBase: (url:string, params:any, responseType: string = "json")=> {
    if (!url) {
      throw new Error("Uh-oh, url not found");
    }
    return fetch(`${url}`, params)
      .then((response:any) => handleErrors(response, responseType))
      .catch(handleCatch);
  }
};

const handleCatch = (err:any) => {
  if (err.toString().indexOf("TypeError") > -1) {
    return {
      error: {
        code: "",
        message: err.toString()
      },
      status: "",
      statusText: ""
    };
  }
  return err;
};

export function handleErrors(response:any, responseType: string) {
  if(response.status === 422) {
    return response.text().then((text:any) => {
      if (!response.ok) {
        const error = {
          error: {
            code: response.status,
            message: text
          },
          status: response.status,
          statusText: response.statusText
        };
        return Promise.reject(error);
      }
      return text;
    })
  }
  // handle for guest user and propmt for login
  if (response.status === 401) {
    // const url = `${window.location.origin}/login`;
    // window.location = url;
    // const noError = {};
    const error = {
      error: {
        code: response.status,
        message: "You are not Authorized to view this page."
      },
      status: response.status,
      statusText: response.statusText
    };
    return Promise.reject(error);
  }
  /* if (response.status === 400) { // Bad Request
    const error = {
      error: {
        code: response.status,
        message: response.statusText
      },
      status: response.status,
      statusText: response.statusText
    };
    return Promise.reject(error);
  } 
  if (response.status === 403) { // Forbidden
    const error = {
      error: {
        code: response.status,
        message: response.statusText
      },
      status: response.status,
      statusText: response.statusText
    };
    return Promise.reject(error);
  }
  if (response.status === 404) { // Not Found
    const error = {
      error: {
        code: response.status,
        message: response.statusText
      },
      status: response.status,
      statusText: response.statusText
    };
    return Promise.reject(error);
  }*/
  
  if (response.status >= 500) {
    const error = {
      error: {
        code: response.status,
        message: "Network error"
      },
      status: response.status,
      statusText: response.statusText
    };
    return Promise.reject(error);
  }

  if(!response.ok) {
    const error = {
      error: {
        code: response.status,
        message: response.statusText
      },
      status: response.status,
      statusText: response.statusText
    };
    return Promise.reject(error);
  }

  if(responseType === "text") {
    return response.text().then((text:any) => {
      if (!response.ok) {
        const error = {
          error: {
            code: response.status,
            message: text
          },
          status: response.status,
          statusText: response.statusText
        };
        return Promise.reject(error);
      }
      return text;
    })
  }
  // normal response handling
  return response.json().then((json:any) => {
    if (!response.ok) {
      // const error = Object.assign({}, json, {
      //   status: response.status,
      //   statusText: response.statusText
      // });

      let _message = response.statusText 

      if(json) {
        _message = Object.keys(json.errors).reduce((acc:any, curr:any) => {
          acc.push(`${curr} : ${json.errors[curr].join(' ')}`)
          return acc;
        }, []);
      }

      const error = {
        error: {
          code: response.status,
          message: _message.join("\n")
        },
        status: response.status,
        statusText: response.statusText
      }
      return Promise.reject(error);
    }
    return json;
  });
}

export function AddScript(options:any) {
  const existingScript = document.getElementById(options.id);

  if(!existingScript) {
    const script = window.document.createElement("script");
    script.src = options.url;
    script.id = options.id;
    if(options.async) script.async = options.async;
    if(options.defer) script.defer = options.defer;
    window.document.head.appendChild(script);
  }
}

export function trim(obj:any) {
  let _obj:any = {}
  for (let [key, value] of Object.entries(obj)) {
    _obj[key] = typeof value === 'string' ? value.replace(/\s+/g, " ").trim() : value;
  } 
  return _obj;
}

export function omitEmpty(obj:any) {
  let _obj:any = {}
  for (let [key, value] of Object.entries(obj)) {
    if(value) {
      _obj[key] = value
    }
  } 
  return _obj;
}

export function getEnumValues(enumValue: any) {
  return Object.keys(enumValue)
    .filter(key => Number.isNaN(Number(key)))
    .reduce((acc:any, curr:any) => {
      acc.push(curr)
      return acc;
    }, [])
}

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: any
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

// query can be a string or a RegExp object
export function localStorageByQuery (query:any) {
  let i, results = [];
  for (i in localStorage) {
    if (localStorage.hasOwnProperty(i)) {
      if (i.match(query) || (!query && typeof i === 'string')) {
        //@ts-ignore
        const value = JSON.parse(localStorage.getItem(i));
        results.push({ key:i, value });
      }
    }
  }
  return results;
}

export function sessionStorageByQuery (query:any) {
  let i, results = [];
  for (i in sessionStorage) {
    if (sessionStorage.hasOwnProperty(i)) {
      if (i.match(query) || (!query && typeof i === 'string')) {
        //@ts-ignore
        const value = JSON.parse(sessionStorage.getItem(i));
        results.push({ key:i, value });
      }
    }
  }
  return results;
}

export function roleAuthorization(module:string, action:string) {
   //@ts-ignore
  return authorization[module][action].indexOf(role) > -1;

  /* if(role === 'Admin') {
    return true
  } else {
    //@ts-ignore
    return authorization[module][action].indexOf(role) > -1;
  } */
}

export function dateFormat(date: string, format: string = 'DD MMM YYYY') {
  const _format = format.toLowerCase();
  // const splitter = _format.match(/\/ | -/g);
  const newDate = date.toLowerCase() === 'today' ? new Date(): new Date(date); 
  const day = ('0' + newDate.getDate()).slice(-2);
  const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
  const year = newDate.getFullYear().toString();

  return `${_format.replace('yyyy', year).replace('mm', month).replace('dd', day)}`

}

export function parseValue(objectData:any) {
  return Object.keys(objectData).reduce((acc:any,key:string) =>  {
    const _key = key.charAt(0).toLowerCase() + key.slice(1);
    acc[_key] = objectData[key];
    return acc;
  },{});
}

export function isJsonParsable(jsonString:string) {
  try {
    JSON.parse(jsonString);
  } catch (e) {
      return false;
  }
  return true;
}