import Settings from './Setting';
import Common from './Common';
import Auth0 from './Auth';
import Couriers from './Couriers';
import Sites from './Sites';
import Pricing from "./Pricing";
import Sponsors from './Sponsors';
import Users from "./Users";
import Notifications from "./Notifications";
import Trials from "./Trials";
import Patients from './Patients';
import Consultations from './Consultations';
import Reports from "./Reports";
import Comments from './Comments';
import Prescriptions from './Prescriptions';
import Stocktake from "./Stocktake";
import Inventory from "./Inventory";
import ReturnInventory from "./ReturnInventory";
import Dashboard from "./Dashboard";
import Manifest from "./Manifest";
import SiteShipping from "./SiteShipping";

const reducers = {
  settings: Settings,
  auth: Auth0,
  common: Common,
  couriers: Couriers,
  pricing: Pricing,
  sponsors: Sponsors,
  sites: Sites,
  users: Users,
  notifications: Notifications,
  trials: Trials,
  patients: Patients,
  comments: Comments,
  consultations: Consultations,
  reports: Reports,
  prescriptions: Prescriptions,
  stocktake: Stocktake,
  inventory: Inventory,
  returnInventory: ReturnInventory,
  dashboard: Dashboard,
  manifest: Manifest,
  siteShipping: SiteShipping
};

export default reducers;
