import { Notification } from "../../types/models/Notifications";
import { 
  FETCH_PENDING_REQUESTS_SUCCEEDED, 
  FETCH_REQUEST_HISTORY_SUCCEEDED,
  APPROVE_QA_REQUEST_SUCCEEDED,
  REJECT_QA_REQUEST_SUCCEEDED,
  UPDATE_QA_REQUEST_SUCCEEDED,
  FETCH_REQUEST_BY_ID_SUCCEEDED,
  RESET_NOTIFICATION,
  REMOVE_SELECTED_REQUEST,
  FETCH_INVENTORY_FROM_QA_SUCCEEDED,
  NotificationActionsTypes
} from "types/actions/Notifications.action";

const initialState: Notification = {
  pendingRequestList: [],
  requestHistoryList: [],
  inventoryQA: [],
  selectedRequest: null,
  currentPage: 0,
  pageSize: 50,
  totalPages: 0,
  totalResults: 0,
  badgeCounter: 0,
  inventoryQAPagination: {
    currentPage: 0,
    pageSize: 25,
    totalPages: 0,
    totalResults: 0,
  }
}

export default (state = initialState, action: NotificationActionsTypes): Notification => {
  switch (action.type) {
    case FETCH_PENDING_REQUESTS_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData || [];
      const badgeCounter = rest.totalResults;
      const pendingRequestList = append ? [...state.pendingRequestList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        pendingRequestList,
        badgeCounter
      }
    }
    case FETCH_REQUEST_HISTORY_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const requestHistoryList = append ? [...state.requestHistoryList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        requestHistoryList
      }
    }
    case FETCH_REQUEST_BY_ID_SUCCEEDED: {
      const selectedRequest = action.payload
      return {
        ...state,
        selectedRequest
      }
    }
    case APPROVE_QA_REQUEST_SUCCEEDED: {
      const selectedRequest = action.payload;
      const badgeCounter:number = state.badgeCounter - 1;
      const totalResults:number = state.totalResults - 1;
      const pendingRequestList = [...state.pendingRequestList].filter(({id}) => id !== selectedRequest.id).map((o) => {
        if(o.id === selectedRequest.nextActionableQa) {
          o.actionable = true
        }
        return o;
      })
      return {
        ...state,
        selectedRequest,
        pendingRequestList,
        badgeCounter,
        totalResults
      }
    }
    case REJECT_QA_REQUEST_SUCCEEDED: {
      const selectedRequest = action.payload;
      const badgeCounter:number = state.badgeCounter - 1;
      const totalResults:number = state.totalResults - 1;
      const pendingRequestList = [...state.pendingRequestList].filter(({id}) => id !== selectedRequest.id).map((o) => {
        if(o.id === selectedRequest.nextActionableQa) {
          o.actionable = true
        }
        return o;
      })
      return {
        ...state,
        selectedRequest,
        pendingRequestList,
        badgeCounter,
        totalResults
      }
    }
    case FETCH_INVENTORY_FROM_QA_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const inventoryQA = append ? [...state.inventoryQA, ...lists] : lists;
      return {
        ...state,
        inventoryQA,
        inventoryQAPagination: {
          ...rest
        }
      }
    }
    case UPDATE_QA_REQUEST_SUCCEEDED: {
      return {
        ...state,
        
      }
    }
    case REMOVE_SELECTED_REQUEST: {
      return {
        ...state,
        selectedRequest: null,
        inventoryQA: [],
        inventoryQAPagination: {
          currentPage: 0,
          pageSize: 25,
          totalPages: 0,
          totalResults: 0,
        }
      }
    }
    case RESET_NOTIFICATION: {
      const {badgeCounter, ...rest} = initialState;
      return {
        ...state,
        ...rest
      }
    }
    default:{
      return state;
    }
  }
}