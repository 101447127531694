import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { setCRUD } from "../actions/Common"
import { 
  fetchSponsorListSucceeded, 
  submitSponsorSucceeded, 
  updateSponsorSucceeded, 
  deleteSponsorSucceeded, 
  restoreSponsorSucceeded,
  fetchSponsorByIdSucceeded
} from "../actions/Sponsors";

const baseUrlv1 = `api/v1`;

function fetchSponsorListByIdApi(id:string) {
  const url = `${baseUrlv1}/Sponsor/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchSponsorListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, Disabled, Type  } = payload
  const type = Type.map((type:number) => `Type=${type}`);
  const url = `${baseUrlv1}/Sponsor?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}&Disabled=${Disabled}&${type.join('&')}`;
  return Api.fetch(url, getGetParams());
}

function submitSponsorApi (payload:any) {
  const url = `${baseUrlv1}/Sponsor`;
  return Api.fetch(url, getPostParams(payload))
}

function updateSponsorApi(payload:any) {
  const url = `${baseUrlv1}/Sponsor`;
  return Api.fetch(url, getPutParams(payload))
} 

function deleteSponsorApi(id:string) {
  const url = `${baseUrlv1}/Sponsor/${id}`;
  return Api.fetch(url, getDeleteParams(), 'text')
}

function restoreSponsorApi(id:string) {
  const url = `${baseUrlv1}/Sponsor/restore`;
  return Api.fetch(url, getPostParams({id}))
}

export function* fetchSponsorById({id}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchSponsorListByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchSponsorByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No Sponsor found"
      } else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSponsorList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append, Disabled } = payload;
    const response  = yield call(fetchSponsorListApi, payload);
    if(!response.error) {
      const pagedData = [...response.pagedData].map(list => {
        list.deletedStatus = Disabled;
        list.fullAddress = `${list.streetNumber}, ${list.address}, ${list.city}, ${list.postCode} ${list.state}, ${list.country}`;
        return list;
      });
      const _response = {
        ...response,
        pagedData
      }
      yield put(fetchSuccess(true));
      yield put(fetchSponsorListSucceeded(_response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitSponsor({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitSponsorApi, payload);
    if(!response.error) {
      const fullAddress = `${response.streetNumber}, ${response.address}, ${response.city}, ${response.postCode} ${response.state}, ${response.country}`;
      yield put(fetchSuccess(true));
      yield put(submitSponsorSucceeded({...response, fullAddress}))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateSponsor({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateSponsorApi, {...payload, id});
    if(!response.error) {
      const fullAddress = `${response.streetNumber}, ${response.address}, ${response.city}, ${response.postCode} ${response.state}, ${response.country}`;
      yield put(fetchSuccess(true));
      yield put(updateSponsorSucceeded(id, {...payload, fullAddress}))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteSponsor({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deleteSponsorApi, id);
    if(!response.error) {
      yield put(setCRUD('delete'));
      yield put(fetchSuccess(true));
      yield put(deleteSponsorSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* restoreSponsor({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(restoreSponsorApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(restoreSponsorSucceeded(id)) // TODO
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}



export default [
  takeLatest('FETCH_SPONSOR_BY_ID' ,fetchSponsorById),
  takeLatest('FETCH_SPONSOR_LIST' ,fetchSponsorList),
  takeLatest('SUBMIT_SPONSOR' ,submitSponsor),
  takeLatest('UPDATE_SPONSOR' ,updateSponsor),
  takeLatest('DELETE_SPONSOR' ,deleteSponsor),
  takeLatest('RESTORE_SPONSOR' ,restoreSponsor)
]