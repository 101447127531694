import {AppActions} from '../../types';
import {UPDATE_AUTH0_USER, SIGNOUT_AUTH0_SUCCESS} from '../../types/actions/Auth.actions';
import {AuthUser} from '../../types/models/AuthUser';
import { AppState } from 'redux/store';

const INIT_STATE: {user: AuthUser | null, loggedOut: boolean} = {
  user: null,
  loggedOut: false
};

export default (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case UPDATE_AUTH0_USER: {
      // localStorage.setItem('auth-user', JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload,
      };
    }
    case SIGNOUT_AUTH0_SUCCESS: {
      localStorage.removeItem('auth-user');
      return{
        ...state,
        loggedOut: true,
        // user: null
      }
    }
    default:
      return state;
  }
};

export const getToken = (state: AppState) => state.auth.user?.token;

