import { takeLatest, put, call } from "redux-saga/effects";import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams } from "../../@crema/utility/Utils";
import { 
  fetchPricingSucceeded,
  submitPricingSucceeded
} from "../actions/Pricing";

const baseUrlv1 = `api/v1`;

function fetchPricingApi() {
  const url = `${baseUrlv1}/Pricing/default`;
  return Api.fetch(url, getGetParams());
}

function submitPricingApi (payload:any) {
  const url = `${baseUrlv1}/Pricing/default`;
  return Api.fetch(url, getPostParams(payload))
}

export function* fetchPricing() {
  yield put(fetchStart(true));
  try {
    const response = yield call(fetchPricingApi);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchPricingSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitPricing({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitPricingApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitPricingSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_PRICING' ,fetchPricing),
  takeLatest('SUBMIT_PRICING' ,submitPricing),
]