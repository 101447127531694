import { GetQueryParams, KVP } from "../models/Common";
import { DefaultPricing } from "../models/Pricing";
import { 
  Payload,
  TrialList, 
  Drug, 
  Repeats, 
  Consultations,
  TriaEnabled,
  TreatmentLabel, 
  TreatmentArm, 
  TrialLabel, 
  Sites, 
  // TrialUser,
  File,
  PackageOptions,
  Shipping,
  User,
  Stratification,
  TrialSummary,
  TrialDetails
} from "../models/Trials";

export const FETCH_TRIAL_LIST = "FETCH_TRIAL_LIST";
export const SUBMIT_TRIAL = "SUBMIT_TRIAL";
export const UPDATE_TRIAL = "UPDATE_TRIAL";
export const DELETE_TRIAL = "DELETE_TRIAL";
export const FETCH_TRIAL_BY_ID = "FETCH_TRIAL_BY_ID";
export const SET_SELECTED_TRIAL = "SET_SELECTED_TRIAL"
export const REMOVE_SELECTED_TRIAL = "REMOVE_SELECTED_TRIAL"
export const RESET_TRIAL = "RESET_TRIAL";
export const FETCH_TRIAL_LIST_SUCCEEDED = "FETCH_TRIAL_LIST_SUCCEEDED";
export const FETCH_TRIAL_BY_ID_SUCCEEDED = "FETCH_TRIAL_BY_ID_SUCCEEDED";
export const SUBMIT_TRIAL_SUCCEEDED = "SUBMIT_TRIAL_SUCCEEDED";
export const UPDATE_TRIAL_SUCCEEDED = "UPDATE_TRIAL_SUCCEEDED";
export const DELETE_TRIAL_SUCCEEDED = "DELETE_TRIAL_SUCCEEDED";
export const FETCH_KVP_SPONSOR = "FETCH_KVP_SPONSOR";
export const FETCH_KVP_QA = "FETCH_KVP_QA";
export const FETCH_KVP_SPONSOR_SUCCEEDED = "FETCH_KVP_SPONSOR_SUCCEEDED";
export const FETCH_KVP_QA_SUCCEEDED = "FETCH_KVP_QA_SUCCEEDED";
export const FETCH_REPEATS = "FETCH_REPEATS";
export const FETCH_REPEATS_SUCCEEDED = "FETCH_REPEATS_SUCCEEDED";
export const SUBMIT_REPEATS = "SUBMIT_REPEATS";
export const SUBMIT_REPEATS_SUCCEEDED = "SUBMIT_REPEATS_SUCCEEDED";
export const FETCH_CONSULTATIONS = "FETCH_CONSULTATIONS";
export const FETCH_CONSULTATIONS_SUCCEEDED = "FETCH_CONSULTATIONS_SUCCEEDED";
export const SUBMIT_CONSULTATIONS = "SUBMIT_CONSULTATIONS";
export const SUBMIT_CONSULTATIONS_SUCCEEDED = "SUBMIT_CONSULTATIONS_SUCCEEDED";
export const FETCH_RETURNS_INVENTORY = "FETCH_RETURNS_INVENTORY";
export const FETCH_RETURNS_INVENTORY_SUCCEEDED = "FETCH_RETURNS_INVENTORY_SUCCEEDED";
export const SUBMIT_RETURNS_INVENTORY = "SUBMIT_RETURNS_INVENTORY";
export const SUBMIT_RETURNS_INVENTORY_SUCCEEDED = "SUBMIT_RETURNS_INVENTORY_SUCCEEDED";
export const VALIDATE_EMERGENCY_UNBLINDING = "VALIDATE_EMERGENCY_UNBLINDING"
export const VALIDATE_EMERGENCY_UNBLINDING_SUCCEEDED = "VALIDATE_EMERGENCY_UNBLINDING_SUCCEEDED"
export const FETCH_EMERGENCY_UNBLINDING = "FETCH_EMERGENCY_UNBLINDING";
export const FETCH_EMERGENCY_UNBLINDING_SUCCEEDED = "FETCH_EMERGENCY_UNBLINDING_SUCCEEDED";
export const SUBMIT_EMERGENCY_UNBLINDING = "SUBMIT_EMERGENCY_UNBLINDING";
export const SUBMIT_EMERGENCY_UNBLINDING_SUCCEEDED = "SUBMIT_EMERGENCY_UNBLINDING_SUCCEEDED";
export const FETCH_TRIAL_PRICING = "FETCH_TRIAL_PRICING";
export const FETCH_TRIAL_PRICING_SUCCEEDED = "FETCH_TRIAL_PRICING_SUCCEEDED";
export const SUBMIT_TRIAL_PRICING = "SUBMIT_TRIAL_PRICING";
export const SUBMIT_TRIAL_PRICING_SUCCEEDED = "SUBMIT_TRIAL_PRICING_SUCCEEDED";
export const FETCH_TRIAL_SITES = "FETCH_TRIAL_SITES";
export const FETCH_TRIAL_SITES_SUCCEEDED = "FETCH_TRIAL_SITES_SUCCEEDED";
export const SUBMIT_TRIAL_SITES = "SUBMIT_TRIAL_SITES";
export const SUBMIT_TRIAL_SITES_SUCCEEDED = "SUBMIT_TRIAL_SITES_SUCCEEDED";
export const FETCH_TRIAL_SITES_SUMMARY = "FETCH_TRIAL_SITES_SUMMARY"
export const FETCH_TRIAL_SITES_SUMMARY_SUCCEEDED = "FETCH_TRIAL_SITES_SUMMARY_SUCCEEDED"
export const FETCH_KVP_MONITOR = "FETCH_KVP_MONITOR"
export const FETCH_KVP_MONITOR_SUCCEEDED = "FETCH_KVP_MONITOR_SUCCEEDED"
export const FETCH_DRUG_LIST = "FETCH_DRUG_LIST";
export const FETCH_DRUG_LIST_SUCCEEDED = "FETCH_DRUG_LIST_SUCCEEDED";
export const ADD_DRUG = "ADD_DRUG";
export const ADD_DRUG_SUCCEEDED = "ADD_DRUG_SUCCEEDED";
export const UPDATE_DRUG = "UPDATE_DRUG";
export const UPDATE_DRUG_SUCCEEDED = "UPDATE_DRUG_SUCCEEDED";
export const DELETE_DRUG = "DELETE_DRUG";
export const DELETE_DRUG_SUCCEEDED = "DELETE_DRUG_SUCCEEDED";
export const FETCH_TREATMENTLABEL_LIST = "FETCH_TREATMENTLABEL_LIST";
export const FETCH_TREATMENTLABEL_LIST_SUCCEEDED = "FETCH_TREATMENTLABEL_LIST_SUCCEEDED";
export const ADD_TREATMENTLABEL = "ADD_TREATMENTLABEL";
export const ADD_TREATMENTLABEL_SUCCEEDED = "ADD_TREATMENTLABEL_SUCCEEDED";
export const UPDATE_TREATMENTLABEL = "UPDATE_TREATMENTLABEL";
export const UPDATE_TREATMENTLABEL_SUCCEEDED = "UPDATE_TREATMENTLABEL_SUCCEEDED";
export const DELETE_TREATMENTLABEL = "DELETE_TREATMENTLABEL";
export const DELETE_TREATMENTLABEL_SUCCEEDED = "DELETE_TREATMENTLABEL_SUCCEEDED";
export const FETCH_TREATMENTARM_LIST = "FETCH_TREATMENTARM_LIST";
export const FETCH_TREATMENTARM_LIST_SUCCEEDED = "FETCH_TREATMENTARM_LIST_SUCCEEDED";
export const ADD_TREATMENTARM = "ADD_TREATMENTARM";
export const ADD_TREATMENTARM_SUCCEEDED = "ADD_TREATMENTARM_SUCCEEDED";
export const UPDATE_TREATMENTARM = "UPDATE_TREATMENTARM";
export const UPDATE_TREATMENTARM_SUCCEEDED = "UPDATE_TREATMENTARM_SUCCEEDED";
export const DELETE_TREATMENTARM = "DELETE_TREATMENTARM";
export const DELETE_TREATMENTARM_SUCCEEDED = "DELETE_TREATMENTARM_SUCCEEDED";
export const FETCH_TREATMENT_ARM_SUMMARY = "FETCH_TREATMENT_ARM_SUMMARY";
export const FETCH_TREATMENT_ARM_SUMMARY_SUCCEEDED = "FETCH_TREATMENT_ARM_SUMMARY_SUCCEEDED";
export const FETCH_TRIALLABEL = "FETCH_TRIALLABEL";
export const FETCH_TRIALLABEL_SUCCEEDED = "FETCH_TRIALLABEL_SUCCEEDED";
export const ADD_TRIALLABEL = "ADD_TRIALLABEL";
export const ADD_TRIALLABEL_SUCCEEDED = "ADD_TRIALLABEL_SUCCEEDED";
export const FETCH_TRIALLABEL_SIZE = "FETCH_TRIALLABEL_SIZE"
export const FETCH_TRIALLABEL_SIZE_SUCCEEDED = "  FETCH_TRIALLABEL_SIZE_SUCCEEDED,"
export const FETCH_TRIAL_FILE = "FETCH_TRIAL_FILE";
export const FETCH_TRIAL_FILE_SUCCEEDED = "FETCH_TRIAL_FILE_SUCCEEDED";
export const SUBMIT_TRIAL_FILE = "SUBMIT_TRIAL_FILE";
export const SUBMIT_TRIAL_FILE_SUCCEEDED = "SUBMIT_TRIAL_FILE_SUCCEEDED";
export const DELETE_TRIAL_FILE = "DELETE_TRIAL_FILE";
export const DELETE_TRIAL_FILE_SUCCEEDED = "DELETE_TRIAL_FILE_SUCCEEDED";
export const DOWNLOAD_TRIAL_FILE = "DOWNLOAD_TRIAL_FILE";
export const DOWNLOAD_TRIAL_FILE_SUCCEEDED = "DOWNLOAD_TRIAL_FILE_SUCCEEDED";
export const SET_TRIAL_PROGRESS = "SET_TRIAL_PROGRESS";
export const UPDATE_TRIAL_PROGRESS = "UPDATE_TRIAL_PROGRESS";
export const UPDATE_TRIAL_PROGRESS_SUCCEEDED = "UPDATE_TRIAL_PROGRESS_SUCCEEDED";
export const UPDATE_MULTIPLE_TRIAL_PROGRESS = "UPDATE_MULTIPLE_TRIAL_PROGRESS";
export const FETCH_PACKAGE_OPTIONS = "FETCH_PACKAGE_OPTIONS";
export const FETCH_PACKAGE_OPTIONS_SUCCEEDED = "FETCH_PACKAGE_OPTIONS_SUCCEEDED";
export const FETCH_SHIPPING = "FETCH_SHIPPING";
export const FETCH_SHIPPING_SUCCEEDED = "FETCH_SHIPPING_SUCCEEDED";
export const SUBMIT_SHIPPING = "SUBMIT_SHIPPING";
export const SUBMIT_SHIPPING_SUCCEEDED = "SUBMIT_SHIPPING_SUCCEEDED";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCEEDED = "FETCH_USERS_SUCCEEDED";
export const FETCH_TRIAL_USERS_LIST = "FETCH_TRIAL_USERS_LIST";
export const FETCH_TRIAL_USERS_LIST_SUCCEEDED = "FETCH_TRIAL_USERS_LIST_SUCCEEDED";
export const FETCH_TRIAL_PATIENT_USERS_LIST = "FETCH_TRIAL_PATIENT_USERS_LIST";
export const FETCH_TRIAL_PATIENT_USERS_LIST_SUCCEEDED = "FETCH_TRIAL_PATIENT_USERS_LIST_SUCCEEDED";
export const SUBMIT_TRIAL_USER = "SUBMIT_TRIAL_USER";
export const SUBMIT_TRIAL_USER_SUCCEEDED = "SUBMIT_TRIAL_USER_SUCCEEDED";
export const TOGGLE_STRATIFICATION = "TOGGLE_STRATIFICATION";
export const FETCH_STRATIFICATION = "FETCH_STRATIFICATION";
export const FETCH_STRATIFICATION_SUCCEEDED = "FETCH_STRATIFICATION_SUCCEEDED";
export const SUBMIT_STRATIFICATION = "SUBMIT_STRATIFICATION";
export const SUBMIT_STRATIFICATION_SUCCEEDED = "SUBMIT_STRATIFICATION_SUCCEEDED";
export const FETCH_RANDOMISATION_ENTRIES = "FETCH_RANDOMISATION_ENTRIES";
export const FETCH_RANDOMISATION_ENTRIES_SUCCEEDED = "FETCH_RANDOMISATION_ENTRIES_SUCCEEDED";
export const FETCH_ALL_RANDOMISATION_ENTRIES = "FETCH_ALL_RANDOMISATION_ENTRIES";
export const FETCH_ALL_RANDOMISATION_ENTRIES_SUCCEEDED = "FETCH_ALL_RANDOMISATION_ENTRIES_SUCCEEDED";
export const FETCH_RANDOMISATION = "FETCH_RANDOMISATION";
export const FETCH_RANDOMISATION_SUCCEEDED = "FETCH_RANDOMISATION_SUCCEEDED";
export const SUBMIT_RANDOMISATION = "SUBMIT_RANDOMISATION";
export const SUBMIT_RANDOMISATION_SUCCEEDED = "SUBMIT_RANDOMISATION_SUCCEEDED";
export const FETCH_QA_REQUEST = "FETCH_QA_REQUEST";
export const FETCH_QA_REQUEST_SUCCEEDED = "FETCH_QA_REQUEST_SUCCEEDED";
export const SUBMIT_QA = "SUBMIT_QA";
export const FETCH_QA = "FETCH_QA";
export const REJECT_QA = "REJECT_QA";
export const FETCH_QA_SUCCEEDED = "FETCH_QA_SUCCEEDED";
export const SUBMIT_QA_SUCCEEDED = "SUBMIT_QA_SUCCEEDED";
export const REJECT_QA_SUCCEEDED = "REJECT_QA_SUCCEEDED";
export const APPROVE_QA = "APPROVE_QA";
export const APPROVE_QA_SUCCEEDED = "APPROVE_QA_SUCCEEDED";
export const FETCH_TRIAL_SUMMARY = "FETCH_TRIAL_SUMMARY";
export const FETCH_TRIAL_SUMMARY_SUCCEEDED = "FETCH_TRIAL_SUMMARY_SUCCEEDED";
export const SUBMIT_TRIAL_QA = "SUBMIT_TRIAL_QA"
export const SUBMIT_TRIAL_QA_SUCCEEDED = "SUBMIT_TRIAL_QA_SUCCEEDED"
export const FETCH_TRIAL_DETAILS_FOR_BULK_ORDER = "FETCH_TRIAL_DETAILS_FOR_BULK_ORDER";
export const FETCH_TRIAL_DETAILS_FOR_BULK_ORDER_SUCCEEDED = "FETCH_TRIAL_DETAILS_FOR_BULK_ORDER_SUCCEEDED";
export const FETCH_COORDINATOR_KVP = "FETCH_COORDINATOR_KVP";
export const FETCH_COORDINATOR_KVP_SUCCEEDED = "FETCH_COORDINATOR_KVP_SUCCEEDED";
export const FETCH_INVESTIGATOR_KVP = "FETCH_INVESTIGATOR_KVP";
export const FETCH_INVESTIGATOR_KVP_SUCCEEDED = "FETCH_INVESTIGATOR_KVP_SUCCEEDED";
export const SUBMIT_BULK_ORDER = "SUBMIT_BULK_ORDER";
export const SUBMIT_BULK_ORDER_SUCCEEDED = "SUBMIT_BULK_ORDER_SUCCEEDED";
export const SET_SELECTED_QA_TYPE_DATA = "SET_SELECTED_QA_TYPE_DATA";
export const REMOVE_SELECTED_QA_TYPE_DATA = "REMOVE_SELECTED_QA_TYPE_DATA";
export const SEARCH_TRIAL_RANDOMISATION = "SEARCH_TRIAL_RANDOMISATION";
export const SEARCH_TRIAL_RANDOMISATION_SUCCEEDED = "SEARCH_TRIAL_RANDOMISATION_SUCCEEDED";
export const UPDATE_TRIAL_RANDOMISATION = "UPDATE_TRIAL_RANDOMISATION";
export const UPDATE_TRIAL_RANDOMISATION_SUCCEEDED = "UPDATE_TRIAL_RANDOMISATION_SUCCEEDED";
export const FETCH_SITE_FOR_BULKORDER = "FETCH_SITE_FOR_BULKORDER";
export const FETCH_SITE_FOR_BULKORDER_SUCCEEDED = "FETCH_SITE_FOR_BULKORDER_SUCCEEDED";
export const UPDATE_MULTI_TRIAL_PROGRESS = "UPDATE_MULTI_TRIAL_PROGRESS";
export const UPDATE_MULTI_TRIAL_PROGRESS_SUCCEEDED = "UPDATE_MULTI_TRIAL_PROGRESS_SUCCEEDED";
export const FETCH_AVAILABLE_INVENTORIES = "FETCH_AVAILABLE_INVENTORIES"
export const FETCH_AVAILABLE_INVENTORIES_SUCCEEDED = "FETCH_AVAILABLE_INVENTORIES_SUCCEEDED";
export const RESET_AVAILABLE_INVENTORIES = "RESET_AVAILABLE_INVENTORIES";
export const FETCH_TRIAL_ARCHIVE_REPORTS = "FETCH_TRIAL_ARCHIVE_REPORTS";
export const FETCH_TRIAL_ARCHIVE_REPORTS_SUCCEEDED = "FETCH_TRIAL_ARCHIVE_REPORTS_SUCCEEDED";
export const SUBMIT_TRIAL_ARCHIVE = "SUBMIT_TRIAL_ARCHIVE";
export const SUBMIT_TRIAL_ARCHIVE_SUCCEEDED = "SUBMIT_TRIAL_ARCHIVE_SUCCEEDED";


export interface FetchTrialListActions {
  type: typeof FETCH_TRIAL_LIST
  payload: GetQueryParams
}

export interface FetchTrialListSucceededActions {
  type: typeof FETCH_TRIAL_LIST_SUCCEEDED
  payload: Payload;
  append: boolean;
}

export interface FetchTrialByIdActions {
  type: typeof FETCH_TRIAL_BY_ID;
  id: string
}

export interface SetSelectedTrialActions {
  type: typeof SET_SELECTED_TRIAL,
  id: string
}

export interface FetchTrialByIdSucceededActions {
  type: typeof FETCH_TRIAL_BY_ID_SUCCEEDED;
  payload: TrialList
}

export interface RemoveSelectedTrialActions {
  type: typeof REMOVE_SELECTED_TRIAL
}

export interface ResetTrialActions{
  type: typeof RESET_TRIAL;
}

export interface SubmitTrialActions {
  type: typeof SUBMIT_TRIAL,
  payload: TrialList
}

export interface SubmitTrialSucceededActions {
  type: typeof SUBMIT_TRIAL_SUCCEEDED;
  payload: TrialList
}

export interface UpdateTrialActions {
  type: typeof UPDATE_TRIAL;
  id: string;
  payload: TrialList;
}

export interface UpdateTrialSucceededActions {
  type: typeof UPDATE_TRIAL_SUCCEEDED;
  id: string;
  payload: TrialList
}

export interface DeleteTrialActions {
  type: typeof DELETE_TRIAL;
  id: string;
}

export interface DeleteTrialSuceededActions {
  type: typeof DELETE_TRIAL_SUCCEEDED;
  id: string;
}

export interface FetchKVPSponsorActions{
  type: typeof FETCH_KVP_SPONSOR,
  searchValue: string
}

export interface FetchKVPSponsorSucceededActions{
  type: typeof FETCH_KVP_SPONSOR_SUCCEEDED,
  payload: KVP[]
}

export interface FetchKVPQAActions{
  type: typeof FETCH_KVP_QA,
  searchValue: string
}

export interface FetchKVPQASucceededActions{
  type: typeof FETCH_KVP_QA_SUCCEEDED,
  payload: KVP[]
}

export interface FetchKVPMonitorActions{
  type: typeof FETCH_KVP_MONITOR,
  searchValue: string
}

export interface FetchKVPMonitorSucceededActions{
  type: typeof FETCH_KVP_MONITOR_SUCCEEDED,
  payload: KVP[]
}

export interface FetchRepeatsActions{
  type: typeof FETCH_REPEATS,
  id: string;
}

export interface FetchRepeatsSucceededActions{
  type: typeof FETCH_REPEATS_SUCCEEDED,
  payload: Repeats;
}

export interface SubmitRepeatsActions{
  type: typeof SUBMIT_REPEATS,
  payload: Repeats;
}

export interface SubmitRepeatsSucceededActions{
  type: typeof SUBMIT_REPEATS_SUCCEEDED,
  payload: Repeats;
}

export interface FetchConsultationsActions{
  type: typeof FETCH_CONSULTATIONS,
  id: string;
}

export interface FetchConsultationsSucceededActions{
  type: typeof FETCH_CONSULTATIONS_SUCCEEDED
  payload: Consultations;
}

export interface SubmitConsultationsActions{
  type: typeof SUBMIT_CONSULTATIONS,
  payload: Consultations
}

export interface SubmitConsultationsSucceededActions{
  type: typeof SUBMIT_CONSULTATIONS_SUCCEEDED,
  payload: Consultations
}

export interface FetchReturnsInventoryActions {
  type: typeof FETCH_RETURNS_INVENTORY,
  id: string
}

export interface FetchReturnsInventorySucceededActions {
  type: typeof FETCH_RETURNS_INVENTORY_SUCCEEDED,
  payload: TriaEnabled
}

export interface SubmitReturnsInventoryActions {
  type: typeof SUBMIT_RETURNS_INVENTORY,
  payload: TriaEnabled
}

export interface SubmitReturnsInventorySucceededActions {
  type: typeof SUBMIT_RETURNS_INVENTORY_SUCCEEDED,
  payload: TriaEnabled
}

export interface ValidateEmergencyUnblinding {
  type: typeof VALIDATE_EMERGENCY_UNBLINDING,
  id: string
}

export interface ValidateEmergencyUnblindingSucceeded {
  type: typeof VALIDATE_EMERGENCY_UNBLINDING_SUCCEEDED,
  payload:any;
}

export interface FetchEmergencyUnblinding {
  type: typeof FETCH_EMERGENCY_UNBLINDING,
  id: string;
}

export interface FetchEmergencyUnblindingSucceeded {
  type: typeof FETCH_EMERGENCY_UNBLINDING_SUCCEEDED,
  payload: TriaEnabled;
}

export interface SubmitEmergencyUnblinding {
  type: typeof SUBMIT_EMERGENCY_UNBLINDING,
  payload: TriaEnabled;
}

export interface SubmitEmergencyUnblindingSucceeded {
  type: typeof SUBMIT_EMERGENCY_UNBLINDING_SUCCEEDED,
  payload: TriaEnabled;
}

export interface fetchTrialPricing {
  type: typeof FETCH_TRIAL_PRICING
  id:string
}

export interface fetchTrialPricingSucceeded {
  type: typeof FETCH_TRIAL_PRICING_SUCCEEDED
  payload: DefaultPricing
}

export interface submitTrialPricing {
  type: typeof SUBMIT_TRIAL_PRICING
  payload: DefaultPricing
}

export interface submitTrialPricingSucceeded {
  type: typeof SUBMIT_TRIAL_PRICING_SUCCEEDED
  payload: DefaultPricing
}

export interface fetchTrialSites{
  type: typeof FETCH_TRIAL_SITES,
  id: string
}

export interface fetchTrialSitesSucceeded{
  type: typeof FETCH_TRIAL_SITES_SUCCEEDED,
  payload: Sites[]
}

export interface submitTrialSites{
  type: typeof SUBMIT_TRIAL_SITES,
  id: string,
  payload: Sites[]
}

export interface submitTrialSitesSucceeded{
  type: typeof SUBMIT_TRIAL_SITES_SUCCEEDED,
  payload: Sites[]
}

export interface FetchTrialSitesActions {
  type: typeof FETCH_TRIAL_SITES_SUMMARY;
  id: string;
}

export interface FetchTrialSitesSucceededActions {
  type: typeof FETCH_TRIAL_SITES_SUMMARY_SUCCEEDED;
  payload: any;
}

export interface FetchDrugListActions{
  type: typeof FETCH_DRUG_LIST;
  id:string;
}

export interface FetchDrugListSucceededActions{
  type: typeof FETCH_DRUG_LIST_SUCCEEDED,
  payload: Drug[]
}

export interface AddDrugActions{
  type: typeof ADD_DRUG,
  payload: Drug
}

export interface UpdateDrugActions{
  type: typeof UPDATE_DRUG,
  payload: Drug
}

export interface DeleteDrugActions{
  type: typeof DELETE_DRUG,
  trialId: string;
  id: string;
}

export interface AddDrugSucceededActions{
  type: typeof ADD_DRUG_SUCCEEDED,
  payload: Drug
}

export interface UpdateDrugSucceededActions{
  type: typeof UPDATE_DRUG_SUCCEEDED,
  payload: Drug
}

export interface DeleteDrugSucceededActions{
  type: typeof DELETE_DRUG_SUCCEEDED,
  id: string;
}

export interface FetchTreatmentLabelListActions{
  type: typeof FETCH_TREATMENTLABEL_LIST;
  id: string;
}

export interface FetchTreatmentLabelListSucceededActions{
  type: typeof FETCH_TREATMENTLABEL_LIST_SUCCEEDED,
  payload: TreatmentLabel[]
}

export interface AddTreatmentLabelActions{
  type: typeof ADD_TREATMENTLABEL,
  payload: TreatmentLabel
}

export interface UpdateTreatmentLabelActions{
  type: typeof UPDATE_TREATMENTLABEL,
  payload: TreatmentLabel
}

export interface DeleteTreatmentLabelActions{
  type: typeof DELETE_TREATMENTLABEL,
  trialId: string;
  id: string;
}

export interface AddTreatmentLabelSucceededActions{
  type: typeof ADD_TREATMENTLABEL_SUCCEEDED,
  payload: TreatmentLabel
}

export interface UpdateTreatmentLabelSucceededActions{
  type: typeof UPDATE_TREATMENTLABEL_SUCCEEDED,
  payload: TreatmentLabel
}

export interface DeleteTreatmentLabelSucceededActions{
  type: typeof DELETE_TREATMENTLABEL_SUCCEEDED,
  id: string;
}

export interface FetchTreatmentArmListActions{
  type: typeof FETCH_TREATMENTARM_LIST;
  id:string;
}

export interface FetchTreatmentArmListSucceededActions{
  type: typeof FETCH_TREATMENTARM_LIST_SUCCEEDED,
  payload: TreatmentArm[]
}

export interface AddTreatmentArmActions{
  type: typeof ADD_TREATMENTARM,
  payload: TreatmentArm
}

export interface UpdateTreatmentArmActions{
  type: typeof UPDATE_TREATMENTARM,
  payload: TreatmentArm
}

export interface DeleteTreatmentArmActions{
  type: typeof DELETE_TREATMENTARM,
  trialId: string;
  id:string;
}

export interface AddTreatmentArmSucceededActions{
  type: typeof ADD_TREATMENTARM_SUCCEEDED,
  payload: TreatmentArm
}

export interface UpdateTreatmentArmSucceededActions{
  type: typeof UPDATE_TREATMENTARM_SUCCEEDED,
  payload: TreatmentArm
}

export interface DeleteTreatmentArmSucceededActions{
  type: typeof DELETE_TREATMENTARM_SUCCEEDED,
  id:string
}

export interface FetchTreatmentArmsActions{
  type: typeof FETCH_TREATMENT_ARM_SUMMARY;
  id:string;
}

export interface FetchTreatmentArmsSucceededActions{
  type: typeof FETCH_TREATMENT_ARM_SUMMARY_SUCCEEDED;
  payload:any;
}

export interface FetchTrialLabelActions{
  type: typeof FETCH_TRIALLABEL;
  id:string;
}

export interface FetchTrialLabelSucceededActions{
  type: typeof FETCH_TRIALLABEL_SUCCEEDED,
  payload: TrialLabel
}

export interface AddTrialLabelActions{
  type: typeof ADD_TRIALLABEL,
  id: string,
  payload: TrialLabel
}

export interface AddTrialLabelSucceededActions{
  type: typeof ADD_TRIALLABEL_SUCCEEDED,
  payload: TrialLabel
}

export interface FetchTrialLabelSizeActions{
  type: typeof FETCH_TRIALLABEL_SIZE
}

export interface FetchTrialLabelSizeSucceededActions{
  type: typeof FETCH_TRIALLABEL_SIZE_SUCCEEDED,
  payload: TrialLabel
}

export interface FetchTrialFileActions {
  type: typeof FETCH_TRIAL_FILE;
  id: string;
}

export interface FetchTrialFileSucceededActions {
  type: typeof FETCH_TRIAL_FILE_SUCCEEDED;
  payload: File[];
}

export interface SubmitTrialFileActions {
  type: typeof SUBMIT_TRIAL_FILE;
  id: string;
  payload: any;
}

export interface SubmitTrialFileSucceededActions {
  type: typeof SUBMIT_TRIAL_FILE_SUCCEEDED;
  payload: File;
}

export interface DeleteTrialFileActions {
  type: typeof DELETE_TRIAL_FILE,
  id: string;
  fileId: string;
}

export interface DeleteTrialFileSucceededActions {
  type: typeof DELETE_TRIAL_FILE_SUCCEEDED,
  fileId: string;
}

export interface DownloadTrialFileActions {
  type: typeof DOWNLOAD_TRIAL_FILE,
  id: string;
  fileId: string;
}

export interface DownloadFileSucceededActions {
  type: typeof DOWNLOAD_TRIAL_FILE_SUCCEEDED,
  link: string;
}
export interface SetTrialProgressActions {
  type: typeof SET_TRIAL_PROGRESS,
  payload:any
}

export interface UpdateTrialProgressActions {
  type: typeof UPDATE_TRIAL_PROGRESS,
  payload:any
}

export interface UpdateTrialProgressSuceededActions {
  type: typeof UPDATE_TRIAL_PROGRESS_SUCCEEDED,
  payload:any
}

export interface UpdateMultipleTrialProgressActions {
  type: typeof UPDATE_MULTIPLE_TRIAL_PROGRESS,
  payload:any
}

export interface FetchPackaingOptionsActions {
  type: typeof FETCH_PACKAGE_OPTIONS
  courierId: string;
}

export interface FetchPackaingOptionsSucceededActions {
  type: typeof FETCH_PACKAGE_OPTIONS_SUCCEEDED,
  payload: PackageOptions[]
}

export interface FetchShippingActions {
  type: typeof FETCH_SHIPPING,
  id: string
}

export interface FetchShippingSucceededActions {
  type: typeof FETCH_SHIPPING_SUCCEEDED,
  payload: Shipping
}

export interface SubmitShippingActions {
  type: typeof SUBMIT_SHIPPING,
  payload: Shipping
}

export interface SubmitShippingSucceededActions {
  type: typeof SUBMIT_SHIPPING_SUCCEEDED,
  payload: Shipping
}

export interface FetchUsersActions {
  type: typeof FETCH_USERS;
  id:string;
}

export interface FetchUsersSucceededActions {
  type: typeof FETCH_USERS_SUCCEEDED,
  payload: User[];
}

export interface FetchTrialUserListActions {
  type: typeof FETCH_TRIAL_USERS_LIST;
  id: string;
}

export interface FetchTrialUserListSucceededActions {
  type: typeof FETCH_TRIAL_USERS_LIST_SUCCEEDED;
  payload: User[];
}
export interface FetchTrialPatientUserListActions {
  type: typeof FETCH_TRIAL_PATIENT_USERS_LIST;
  id: string;
}

export interface FetchTrialPatientUserListSucceededActions {
  type: typeof FETCH_TRIAL_PATIENT_USERS_LIST_SUCCEEDED;
  payload: User[];
}

export interface SubmitTrialUserActions {
  type: typeof SUBMIT_TRIAL_USER,
  trialId: string;
  payload: User
}

export interface SubmitTrialUserSucceededActions {
  type: typeof SUBMIT_TRIAL_USER_SUCCEEDED,
  payload: User
}

export interface ToggleStratificationActions {
  type: typeof TOGGLE_STRATIFICATION;
  enabled: boolean;
}

export interface FetchStratificationActions {
  type: typeof FETCH_STRATIFICATION,
  id: string;
}

export interface FetchStratificationSucceededActions {
  type: typeof FETCH_STRATIFICATION_SUCCEEDED,
  payload: Stratification
}

export interface SubmitStratificationActions {
  type: typeof SUBMIT_STRATIFICATION,
  id:string;
  payload: Stratification
}

export interface SubmitStratificationSucceededActions {
  type: typeof SUBMIT_STRATIFICATION_SUCCEEDED,
  payload: Stratification
}

export interface FetchRandomisationEntriesActions {
  type: typeof FETCH_RANDOMISATION_ENTRIES;
  payload:any;
}
export interface FetchRandomisationEntriesSucceededActions {
  type: typeof FETCH_RANDOMISATION_ENTRIES_SUCCEEDED;
  payload:any;
  append: boolean
}
export interface FetchRandomisationActions {
  type: typeof FETCH_RANDOMISATION;
  id: string;
}
export interface FetchRandomisationSucceededActions {
  type: typeof FETCH_RANDOMISATION_SUCCEEDED;
  payload:any
}
export interface SubmitRandomisationActions {
  type: typeof SUBMIT_RANDOMISATION;
  payload:any
}
export interface SubmitRandomisationSucceededActions {
  type: typeof SUBMIT_RANDOMISATION_SUCCEEDED;
  payload:any
}

export interface FetchQARequestActions {
  type: typeof FETCH_QA_REQUEST;
  objectType: number;
  trialId: string
}

export interface FetchQARequestSucceededActions {
  type: typeof FETCH_QA_REQUEST_SUCCEEDED;
  payload: any
}

export interface FetchQAActions {
  type: typeof FETCH_QA,
  payload:any
}

export interface FetchQASucceededActions {
  type: typeof FETCH_QA_SUCCEEDED,
  payload:any
}

export interface SubmitQAActions {
  type: typeof SUBMIT_QA,
  id:string;
  payload:any
}

export interface SubmitQASucceededActions {
  type: typeof SUBMIT_QA_SUCCEEDED,
  payload:any
}

export interface RejectQAActions {
  type: typeof REJECT_QA,
  payload:any
}

export interface RejectQASucceededActions {
  type: typeof REJECT_QA_SUCCEEDED,
  payload:any
}

export interface ApproveQAActions {
  type: typeof APPROVE_QA,
  id:string;
}

export interface ApproveQASucceededActions {
  type: typeof APPROVE_QA_SUCCEEDED,
  payload:any
}

export interface FetchTrialSummaryActions {
  type: typeof FETCH_TRIAL_SUMMARY,
  id: string;
}
export interface FetchTrialSummarySucceededActions {
  type: typeof FETCH_TRIAL_SUMMARY_SUCCEEDED,
  payload: TrialSummary
}

export interface SubmitTrialQAActions{
  type: typeof SUBMIT_TRIAL_QA;
  payload: any;
}

export interface SubmitTrialQASucceededActions{
  type: typeof SUBMIT_TRIAL_QA_SUCCEEDED;
  payload: any;
}

export interface FetchTrialDetailsForBulkOrderActions {
  type: typeof FETCH_TRIAL_DETAILS_FOR_BULK_ORDER;
  trialId: string;
  bulkOrderId: string;
}

export interface FetchTrialDetailsForBulkOrderSucceededActions {
  type: typeof FETCH_TRIAL_DETAILS_FOR_BULK_ORDER_SUCCEEDED,
  payload: TrialDetails
}

export interface FetchCoordinatorKvpActions {
  type: typeof FETCH_COORDINATOR_KVP;
  siteId: string;
  trialId: string;
}

export interface FetchCoordinatorKvpSucceededActions {
  type: typeof FETCH_COORDINATOR_KVP_SUCCEEDED,
  payload: any
}

export interface FetchInvestigatorKvpActions {
  type: typeof FETCH_INVESTIGATOR_KVP;
  siteId: string;
  trialId: string;
}

export interface FetchInvestigatorKvpSucceededActions {
  type: typeof FETCH_INVESTIGATOR_KVP_SUCCEEDED,
  payload: any
}

export interface SubmitBulkOrderActions{
  type: typeof SUBMIT_BULK_ORDER;
  payload: any;
}

export interface SubmitBulkOrderSucceededActions{
  type: typeof SUBMIT_BULK_ORDER_SUCCEEDED;
  payload: any;
}

export interface SetSelectedQATypeDataActions{ 
  type: typeof SET_SELECTED_QA_TYPE_DATA
  payload:any;
}
export interface RemoveSelectedQATypeDataActions {
  type: typeof REMOVE_SELECTED_QA_TYPE_DATA;
}

export interface SearchTrialRandomisationActions {
  type: typeof SEARCH_TRIAL_RANDOMISATION;
  payload: any;
}

export interface SearchTrialRandomisationSucceededActions {
  type: typeof SEARCH_TRIAL_RANDOMISATION_SUCCEEDED;
  payload: any;
}

export interface UpdateTrialRandomisationActions {
  type: typeof UPDATE_TRIAL_RANDOMISATION,
  id: string;
  payload: any;
}

export interface UpdateTrialRandomisationSucceededActions {
  type: typeof UPDATE_TRIAL_RANDOMISATION_SUCCEEDED;
  payload: any;
}

export interface FetchSiteForBulkOrderActions {
  type: typeof FETCH_SITE_FOR_BULKORDER;
  id: string;
}

export interface FetchSiteForBulkOrderSucceededActions {
  type: typeof FETCH_SITE_FOR_BULKORDER_SUCCEEDED;
  payload: any;
}

export interface UpdateMultiTrialProgressActions {
  type: typeof UPDATE_MULTI_TRIAL_PROGRESS;
  id: string;
  payload:any
}

export interface UpdateMultiTrialProgressSucceededActions {
  type: typeof UPDATE_MULTI_TRIAL_PROGRESS_SUCCEEDED;
  payload:any
}

export interface FetchTrialArchiveReportsActions {
  type: typeof FETCH_TRIAL_ARCHIVE_REPORTS;
  id: string;
}

export interface FetchTrialArchiveReportsSucceededActions {
  type: typeof FETCH_TRIAL_ARCHIVE_REPORTS_SUCCEEDED;
  payload:any;
}

export interface SubmitTrialArchiveActions {
  type: typeof SUBMIT_TRIAL_ARCHIVE;
  id: string;
}

export interface SubmitTrialArchiveSucceededActions {
  type: typeof SUBMIT_TRIAL_ARCHIVE_SUCCEEDED;
  payload:any;
}

export type TrialActionsTypes =
  | FetchTrialListActions
  | SubmitTrialActions
  | UpdateTrialActions
  | DeleteTrialActions
  | FetchTrialListSucceededActions
  | FetchTrialByIdSucceededActions
  | SubmitTrialSucceededActions
  | UpdateTrialSucceededActions
  | DeleteTrialSuceededActions
  | FetchTrialByIdActions
  | SetSelectedTrialActions
  | RemoveSelectedTrialActions
  | ResetTrialActions
  | FetchKVPSponsorActions
  | FetchKVPSponsorSucceededActions
  | FetchKVPQAActions
  | FetchKVPQASucceededActions
  | FetchKVPMonitorActions
  | FetchKVPMonitorSucceededActions
  | FetchRepeatsActions
  | FetchRepeatsSucceededActions
  | SubmitRepeatsActions
  | SubmitRepeatsSucceededActions
  | FetchConsultationsActions
  | FetchConsultationsSucceededActions
  | SubmitConsultationsActions
  | SubmitConsultationsSucceededActions
  | FetchReturnsInventoryActions
  | FetchReturnsInventorySucceededActions
  | SubmitReturnsInventoryActions
  | SubmitReturnsInventorySucceededActions
  | ValidateEmergencyUnblinding
  | ValidateEmergencyUnblindingSucceeded
  | FetchEmergencyUnblinding
  | FetchEmergencyUnblindingSucceeded
  | SubmitEmergencyUnblinding
  | SubmitEmergencyUnblindingSucceeded
  | fetchTrialPricing
  | fetchTrialPricingSucceeded
  | submitTrialPricing
  | submitTrialPricingSucceeded
  | fetchTrialSites
  | fetchTrialSitesSucceeded
  | submitTrialSites
  | submitTrialSitesSucceeded
  | FetchTrialSitesActions
  | FetchTrialSitesSucceededActions
  | FetchDrugListActions
  | FetchDrugListSucceededActions
  | AddDrugActions
  | UpdateDrugActions
  | DeleteDrugActions
  | AddDrugSucceededActions
  | UpdateDrugSucceededActions
  | DeleteDrugSucceededActions
  | FetchTreatmentLabelListActions
  | FetchTreatmentLabelListSucceededActions
  | AddTreatmentLabelActions
  | UpdateTreatmentLabelActions
  | DeleteTreatmentLabelActions
  | AddTreatmentLabelSucceededActions
  | UpdateTreatmentLabelSucceededActions
  | DeleteTreatmentLabelSucceededActions
  | FetchTreatmentArmListActions
  | FetchTreatmentArmListSucceededActions
  | AddTreatmentArmActions
  | UpdateTreatmentArmActions
  | DeleteTreatmentArmActions
  | AddTreatmentArmSucceededActions
  | UpdateTreatmentArmSucceededActions
  | DeleteTreatmentArmSucceededActions
  | FetchTreatmentArmsActions
  | FetchTreatmentArmsSucceededActions
  | FetchTrialLabelActions
  | FetchTrialLabelSucceededActions
  | AddTrialLabelActions
  | AddTrialLabelSucceededActions
  | FetchTrialLabelSizeActions
  | FetchTrialLabelSizeSucceededActions
  | FetchTrialFileActions
  | FetchTrialFileSucceededActions
  | SubmitTrialFileActions
  | SubmitTrialFileSucceededActions
  | DeleteTrialFileActions
  | DeleteTrialFileSucceededActions
  | DownloadTrialFileActions
  | DownloadFileSucceededActions
  | SetTrialProgressActions
  | UpdateTrialProgressActions
  | UpdateTrialProgressSuceededActions
  | UpdateMultipleTrialProgressActions
  | FetchPackaingOptionsActions
  | FetchPackaingOptionsSucceededActions
  | FetchShippingActions
  | FetchShippingSucceededActions
  | SubmitShippingActions
  | SubmitShippingSucceededActions
  | FetchUsersActions
  | FetchUsersSucceededActions
  | FetchTrialUserListActions
  | FetchTrialUserListSucceededActions
  | FetchTrialPatientUserListActions
  | FetchTrialPatientUserListSucceededActions
  | SubmitTrialUserActions
  | SubmitTrialUserSucceededActions
  | ToggleStratificationActions
  | FetchStratificationActions
  | FetchStratificationSucceededActions
  | SubmitStratificationActions
  | SubmitStratificationSucceededActions
  | FetchRandomisationEntriesActions
  | FetchRandomisationEntriesSucceededActions
  | FetchRandomisationActions
  | FetchRandomisationSucceededActions
  | SubmitRandomisationActions
  | SubmitRandomisationSucceededActions
  | FetchQARequestActions
  | FetchQARequestSucceededActions
  | FetchQAActions
  | SubmitQAActions
  | RejectQAActions
  | FetchQASucceededActions
  | SubmitQASucceededActions
  | RejectQASucceededActions
  | ApproveQAActions
  | ApproveQASucceededActions
  | FetchTrialSummaryActions
  | FetchTrialSummarySucceededActions
  | SubmitTrialQAActions
  | SubmitTrialQASucceededActions
  | FetchTrialDetailsForBulkOrderActions
  | FetchTrialDetailsForBulkOrderSucceededActions
  | FetchCoordinatorKvpActions
  | FetchCoordinatorKvpSucceededActions
  | FetchInvestigatorKvpActions
  | FetchInvestigatorKvpSucceededActions
  | SubmitBulkOrderActions
  | SubmitBulkOrderSucceededActions
  | SetSelectedQATypeDataActions
  | RemoveSelectedQATypeDataActions
  | SearchTrialRandomisationActions
  | SearchTrialRandomisationSucceededActions
  | UpdateTrialRandomisationActions
  | UpdateTrialRandomisationSucceededActions
  | FetchSiteForBulkOrderActions
  | FetchSiteForBulkOrderSucceededActions
  | UpdateMultiTrialProgressActions
  | UpdateMultiTrialProgressSucceededActions
  | FetchTrialArchiveReportsActions
  | FetchTrialArchiveReportsSucceededActions
  | SubmitTrialArchiveActions
  | SubmitTrialArchiveSucceededActions
  ;