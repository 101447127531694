/* eslint-disable react-hooks/exhaustive-deps */
import React, {ReactNode, useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {setInitialPath} from '../../redux/actions';
import {matchRoutes} from 'react-router-config';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import {AppState} from '../../redux/store';
import AppContextPropsType from '../../types/AppContextPropsType';
import { fetchCurrentUserById } from 'redux/actions/Users';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({children}) => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {routes} = useContext<AppContextPropsType>(AppContext);

  const [token, loading, userId] = useAuthToken();
  const {initialPath} = useSelector<AppState, AppState['settings']>(
    ({settings}) => settings,
  );
  const { currentUser } = useSelector<AppState, AppState['users']>(({users}) => users);
  const { baseApiUrl } = useSelector<AppState, AppState['settings']>(({settings}) => settings);
  // const { user } = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/reset-password',
          '/forget-password',
        ].indexOf(pathname) === -1 && 
        baseApiUrl
      ) {
        dispatch(setInitialPath(pathname));
      } 
    }

    setInitPath();
  }, [dispatch, initialPath, pathname]);

  useEffect(() => {
    if(baseApiUrl && currentUser === null && userId && token){
      dispatch(fetchCurrentUserById(userId as string))
    }
  }, [baseApiUrl, token, userId, currentUser, dispatch])

  useEffect(() => {
    if (!loading) {
      if (!token && pathname === '/') { //&& currentRoute.auth && currentRoute.auth.length >= 1
        history.push('/signin');
      } else if (
        (pathname === '/signin' ||
          pathname === '/reset-password') &&
        token && baseApiUrl
      ) {
        
        window.location.replace('/');
      }
    }
  }, [token, loading, pathname, initialPath, currentRoute.auth, history]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
