import React from 'react';

export const pharmacyRouteConfig = [
  {
    auth: ['Pharmacy'],
    routes: [
      {
        path: '/pharmacies',
        component: React.lazy(() => import('./Pharmacies')),
      },
    ],
  },
];
