import { GetQueryParams, KVP } from "../../types/models/Common";
import { DefaultPricing } from "../../types/models/Pricing";
import { 
  Payload,
  TrialList, 
  Drug, 
  TreatmentLabel, 
  TreatmentArm, 
  Repeats, 
  Consultations,
  TriaEnabled,
  Sites, 
  TrialLabel, 
  File,
  PackageOptions,
  Shipping,
  User,
  Stratification,
  TrialSummary,
  TrialDetails
} from "../../types/models/Trials";

import { 
  FETCH_TRIAL_LIST,
  FETCH_TRIAL_BY_ID,
  SUBMIT_TRIAL,
  UPDATE_TRIAL,
  DELETE_TRIAL,
  FETCH_TRIAL_LIST_SUCCEEDED,
  FETCH_TRIAL_BY_ID_SUCCEEDED,
  SUBMIT_TRIAL_SUCCEEDED,
  UPDATE_TRIAL_SUCCEEDED,
  DELETE_TRIAL_SUCCEEDED,
  SET_SELECTED_TRIAL,
  REMOVE_SELECTED_TRIAL,
  RESET_TRIAL,
  FETCH_KVP_SPONSOR,
  FETCH_KVP_SPONSOR_SUCCEEDED,
  FETCH_KVP_QA,
  FETCH_KVP_QA_SUCCEEDED,
  FETCH_KVP_MONITOR,
  FETCH_KVP_MONITOR_SUCCEEDED,
  FETCH_REPEATS,
  FETCH_REPEATS_SUCCEEDED,
  SUBMIT_REPEATS,
  SUBMIT_REPEATS_SUCCEEDED,
  FETCH_CONSULTATIONS,
  FETCH_CONSULTATIONS_SUCCEEDED,
  SUBMIT_CONSULTATIONS,
  SUBMIT_CONSULTATIONS_SUCCEEDED,
  FETCH_RETURNS_INVENTORY,
  FETCH_RETURNS_INVENTORY_SUCCEEDED,
  SUBMIT_RETURNS_INVENTORY,
  SUBMIT_RETURNS_INVENTORY_SUCCEEDED,
  VALIDATE_EMERGENCY_UNBLINDING,
  VALIDATE_EMERGENCY_UNBLINDING_SUCCEEDED,
  FETCH_EMERGENCY_UNBLINDING,
  FETCH_EMERGENCY_UNBLINDING_SUCCEEDED,
  SUBMIT_EMERGENCY_UNBLINDING,
  SUBMIT_EMERGENCY_UNBLINDING_SUCCEEDED,
  FETCH_TRIAL_PRICING,
  FETCH_TRIAL_PRICING_SUCCEEDED,
  SUBMIT_TRIAL_PRICING,
  SUBMIT_TRIAL_PRICING_SUCCEEDED,
  FETCH_TRIAL_SITES,
  FETCH_TRIAL_SITES_SUCCEEDED,
  SUBMIT_TRIAL_SITES,
  SUBMIT_TRIAL_SITES_SUCCEEDED,
  FETCH_DRUG_LIST_SUCCEEDED,
  FETCH_DRUG_LIST,
  ADD_DRUG_SUCCEEDED,
  ADD_DRUG,
  DELETE_DRUG,
  DELETE_DRUG_SUCCEEDED,
  UPDATE_DRUG,
  UPDATE_DRUG_SUCCEEDED,
  FETCH_TREATMENTLABEL_LIST,
  FETCH_TREATMENTLABEL_LIST_SUCCEEDED,
  ADD_TREATMENTLABEL_SUCCEEDED,
  ADD_TREATMENTLABEL,
  DELETE_TREATMENTLABEL,
  DELETE_TREATMENTLABEL_SUCCEEDED,
  UPDATE_TREATMENTLABEL,
  UPDATE_TREATMENTLABEL_SUCCEEDED,
  FETCH_TREATMENTARM_LIST,
  FETCH_TREATMENTARM_LIST_SUCCEEDED,
  ADD_TREATMENTARM,
  ADD_TREATMENTARM_SUCCEEDED,
  UPDATE_TREATMENTARM,
  UPDATE_TREATMENTARM_SUCCEEDED,
  DELETE_TREATMENTARM,
  DELETE_TREATMENTARM_SUCCEEDED,
  FETCH_TREATMENT_ARM_SUMMARY,
  FETCH_TREATMENT_ARM_SUMMARY_SUCCEEDED,
  TrialActionsTypes, 
  FETCH_TRIALLABEL_SUCCEEDED, 
  FETCH_TRIALLABEL, 
  ADD_TRIALLABEL, 
  ADD_TRIALLABEL_SUCCEEDED,
  FETCH_TRIALLABEL_SIZE,
  FETCH_TRIALLABEL_SIZE_SUCCEEDED,
  FETCH_TRIAL_FILE,
  FETCH_TRIAL_FILE_SUCCEEDED,
  SUBMIT_TRIAL_FILE,
  SUBMIT_TRIAL_FILE_SUCCEEDED,
  DELETE_TRIAL_FILE,
  DELETE_TRIAL_FILE_SUCCEEDED,
  DOWNLOAD_TRIAL_FILE,
  DOWNLOAD_TRIAL_FILE_SUCCEEDED,
  SET_TRIAL_PROGRESS,
  UPDATE_TRIAL_PROGRESS,
  UPDATE_TRIAL_PROGRESS_SUCCEEDED,
  FETCH_PACKAGE_OPTIONS,
  FETCH_PACKAGE_OPTIONS_SUCCEEDED,
  FETCH_SHIPPING,
  FETCH_SHIPPING_SUCCEEDED,
  SUBMIT_SHIPPING,
  SUBMIT_SHIPPING_SUCCEEDED,
  FETCH_USERS,
  FETCH_USERS_SUCCEEDED,
  FETCH_TRIAL_USERS_LIST,
  FETCH_TRIAL_USERS_LIST_SUCCEEDED,
  FETCH_TRIAL_PATIENT_USERS_LIST,
  FETCH_TRIAL_PATIENT_USERS_LIST_SUCCEEDED,
  SUBMIT_TRIAL_USER,
  SUBMIT_TRIAL_USER_SUCCEEDED,
  TOGGLE_STRATIFICATION,
  FETCH_STRATIFICATION,
  FETCH_STRATIFICATION_SUCCEEDED,
  SUBMIT_STRATIFICATION,
  SUBMIT_STRATIFICATION_SUCCEEDED,
  FETCH_RANDOMISATION_ENTRIES,
  FETCH_RANDOMISATION_ENTRIES_SUCCEEDED,
  FETCH_ALL_RANDOMISATION_ENTRIES,
  FETCH_ALL_RANDOMISATION_ENTRIES_SUCCEEDED,
  FETCH_RANDOMISATION,
  FETCH_RANDOMISATION_SUCCEEDED,
  SUBMIT_RANDOMISATION,
  SUBMIT_RANDOMISATION_SUCCEEDED,
  FETCH_QA_REQUEST,
  FETCH_QA_REQUEST_SUCCEEDED,
  FETCH_QA,
  FETCH_QA_SUCCEEDED,
  SUBMIT_QA,
  SUBMIT_QA_SUCCEEDED,
  REJECT_QA,
  REJECT_QA_SUCCEEDED,
  APPROVE_QA,
  APPROVE_QA_SUCCEEDED,
  FETCH_TRIAL_SUMMARY,
  FETCH_TRIAL_SUMMARY_SUCCEEDED,
  FETCH_TRIAL_SITES_SUMMARY,
  FETCH_TRIAL_SITES_SUMMARY_SUCCEEDED,
  SUBMIT_TRIAL_QA,
  SUBMIT_TRIAL_QA_SUCCEEDED,
  FETCH_TRIAL_DETAILS_FOR_BULK_ORDER,
  FETCH_TRIAL_DETAILS_FOR_BULK_ORDER_SUCCEEDED,
  FETCH_COORDINATOR_KVP,
  FETCH_COORDINATOR_KVP_SUCCEEDED,
  FETCH_INVESTIGATOR_KVP,
  FETCH_INVESTIGATOR_KVP_SUCCEEDED,
  SUBMIT_BULK_ORDER,
  SUBMIT_BULK_ORDER_SUCCEEDED,
  SET_SELECTED_QA_TYPE_DATA,
  REMOVE_SELECTED_QA_TYPE_DATA,
  SEARCH_TRIAL_RANDOMISATION,
  SEARCH_TRIAL_RANDOMISATION_SUCCEEDED,
  UPDATE_TRIAL_RANDOMISATION,
  UPDATE_TRIAL_RANDOMISATION_SUCCEEDED,
  FETCH_SITE_FOR_BULKORDER,
  FETCH_SITE_FOR_BULKORDER_SUCCEEDED,
  UPDATE_MULTI_TRIAL_PROGRESS,
  UPDATE_MULTI_TRIAL_PROGRESS_SUCCEEDED,
  FETCH_AVAILABLE_INVENTORIES,
  FETCH_AVAILABLE_INVENTORIES_SUCCEEDED,
  RESET_AVAILABLE_INVENTORIES,
  FETCH_TRIAL_ARCHIVE_REPORTS,
  FETCH_TRIAL_ARCHIVE_REPORTS_SUCCEEDED,
  SUBMIT_TRIAL_ARCHIVE,
  SUBMIT_TRIAL_ARCHIVE_SUCCEEDED
} from "../../types/actions/Trials.action";

export const fetchTrialList = (payload: GetQueryParams) => ({
  type: FETCH_TRIAL_LIST,
  payload
});

export const submitTrial = (payload:TrialList):TrialActionsTypes => ({
  type: SUBMIT_TRIAL,
  payload
});

export const updateTrial = (id: string, payload:TrialList):TrialActionsTypes => ({
  type: UPDATE_TRIAL,
  id,
  payload
});

export const deleteTrial = (id: string):TrialActionsTypes => ({
  type: DELETE_TRIAL,
  id
});

export const fetchTrialById = (id:string) => ({
  type: FETCH_TRIAL_BY_ID,
  id
});

export const setSelectedTrial = (id:string) => ({
  type: SET_SELECTED_TRIAL,
  id
});

export const removeSelectedTrial = () => ({
  type: REMOVE_SELECTED_TRIAL
});

export const resetTrial = ():TrialActionsTypes => ({
  type: RESET_TRIAL
});

export const fetchTrialListSucceeded = (payload:Payload, append:boolean): TrialActionsTypes=> ({
  type: FETCH_TRIAL_LIST_SUCCEEDED,
  payload,
  append
});

export const submitTrialSucceeded = (payload:TrialList):TrialActionsTypes => ({
  type: SUBMIT_TRIAL_SUCCEEDED,
  payload
});

export const updateTrialSucceeded = (id: string, payload:TrialList):TrialActionsTypes => ({
  type: UPDATE_TRIAL_SUCCEEDED,
  id,
  payload
});

export const deleteTrialSucceeded = (id: string):TrialActionsTypes => ({
  type: DELETE_TRIAL_SUCCEEDED,
  id
});

export const fetchTrialByIdSucceeded = (payload:TrialList):TrialActionsTypes => ({
  type: FETCH_TRIAL_BY_ID_SUCCEEDED,
  payload
});

export const fetch_KVP_Sponsor = (searchValue: string):TrialActionsTypes => ({
  type: FETCH_KVP_SPONSOR,
  searchValue
});

export const fetch_KVP_Sponsor_Succeeded = (payload: KVP[]):TrialActionsTypes => ({
  type: FETCH_KVP_SPONSOR_SUCCEEDED,
  payload
});

export const fetch_KVP_QA = (searchValue: string):TrialActionsTypes => ({
  type: FETCH_KVP_QA,
  searchValue
});

export const fetch_KVP_QA_Succeeded = (payload: KVP[]):TrialActionsTypes => ({
  type: FETCH_KVP_QA_SUCCEEDED,
  payload
});

export const fetch_KVP_Monitor = (searchValue: string):TrialActionsTypes => ({
  type: FETCH_KVP_MONITOR,
  searchValue
});

export const fetch_KVP_Monitor_Succeeded = (payload: KVP[]):TrialActionsTypes => ({
  type: FETCH_KVP_MONITOR_SUCCEEDED,
  payload
});

export const fetchRepeats = (id: string): TrialActionsTypes => ({
  type: FETCH_REPEATS,
  id
});

export const fetchRepeatsSucceeded = (payload:Repeats): TrialActionsTypes => ({
  type: FETCH_REPEATS_SUCCEEDED,
  payload
});

export const submitRepeats = (payload:Repeats): TrialActionsTypes => ({
  type: SUBMIT_REPEATS,
  payload
});

export const submitRepeatsSucceeded = (payload:Repeats): TrialActionsTypes => ({
  type: SUBMIT_REPEATS_SUCCEEDED,
  payload
});

export const fetchConsultations = (id: string): TrialActionsTypes => ({
  type: FETCH_CONSULTATIONS,
  id
});

export const fetchConsultationsSucceeded = (payload:Consultations): TrialActionsTypes => ({
  type: FETCH_CONSULTATIONS_SUCCEEDED,
  payload
});

export const submitConsultations = (payload:Consultations): TrialActionsTypes => ({
  type: SUBMIT_CONSULTATIONS,
  payload
});

export const submitConsultationsSucceeded = (payload:Consultations): TrialActionsTypes => ({
  type: SUBMIT_CONSULTATIONS_SUCCEEDED,
  payload
});

export const fetchReturnsInventory = (id:string): TrialActionsTypes => ({
  type: FETCH_RETURNS_INVENTORY,
  id
}); 

export const fetchReturnsInventorySucceeded = (payload: TriaEnabled): TrialActionsTypes => ({
  type: FETCH_RETURNS_INVENTORY_SUCCEEDED,
  payload
}); 

export const submitReturnsInventory = (payload: TriaEnabled): TrialActionsTypes => ({
  type: SUBMIT_RETURNS_INVENTORY,
  payload
}); 

export const submitReturnsInventorySucceeded = (payload: TriaEnabled): TrialActionsTypes => ({
  type: SUBMIT_RETURNS_INVENTORY_SUCCEEDED,
  payload
}); 

export const validateEmergencyUnblinding = (id: string) => ({
  type: VALIDATE_EMERGENCY_UNBLINDING,
  id
})

export const validateEmergencyUnblindingSucceeded = (payload: any) => ({
  type: VALIDATE_EMERGENCY_UNBLINDING_SUCCEEDED,
  payload
})

export const fetchEmergencyUnblinding = (id:string): TrialActionsTypes => ({
  type: FETCH_EMERGENCY_UNBLINDING,
  id
}); 

export const fetchEmergencyUnblindingSucceeded = (payload: TriaEnabled): TrialActionsTypes => ({
  type: FETCH_EMERGENCY_UNBLINDING_SUCCEEDED,
  payload
}); 

export const submitEmergencyUnblinding = (payload: TriaEnabled): TrialActionsTypes => ({
  type: SUBMIT_EMERGENCY_UNBLINDING,
  payload
}); 

export const submitEmergencyUnblindingSucceeded = (payload: TriaEnabled): TrialActionsTypes => ({
  type: SUBMIT_EMERGENCY_UNBLINDING_SUCCEEDED,
  payload
}); 

export const fetchTrialPricing = (id: string): TrialActionsTypes => ({
  type: FETCH_TRIAL_PRICING,
  id
});

export const fetchTrialPricingSucceeded = (payload: DefaultPricing): TrialActionsTypes => ({
  type: FETCH_TRIAL_PRICING_SUCCEEDED,
  payload
}); 

export const submitTrialPricing = (payload: DefaultPricing): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_PRICING,
  payload
});

export const submitTrialPricingSucceeded = (payload: DefaultPricing): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_PRICING_SUCCEEDED,
  payload
}); 

export const fetchTrialSites = (id: string): TrialActionsTypes => ({
  type: FETCH_TRIAL_SITES,
  id
});

export const fetchTrialSitesSucceeded = (payload:Sites[]): TrialActionsTypes => ({
  type: FETCH_TRIAL_SITES_SUCCEEDED,
  payload
});

export const submitTrialSites = (id: string, payload:any): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_SITES,
  id,
  payload
});

export const submitTrialSitesSucceeded = (payload:Sites[]): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_SITES_SUCCEEDED,
  payload
});

export const fetchTrialSitesSummary = (id:string): TrialActionsTypes => ({
  type: FETCH_TRIAL_SITES_SUMMARY,
  id
});

export const fetchTrialSitesSummarySucceeded = (payload:any): TrialActionsTypes => ({
  type: FETCH_TRIAL_SITES_SUMMARY_SUCCEEDED,
  payload
});

export const fetchDrugList = (id:string):TrialActionsTypes=>({
  type: FETCH_DRUG_LIST,
  id
});

export const fetchDrugListSucceeded = (payload: Drug[]):TrialActionsTypes => ({
  type: FETCH_DRUG_LIST_SUCCEEDED,
  payload
});

export const addDrug = (payload: Drug):TrialActionsTypes => ({
  type: ADD_DRUG,
  payload
});

export const addDrugSucceeded = (payload: Drug):TrialActionsTypes =>({
  type: ADD_DRUG_SUCCEEDED,
  payload
});

export const deleteDrug = (trialId:string, id:string):TrialActionsTypes =>({
  type: DELETE_DRUG,
  trialId,
  id
});

export const deleteDrugSucceeded = (id: string):TrialActionsTypes =>({
  type: DELETE_DRUG_SUCCEEDED,
  id
});

export const updateDrug = (payload: Drug):TrialActionsTypes =>({
  type: UPDATE_DRUG,
  payload
});

export const updateDrugSucceeded = (payload: Drug):TrialActionsTypes =>({
  type: UPDATE_DRUG_SUCCEEDED,
  payload
});

export const fetchTreatmentLabelList = (id:string):TrialActionsTypes=>({
  type: FETCH_TREATMENTLABEL_LIST,
  id
});

export const fetchTreatmentLabelListSucceeded = (payload: TreatmentLabel[]):TrialActionsTypes => ({
  type: FETCH_TREATMENTLABEL_LIST_SUCCEEDED,
  payload
});

export const addTreatmentLabel = (payload: TreatmentLabel): TrialActionsTypes => ({
  type: ADD_TREATMENTLABEL,
  payload
});

export const addTreatmentLabelSucceeded = (payload: TreatmentLabel): TrialActionsTypes => ({
  type: ADD_TREATMENTLABEL_SUCCEEDED,
  payload
});

export const deleteTreatmentLabel = (trialId: string, id: string): TrialActionsTypes => ({
  type: DELETE_TREATMENTLABEL,
  trialId,
  id
});

export const deleteTreatmentLabelSucceeded = (id:string): TrialActionsTypes => ({
  type: DELETE_TREATMENTLABEL_SUCCEEDED,
  id
});

export const updateTreatmentLabel = (payload: TreatmentLabel): TrialActionsTypes => ({
  type: UPDATE_TREATMENTLABEL,
  payload
});

export const updateTreatmentLabelSucceeded = (payload: TreatmentLabel): TrialActionsTypes => ({
  type: UPDATE_TREATMENTLABEL_SUCCEEDED,
  payload
});

export const fetchTreatmentArmList = (id:string):TrialActionsTypes=>({
  type: FETCH_TREATMENTARM_LIST,
  id
});

export const fetchTreatmentArmListSucceeded = (payload: TreatmentArm[]):TrialActionsTypes => ({
  type: FETCH_TREATMENTARM_LIST_SUCCEEDED,
  payload
});

export const addTreatmentArm = (payload: TreatmentArm): TrialActionsTypes => ({
  type: ADD_TREATMENTARM,
  payload
});

export const addTreatmentArmSucceeded = (payload: TreatmentArm): TrialActionsTypes => ({
  type: ADD_TREATMENTARM_SUCCEEDED,
  payload
});

export const deleteTreatmentArm = (trialId:string, id:string): TrialActionsTypes => ({
  type: DELETE_TREATMENTARM,
  trialId,
  id
});

export const deleteTreatmentArmSucceeded = (id:string): TrialActionsTypes => ({
  type: DELETE_TREATMENTARM_SUCCEEDED,
  id
});

export const updateTreatmentArm = (payload: TreatmentArm): TrialActionsTypes => ({
  type: UPDATE_TREATMENTARM,
  payload
});

export const updateTreatmentArmSucceeded = (payload: TreatmentArm): TrialActionsTypes => ({
  type: UPDATE_TREATMENTARM_SUCCEEDED,
  payload
});

export const fetchTreatmentArmSummary = (id:string): TrialActionsTypes => ({
  type: FETCH_TREATMENT_ARM_SUMMARY,
  id
});

export const fetchTreatmentArmSummarySucceeded = (payload:any): TrialActionsTypes => ({
  type: FETCH_TREATMENT_ARM_SUMMARY_SUCCEEDED,
  payload
});

export const fetchTrialLabel = (id:string):TrialActionsTypes=>({
  type: FETCH_TRIALLABEL,
  id
});

export const fetchTrialLabelSucceeded = (payload: TrialLabel):TrialActionsTypes => ({
  type: FETCH_TRIALLABEL_SUCCEEDED,
  payload
});

export const fetchTrialLabelSize = ():TrialActionsTypes=>({
  type: FETCH_TRIALLABEL_SIZE
});

export const fetchTrialLabelSizeSucceeded = (payload: TrialLabel):TrialActionsTypes => ({
  type: FETCH_TRIALLABEL_SIZE_SUCCEEDED,
  payload
});

export const addTrialLabel = (id:string, payload: TrialLabel): TrialActionsTypes => ({
  type: ADD_TRIALLABEL,
  id,
  payload
});

export const addTrialLabelSucceeded = (payload: TrialLabel): TrialActionsTypes => ({
  type: ADD_TRIALLABEL_SUCCEEDED,
  payload
});

export const fetchTrialFile = (id:string): TrialActionsTypes => ({
  type: FETCH_TRIAL_FILE,
  id
});

export const fetchTrialFileSucceeded = (payload:File[]): TrialActionsTypes => ({
  type: FETCH_TRIAL_FILE_SUCCEEDED,
  payload
});

export const submitTrialFile = (id: string, payload:any): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_FILE,
  id,
  payload
});

export const submitTrialFileSucceeded = (payload:File): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_FILE_SUCCEEDED,
  payload
});

export const deleteTrialFile = (id:string, fileId: string): TrialActionsTypes => ({
  type: DELETE_TRIAL_FILE,
  id,
  fileId
});

export const deleteTrialFileSucceeded = (fileId:string): TrialActionsTypes => ({
  type: DELETE_TRIAL_FILE_SUCCEEDED,
  fileId
});

export const downloadTrialFile = (id:string, fileId: string): TrialActionsTypes => ({
  type: DOWNLOAD_TRIAL_FILE,
  id,
  fileId
});

export const downloadTrialFileSucceeded = (link:string): TrialActionsTypes => ({
  type: DOWNLOAD_TRIAL_FILE_SUCCEEDED,
  link
});

export const setTrialProgress = (payload:any): TrialActionsTypes => ({
  type: SET_TRIAL_PROGRESS,
  payload
});
export const updateTrialProgress = (payload:any): TrialActionsTypes => ({
  type: UPDATE_TRIAL_PROGRESS,
  payload
});

export const updateTrialProgressSucceeded = (payload:any): TrialActionsTypes => ({
  type: UPDATE_TRIAL_PROGRESS_SUCCEEDED,
  payload
});

export const fetchPackageOptions = (courierId:string): TrialActionsTypes => ({
  type: FETCH_PACKAGE_OPTIONS,
  courierId
});

export const fetchPackageOptionsSucceeded = (payload: PackageOptions[]): TrialActionsTypes => ({
  type: FETCH_PACKAGE_OPTIONS_SUCCEEDED,
  payload
});

export const fetchShipping = (id:string): TrialActionsTypes => ({
  type: FETCH_SHIPPING,
  id
});

export const fetchShippingSucceeded = (payload:Shipping): TrialActionsTypes => ({
  type: FETCH_SHIPPING_SUCCEEDED,
  payload
});

export const submitShipping = (payload:Shipping): TrialActionsTypes => ({
  type: SUBMIT_SHIPPING,
  payload
});

export const submitShippingSucceeded = (payload:Shipping): TrialActionsTypes => ({
  type: SUBMIT_SHIPPING_SUCCEEDED,
  payload
});

export const fetchUsers = (id:string): TrialActionsTypes => ({
  type: FETCH_USERS,
  id
});

export const fetchUsersSucceeded = (payload:User[]): TrialActionsTypes => ({
  type: FETCH_USERS_SUCCEEDED,
  payload
});

export const fetchTrialUserList = (id:string): TrialActionsTypes => ({
  type: FETCH_TRIAL_USERS_LIST,
  id
});

export const fetchTrialUserListSucceeded = (payload: User[]): TrialActionsTypes => ({
  type: FETCH_TRIAL_USERS_LIST_SUCCEEDED,
  payload
});

export const fetchTrialPatientUserList = (id:string): TrialActionsTypes => ({
  type: FETCH_TRIAL_PATIENT_USERS_LIST,
  id
});

export const fetchTrialPatientUserListSucceeded = (payload: User[]): TrialActionsTypes => ({
  type: FETCH_TRIAL_PATIENT_USERS_LIST_SUCCEEDED,
  payload
});

export const submitTrialUser = (trialId:string, payload: User): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_USER,
  trialId,
  payload
});

export const submitTrialUserSucceeded = (payload: User): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_USER_SUCCEEDED,
  payload
});

export const fetchStratification = (id:string): TrialActionsTypes => ({
  type: FETCH_STRATIFICATION,
  id
});

export const fetchStratificationSucceeded = (payload: Stratification): TrialActionsTypes => ({
  type: FETCH_STRATIFICATION_SUCCEEDED,
  payload
});

export const submitStratification = (id:string, payload:Stratification): TrialActionsTypes => ({
  type: SUBMIT_STRATIFICATION,
  id,
  payload
});

export const submitStratificationSucceeded = (payload: Stratification): TrialActionsTypes => ({
  type: SUBMIT_STRATIFICATION_SUCCEEDED,
  payload
});

export const toggleStratification = (enabled: boolean): TrialActionsTypes => ({
  type: TOGGLE_STRATIFICATION,
  enabled
});

export const fetchRandomisationEntries = (payload:any): TrialActionsTypes => ({
  type: FETCH_RANDOMISATION_ENTRIES,
  payload
});

export const fetchRandomisationEntriesSucceeded = (payload:any,append: boolean): TrialActionsTypes => ({
  type: FETCH_RANDOMISATION_ENTRIES_SUCCEEDED,
  payload,
  append
});

export const fetchAllRandomisationEntries = (payload:any) => ({
  type: FETCH_ALL_RANDOMISATION_ENTRIES,
  payload
});

export const fetchAllRandomisationEntriesSucceeded = (payload:any,append: boolean) => ({
  type: FETCH_ALL_RANDOMISATION_ENTRIES_SUCCEEDED,
  payload,
  append
});

export const fetchRandomisation = (id:string): TrialActionsTypes => ({
  type: FETCH_RANDOMISATION,
  id
});

export const fetchRandomisationSucceeded = (payload:any): TrialActionsTypes => ({
  type: FETCH_RANDOMISATION_SUCCEEDED,
  payload
});

export const submitRandomisation = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_RANDOMISATION,
  payload
});

export const submitRandomisationSucceeded = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_RANDOMISATION_SUCCEEDED,
  payload
});

export const fetchQARequest = (objectType: number, trialId: string): TrialActionsTypes => ({
  type: FETCH_QA_REQUEST, // GetMostRecentQARequest,
  objectType,
  trialId
});

export const fetchQARequestSucceeded = (payload:any): TrialActionsTypes => ({
  type: FETCH_QA_REQUEST_SUCCEEDED, // GetMostRecentQARequest,
  payload
});

export const fetchQA = (payload:any): TrialActionsTypes => ({
  type: FETCH_QA,
  payload
});

export const fetchQASucceeded = (payload:any): TrialActionsTypes => ({
  type: FETCH_QA_SUCCEEDED,
  payload
});

export const submitQA = (id:string, payload:any): TrialActionsTypes => ({
  type: SUBMIT_QA,
  id,
  payload
});

export const submitQASucceeded = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_QA_SUCCEEDED,
  payload
});

export const rejectQA = (payload:any):TrialActionsTypes => ({
  type: REJECT_QA,
  payload
});

export const rejectQASucceeded = (payload:any):TrialActionsTypes => ({
  type: REJECT_QA_SUCCEEDED,
  payload
});

export const approveQA = (id:string): TrialActionsTypes => ({
  type: APPROVE_QA,
  id
});

export const approveQASucceeded = (payload:any): TrialActionsTypes => ({
  type: APPROVE_QA_SUCCEEDED,
  payload
});


export const fetchTrialSummary = (id:string): TrialActionsTypes => ({
  type: FETCH_TRIAL_SUMMARY,
  id
});

export const fetchTrialSummarySucceeded = (payload:TrialSummary): TrialActionsTypes => ({
  type: FETCH_TRIAL_SUMMARY_SUCCEEDED,
  payload
});

export const submitTrialQA = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_QA,
  payload
});

export const submitTrialQASucceeded = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_QA_SUCCEEDED,
  payload
});

export const fetchTrialDetailsForBulkOrder = (trialId:string, bulkOrderId: string): TrialActionsTypes => ({
  type: FETCH_TRIAL_DETAILS_FOR_BULK_ORDER,
  trialId,
  bulkOrderId
});

export const fetchTrialDetailsForBulkOrderSucceeded = (payload:TrialDetails): TrialActionsTypes => ({
  type: FETCH_TRIAL_DETAILS_FOR_BULK_ORDER_SUCCEEDED,
  payload
});

export const fetchCoordinatorKvp = (siteId:string, trialId: string): TrialActionsTypes => ({
  type: FETCH_COORDINATOR_KVP,
  siteId,
  trialId
});

export const fetchCoordinatorKvpSucceeded = (payload:any): TrialActionsTypes => ({
  type: FETCH_COORDINATOR_KVP_SUCCEEDED,
  payload
});

export const fetchInvestigatorKvp = (siteId:string, trialId: string): TrialActionsTypes => ({
  type: FETCH_INVESTIGATOR_KVP,
  siteId,
  trialId
});

export const fetchInvestigatorKvpSucceeded = (payload:any): TrialActionsTypes => ({
  type: FETCH_INVESTIGATOR_KVP_SUCCEEDED,
  payload
});

export const submitBulkOrder = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_BULK_ORDER,
  payload
});

export const submitBulkOrderSucceeded = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_BULK_ORDER_SUCCEEDED,
  payload
});

export const setSelectedQATypeData = (payload:any): TrialActionsTypes => ({
  type: SET_SELECTED_QA_TYPE_DATA,
  payload
});
export const removeSelectedQATypeData = (): TrialActionsTypes => ({
  type: REMOVE_SELECTED_QA_TYPE_DATA
});

export const searchTrialRandomisation = (payload:any): TrialActionsTypes => ({
  type: SEARCH_TRIAL_RANDOMISATION,
  payload
});

export const searchTrialRandomisationSucceeded = (payload:any): TrialActionsTypes => ({
  type: SEARCH_TRIAL_RANDOMISATION_SUCCEEDED,
  payload
});

export const updateTrialRandomisation = (id: string, payload:any) : TrialActionsTypes => ({
  type: UPDATE_TRIAL_RANDOMISATION,
  id,
  payload
});

export const updateTrialRandomisationSucceeded = (payload:any) : TrialActionsTypes => ({
  type: UPDATE_TRIAL_RANDOMISATION_SUCCEEDED,
  payload
});


export const fetchSiteforBulkOrder = (id: string): TrialActionsTypes => ({
  type: FETCH_SITE_FOR_BULKORDER,
  id
});

export const fetchSiteforBulkOrderSucceeded = (payload: string): TrialActionsTypes => ({
  type: FETCH_SITE_FOR_BULKORDER_SUCCEEDED,
  payload
});

export const updateMultiTrialProgress = (id:string, payload:any): TrialActionsTypes => ({
  type: UPDATE_MULTI_TRIAL_PROGRESS,
  id,
  payload
});

export const updateMultiTrialProgressSucceeded = (payload:any): TrialActionsTypes => ({
  type: UPDATE_MULTI_TRIAL_PROGRESS_SUCCEEDED,
  payload
});

export const fetchAvailableInventories = (trialId:string, treatmentId:string, siteId:string, stratificationId:string) => ({
  type: FETCH_AVAILABLE_INVENTORIES,
  trialId,
  treatmentId,
  siteId,
  stratificationId
})

export const fetchAvailableInventoriesSucceeded = (treatmentId:string, payload:any) => ({
  type: FETCH_AVAILABLE_INVENTORIES_SUCCEEDED,
  treatmentId,
  payload
})

export const resetAvailableInventories = () => ({
  type: RESET_AVAILABLE_INVENTORIES
})

export const fetchTrialArchiveReports = (id: string): TrialActionsTypes => ({
  type: FETCH_TRIAL_ARCHIVE_REPORTS,
  id
});

export const fetchTrialArchiveReportsSucceeded = (payload: any): TrialActionsTypes => ({
  type: FETCH_TRIAL_ARCHIVE_REPORTS_SUCCEEDED,
  payload
});

export const submitTrialArchive = (id: string): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_ARCHIVE,
  id
});

export const submitTrialArchiveSucceeded = (payload:any): TrialActionsTypes => ({
  type: SUBMIT_TRIAL_ARCHIVE_SUCCEEDED,
  payload
});