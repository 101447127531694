import React from 'react';

export const despensingRouteConfig = [
  {
    auth: ["Admin", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/dispensings',
        component: React.lazy(() => import('./DispensingList')),
      },
      {
        path: '/dispensing/:prescriptionId',
        component:  React.lazy(() => import('./DetailsPage'))
      }
    ],
  },
];

