import { 
  ManifestActionsTypes,
  FETCH_CONSIGNMENT_LIST,
  FETCH_CONSIGNMENT_LIST_SUCCEEDED,
  SUBMIT_MANIFEST,
  SUBMIT_MANIFEST_SUCCEEDED,
  RESET_MANIFEST
} from "../../types/actions/Manifest.actions";

export const fetchConsignmentList = ():ManifestActionsTypes => ({
  type: FETCH_CONSIGNMENT_LIST
});

export const fetchConsignmentListSuccedded = (payload: any): ManifestActionsTypes => ({
  type: FETCH_CONSIGNMENT_LIST_SUCCEEDED,
  payload
});

export const submitManifest = (payload: any): ManifestActionsTypes => ({
  type: SUBMIT_MANIFEST,
  payload
});

export const submitManifestSucceeded = (payload: any): ManifestActionsTypes => ({
  type: SUBMIT_MANIFEST_SUCCEEDED,
  payload
});

export const resetManifest = (): ManifestActionsTypes => ({
  type: RESET_MANIFEST
});