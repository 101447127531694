import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { 
  fetchTrialStocktakeListSucceeded,
  fetchStocktakeListSucceeded,
  updateStocktakeSucceeded,
  fetchInventoryRandomisationSucceeded,
  deleteRandomisationSucceeded,
  deleteKitNumberSucceeded,
  deleteBottleNumberSucceeded
} from "../actions/Stocktake";

const baseUrlv1 = 'api/v1';

function fetchTrialStocktakeListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, trialId  } = payload
  const url = `${baseUrlv1}/stocktake/?trialId=${trialId}&PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchStocktakeListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, id } = payload
  const url = `${baseUrlv1}/stocktake/${id}?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function updateStocktakeApi(payload:any) {
  const url = `${baseUrlv1}/stocktake/${payload.stocktakeId}`;
  return Api.fetch(url, getPutParams(payload));
}
//randomisation/{trialId}/{inventoryId}
function fetchInventoryRandomisationApi(trialId:string, inventoryId:string) {
  const url = `${baseUrlv1}/randomisation/${trialId}/${inventoryId}`;
  return Api.fetch(url, getGetParams());
}

function deleteRandomisationApi(id:string, reason: string) {
  const url = `${baseUrlv1}/randomisation/${id}?reason=${reason}`;
  return Api.fetch(url, getDeleteParams())
}

function deleteKitNumberApi(id:string, reason: string) {
  const url = `${baseUrlv1}/kitnumbers/${id}?reason=${reason}`;
  return Api.fetch(url, getDeleteParams())
}

function deleteBottleNumberApi(id:string, reason: string) {
  const url = `${baseUrlv1}/bottlenumbers/${id}?reason=${reason}`;
  return Api.fetch(url, getDeleteParams())
}

export function* fetchTrialStocktakeList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchTrialStocktakeListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchTrialStocktakeListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchStocktakeList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchStocktakeListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchStocktakeListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateStocktake({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateStocktakeApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateStocktakeSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInventoryRandomisation({trialId, inventoryId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchInventoryRandomisationApi, trialId, inventoryId);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchInventoryRandomisationSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteRandomisation({id, reason}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteRandomisationApi, id, reason);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteRandomisationSucceeded());
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteKitNumber({id, reason}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteKitNumberApi, id, reason);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteKitNumberSucceeded());
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteBottleNumber({id, reason}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteBottleNumberApi, id, reason);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteBottleNumberSucceeded());
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export default [
  takeLatest('FETCH_TRIAL_STOCKTAKE_LIST' ,fetchTrialStocktakeList),
  takeLatest('FETCH_STOCKTAKE_LIST' ,fetchStocktakeList),
  takeLatest('UPDATE_STOCKTAKE' ,updateStocktake),
  takeLatest('FETCH_INVENTORY_RANDOMISATION' ,fetchInventoryRandomisation),
  takeLatest('DELETE_RANDOMISATION' ,deleteRandomisation),
  takeLatest('DELETE_KIT_NUMBER' ,deleteKitNumber),
  takeLatest('DELETE_BOTTLE_NUMBER' ,deleteBottleNumber),
]