import React from 'react';

export const trialRouteConfig = [
  {
    auth: ["Admin", "Monitor", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant", "Site", "Principal Investigators", "Quality Associate QA", "Sponsor", "Sponsor Representative", "Study Coordinators"],
    routes: [
      {
        path: '/trials',
        exact: true,
        component: React.lazy(() => import('./Trials')),
      },
      {
        auth: ["Admin"],
        path: '/trials/create',
        exact: true,
        component: React.lazy(() => import('./TrialDetails'))
      },
      {
        auth: ["Admin", "Quality Associate QA"],
        path: '/trials/:id',
        exact: true,
        component: React.lazy(() => import('./TrialDetails'))
      },
      {
        path: '/trials/:id/qa',
        exact: true,
        activeTab: 16,
        component: React.lazy(() => import('./TrialDetails'))
      },
      {
        path: '/trials/:id/summary',
        exact: true,
        component: React.lazy(() => import('./TrialSummary'))
      },
      {
        path: '/trials/:id/bulkorder',
        exact: true,
        component: React.lazy(() => import('./AddBulkOrder'))
      },
      {
        path: '/trials/:id/patients/:patientId',
        exact: true,
        component: React.lazy(() => import('../patients/PatientDetails'))
      },
      {
        path: '/trials/:id/patients/:patientId/prescription/create',
        exact: true,
        component: React.lazy(() => import('../patients/Prescription'))
      },
      {
        path: '/prescriptions/view/:prescriptionId',
        exact: true,
        key: 'view',
        component: React.lazy(() => import('../patients/Prescription'))
      }
    ],
  },
];
