import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { 
  fetchSiteListSucceeded, 
  submitSiteSucceeded, 
  updateSiteSucceeded, 
  deleteSiteSucceeded, 
  fetchSiteByIdSucceeded,
} from "../actions/Sites";

const baseUrlv1 = 'api/v1';

function fetchSiteListByIdApi(id:string) {
  const url = `${baseUrlv1}/Sites/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchSiteListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/Sites?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function submitSiteApi (payload:any) {
  const url = `${baseUrlv1}/Sites`;
  return Api.fetch(url, getPostParams(payload))
}

function updateSiteApi(payload:any) {
  const url = `${baseUrlv1}/Sites`;;
  return Api.fetch(url, getPutParams(payload))
} 

function deleteSiteApi(id:number) {
  const url = `${baseUrlv1}/Sites/${id}`;
  return Api.fetch(url, getDeleteParams())
}

export function* fetchSiteById({id}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchSiteListByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchSiteByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No Site found"
      }else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSiteList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchSiteListApi, payload);
    if(!response.error) {
      let pagedData = [...response.pagedData];
      if(pagedData.length > 0) {
        pagedData = pagedData.map((list:any) => {
          const site = {
            ...list, 
            fullAddress: `${list.streetNumber ? list.streetNumber + ',' : ''} ${list.address}, ${list.city}, ${list.postcode} ${list.state}, ${list.country}`
          }
          return site;
        }) 
      }
      yield put(fetchSuccess(true));
      yield put(fetchSiteListSucceeded({...response, pagedData}, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitSite({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitSiteApi, payload);
    if(!response.error) {
      const fullAddress = `${response.streetNumber ? response.streetNumber + ',' : ''} ${response.address}, ${response.city}, ${response.postcode} ${response.state}, ${response.country}`;
      yield put(fetchSuccess(true));
      yield put(submitSiteSucceeded({...response, fullAddress}))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateSite({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateSiteApi, {...payload, id});
    if(!response.error) {
      const fullAddress = `${response.streetNumber ? response.streetNumber + ',' : ''} ${response.address}, ${response.city}, ${response.postcode} ${response.state}, ${response.country}`;
      yield put(fetchSuccess(true));
      yield put(updateSiteSucceeded(id, {...payload, fullAddress}))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteSite({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deleteSiteApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteSiteSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export default [
  takeLatest('FETCH_SITE_BY_ID' ,fetchSiteById),
  takeLatest('FETCH_SITE_LIST' ,fetchSiteList),
  takeLatest('SUBMIT_SITE' ,submitSite),
  takeLatest('UPDATE_SITE' ,updateSite),
  takeLatest('DELETE_SITE' ,deleteSite)
]