import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams } from "../../@crema/utility/Utils";
import { 
  fetchSiteShippingListSucceeded,
  fetchSiteShippingDetailListSucceeded,
  fetchSiteShippedListSucceeded,
  fetchSiteToShipDetailListSucceeded,
  addParcelInfoSucceeded,
  UpdateShippingInfoSucceeded,
  fetchSiteShippingPickingSlipSucceeded,
  fetchSiteReceivedListSucceeded,
  updateReceivedSucceeded,
  updatePriceSucceeded,
  fetchShippingHistoryListSucceeded
} from "../actions/SiteShipping";

const baseUrlv1 = 'api/v1';

function fetchSiteShippingListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/SiteShipping/packed?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchSiteShippedListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/siteshipping/shipped?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchSiteToShipDetailListApi(payload:any) {
  const { id, trialId } = payload
  const url = `${baseUrlv1}/siteshipping/toshipdetail/${id}/${trialId}`;
  return Api.fetch(url, getGetParams());
}

function fetchSiteShippingDetailListApi(payload:any) {
  const { id } = payload
  const url = `${baseUrlv1}/siteshipping/sitedetail/${id}`;
  return Api.fetch(url, getGetParams());
}

function addParcelInfoApi(payload:any) {
  const url = `${baseUrlv1}/siteshipping`;;
  return Api.fetch(url, getPostParams(payload))
} 

function updateShippingInfoApi(payload:any) {
  const {id} = payload;
  const url = `${baseUrlv1}/siteshipping/received/${id}`;;
  return Api.fetch(url, getPutParams(payload))
} 

function fetchSiteShippingPickingSlipApi(payload:any) {
  const { id, blinded, trialId, status, bulkOrderId } = payload;
  let url;
  if(status === 'toShip') url = `${baseUrlv1}/siteshipping/pickingslip/${id}/${trialId}/${blinded}`;
  else if(status === 'toPick') url = `${baseUrlv1}/siteshipping/printpickingslip/${id}/${trialId}/${bulkOrderId}`
  else url = `${baseUrlv1}/siteshipping/pickingslip/${id}/${blinded}`;
  return Api.fetch(url, getGetParams());
}

function fetchSiteReceivedListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/SiteShipping/received?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function updateReceivedApi(id: string) {
  const url = `${baseUrlv1}/shipping/consignment/siteshipping/${id}`;
  return Api.fetch(url, getGetParams());
}

function updatePriceApi(id:string, price: number) {
  const url = `${baseUrlv1}/shipping/consignment/${id}/${price}`;;
  return Api.fetch(url, getPutParams({}))
}

function fetchShippingHistoryListApi(payload:any) {
  const { allData, sponsorId, fromDate, toDate } = payload;
  const url = `${baseUrlv1}/shipping/shipping/GetShipmentDetail/${allData}?sponsorId=${sponsorId}&fromDate=${fromDate}&toDate=${toDate}`;;
  return Api.fetch(url, getPostParams({}))
}

export function* fetchSiteShippingList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchSiteShippingListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchSiteShippingListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSiteToShipDetailList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchSiteToShipDetailListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchSiteToShipDetailListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSiteShippingDetailList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchSiteShippingDetailListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchSiteShippingDetailListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSiteShippedList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchSiteShippedListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchSiteShippedListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* addParcelInfo({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(addParcelInfoApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(addParcelInfoSucceeded({...payload, ...response}))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateShippingInfo({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateShippingInfoApi, {...payload, id});
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(UpdateShippingInfoSucceeded(payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSiteShippingPickingSlip({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchSiteShippingPickingSlipApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchSiteShippingPickingSlipSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSiteReceivedList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchSiteReceivedListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchSiteReceivedListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateReceived({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateReceivedApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateReceivedSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updatePrice({id, price}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updatePriceApi, id, price);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updatePriceSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchShippingHistoryList({payload}:any) {
  yield put(fetchStart(true));
  try {
    const { append} = payload;
    const response  = yield call(fetchShippingHistoryListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchShippingHistoryListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_SITE_SHIPPING_LIST' ,fetchSiteShippingList),
  takeLatest('FETCH_SITE_TO_SHIP_DETAIL_LIST' ,fetchSiteToShipDetailList),
  takeLatest('FETCH_SITE_SHIPPING_DETAIL_LIST' ,fetchSiteShippingDetailList),
  takeLatest('FETCH_SITE_SHIPPED_LIST' ,fetchSiteShippedList),
  takeLatest('UPDATE_SHIPPING_INFO' ,updateShippingInfo),
  takeLatest('ADD_PARCEL_INFO' ,addParcelInfo),
  takeLatest('FETCH_SITE_SHIPPING_PICKING_SLIP' ,fetchSiteShippingPickingSlip),
  takeLatest('FETCH_SITE_RECEIVED_LIST' ,fetchSiteReceivedList),
  takeLatest('UPDATE_RECEIVED' ,updateReceived),
  takeLatest('UPDATE_PRICE' ,updatePrice),
  takeLatest('FETCH_SHIPPING_HISTORY' ,fetchShippingHistoryList),
]