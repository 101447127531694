import React from 'react';

export const inventoryRouteConfig = [
  {
    auth: ["Admin", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/inventory',
        component: React.lazy(() => import('./Inventory')),
      },
    ],
  },
];
