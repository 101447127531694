import { SiteList, Payload } from "../models/Sites";
import { GetQueryParams } from "../models/Common";

export const FETCH_SITE_LIST = "FETCH_SITE_LIST";
export const SUBMIT_SITE = "SUBMIT_SITE";
export const UPDATE_SITE = "UPDATE_SITE";
export const DELETE_SITE = "DELETE_SITE";
export const FETCH_SITE_BY_ID = "FETCH_SITE_BY_ID";
export const SET_SELECTED_SITE = "SET_SELECTED_SITE"
export const REMOVE_SELECTED_SITE = "REMOVE_SELECTED_SITE"
export const RESET_SITE = "RESET_SITE";
export const FETCH_SITE_LIST_SUCCEEDED = "FETCH_SITE_LIST_SUCCEEDED";
export const FETCH_SITE_BY_ID_SUCCEEDED = "FETCH_SITE_BY_ID_SUCCEEDED";
export const SUBMIT_SITE_SUCCEEDED = "SUBMIT_SITE_SUCCEEDED";
export const UPDATE_SITE_SUCCEEDED = "UPDATE_SITE_SUCCEEDED";
export const DELETE_SITE_SUCCEEDED = "DELETE_SITE_SUCCEEDED";
export const RESET_FETCHCOMPLETE = "RESET_FETCHCOMPLETE";

export interface FetchSiteListActions {
  type: typeof FETCH_SITE_LIST,
  payload: GetQueryParams
}

export interface FetchSiteListSucceededActions {
  type: typeof FETCH_SITE_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchSiteByIdActions {
  type: typeof FETCH_SITE_BY_ID;
  id: string
}

export interface SetSelectedSiteActions {
  type: typeof SET_SELECTED_SITE;
  id: string
}

export interface FetchSiteByIdSucceededActions {
  type: typeof FETCH_SITE_BY_ID_SUCCEEDED;
  payload: SiteList
}

export interface RemoveSelectedSiteActions {
  type: typeof REMOVE_SELECTED_SITE
}

export interface SubmitSiteActions {
  type: typeof SUBMIT_SITE;
  payload: SiteList | null;
}

export interface SubmitSiteSucceededActions {
  type: typeof SUBMIT_SITE_SUCCEEDED;
  payload: SiteList;
}

export interface UpdateSiteActions {
  type: typeof UPDATE_SITE;
  id: string;
  payload: SiteList;
}

export interface UpdateSiteSucceededActions {
  type: typeof UPDATE_SITE_SUCCEEDED;
  id: string;
  payload: SiteList;
}

export interface DeleteSiteActions {
  type: typeof DELETE_SITE;
  id: string
}

export interface DeleteSiteSuceededActions {
  type: typeof DELETE_SITE_SUCCEEDED;
  id: string
}

export interface ResetSiteActions {
  type: typeof RESET_SITE
}

export interface  ResetFetchCompleteActions {
  type: typeof RESET_FETCHCOMPLETE
}

export type SiteActionsTypes =
  | FetchSiteListActions
  | SubmitSiteActions
  | UpdateSiteActions
  | DeleteSiteActions
  | FetchSiteListSucceededActions
  | FetchSiteByIdSucceededActions
  | SubmitSiteSucceededActions
  | UpdateSiteSucceededActions
  | DeleteSiteSuceededActions
  | FetchSiteByIdActions
  | SetSelectedSiteActions
  | RemoveSelectedSiteActions
  | ResetSiteActions
  | ResetFetchCompleteActions
  ;