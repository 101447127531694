import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from "../actions/Common";
import { Api, getGetParams, getPostParams } from "../../@crema/utility/Utils";

import { 
  fetchConsignmentListSuccedded,
  submitManifestSucceeded
} from "../actions/Manifest";

const baseUrlv1 = 'api/v1';

function fetchConsignmentListApi() {
  // const { PageSize, PageNumber, SearchTerm  } = payload
  // const url = `${baseUrlv1}/shipping/consigmentsformanifesting?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  const url = `${baseUrlv1}/shipping/consignmentsformanifesting`;
  return Api.fetch(url, getGetParams());
}

function submitManifestApi (payload:any) {
  const url = `${baseUrlv1}/shipping/createmanifests`;
  return Api.fetch(url, getPostParams(payload))
}


export function* fetchConsignmentList() {
  yield put(fetchStart());
  try {
    const response = yield call(fetchConsignmentListApi);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchConsignmentListSuccedded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitManifest({payload}:any) {
  yield put(fetchStart(true)); // backdrop enable
  try {
    const response  = yield call(submitManifestApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitManifestSucceeded(payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export default [
  takeLatest("FETCH_CONSIGNMENT_LIST" ,fetchConsignmentList),
  takeLatest("SUBMIT_MANIFEST" ,submitManifest),
]