import { Reports } from "../../types/models/Reports";
import { FETCH_BILLING_REPORTS_SUCCEEDED, ReportActionTypes } from "types/actions/Reports.actions";

const initialState:Reports = {
  files: null,
  yearMonths: null
}

export default (state = initialState, action: ReportActionTypes): Reports => {
  switch (action.type) {
    case FETCH_BILLING_REPORTS_SUCCEEDED: {
      const { files, yearMonths } = action.payload
      return {
        ...state,
        files,
        yearMonths
      }
    }
    default: {
      return state;
    } 
  }
}