// import { SiteShippingListPayload } from "../../types/models/SiteShipping";
import { GetQueryParams } from "../../types/models/Common";
import {
  FETCH_SITE_SHIPPED_LIST,
  FETCH_SITE_SHIPPED_LIST_SUCCEEDED,
  FETCH_SITE_SHIPPING_LIST,
  FETCH_SITE_SHIPPING_LIST_SUCCEEDED,
  SiteShippingActionsTypes,
  FETCH_SITE_TO_SHIP_DETAIL_LIST,
  FETCH_SITE_TO_SHIP_DETAIL_LIST_SUCCEEDED,
  FETCH_SITE_SHIPPING_DETAIL_LIST,
  FETCH_SITE_SHIPPING_DETAIL_LIST_SUCCEEDED,
  ADD_PARCEL_INFO,
  ADD_PARCEL_INFO_SUCCEEDED,
  UPDATE_SHIPPING_INFO,
  UPDATE_SHIPPING_INFO_SUCCEEDED,
  FETCH_SITE_SHIPPING_PICKING_SLIP,
  FETCH_SITE_SHIPPING_PICKING_SLIP_SUCCEEDED,
  RESET_SITE_SHIPPING,
  RESET_SITE_SHIPPING_SLIP,
  RESET_SITE_SHIPPING_DETAILS,
  FETCH_SITE_RECEIVED_LIST,
  FETCH_SITE_RECEIVED_LIST_SUCCEEDED,
  UPDATE_RECEIVED,
  UPDATE_RECEIVED_SUCCEEDED,
  UPDATE_PRICE,
  UPDATE_PRICE_SUCCEEDED,
  RESET_RECEIVED,
  FETCH_SHIPPING_HISTORY,
  FETCH_SHIPPING_HISTORY_SUCCEEDED,
  RESET_SHIPPING_HISTORY
} from "../../types/actions/SiteShipping.action";

export const fetchSiteShippingList = (payload: GetQueryParams) => ({
  type: FETCH_SITE_SHIPPING_LIST,
  payload
});

export const fetchSiteShippedList = (payload: GetQueryParams) => ({
  type: FETCH_SITE_SHIPPED_LIST,
  payload
});

export const fetchSiteShippingListSucceeded = (payload:any, append:boolean): SiteShippingActionsTypes=> ({
  type: FETCH_SITE_SHIPPING_LIST_SUCCEEDED,
  payload,
  append
});

export const fetchSiteShippedListSucceeded = (payload:any, append:boolean): SiteShippingActionsTypes=> ({
  type: FETCH_SITE_SHIPPED_LIST_SUCCEEDED,
  payload,
  append
});

export const fetchSiteToShipDetailList = (payload:GetQueryParams) => ({
  type: FETCH_SITE_TO_SHIP_DETAIL_LIST,
  payload
});

export const fetchSiteToShipDetailListSucceeded = (payload:any, append:boolean): SiteShippingActionsTypes => ({
  type: FETCH_SITE_TO_SHIP_DETAIL_LIST_SUCCEEDED,
  payload,
  append
});

export const fetchSiteShippingDetaiList = (payload: GetQueryParams) => ({
  type: FETCH_SITE_SHIPPING_DETAIL_LIST,
  payload
});

export const fetchSiteShippingDetailListSucceeded = (payload:any, append:boolean): SiteShippingActionsTypes=> ({
  type: FETCH_SITE_SHIPPING_DETAIL_LIST_SUCCEEDED,
  payload,
  append
});

export const addParcelInfo = (payload: any) => ({
  type: ADD_PARCEL_INFO,
  payload
});

export const addParcelInfoSucceeded = (payload: any) => ({
  type: ADD_PARCEL_INFO_SUCCEEDED,
  payload
});

export const updateShippingInfo = (id: string) => ({
  type: UPDATE_SHIPPING_INFO,
  id
});

export const UpdateShippingInfoSucceeded = (payload: any) => ({
  type: UPDATE_SHIPPING_INFO_SUCCEEDED,
  payload
});

export const fetchSiteShippingPickingSlip = (payload: any): SiteShippingActionsTypes => ({
  type: FETCH_SITE_SHIPPING_PICKING_SLIP,
  payload
});

export const fetchSiteShippingPickingSlipSucceeded = (payload:any): SiteShippingActionsTypes=> ({
  type: FETCH_SITE_SHIPPING_PICKING_SLIP_SUCCEEDED,
  payload
});

export const resetSiteShippingSlip = (): any=> ({
  type: RESET_SITE_SHIPPING_SLIP
});

export const resetSiteShipping = (): SiteShippingActionsTypes=> ({
  type: RESET_SITE_SHIPPING
});

export const resetSiteShippingDetails = (): SiteShippingActionsTypes=> ({
  type: RESET_SITE_SHIPPING_DETAILS
});

export const fetchSiteReceivedList = (payload: GetQueryParams) => ({
  type: FETCH_SITE_RECEIVED_LIST,
  payload
});

export const fetchSiteReceivedListSucceeded = (payload:any, append:boolean): SiteShippingActionsTypes=> ({
  type: FETCH_SITE_RECEIVED_LIST_SUCCEEDED,
  payload,
  append
});

export const updateReceived = (id: string) => ({
  type: UPDATE_RECEIVED,
  id
});

export const updateReceivedSucceeded = (payload: any) => ({
  type: UPDATE_RECEIVED_SUCCEEDED,
  payload
});

export const updatePrice = (id: string, price: number) => ({
  type: UPDATE_PRICE,
  id,
  price
})

export const updatePriceSucceeded = (payload:any) => ({
  type: UPDATE_PRICE_SUCCEEDED,
  payload
}) 

export const resetReceived = () => ({
  type: RESET_RECEIVED
})

export const fetchShippingHistoryList = (payload: any) => ({
  type: FETCH_SHIPPING_HISTORY,
  payload
})

export const fetchShippingHistoryListSucceeded = (payload: any, append: boolean) => ({
  type: FETCH_SHIPPING_HISTORY_SUCCEEDED,
  payload,
  append
})

export const resetShippingHistorySucceeded = (payload: any) => ({
  type: RESET_SHIPPING_HISTORY,
  payload
})