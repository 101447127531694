import { SiteShipping } from "../../types/models/SiteShipping";
import { 
  SiteShippingActionsTypes,
  FETCH_SITE_SHIPPED_LIST_SUCCEEDED,
  FETCH_SITE_SHIPPING_LIST_SUCCEEDED,
  FETCH_SITE_TO_SHIP_DETAIL_LIST_SUCCEEDED,
  FETCH_SITE_SHIPPING_DETAIL_LIST_SUCCEEDED,
  ADD_PARCEL_INFO_SUCCEEDED,
  UPDATE_SHIPPING_INFO_SUCCEEDED,
  FETCH_SITE_SHIPPING_PICKING_SLIP_SUCCEEDED,
  RESET_SITE_SHIPPING,
  RESET_SITE_SHIPPING_DETAILS,
  FETCH_SITE_RECEIVED_LIST_SUCCEEDED,
  UPDATE_RECEIVED_SUCCEEDED,
  RESET_RECEIVED,
  RESET_SITE_SHIPPING_SLIP,
  FETCH_SHIPPING_HISTORY_SUCCEEDED,
  RESET_SHIPPING_HISTORY
} from "../../types/actions/SiteShipping.action";

const initialState: SiteShipping = {
  siteShippedList: [],
  siteShippingList: [],
  siteShippingDetailList: [],
  siteReceivedList: [],
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  parcelInfoAdded: false,
  parcelInfo: null,
  markReceived: false,
  siteShippingPickingSlip: null,
  courierName: '',
  trackingCode: '',
  courierId: '',
  price: '',
  siteName: '',
  trialName: '',
  selectedSiteReceived: null,
  shippingHistoryList: []
}

export default (state = initialState, action: SiteShippingActionsTypes): SiteShipping => {
  switch (action.type) {
    case FETCH_SITE_SHIPPING_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const { pagedData, ...rest } = payload;
      const lists = pagedData;
      const siteShippingList = append ? [...state.siteShippingList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        siteShippingList
      }

      /* const { payload} = action;
      const siteShippingList = payload;
      return {
        ...state,
        siteShippingList
      } */
    }
    case FETCH_SITE_SHIPPED_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const { pagedData, ...rest } = payload;
      const lists = pagedData;
      const siteShippedList = append ? [...state.siteShippedList, ...lists] : lists;

      return {
        ...state,
        ...rest,
        siteShippedList
      }

      /* const { payload} = action;
      const siteShippedList = payload;
      return {
        ...state,
        siteShippedList
      } */
    }
    case FETCH_SITE_RECEIVED_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const siteReceivedList = append ? [...state.siteReceivedList, ...lists] : lists;

      return {
        ...state,
        ...rest,
        siteReceivedList
      } 
    }
    case UPDATE_RECEIVED_SUCCEEDED: {
      const selectedSiteReceived = action.payload;
      return {
        ...state,
        selectedSiteReceived
      }
    }
    case FETCH_SITE_TO_SHIP_DETAIL_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {scriptList, courierName, trackingCode, ...rest} = payload;
      const lists = scriptList;
      const siteShippingDetailList = append ? [...state.siteShippingDetailList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        courierName,
        trackingCode,
        siteShippingDetailList
      }
    }

    case FETCH_SITE_SHIPPING_DETAIL_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {scriptList, courierName, trackingCode, ...rest} = payload;
      const lists = scriptList;
      const siteShippingDetailList = append ? [...state.siteShippingDetailList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        courierName,
        trackingCode,
        siteShippingDetailList
      }
    }
    case ADD_PARCEL_INFO_SUCCEEDED: {
      const {
        parcelHeight, parcelWidth, parcelWeight,parcelDepth, numberOfBoxes, shippingTemperature,
        id: siteShippingId, courierId, deliverySiteAddress
      } = action.payload;
      // const toAddress = `${site.streetNumber} ${site.address}, ${site.city}, ${site.postcode} ${site.state}, ${site.country}`;
      const toAddress = deliverySiteAddress;
      const parcelInfo = {parcelHeight, parcelWidth, parcelWeight,parcelDepth, numberOfBoxes, shippingTemperature, courierId, siteShippingId, toAddress}
      
      return {
        ...state,
        parcelInfoAdded: true,
        parcelInfo
      }
    }
    case UPDATE_SHIPPING_INFO_SUCCEEDED: {
      return {
        ...state,
        markReceived: true
      }
    }
    case FETCH_SITE_SHIPPING_PICKING_SLIP_SUCCEEDED: {
      const { payload } = action;
      const siteShippingPickingSlip = {...payload, siteAddress: payload.siteAddress ? payload.siteAddress.trim() : payload.siteAddress};
      return {
        ...state,
        siteShippingPickingSlip
      }
    }
    case RESET_SITE_SHIPPING_SLIP: {
      return {
        ...state,
        siteShippingPickingSlip: null
      }
    }
    case RESET_SITE_SHIPPING: {
      return {
        ...state,
        ...initialState
      }
    }
    case RESET_SITE_SHIPPING_DETAILS: {
      return {
        ...state,
        siteShippingDetailList: [],
        courierName: '',
        trackingCode: '',
        price: '',
        selectedSiteReceived: null
      }
    }
    case RESET_RECEIVED: {
      return{
        ...state,
        selectedSiteReceived: null
      }
    }
    case FETCH_SHIPPING_HISTORY_SUCCEEDED: {
      const { append, payload} = action;
      // const {pagedData, ...rest} = payload;
      const lists = payload;
      const shippingHistoryList = append ? [...state.shippingHistoryList, ...lists] : lists;

      return {
        ...state,
        // ...rest,
        shippingHistoryList
      } 
    }
    case RESET_SHIPPING_HISTORY: {
      return {
        ...state,
        shippingHistoryList: null
      } 
    }
    default:{
      return state;
    }
  }
}