import {Setting} from '../../types/models/Setting';
import {
  ROUTE_CHANGE,
  SET_INITIAL_PATH,
  SettingsActionTypes,
  TOGGLE_NAV_COLLAPSED,
  SET_SETTINGS,
  RESET_SETTINGS
} from '../../types/actions/Settings.action';

const initialSettings: Setting = {
  serverSettings: process.env.NODE_ENV !== 'development' ? false : true,
  navCollapsed: false,
  initialPath: "/",
  AUTH0_ClientId: `${process.env.NODE_ENV !== 'development' ? '' : '3xeQEuRScslee1qEreEH9wH3MjAbz0wH'}`,
  AUTH0_DOMAIN: `${process.env.NODE_ENV !== 'development' ? '' : 'syntro.au.auth0.com'}`,
  AUTH0_redirect_uri: `${process.env.NODE_ENV !== 'development' ? '' : 'http://localhost:3000/signin'}`,
  AUTH0_audience: `${process.env.NODE_ENV !== 'development' ? '' : 'http://syntro-dev-api.azurewebsites.net/'}`,
  baseApiUrl: `${process.env.NODE_ENV !== 'development' ? '' : 'http://syntro-dev-api.azurewebsites.net/'}`
};

const settings = (state = initialSettings, action: SettingsActionTypes) => {
  switch (action.type) {
    case ROUTE_CHANGE:
      return {
        ...state,
        navCollapsed: false,
      };

    case TOGGLE_NAV_COLLAPSED:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };

    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPath: action.initialPath,
      };
    case SET_SETTINGS:
      const { AUTH0_ClientId, AUTH0_DOMAIN, AUTH0_audience, 
        AUTH0_redirect_uri, baseApiUrl 
      } = action.payload;
      return {
        ...state,
        serverSettings: true,
        AUTH0_ClientId,
        AUTH0_DOMAIN,
        AUTH0_audience,
        AUTH0_redirect_uri,
        baseApiUrl
      };
    case RESET_SETTINGS: {
      return {
        ...state,
        ...initialSettings
      }
    }
    default:
      return state;
  }
};

// export const getBaseUrl = (state: AppState) => state.settings.baseApiUrl;

export default settings;
