import { ReturnInventory } from "../../types/models/ReturnInventory";
import { 
  ReturnInventoryActionsTypes,
  FETCH_RETURN_INVENTORY_LIST_SUCCEEDED,
  SUBMIT_RETURN_INVENTORY_SUCCEEDED,
  REMOVE_SELECTED_RETURN_INVENTORY,
  UPDATE_RETURN_INVENTORY_SUCCEEDED,
  SET_SELECTED_RETURN_INVENTORY,
  DELETE_RETURN_INVENTORY_SUCCEEDED,
  FETCH_RETURN_INVENTORY_BY_ID_SUCCEEDED,
  RESET_RETURN_INVENTORY,
  FETCH_ACTIVE_RETURNED_LIST_SUCCEEDED,
  FETCH_DESTROYED_BATCH_LIST_SUCCEEDED,
  FETCH_DESTROYED_STOCK_REPORT_SUCCEEDED,
  UPDATE_DESTROYED_STOCK_REPORT_SUCCEEDED,
  MARK_STOCK_REPORT_SUCCEEDED,
  LOOKUP_PATIENT_SUCCEEDED,
  RESET_LOOKUP_PATIENT,
  RESET_DESTROYED_STOCK_REPORT,
  RESET_ACTIVE_RETURNED_LIST,
  RESET_DESTROYED_BATCH_LIST
} from "../../types/actions/ReturnInventory.action";

const initialState: ReturnInventory = {
  returnInventoryList: [],
  activeReturnedInventoryList: [],
  destroyedBatchList: [],
  destroyedStockReport: null,
  selectedReturnInventory: null,
  patientDetails: null,
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0
}

export default (state = initialState, action: ReturnInventoryActionsTypes): ReturnInventory => {
  switch (action.type) {
    case FETCH_RETURN_INVENTORY_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const returnInventoryList = append ? [...state.returnInventoryList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        returnInventoryList
      }
    }
    case SUBMIT_RETURN_INVENTORY_SUCCEEDED: {
      const { payload } = action
      const totalResults:number = state.totalResults + 1;
      // const returnInventoryList = [...state.returnInventoryList, payload]
      let returnInventoryList = state.returnInventoryList.filter((list:any) => list.returnBatchId !== payload.returnBatchId)
      returnInventoryList = [...returnInventoryList, payload];
      return {
        ...state,
        returnInventoryList,
        totalResults
      }
    }
    case UPDATE_RETURN_INVENTORY_SUCCEEDED: {
      const {id, payload } = action;
      const returnInventoryList = [...state.returnInventoryList].map(list => {
        if(list.returnBatchId === id) {
          list = {...list, ...payload}
        }
        return list
      })
      return {
        ...state,
        returnInventoryList,
        selectedReturnInventory: payload
      }
    }
    case DELETE_RETURN_INVENTORY_SUCCEEDED: {
      const { id } = action;
      const returnInventoryList = [...state.returnInventoryList].filter(list => list.returnBatchId !== id);
      return {
        ...state,
        returnInventoryList
      }
    }
    case FETCH_RETURN_INVENTORY_BY_ID_SUCCEEDED: {
      const selectedReturnInventory = action.payload
      return{
        ...state,
        selectedReturnInventory
      }
    }
    case SET_SELECTED_RETURN_INVENTORY: {
      const { id } =  action;
      const selectedReturnInventory = [...state.returnInventoryList].find((list:any) => list.id === id) || null;
      return{
        ...state,
        selectedReturnInventory
      }
    }
    case REMOVE_SELECTED_RETURN_INVENTORY: {
      return {
        ...state,
        selectedReturnInventory: null
      }
    }
    case FETCH_ACTIVE_RETURNED_LIST_SUCCEEDED: {
      /*const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const activeReturnedInventoryList = append ? [...state.activeReturnedInventoryList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        activeReturnedInventoryList
      } */

      const activeReturnedInventoryList = action.payload;
      return {
        ...state,
        activeReturnedInventoryList
      }
    }
    case FETCH_DESTROYED_BATCH_LIST_SUCCEEDED: {
      /* const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const destroyedBatchList = append ? [...state.destroyedBatchList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        destroyedBatchList
      } */

      const destroyedBatchList = action.payload;
      return {
        ...state,
        destroyedBatchList
      }
    }
    case FETCH_DESTROYED_STOCK_REPORT_SUCCEEDED: {
      const destroyedStockReport = action.payload
      return{
        ...state,
        destroyedStockReport
      }
    }
    case UPDATE_DESTROYED_STOCK_REPORT_SUCCEEDED: {
      // const { payload} = action;
      // const {pagedData, ...rest} = payload;
      // const lists = pagedData;
      // const destroyedBatchList = append ? [...state.destroyedBatchList, ...lists] : lists;
      return {
        ...state,
        // ...rest,
        // destroyedBatchList
      }
    }
    case MARK_STOCK_REPORT_SUCCEEDED: {
      const { inventoryItems } = action.payload
      const activeReturnedInventoryList = [...state.activeReturnedInventoryList].filter(({id}:any) => !inventoryItems.includes(id));
      return {
        ...state,
        activeReturnedInventoryList
      }
    }
    case LOOKUP_PATIENT_SUCCEEDED: {
      const {payload} = action;
      return {
        ...state,
        patientDetails: payload
      }
    }
    case RESET_LOOKUP_PATIENT: {
      return {
        ...state,
        patientDetails: null
      }
    }
    case RESET_DESTROYED_STOCK_REPORT: {
      return {
        ...state,
        destroyedStockReport: null
      }
    }

    case RESET_RETURN_INVENTORY: {
      return {
        ...state,
        ...initialState
      }
    }
    case RESET_ACTIVE_RETURNED_LIST: {
      return {
        ...state,
        activeReturnedInventoryList: []
      }
    }
    case RESET_DESTROYED_BATCH_LIST: {
      return {
        ...state,
        destroyedBatchList: []

      }
    }
    default:{
      return state;
    }
  }
}