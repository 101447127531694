import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
// import thunk from 'redux-thunk';
import createSagaMiddleware from "redux-saga";
import {connectRouter, routerMiddleware} from 'connected-react-router';
import reducers from '../reducers';
import rootSaga from "../../sagas";

const sagaMiddleware = createSagaMiddleware();
const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory();
const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});


export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore(initialState?: AppState) {
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [];
  const windowIfDefined =
    typeof window === 'undefined' ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );

   // Extensions
   //@ts-ignore
   store.runSaga = sagaMiddleware.run(rootSaga);
    //@ts-ignore
   store.injectedReducers = {}; // Reducer registry
    //@ts-ignore
   store.injectedSagas = {}; // Saga registry

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;
      store.replaceReducer(nextReducer)
    })
  }

  return store;
}

export {history};
export {rootReducer};

// export type AppState = ReturnType<typeof rootReducer>;
