import { Site } from "../../types/models/Sites";
import { 
  SiteActionsTypes,
  FETCH_SITE_LIST_SUCCEEDED,
  SUBMIT_SITE_SUCCEEDED,
  REMOVE_SELECTED_SITE,
  UPDATE_SITE_SUCCEEDED,
  SET_SELECTED_SITE,
  DELETE_SITE_SUCCEEDED,
  FETCH_SITE_BY_ID_SUCCEEDED,
  RESET_SITE,
  RESET_FETCHCOMPLETE
} from "../../types/actions/Sites.action";

const initialState: Site = {
  siteList: [],
  selectedSite: null,
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  fetchComplete: false
}

export default (state = initialState, action: SiteActionsTypes): Site => {
  switch (action.type) {
    case FETCH_SITE_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const siteList = append ? [...state.siteList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        siteList 
      }
    }
    case SUBMIT_SITE_SUCCEEDED: {
      const { payload } = action
      const siteList = [...state.siteList, payload]
      const totalResults:number = state.totalResults + 1;
      return {
        ...state,
        siteList,
        fetchComplete: true,
        totalResults
      }
    }
    case UPDATE_SITE_SUCCEEDED: {
      const {id, payload } = action;
      const siteList = [...state.siteList].map(list => {
        if(list.id === id) {
          list = {...list, ...payload}
        }
        return list
      })
      return {
        ...state,
        siteList,
        selectedSite: payload,
        fetchComplete: true
      }
    }
    case DELETE_SITE_SUCCEEDED: {
      const { id } = action;
      const siteList = [...state.siteList].filter(list => list.id !== id);
      const totalResults:number = state.totalResults - 1;
      return {
        ...state,
        siteList,
        fetchComplete: true,
        totalResults
      }
    }
    case FETCH_SITE_BY_ID_SUCCEEDED: {
      const selectedSite = action.payload
      return{
        ...state,
        selectedSite
      }
    }
    case SET_SELECTED_SITE: {
      const { id } =  action;
      const selectedSite = [...state.siteList].find((list:any) => list.id === id) || null;
      return{
        ...state,
        selectedSite
      }
    }
    case REMOVE_SELECTED_SITE: {
      return {
        ...state,
        selectedSite: null
      }
    }
    case RESET_FETCHCOMPLETE: {
      return {
        ...state,
        fetchComplete: false
      }
    }
    case RESET_SITE: {
      return {
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}