import React from 'react';

export const sponsorRouteConfig = [
  {
    auth: ['Admin'],
    routes: [
      {
        path: '/sponsors',
        exact: true,
        component: React.lazy(() => import('./Sponsors')),
      },
      {
        path: '/sponsors/:id',
        exact: true,
        component: React.lazy(() => import('./SponsorDetails')),
      }
    ],
  },
];
