import { CourierList, Payload } from "../../types/models/Couriers";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_COURIER_LIST,
  FETCH_COURIER_BY_ID,
  SUBMIT_COURIER,
  UPDATE_COURIER,
  DELETE_COURIER,
  SET_SELECTED_COURIER,
  RESET_COURIER,
  FETCH_COURIER_LIST_SUCCEEDED,
  FETCH_COURIER_BY_ID_SUCCEEDED,
  SUBMIT_COURIER_SUCCEEDED,
  UPDATE_COURIER_SUCCEEDED,
  DELETE_COURIER_SUCCEEDED,
  REMOVE_SELECTED_COURIER,
  FETCH_PHARMACY_ADDRESS,
  FETCH_PHARMACY_ADDRESS_SUCCEEDED,
  FETCH_AVAILABLE_ROUTES,
  FETCH_AVAILABLE_ROUTES_SUCCEEDED,
  FETCH_COURIER_KVP_LIST,
  FETCH_COURIER_KVP_LIST_SUCCEEDED,
  CREATE_API_CONSIGNMENT,
  CREATE_API_CONSIGNMENT_SUCCEEDED,
  SAVE_MANUAL_CONSIGNMENT,
  SAVE_MANUAL_CONSIGNMENT_SUCCEEDED,
  CONSIGNMENT_COMPLETED,
  RESET_MANUAL_CONSIGNMENT,
  CourierActionsTypes
} from "../../types/actions/Couriers.action";

export const fetchCourierList = (payload: GetQueryParams) => ({
  type: FETCH_COURIER_LIST,
  payload
});

export const submitCourier = (payload:CourierList):CourierActionsTypes => ({
  type: SUBMIT_COURIER,
  payload
});

export const updateCourier = (id: string, payload:CourierList):CourierActionsTypes => ({
  type: UPDATE_COURIER,
  id,
  payload
});

export const deleteCourier = (id: string):CourierActionsTypes => ({
  type: DELETE_COURIER,
  id
});

export const fetchCourierById = (id:string) => ({
  type: FETCH_COURIER_BY_ID,
  id
});

export const setSelectedCourier = (id: string) => ({
  type: SET_SELECTED_COURIER,
  id
});

export const removeSelectedCourier = () => ({
  type: REMOVE_SELECTED_COURIER
});

export const resetCourier = (): CourierActionsTypes => ({
  type: RESET_COURIER
});


export const fetchCourierListSucceeded = (payload:Payload, append:boolean): CourierActionsTypes=> ({
  type: FETCH_COURIER_LIST_SUCCEEDED,
  payload,
  append
});

export const submitCourierSucceeded = (payload:CourierList):CourierActionsTypes => ({
  type: SUBMIT_COURIER_SUCCEEDED,
  payload
});

export const updateCourierSucceeded = (id: string, payload:CourierList):CourierActionsTypes => ({
  type: UPDATE_COURIER_SUCCEEDED,
  id,
  payload
});

export const deleteCourierSucceeded = (id: string):CourierActionsTypes => ({
  type: DELETE_COURIER_SUCCEEDED,
  id
});

export const fetchCourierByIdSucceeded = (id:string):CourierActionsTypes => ({
  type: FETCH_COURIER_BY_ID_SUCCEEDED,
  id
});

export const fetchPharmacyAddress = (payload:GetQueryParams):CourierActionsTypes => ({
  type: FETCH_PHARMACY_ADDRESS,
  payload
});

export const fetchAvailableRoutes = (payload:any):CourierActionsTypes => ({
  type: FETCH_AVAILABLE_ROUTES,
  payload
});

export const createApiConsignment = (payload:any):CourierActionsTypes => ({
  type: CREATE_API_CONSIGNMENT,
  payload
});

export const saveManualConsignment = (payload:any):CourierActionsTypes => ({
  type: SAVE_MANUAL_CONSIGNMENT,
  payload
});

export const fetchPharmacyAddressSucceeded = (payload:any):CourierActionsTypes => ({
  type: FETCH_PHARMACY_ADDRESS_SUCCEEDED,
  payload
});

export const fetchAvailableRoutesSucceeded = (payload:any):CourierActionsTypes => ({
  type: FETCH_AVAILABLE_ROUTES_SUCCEEDED,
  payload
});

export const createApiConsignmentSucceeded = (payload:any):CourierActionsTypes => ({
  type: CREATE_API_CONSIGNMENT_SUCCEEDED,
  payload
});

export const saveManualConsignmentSucceeded = (payload:any):CourierActionsTypes => ({
  type: SAVE_MANUAL_CONSIGNMENT_SUCCEEDED,
  payload
});

export const consignmentCompleted = (): CourierActionsTypes => ({
  type: CONSIGNMENT_COMPLETED
});

export const resetManualConsignment = ():CourierActionsTypes => ({
  type: RESET_MANUAL_CONSIGNMENT
});

export const fetchCourierKvpList = (payload: GetQueryParams) => ({
  type: FETCH_COURIER_KVP_LIST,
  payload
});

export const fetchCourierKvpListSucceeded = (payload:Payload, append:boolean): CourierActionsTypes=> ({
  type: FETCH_COURIER_KVP_LIST_SUCCEEDED,
  payload,
  append
});
