import { Manifest } from "../../types/models/Manifest";

import { 
  ManifestActionsTypes,
  FETCH_CONSIGNMENT_LIST_SUCCEEDED,
  SUBMIT_MANIFEST_SUCCEEDED,
  RESET_MANIFEST
} from "../../types/actions/Manifest.actions";


const initialState: Manifest = {
  prescriptions: [],
  siteShippings: [],
  bulkOrder: []
}

export default (state = initialState, action: ManifestActionsTypes): Manifest => {
  switch (action.type) {
    case FETCH_CONSIGNMENT_LIST_SUCCEEDED: {
      const { payload} = action;
      return {
        ...state,
        ...payload,
      }
    }
    case SUBMIT_MANIFEST_SUCCEEDED: {
      const { payload} = action;
      const prescriptions = [...state.prescriptions].filter(({machShipConsignmentId}:any) => !payload.includes(machShipConsignmentId));
      const siteShippings = [...state.siteShippings].filter(({machShipConsignmentId}:any) => !payload.includes(machShipConsignmentId));
      const bulkOrder = [...state.bulkOrder].filter(({machShipConsignmentId}:any) => !payload.includes(machShipConsignmentId));
      return {
        ...state,
        prescriptions,
        siteShippings,
        bulkOrder
      }
    }
    case RESET_MANIFEST: {
      return{
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}