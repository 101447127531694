import { PatientList, Payload } from "../../types/models/Patients";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_PATIENT_LIST,
  FETCH_PATIENT_BY_ID,
  SUBMIT_PATIENT,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  FETCH_PATIENT_LIST_SUCCEEDED,
  FETCH_PATIENT_BY_ID_SUCCEEDED,
  SUBMIT_PATIENT_SUCCEEDED,
  UPDATE_PATIENT_SUCCEEDED,
  DELETE_PATIENT_SUCCEEDED,
  SET_SELECTED_PATIENT,
  REMOVE_SELECTED_PATIENT,
  RESET_PATIENT,
  FETCH_PATIENT_SCRIPT_HISTORY,
  FETCH_PATIENT_SCRIPT_HISTORY_SUCCEEDED,
  SUBMIT_PRESCRIPTION,
  SUBMIT_PRESCRIPTION_SUCCEEDED,
  FETCH_PRESCRIPTION_SUMMARY,
  FETCH_PRESCRIPTION_SUMMARY_SUCCEEDED,
  SUBMIT_PIN_GENERATION,
  SUBMIT_PIN_GENERATION_SUCCEEDED,
  FETCH_UNBLINDED,
  FETCH_UNBLINDED_SUCCEEDED,
  FETCH_UNBLINDED_NAMES,
  FETCH_UNBLINDED_NAMES_SUCCEEDED,
  RESET_UNBLIND,
  STOCK_AVAILABILITY,
  STOCK_AVAILABILITY_SUCCEEDED,
  CREATE_REPEAT,
  CREATE_REPEAT_SUCCEEDED,
  PatientActionsTypes
} from "../../types/actions/Patients.action";

export const fetchPatientList = (payload: GetQueryParams) => ({
  type: FETCH_PATIENT_LIST,
  payload
});

export const submitPatient = (payload:PatientList):PatientActionsTypes => ({
  type: SUBMIT_PATIENT,
  payload
});

export const updatePatient = (id: string, payload:PatientList):PatientActionsTypes => ({
  type: UPDATE_PATIENT,
  id,
  payload
});

export const deletePatient = (trialId:string, id: string):PatientActionsTypes => ({
  type: DELETE_PATIENT,
  trialId,
  id
});

export const fetchPatientById = (trialId:string, id: string) => ({
  type: FETCH_PATIENT_BY_ID,
  trialId,
  id
});

export const setSelectedPatient = (id: string) => ({
  type: SET_SELECTED_PATIENT,
  id
});

export const removeSelectedPatient = () => ({
  type: REMOVE_SELECTED_PATIENT
});

export const resetPatient = (): PatientActionsTypes => ({
  type: RESET_PATIENT
});

export const fetchPatientListSucceeded = (payload:Payload, append:boolean): PatientActionsTypes=> ({
  type: FETCH_PATIENT_LIST_SUCCEEDED,
  payload,
  append
});

export const submitPatientSucceeded = (payload:PatientList):PatientActionsTypes => ({
  type: SUBMIT_PATIENT_SUCCEEDED,
  payload
});

export const updatePatientSucceeded = (id: string, payload:PatientList):PatientActionsTypes => ({
  type: UPDATE_PATIENT_SUCCEEDED,
  id,
  payload
});

export const deletePatientSucceeded = (id: string):PatientActionsTypes => ({
  type: DELETE_PATIENT_SUCCEEDED,
  id
});

export const fetchPatientByIdSucceeded = (payload:PatientList):PatientActionsTypes => ({
  type: FETCH_PATIENT_BY_ID_SUCCEEDED,
  payload
});

export const fetchPatientScriptHistory = (id:string, patientId:string):PatientActionsTypes => ({
  type: FETCH_PATIENT_SCRIPT_HISTORY,
  id,
  patientId
});

export const fetchPatientScriptHistorySucceeded = (payload:any):PatientActionsTypes => ({
  type: FETCH_PATIENT_SCRIPT_HISTORY_SUCCEEDED,
  payload
});

export const submitPrescription = (payload:any): PatientActionsTypes => ({
  type: SUBMIT_PRESCRIPTION,
  payload
});

export const submitPrescriptionSucceeded = (payload:any): PatientActionsTypes => ({
  type: SUBMIT_PRESCRIPTION_SUCCEEDED,
  payload
});

export const fetchPrescriptonSummary = (id: string): PatientActionsTypes => ({
  type: FETCH_PRESCRIPTION_SUMMARY,
  id
});

export const fetchPrescriptonSummarySucceeded = (payload: any): PatientActionsTypes => ({
  type: FETCH_PRESCRIPTION_SUMMARY_SUCCEEDED,
  payload
});

export const submitPinGeneration = (payload:any): PatientActionsTypes => ({
  type: SUBMIT_PIN_GENERATION,
  payload
});

export const submitPinGenerationSucceeded = (payload:any): PatientActionsTypes => ({
  type: SUBMIT_PIN_GENERATION_SUCCEEDED,
  payload
});

export const fetchUnblinded = (payload:any): PatientActionsTypes => ({
  type: FETCH_UNBLINDED,
  payload
});

export const fetchUnblindedSucceeded = (payload:any): PatientActionsTypes => ({
  type: FETCH_UNBLINDED_SUCCEEDED,
  payload
});

export const fetchUnblindedNames = (payload:any): PatientActionsTypes => ({
  type: FETCH_UNBLINDED_NAMES,
  payload
});

export const fetchUnblindedNamesSucceeded = (payload:any): PatientActionsTypes => ({
  type: FETCH_UNBLINDED_NAMES_SUCCEEDED,
  payload
});

export const resetUnblind = (): PatientActionsTypes => ({
  type: RESET_UNBLIND
});

export const stockAvailability = (trialId: string, patientId: string): PatientActionsTypes => ({
  type: STOCK_AVAILABILITY,
  trialId,
  patientId
});

export const stockAvailabilitySucceeded = (payload:any): PatientActionsTypes => ({
  type: STOCK_AVAILABILITY_SUCCEEDED,
  payload
});

export const createRepeat = (trialId: string, patientId: string): PatientActionsTypes => ({
  type: CREATE_REPEAT,
  trialId,
  patientId
});

export const createRepeatSucceeded = (payload:any): PatientActionsTypes => ({
  type: CREATE_REPEAT_SUCCEEDED,
  payload
});