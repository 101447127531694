import { useDispatch, useSelector } from 'react-redux';
import {useEffect, useState} from 'react';
import {AppState} from '../../redux/store';
import {UPDATE_AUTH0_USER} from '../../types/actions/Auth.actions';
// import { updateAuth0User } from "../../redux/actions/Auth";

export const useAuthToken = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);

  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const authUser = user;
  
  useEffect(() => {
    const userAthCheck = () =>
      new Promise((resolve) => {
        const auth0User = localStorage.getItem("auth-user"); 
        const user = auth0User ? JSON.parse(auth0User) : null;
        dispatch({
          type: UPDATE_AUTH0_USER,
          payload: user,
        });
        resolve('');
        return Promise.resolve();
      });

    const checkAuth = () => {
      Promise.all([userAthCheck()]).then(() => {
        setLoading(false);
      });
    };
    
    checkAuth();
  }, [dispatch]);

  useEffect(() => {
    if (!authUser) {
      setToken(null);
    }
    if (authUser && authUser.token) {
      setToken(authUser.token);
    }
  }, [authUser]);

  return [token, loading, authUser?.uid];
};

export const useAuthUser = () => {
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  return user || null;

  // const auth0User = localStorage.getItem("auth-user"); 
  // const user = auth0User ? JSON.parse(auth0User) : null;
  // return user;
};
