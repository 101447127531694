import { Payload } from "../../types/models/Stocktake";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_TRIAL_STOCKTAKE_LIST,
  FETCH_TRIAL_STOCKTAKE_LIST_SUCCEEDED,
  RESET_STOCKTAKE,
  // FETCH_TRIAL_STOCKTAKE_BY_ID,
  // FETCH_TRIAL_STOCKTAKE_BY_ID_SUCCEEDED,
  FETCH_STOCKTAKE_LIST,
  FETCH_STOCKTAKE_LIST_SUCCEEDED,
  UPDATE_STOCKTAKE,
  UPDATE_STOCKTAKE_SUCCEEDED,
  FETCH_INVENTORY_RANDOMISATION,
  FETCH_INVENTORY_RANDOMISATION_SUCCEEDED,
  UPDATE_INVENTORY_LIST,
  SET_SELECTED_STOCKTAKE,
  DELETE_RANDOMISATION,
  DELETE_RANDOMISATION_SUCCEEDED,
  DELETE_KIT_NUMBER,
  DELETE_KIT_NUMBER_SUCCEEDED,
  DELETE_BOTTLE_NUMBER,
  DELETE_BOTTLE_NUMBER_SUCCEEDED,
  UPDATED_SELECTED_INVENTORY,
  RESET_STOCKTAKE_INVENTORY,
  StocktakeActionTypes
} from "../../types/actions/Stocktake.action";

export const fetchTrialStocktakeList = (payload: GetQueryParams): StocktakeActionTypes => ({
  type: FETCH_TRIAL_STOCKTAKE_LIST,
  payload
});

export const fetchTrialStocktakeListSucceeded = (payload:Payload, append:boolean): StocktakeActionTypes => ({
  type: FETCH_TRIAL_STOCKTAKE_LIST_SUCCEEDED,
  payload,
  append
});

/* export const fetchTrialStocktakeById = (id:string): StocktakeActionTypes => ({
  type: FETCH_TRIAL_STOCKTAKE_BY_ID,
  id
});

export const fetchTrialStocktakeByIdSucceeded = (payload:TrialStocktakeList): StocktakeActionTypes => ({
  type: FETCH_TRIAL_STOCKTAKE_BY_ID_SUCCEEDED,
  payload
}); */

export const fetchStocktakeList = (payload: GetQueryParams): StocktakeActionTypes => ({
  type: FETCH_STOCKTAKE_LIST,
  payload
});

export const fetchStocktakeListSucceeded = (payload:Payload, append:boolean): StocktakeActionTypes => ({
  type: FETCH_STOCKTAKE_LIST_SUCCEEDED,
  payload,
  append
});

export const updateStocktake = (payload:any): StocktakeActionTypes => ({
  type: UPDATE_STOCKTAKE,
  payload
});

export const updateStocktakeSucceeded = (payload:any): StocktakeActionTypes => ({
  type: UPDATE_STOCKTAKE_SUCCEEDED,
  payload
});

export const fetchInventoryRandomisation = (trialId:string, inventoryId:string): StocktakeActionTypes => ({
  type: FETCH_INVENTORY_RANDOMISATION,
  trialId,
  inventoryId
});

export const fetchInventoryRandomisationSucceeded = (payload:any): StocktakeActionTypes => ({
  type: FETCH_INVENTORY_RANDOMISATION_SUCCEEDED,
  payload
});

export const updateInventoryList = (payload:any) => ({
  type: UPDATE_INVENTORY_LIST,
  payload
});

export const setSelectedStocktake = (payload:any): StocktakeActionTypes => ({
  type: SET_SELECTED_STOCKTAKE,
  payload
});

export const resetStocktake = (): StocktakeActionTypes => ({
  type: RESET_STOCKTAKE
});

export const deleteRandomisation = (id: string, reason: string): StocktakeActionTypes => ({
  type: DELETE_RANDOMISATION,
  id,
  reason
});

export const deleteRandomisationSucceeded = (): StocktakeActionTypes => ({
  type: DELETE_RANDOMISATION_SUCCEEDED,
});

export const deleteKitNumber = (id: string, reason: string): StocktakeActionTypes => ({
  type: DELETE_KIT_NUMBER,
  id,
  reason
});

export const deleteKitNumberSucceeded = (): StocktakeActionTypes => ({
  type: DELETE_KIT_NUMBER_SUCCEEDED,
});

export const deleteBottleNumber = (id: string, reason: string): StocktakeActionTypes => ({
  type: DELETE_BOTTLE_NUMBER,
  id,
  reason
});

export const deleteBottleNumberSucceeded = (): StocktakeActionTypes => ({
  type: DELETE_BOTTLE_NUMBER_SUCCEEDED,
});

export const updateSelectedInventory = (id: string, value:string): StocktakeActionTypes => ({
  type: UPDATED_SELECTED_INVENTORY,
  id,
  value
});

export const resetStocktakeInventory = ():StocktakeActionTypes => ({
  type: RESET_STOCKTAKE_INVENTORY
});