import React from 'react';
import ReactDOM from 'react-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-notifications-component/dist/theme.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-table/react-table.css';

import './shared/styles/index.css';
import './assets/vendors/country-flag/sprite-flags-24x24.css';
import App from './App';
import '@crema/services';

ReactDOM.render(<App />, document.getElementById('root'));

if(module.hot) {
  module.hot.accept('./App', () => {
    const HotApp = require('./App').default;
    ReactDOM.render(<HotApp />, document.getElementById('root'));
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
