import { Payload } from "../../types/models/Notifications";
import { GetQueryParams } from "../../types/models/Common";
import {
  FETCH_PENDING_REQUESTS, 
  FETCH_PENDING_REQUESTS_SUCCEEDED, 
  FETCH_REQUEST_HISTORY,
  FETCH_REQUEST_HISTORY_SUCCEEDED,
  APPROVE_QA_REQUEST,
  APPROVE_QA_REQUEST_SUCCEEDED,
  UPDATE_QA_REQUEST,
  UPDATE_QA_REQUEST_SUCCEEDED,
  REJECT_QA_REQUEST,
  REJECT_QA_REQUEST_SUCCEEDED,
  RESET_NOTIFICATION,
  FETCH_REQUEST_BY_ID,
  FETCH_REQUEST_BY_ID_SUCCEEDED,
  REMOVE_SELECTED_REQUEST,
  FETCH_INVENTORY_FROM_QA,
  FETCH_INVENTORY_FROM_QA_SUCCEEDED,
  NotificationActionsTypes
} from "types/actions/Notifications.action";

export const fetchPendingRequestList = (payload: GetQueryParams) => ({
  type: FETCH_PENDING_REQUESTS,
  payload
});

export const fetchPendingRequestListSucceeded = (payload:Payload, append:boolean): NotificationActionsTypes => ({
  type: FETCH_PENDING_REQUESTS_SUCCEEDED,
  payload,
  append
});

export const fetchRequestHistoryList = (payload: GetQueryParams) => ({
  type: FETCH_REQUEST_HISTORY,
  payload
});

export const fetchRequestHistoryListSucceeded = (payload:Payload, append:boolean): NotificationActionsTypes => ({
  type: FETCH_REQUEST_HISTORY_SUCCEEDED,
  payload,
  append
});

export const approveQARequest = (id: string): NotificationActionsTypes => ({
  type: APPROVE_QA_REQUEST,
  id
});

export const approveQARequestSucceeded = (payload:any): NotificationActionsTypes => ({
  type: APPROVE_QA_REQUEST_SUCCEEDED,
  payload
});

export const updateQARequest = (payload: any) => ({
  type: UPDATE_QA_REQUEST,
  payload
});

export const updateQARequestSucceeded = (payload:Payload, append:boolean): NotificationActionsTypes => ({
  type: UPDATE_QA_REQUEST_SUCCEEDED,
  payload,
  append
});

export const rejectQARequest = (payload: any) => ({
  type: REJECT_QA_REQUEST,
  payload
});

export const rejectQARequestSucceeded = (payload:any): NotificationActionsTypes => ({
  type: REJECT_QA_REQUEST_SUCCEEDED,
  payload
});

export const fetchRequestById = (id:string): NotificationActionsTypes => ({
  type: FETCH_REQUEST_BY_ID,
  id
});

export const fetchRequestByIdSucceeded = (payload:any): NotificationActionsTypes => ({
  type: FETCH_REQUEST_BY_ID_SUCCEEDED,
  payload
});

export const resetNotification = (): NotificationActionsTypes => ({
  type: RESET_NOTIFICATION
});

export const removeSelectedRequested = (): NotificationActionsTypes => ({
  type: REMOVE_SELECTED_REQUEST
});

export const fetchInventoryFromQA = (payload: any): NotificationActionsTypes => ({
  type: FETCH_INVENTORY_FROM_QA,
  payload
});

export const fetchInventoryFromQASucceeded = (payload: string, append: boolean): NotificationActionsTypes => ({
  type: FETCH_INVENTORY_FROM_QA_SUCCEEDED,
  payload,
  append
});