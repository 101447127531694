import React from 'react';

export const prescriptionRouteConfig = [
  {
    // auth: ['Admin'],
    auth: ["Admin","Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/dispensing',
        component: React.lazy(() => import('./Prescriptions')),
      },
    ],
  },
];
