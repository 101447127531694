import { Payload } from "../models/Stocktake";
import { GetQueryParams } from "../models/Common";

export const FETCH_TRIAL_STOCKTAKE_LIST = "FETCH_TRIAL_STOCKTAKE_LIST";
export const FETCH_TRIAL_STOCKTAKE_LIST_SUCCEEDED = "FETCH_TRIAL_STOCKTAKE_LIST_SUCCEEDED";
// export const FETCH_TRIAL_STOCKTAKE_BY_ID = "FETCH_TRIAL_STOCKTAKE_BY_ID";
// export const FETCH_TRIAL_STOCKTAKE_BY_ID_SUCCEEDED = "FETCH_TRIAL_STOCKTAKE_BY_ID_SUCCEEDED";
export const FETCH_STOCKTAKE_LIST = "FETCH_STOCKTAKE_LIST";
export const FETCH_STOCKTAKE_LIST_SUCCEEDED = "FETCH_STOCKTAKE_LIST_SUCCEEDED";
export const FETCH_STOCKTAKE_BY_ID = "FETCH_STOCKTAKE_BY_ID";
export const FETCH_STOCKTAKE_BY_ID_SUCCEEDED = "FETCH_STOCKTAKE_BY_ID_SUCCEEDED";
export const RESET_STOCKTAKE = "RESET_STOCKTAKE";
export const UPDATE_STOCKTAKE = "UPDATE_STOCKTAKE";
export const UPDATE_STOCKTAKE_SUCCEEDED = "UPDATE_STOCKTAKE_SUCCEEDED";
export const FETCH_INVENTORY_RANDOMISATION = "FETCH_INVENTORY_RANDOMISATION";
export const FETCH_INVENTORY_RANDOMISATION_SUCCEEDED = "FETCH_INVENTORY_RANDOMISATION_SUCCEEDED";
export const UPDATE_INVENTORY_LIST = "UPDATE_INVENTORY_LIST";
export const SET_SELECTED_STOCKTAKE = "SET_SELECTED_STOCKTAKE";
export const DELETE_RANDOMISATION = "DELETE_RANDOMISATION";
export const DELETE_RANDOMISATION_SUCCEEDED = "DELETE_RANDOMISATION_SUCCEEDED";
export const DELETE_KIT_NUMBER = "DELETE_KIT_NUMBER";
export const DELETE_KIT_NUMBER_SUCCEEDED = "DELETE_KIT_NUMBER_SUCCEEDED";
export const DELETE_BOTTLE_NUMBER = "DELETE_BOTTLE_NUMBER";
export const DELETE_BOTTLE_NUMBER_SUCCEEDED = "DELETE_BOTTLE_NUMBER_SUCCEEDED";
export const UPDATED_SELECTED_INVENTORY = "UPDATED_SELECTED_INVENTORY";
export const RESET_STOCKTAKE_INVENTORY = "RESET_STOCKTAKE_INVENTORY";
export interface FetchTrialStocktakeListActions {
  type: typeof FETCH_TRIAL_STOCKTAKE_LIST;
  payload: GetQueryParams;
}

export interface FetchTrialStocktakeListSucceededActions {
  type: typeof FETCH_TRIAL_STOCKTAKE_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

/* export interface FetchTrialStocktakeListByIdActions {
  type: typeof FETCH_TRIAL_STOCKTAKE_BY_ID;
  id: string;
}

export interface FetchTrialStocktakeListByIdSucceededActions {
  type: typeof FETCH_TRIAL_STOCKTAKE_BY_ID_SUCCEEDED;
  payload: TrialStocktakeList;
}
 */

export interface FetchStocktakeListActions {
  type: typeof FETCH_STOCKTAKE_LIST;
  payload: GetQueryParams;
}

export interface FetchStocktakeListSucceededActions {
  type: typeof FETCH_STOCKTAKE_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface UpdateStocktakeActions {
  type: typeof UPDATE_STOCKTAKE,
  payload: any;
}

export interface UpdateStocktakeSucceededActions {
  type: typeof UPDATE_STOCKTAKE_SUCCEEDED,
  payload: any;
}

export interface FetchInventoryRandomisationActions {
  type: typeof FETCH_INVENTORY_RANDOMISATION
  trialId: string;
  inventoryId: string;
}

export interface FetchInventoryRandomisationSucceededActions {
  type: typeof FETCH_INVENTORY_RANDOMISATION_SUCCEEDED
  payload: any;
}

export interface SetSelectedStocktakeActions {
  type: typeof SET_SELECTED_STOCKTAKE;
  payload: any;
}

export interface ResetStocktakeActions {
  type: typeof RESET_STOCKTAKE
}

export interface DeleteRandomisationActions {
  type: typeof DELETE_RANDOMISATION;
  id: string;
  reason: string;
}
export interface DeleteRandomisationSucceededActions {
  type: typeof DELETE_RANDOMISATION_SUCCEEDED
}

export interface DeleteKitNumberActions {
  type: typeof DELETE_KIT_NUMBER;
  id: string;
  reason: string;
}
export interface DeleteKitNumberSucceededActions {
  type: typeof DELETE_KIT_NUMBER_SUCCEEDED
}

export interface DeleteBottleNumberActions {
  type: typeof DELETE_BOTTLE_NUMBER;
  id: string;
  reason: string;
}
export interface DeleteBottleNumberSucceededActions {
  type: typeof DELETE_BOTTLE_NUMBER_SUCCEEDED
}

export interface UpdatedSelectedInventoryActions {
  type: typeof UPDATED_SELECTED_INVENTORY;
  id: string;
  value: string;
}

export interface ResetStocktakeInventoryActions {
  type: typeof RESET_STOCKTAKE_INVENTORY
}

export type StocktakeActionTypes = 
  | FetchTrialStocktakeListActions
  | FetchTrialStocktakeListSucceededActions
  // | FetchTrialStocktakeListByIdActions
  // | FetchTrialStocktakeListByIdSucceededActions
  | FetchStocktakeListActions
  | FetchStocktakeListSucceededActions
  | UpdateStocktakeActions
  | UpdateStocktakeSucceededActions
  | FetchInventoryRandomisationActions
  | FetchInventoryRandomisationSucceededActions
  | DeleteRandomisationActions
  | DeleteRandomisationSucceededActions
  | DeleteKitNumberActions
  | DeleteBottleNumberActions
  | DeleteKitNumberSucceededActions
  | DeleteBottleNumberSucceededActions
  | SetSelectedStocktakeActions
  | UpdatedSelectedInventoryActions
  | ResetStocktakeActions
  | ResetStocktakeInventoryActions
  ;
  