import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { 
  fetchReturnInventoryListSucceeded, 
  submitReturnInventorySucceeded, 
  updateReturnInventorySucceeded, 
  deleteReturnInventorySucceeded, 
  fetchReturnInventoryByIdSucceeded,
  fetchActiveReturnedListSucceeded,
  fetchDestroyedBatchListSucceeded,
  fetchDestroyedStockReportSucceeded,
  updateDestroyedStockReportSucceeded,
  markStockReportSucceeded,
  lookupPatientSucceeded
} from "../actions/ReturnInventory";

const baseUrlv1 = 'api/v1';

function fetchReturnInventoryListByIdApi(id:string, inventoryId:string) {
  const url = `${baseUrlv1}/returnsInventory/${id}/item/${inventoryId}`;
  return Api.fetch(url, getGetParams());
}

function fetchReturnInventoryListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/returnsInventory?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function submitReturnInventoryApi (payload:any) {
  const url = `${baseUrlv1}/returnsInventory`;
  return Api.fetch(url, getPostParams(payload))
}

function updateReturnInventoryApi(id:string, payload:any) {
  const url = `${baseUrlv1}/returnsInventory/${id}/item/${payload.id}`;;
  return Api.fetch(url, getPutParams(payload))
} 

function deleteReturnInventoryApi(id:number) {
  const url = `${baseUrlv1}/ReturnInventory/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchDestroyedStockReportApi(id:string, stockId: string) {
  const url = `${baseUrlv1}/returnsInventory/${id}/destroyed/${stockId}`;
  return Api.fetch(url, getGetParams());
}

function updateDestroyedStockReportApi(id:string, stockId:string, payload: any) {
  const formUpload = true;
  const url = `${baseUrlv1}/returnsInventory/${id}/destroyed/${stockId}`;
  return Api.fetch(url, getPutParams(payload, formUpload));
}

function markStockReportApi(payload: any) {
  const {returnBatchId} = payload;
  const url = `${baseUrlv1}/returnsInventory/${returnBatchId}/destroy`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchActiveReturnedListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, id  } = payload
  const url = `${baseUrlv1}/returnsInventory/${id}/active?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchDestroyedBatchListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, id  } = payload
  const url = `${baseUrlv1}/returnsInventory/${id}/destroyed?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function lookupPatientApi(id:string, protocolNumber:string, treatmentType: string) {
  const url = `${baseUrlv1}/trials/patientByIdentifier?identifier=${id}&trialProtocolNumber=${encodeURIComponent(protocolNumber)}&treatmentType=${treatmentType}`;
  return Api.fetch(url, getGetParams());
}

export function* fetchReturnInventoryById({id, inventoryId}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchReturnInventoryListByIdApi, id, inventoryId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchReturnInventoryByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No ReturnInventory found"
      }else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchReturnInventoryList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchReturnInventoryListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchReturnInventoryListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitReturnInventory({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitReturnInventoryApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitReturnInventorySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateReturnInventory({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateReturnInventoryApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateReturnInventorySucceeded(id, payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteReturnInventory({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deleteReturnInventoryApi, id);
    if(response) {
      yield put(fetchSuccess(true));
      yield put(deleteReturnInventorySucceeded(id))
    } else {
      yield put(fetchError(response.message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* fetchActiveReturnedList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchActiveReturnedListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchActiveReturnedListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchDestroyedBatchList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchDestroyedBatchListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchDestroyedBatchListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchDestroyedStockReport({id, stockId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchDestroyedStockReportApi, id, stockId);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchDestroyedStockReportSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateDestroyedStockReport({id, stockId, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateDestroyedStockReportApi, id, stockId, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateDestroyedStockReportSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* markStockReport({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(markStockReportApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(markStockReportSucceeded(payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* lookupPatient({id, protocolNumber, treatmentType}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(lookupPatientApi, id, protocolNumber, treatmentType);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(lookupPatientSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No patient found"
      }else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export default [
  takeLatest('FETCH_RETURN_INVENTORY_BY_ID' ,fetchReturnInventoryById),
  takeLatest('FETCH_RETURN_INVENTORY_LIST' ,fetchReturnInventoryList),
  takeLatest('SUBMIT_RETURN_INVENTORY' ,submitReturnInventory),
  takeLatest('UPDATE_RETURN_INVENTORY' ,updateReturnInventory),
  takeLatest('DELETE_RETURN_INVENTORY' ,deleteReturnInventory),
  takeLatest('FETCH_ACTIVE_RETURNED_LIST' ,fetchActiveReturnedList),
  takeLatest('FETCH_DESTROYED_BATCH_LIST' ,fetchDestroyedBatchList),
  takeLatest('FETCH_DESTROYED_STOCK_REPORT' ,fetchDestroyedStockReport),
  takeLatest('UPDATE_DESTROYED_STOCK_REPORT' ,updateDestroyedStockReport),
  takeLatest('MARK_STOCK_REPORT' ,markStockReport),
  takeLatest('LOOKUP_PATIENT' ,lookupPatient),
]