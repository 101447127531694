import { CommentList } from "../../types/models/Comment";
import {
  SUBMIT_COMMENT_SUCCEEDED,
  FETCH_COMMENTS_LIST_SUCCEEDED,
  CommentActionsTypes,
} from "../../types/actions/Comment.action";

const initialState: CommentList = {
  currentPage: 0,
  pageSize: 0,
  pagedData: null,
  totalPages: 0,
  totalResults: 0
}

export default (state = initialState, action: CommentActionsTypes): CommentList => {
  switch (action.type) {
    case SUBMIT_COMMENT_SUCCEEDED: {
      const comment = action.payload;

      return {
        ...state,
        pagedData: [comment, ...(state.pagedData ? state.pagedData : [])],
        totalResults: +state.totalResults + 1
      }
    }
    case FETCH_COMMENTS_LIST_SUCCEEDED: {
      const {append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const commentHistory = (append && state.pagedData) ? [...state.pagedData, ...lists] : lists;

      return {
        ...state,
        ...rest,
        pagedData: commentHistory
      }
    }
    default:{
      return state;
    }
  }
}
