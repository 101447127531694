export const dropdownRouteConfig = [
  {
    id: 'couriers',
    title: 'Couriers',
    messageId: 'menu.couriers',
    url: '/couriers',
    type: 'item',
  },
  {
    id: 'users',
    title: 'Users',
    messageId: 'menu.users',
    url: '/users',
    type: 'item',
  },
  {
    id: 'pricing',
    title: 'Pricing',
    messageId: 'menu.pricing',
    url: '/pricing',
    type: 'item',
  },
  {
    id: 'pin',
    title: 'Pin',
    messageId: 'menu.pin',
    url: '/user/updatePin',
    type: 'item'
  },
  {
    id: 'billingReports',
    title: 'Billing Reports',
    messageId: 'menu.billingReports',
    url: '/billingReports',
    type: 'item'
  },
  {
    id: 'contactUs',
    title: 'Contact Us',
    messageId: 'menu.contactus',
    url: '/contactus',
    type: 'item'
  }
]

const routesConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'menu.dashboard',
    url: '/dashboard',
    type: 'item',
  },
  {
    id: 'sites',
    title: 'Sites',
    messageId: 'menu.sites',
    url: '/sites',
    type: 'item',
  },
  {
    id: 'sponsors',
    title: 'Sponsors',
    messageId: 'menu.sponsors',
    url: '/sponsors',
    type: 'item',
  },
  {
    id: 'trials',
    title: 'Trials',
    messageId: 'menu.trials',
    url: '/trials',
    type: 'item',
  },
  {
    id: 'inventory',
    title: 'Inventory',
    messageId: 'menu.inventory',
    url: '/inventory',
    type: 'item',
  },
  {
    id: 'returns',
    title: 'Returns',
    messageId: 'menu.returns',
    url: '/returnsInventory',
    type: 'item',
  },
  {
    id: 'dispensing',
    title: 'Dispensing',
    messageId: 'menu.dispensing',
    url: '/dispensing',
    type: 'item',
  },
  {
    id: 'siteShipping',
    title: 'Site Shipping',
    messageId: 'menu.siteShipping',
    url: '/site-shipping',
    type: 'item',
  },
  {
    id: 'consultations',
    title: 'Consultations',
    messageId: 'menu.consulations',
    url: '/counselling',
    type: 'item',
  },
  {
    id: 'notifications',
    title: 'Notifications',
    messageId: 'menu.notifications',
    url: '/notifications',
    type: 'item',
  },
  {
    id: 'qa',
    title: 'QA',
    messageId: 'menu.qa',
    url: '/qa',
    type: 'item',
  },
];
export default routesConfig;