import React from 'react';

export const courierRouteConfig = [
  {
    auth: ["Admin", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/couriers',
        component: React.lazy(() => import('./Couriers')),
      }
    ],
  },
];
