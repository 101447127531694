import { takeLatest, put, call } from "redux-saga/effects";import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams } from "../../@crema/utility/Utils";
import { 
  fetchActiveTrialSucceeded,
  fetchReportForTrialSucceeded,
  fetchStatsSucceeded,
  fetchDailyDispensePerTrialSucceeded
} from "../actions/Dashboard";

const baseUrlv1 = `api/v1`;

function fetchActiveTrialApi() {
  const url = `${baseUrlv1}/dashboard/trialkvp`;
  return Api.fetch(url, getGetParams());
}

function fetchReportForTrialApi(payload: any) {
  const { id } = payload
  const url = `${baseUrlv1}/dashboard/${payload.type}/${id}`;
  return Api.fetch(url, getGetParams());
}

function downloadReportFileApi(fileType:string, trialId:string) {
  const url = `${baseUrlv1}/dashboard/${fileType}/downloadReport?trialId=${trialId}`;
  return Api.fetch(url, getGetParams(), 'text');
}

function fetchStatsApi() {
  const url = `${baseUrlv1}/dashboard/getAdminStats`;
  return Api.fetch(url, getGetParams());
}

function fetchDailyDispensePerTrialApi(trialId:string) {
  const url = `${baseUrlv1}/dashboard/getDailyDispensesPerTrial/${trialId}`;
  return Api.fetch(url, getGetParams());
}

export function* fetchActiveTrial() {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchActiveTrialApi);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchActiveTrialSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchReportForTrial({payload}: any) {
  yield put(fetchStart());
  try {
    const { append } = payload;
    let response  = yield call(fetchReportForTrialApi, payload) || [];
    if(response.name === 'SyntaxError') {
      response = []
    }
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchReportForTrialSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* downloadReportFile({fileType, trialId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(downloadReportFileApi, fileType, trialId);
    if(!response.error) {
      yield put(fetchSuccess(true));
      window.open(response, "_blank");
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchStats() {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchStatsApi);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchStatsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchDailyDispensePerTrial({trialId}: any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchDailyDispensePerTrialApi, trialId);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchDailyDispensePerTrialSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_ACTIVE_TRIAL' ,fetchActiveTrial),
  takeLatest('FETCH_REPORT_FOR_TRIAL' ,fetchReportForTrial),
  takeLatest('DOWNLOAD_REPORT_FILE' ,downloadReportFile),
  takeLatest('FETCH_DAILY_DISPENSE_PER_TRIAL' ,fetchDailyDispensePerTrial),
  takeLatest('FETCH_STATS' ,fetchStats),
]