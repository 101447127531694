import { Prescription } from "../../types/models/Prescription";
import { PrescriptionStatus } from "../../shared/constants/AppEnums";

import { 
  PrescriptionActionsTypes,
  FETCH_PRESCRIPTION_LIST_SUCCEEDED,
  FETCH_ORDER_LIST_SUCCEEDED,
  FETCH_KVP_TRIAL_FOR_PHARMACY_SUCCEEDED,
  FETCH_KVP_SITES_BY_PHARMACY_ID_SUCCEEDED,
  FETCH_KVP_SITES_BY_TRIAL_ID_SUCCEEDED,
  DELETE_DISPENSE_SUCCEEDED,
  FETCH_PRESCRIPTION_BY_ID_SUCCEEDED,
  FETCH_BULKORDER_DISPENSE_BY_ID_SUCCEEDED,
  FETCH_INVENTORY_FOR_KIT_AND_BOTTLE_SUCCEEDED,
  FETCH_BULKORDER_RANDOMISATION_SUCCEEDED,
  FETCH_BULKORDER_KIT_NUMBERS_SUCCEEDED,
  SUBMIT_MANUAL_ASSIGN_TO_BULKORDER,
  FETCH_PRESCRIPTION_INVENTORY_BY_ID_SUCCEEDED,
  MARK_BULKORDER_DISPENSED_SUCCEEDED,
  MARK_PRESCRIPTION_DISPENSED_SUCCEEDED,
  PRINT_PRESCRIPTION_LABELS_SUCCEEDED,
  APPROVE_PRESCRIPTION_SUCCEEDED,
  APPROVE_BULKORDER_SUCCEEDED,
  REJECT_BULKORDER_SUCCEEDED,
  MARK_PRESCRIPTION_PACKED_SUCCEEDED,
  MARK_BULKORDER_PACKED_SUCCEEDED,
  FETCH_INVENTORY_OF_DISPENSED_DRUGS_SUCCEEDED,
  GET_SHIPPING_DETAILS_SUCCEEDED,
  FETCH_CONSIGNMENT_DETAILS_SUCCEEDED,
  // FETCH_PHARMACY_ADDRESS_SUCCEEDED,
  // FETCH_AVAILABLE_ROUTES_SUCCEEDED,
  UPLOAD_RECEIVED_STATUS_FILES_SUCCEEDED,
  MARK_PRESCRIPTION_RECEIVED_SUCCEEDED,
  MARK_BULKORDER_RECEIVED_SUCCEEDED,
  REMOVE_SELECTED_PRESCRIPTION,
  UPDATE_STATUS,
  FETCH_USERS_TO_APPROVE_SUCCEEDED,
  FETCH_ORDERLIST_REPORT_SUCCEEDED,
  FETCH_ALL_ORDERLIST_REPORT_SUCCEEDED,
  RESET_PRESCRIPTION
} from "../../types/actions/Prescription.action";

const initialState: Prescription = {
  prescriptionList: [],
  siteKVP: [],
  trialKVP: [],
  // selectedPrescription: null,
  selectedPrescription: {
    inventory: null,
    shippingDetails: null
  },
  selectedBulkOrder: null,
  randomisationNumber: null,
  kitNumber: null,
  currentPage: 0,
  pageSize: 50,
  totalPages: 0,
  totalResults: 0,
  pharmacyAddress: [],
  availableRoutes: [],
  shippingConsignment: null,
  approvingUsers: [],
  shipmentReport: [],
  shipmentReportDownload: []
}

export default (state = initialState, action: PrescriptionActionsTypes): Prescription => {
  switch (action.type) {
    case FETCH_PRESCRIPTION_LIST_SUCCEEDED:
    case FETCH_ORDER_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const prescriptionList = append ? [...state.prescriptionList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        prescriptionList
      }
    }
    case FETCH_KVP_SITES_BY_PHARMACY_ID_SUCCEEDED:
    case FETCH_KVP_SITES_BY_TRIAL_ID_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        siteKVP: payload
      }
    }
    case FETCH_KVP_TRIAL_FOR_PHARMACY_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        trialKVP: payload
      }
    }
    case DELETE_DISPENSE_SUCCEEDED: {
      const cancelId = action.id;
      const totalResults:number = state.totalResults - 1;
      const prescriptionList = [...state.prescriptionList].map((list:any) => {
        if(list.id === cancelId || list.bulkOrderId === cancelId) {
          list.status = PrescriptionStatus['Cancelled']
        }
        return list;
      })
      return {
        ...state,
        prescriptionList,
        totalResults
      }
    }
    case FETCH_PRESCRIPTION_BY_ID_SUCCEEDED: {
      const selectedPrescription = action.payload;
      return {
        ...state,
        selectedPrescription
      }
    }
    case FETCH_BULKORDER_RANDOMISATION_SUCCEEDED: {
      const randomisationNumber = action.payload;
      return {
        ...state,
        randomisationNumber
      }
    }
    case FETCH_BULKORDER_KIT_NUMBERS_SUCCEEDED: {
      const kitNumber = action.payload;
      return {
        ...state,
        kitNumber
      }
    }
    case SUBMIT_MANUAL_ASSIGN_TO_BULKORDER: {
      
      return {
        ...state
      }
    }
    case FETCH_INVENTORY_FOR_KIT_AND_BOTTLE_SUCCEEDED: {
      const { bottleIdentifier, inventoryId, payload, append } = action;
      const property = bottleIdentifier === 2 ? 'kitNumbers' : 'bottleNumbers';
      const {pagedData, ...rest} = payload;
      const selectedBulkOrder:any = {...state.selectedBulkOrder};
      const inventories:any = selectedBulkOrder?.inventories.map((list:any) => {
        let _list:any;
        if(list.inventoryId === inventoryId) {
          _list = {
            ...list, 
            [property]: append ? [...list[property], ...pagedData] : pagedData,
            ...rest
          };
          return _list
        }
        return list;
      })
      console.log(inventories)
      return {
        ...state,
        selectedBulkOrder: {
          ...state.selectedBulkOrder,
          inventories,
          inventoryListingComplete: true
        },
      }
    }
    case FETCH_BULKORDER_DISPENSE_BY_ID_SUCCEEDED:
    case MARK_BULKORDER_DISPENSED_SUCCEEDED:
    case APPROVE_BULKORDER_SUCCEEDED:
    case REJECT_BULKORDER_SUCCEEDED: 
    case MARK_BULKORDER_PACKED_SUCCEEDED:
    case MARK_BULKORDER_RECEIVED_SUCCEEDED:
    {
      const selectedBulkOrder = action.payload;
      return {
        ...state,
        selectedBulkOrder,
        selectedPrescription: {
          status: selectedBulkOrder.status,
          shippingOption: selectedBulkOrder.shippingOption,
          dispensedByUserRole: selectedBulkOrder.dispensedByUserRole,
          dispensedBy: selectedBulkOrder.dispensedBy,
          dataLoggerFileLocation: selectedBulkOrder.dataLoggerFileLocation,
          receiptAcknowledgementFileLocation: selectedBulkOrder.receiptAcknowledgementFileLocation,
          trialId: selectedBulkOrder.trialId,
          hasDataLogger: selectedBulkOrder.hasDataLogger
        }
      }
    }
    case APPROVE_PRESCRIPTION_SUCCEEDED: 
    case MARK_PRESCRIPTION_DISPENSED_SUCCEEDED: 
    case MARK_PRESCRIPTION_PACKED_SUCCEEDED:
    case MARK_PRESCRIPTION_RECEIVED_SUCCEEDED:
    case UPLOAD_RECEIVED_STATUS_FILES_SUCCEEDED:
    {
      const selectedPrescription = action.payload;
      return {
        ...state,
        selectedPrescription: {
          ...state.selectedPrescription,
          ...selectedPrescription
        }
      }
    }
    case UPDATE_STATUS: {
      const { status } = action
      return {
        ...state,
        selectedPrescription: {
          ...state.selectedPrescription,
          status
        }
      }
    }
    case FETCH_PRESCRIPTION_INVENTORY_BY_ID_SUCCEEDED: {
      const prescriptionInventory = action.payload;
      return {
        ...state,
        selectedPrescription: {
          ...state.selectedPrescription,
          inventory: prescriptionInventory
        }
      }
    }
    case PRINT_PRESCRIPTION_LABELS_SUCCEEDED: {
      return {...state}
    }
    case FETCH_INVENTORY_OF_DISPENSED_DRUGS_SUCCEEDED: {
      const inventory = action.payload;
      return {
        ...state,
        selectedPrescription: {
          ...state.selectedPrescription,
          inventory
        }
      }
    }
    case GET_SHIPPING_DETAILS_SUCCEEDED: {
      const shippingDetails = action.payload;
      return {
        ...state,
        selectedPrescription: {
          ...state.selectedPrescription,
          shippingDetails: shippingDetails
        }
      }
    }
    case FETCH_CONSIGNMENT_DETAILS_SUCCEEDED: {
      const shippingConsignment = action.payload;
      return {
        ...state,
        shippingConsignment
      }
    }
    case FETCH_USERS_TO_APPROVE_SUCCEEDED: {
      return {
        ...state,
        approvingUsers: action.payload
      }
    }
    case FETCH_ORDERLIST_REPORT_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      let lists = pagedData !== null ? pagedData : [];
      lists = lists.reduce((acc:any, curr:any, index:number) => {
        let obj:any = {
          orderNo: index+1,
          siteName: curr.siteName,
          shippedQty: curr.shippedQty,
          stratification: curr.stratifactionName,
          trialName: curr.trialName,
          dispensedOn: curr.dispensedOn
        }

        for (let inv of curr.inventories){
          obj = {
            ...obj,
            treatmentName: inv.treatmentName,
            batch: inv.batch,
            drugType: inv.drugType,
            productCode: inv.productCode,
            kitNumbers: inv.kitNumbers,
            bottleNumbers: inv.bottleNumbers,
            randomisationNumbers: inv.randomisationNumbers,
            expiry: inv.expiry,
            quantityOrdered: Number(inv.quantityToDispense) > 0 ? inv.quantityToDispense : inv.qtyFulfilled
          }
          acc.push(obj)
        }

        return acc;
      }, []);

      const shipmentReport = append ? [...state.shipmentReport, ...lists] : lists;
      return {
        ...state,
        ...rest,
        shipmentReport
      }
    }
    case FETCH_ALL_ORDERLIST_REPORT_SUCCEEDED: {
      const { payload } = action;
      let lists = payload !== null ? payload : [];
      lists = lists.reduce((acc:any, curr:any, index:number) => {
        let obj:any = {
          orderNo: index+1,
          siteName: curr.siteName,
          shippedQty: curr.shippedQty,
          stratification: curr.stratifactionName,
          trialName: curr.trialName,
          dispensedOn: curr.dispensedOn
        }

        for (let inv of curr.inventories){
          obj = {
            ...obj,
            treatmentName: inv.treatmentName,
            batch: inv.batch,
            drugType: inv.drugType,
            productCode: inv.productCode,
            kitNumbers: inv.kitNumbers,
            bottleNumbers: inv.bottleNumbers,
            randomisationNumbers: inv.randomisationNumbers,
            expiry: inv.expiry,
            quantityOrdered: Number(inv.quantityToDispense) > 0 ? inv.quantityToDispense : inv.qtyFulfilled 
          }
          acc.push(obj)
        }

        return acc;
      }, []);

      const shipmentReportDownload = lists;
      return {
        ...state,
        shipmentReportDownload
      }
    }
    /* case FETCH_AVAILABLE_ROUTES_SUCCEEDED: {
      const availableRoutes = action.payload;
      return {
        ...state,
        availableRoutes
      }
    } */
    case REMOVE_SELECTED_PRESCRIPTION: {
      return {
        ...state,
        selectedPrescription: {
          inventory: null,
          shippingDetails: null
        }
      }
    }
    case RESET_PRESCRIPTION: {
      return {
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}
