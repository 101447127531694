import React, {useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';
import {ContentView} from '../../index';
import useStyles from '../../../shared/jss/common/common.style';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {AppState} from '../../../redux/store';
import { loadBaseUrl } from '@crema/utility/Utils';

const useStyle = makeStyles((theme) => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    // background: '#76CFE7',
    background: '#00001B',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {
      padding: 20,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: '#fff',
  }
}));

interface CremaLayoutProps {}

const CremaLayout: React.FC<CremaLayoutProps> = () => {
  useStyles();
  const {navStyle} = useContext<AppContextPropsType>(AppContext);
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const { baseApiUrl } = useSelector<AppState, AppState['settings']>(({settings}) => settings);
  const { backdropLoader } = useSelector<AppState, AppState['common']>(({common}) => common);
  const AppLayout = Layouts[navStyle];

  const classes = useStyle();
  
  useEffect(() =>{
    loadBaseUrl(baseApiUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseApiUrl, user]);

  return (
    <>
      {baseApiUrl && (
        <>
          {user ? (
            <>
              <AppLayout />
              <Backdrop className={classes.backdrop} open={backdropLoader} >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
          ) : (
            <Box className={classes.appAuth}>
              <ContentView />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(CremaLayout);
