import { Inventory } from "../../types/models/Inventory";
import { FETCH_KVP_FROM_INVENTORY_SUCCEEDED } from "../../types/actions/Common.action";
import { 
  InventoryActionsTypes,
  FETCH_INVENTORY_LIST_SUCCEEDED,
  FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR_SUCCEEDED,
  SUBMIT_INVENTORY_SUCCEEDED,
  REMOVE_SELECTED_INVENTORY,
  UPDATE_INVENTORY_SUCCEEDED,
  SET_SELECTED_INVENTORY,
  DELETE_INVENTORY_SUCCEEDED,
  FETCH_INVENTORY_BY_ID_SUCCEEDED,
  SUBMIT_PRINT_INVENTORY_SUCCEEDED,
  FETCH_KVP_TRIAL_SUCCEEDED,
  RESET_PRINT_INVENTORY,
  RESET_INVENTORY,
  FETCH_INVENTORY_DETAILS_SUCCEEDED,
  FETCH_BATCH_BY_TRIALID_SUCCEEDED,
  FETCH_PRODUCTCODE_BY_TRIALID_SUCCEEDED,
  FETCH_UPLOADED_FILES_SUCCEEDED,
  RESET_UPLOADEDFILES
} from "../../types/actions/Inventory.action";

const initialState: Inventory = {
  inventoryList: [],
  selectedInventory: null,
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  trialKVP: [],
  trialTreatments: [],
  printInventory: false,
  inventoryDetails: null,
  allBatches: [],
  allProductCodes: [],
  uploadedFiles: []
}

export default (state = initialState, action: InventoryActionsTypes | any): Inventory => {
  switch (action.type) {
    case FETCH_INVENTORY_LIST_SUCCEEDED:
    case FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const inventoryList = append ? [...state.inventoryList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        inventoryList
      }
    }
    case SUBMIT_INVENTORY_SUCCEEDED: {
      // const { payload } = action
      // const inventoryList = [...state.inventoryList, payload]
      return {
        ...state,
        // inventoryList
      }
    }
    case UPDATE_INVENTORY_SUCCEEDED: {
      const {id, payload } = action;
      const inventoryList = [...state.inventoryList].map((list:any) => {
        if(list.id === id && payload.isLocationUpdated) {
          list = {...list, location: payload.inventoryLocation }
        }
        return list
      })
      return {
        ...state,
        inventoryList,
        // selectedInventory: payload
      }
    }
    case DELETE_INVENTORY_SUCCEEDED: {
      const { id } = action;
      const inventoryList = [...state.inventoryList].filter(list => list.id !== id);
      return {
        ...state,
        inventoryList
      }
    }
    case FETCH_INVENTORY_BY_ID_SUCCEEDED: {
      const selectedInventory = action.payload
      return{
        ...state,
        selectedInventory
      }
    }
    
    case FETCH_KVP_TRIAL_SUCCEEDED:
    case FETCH_KVP_FROM_INVENTORY_SUCCEEDED: {
      const { payload:trialKVP } = action;
      return {
        ...state,
        trialKVP
      }
    }
    case SUBMIT_PRINT_INVENTORY_SUCCEEDED: {
      return {
        ...state,
        printInventory: true
      }
    }
    case FETCH_INVENTORY_DETAILS_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        inventoryDetails: payload
      }
    }
    case FETCH_BATCH_BY_TRIALID_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        allBatches: payload
      }
    }

    case FETCH_PRODUCTCODE_BY_TRIALID_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        allProductCodes: payload
      }
    }

    case FETCH_UPLOADED_FILES_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        uploadedFiles: payload
      }
    }

    case SET_SELECTED_INVENTORY: {
      const { id } =  action;
      const selectedInventory = [...state.inventoryList].find((list:any) => list.id === id) || null;
      return{
        ...state,
        selectedInventory
      }
    }
    case REMOVE_SELECTED_INVENTORY: {
      return {
        ...state,
        selectedInventory: null,
        inventoryDetails: null
      }
    }
    case RESET_PRINT_INVENTORY:{
      return {
        ...state,
        printInventory: false
      }
    }
    case RESET_UPLOADEDFILES: {
      return {
        ...state,
        uploadedFiles: []
      }
    }
    case RESET_INVENTORY: {
      return {
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}