import { DefaultPricing } from "../../types/models/Pricing";
import { 
  FETCH_PRICING,
  SUBMIT_PRICING,
  FETCH_PRICING_SUCCEEDED,
  SUBMIT_PRICING_SUCCEEDED,
  PricingActionTypes
} from "../../types/actions/Pricing.action";

export const fetchPricing = () => ({
  type: FETCH_PRICING
});

export const fetchPricingSucceeded = (payload: DefaultPricing):PricingActionTypes => ({
  type: FETCH_PRICING_SUCCEEDED,
  payload
});

export const submitPricing = (payload:DefaultPricing):PricingActionTypes => ({
  type: SUBMIT_PRICING,
  payload
});

export const submitPricingSucceeded = (payload:DefaultPricing):PricingActionTypes => ({
  type: SUBMIT_PRICING_SUCCEEDED,
  payload
});