import {Common} from '../../types/models/Common';
import {
  CommonActionTypes,
  FETCH_ERROR,
  FETCH_START,
  FETCH_STOP,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  RESET_PROMISE_COMPLETE,
  SET_CRUD,
  RESET_CRUD,
  RESET_ERROR
} from '../../types/actions/Common.action';


const INIT_STATE: Common = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  promiseComplete: false,
  backdropLoader: false,
  crudAction: ''
};

export default (state = INIT_STATE, action: CommonActionTypes): Common => {
  switch (action.type) {
    case FETCH_START: {
      const { backdropLoader } = action;
      return {
        ...state, 
        // error: '', 
        message: '', 
        loading: true, 
        promiseComplete: false,
        backdropLoader
      };
    }
    case FETCH_STOP: {
      return {
        ...state, 
        backdropLoader: false
      };
    }
    case FETCH_SUCCESS: {
      const { promiseComplete } = action
      return {
        ...state,
        // error: '',
        message: '',
        loading: false,
        updatingContent: false,
        promiseComplete,
        backdropLoader: false
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.message,
        loading: false,
        updatingContent: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error || '',
        message: '',
        updatingContent: false,
        promiseComplete: true,
        backdropLoader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        updatingContent: false,
      };
    }
    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }
    case RESET_PROMISE_COMPLETE: {
      return {
        ...state,
        promiseComplete: false
      }
    }
    case SET_CRUD: {
      const { crudAction } = action
      return {
        ...state,
        crudAction
      }
    }
    case RESET_CRUD: {
      return {
        ...state,
        crudAction: ''
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: '',
        promiseComplete: false
      }
    }
    default:
      return state;
  }
};
