import { 
  FETCH_BILLING_REPORTS, 
  FETCH_BILLING_REPORTS_SUCCEEDED, 
  FETCH_REPORT_URL,
  ReportActionTypes 
} from "types/actions/Reports.actions";

export const fetchBillingReports = ():ReportActionTypes => ({
  type: FETCH_BILLING_REPORTS
});

export const fetchBillingReportsSucceeded = (payload: any):ReportActionTypes => ({
  type: FETCH_BILLING_REPORTS_SUCCEEDED,
  payload
});

export const fetchReportUrl = (uri:string): ReportActionTypes => ({
  type: FETCH_REPORT_URL,
  uri
});