import { User } from "../../types/models/Users";
import { FETCH_KVP_FROM_USERS_SUCCEEDED, FetchKVPSuccededAction  } from "../../types/actions/Common.action";
import { 
  UserActionsTypes,
  FETCH_SYSTEM_ROLES_SUCCEEDED,
  FETCH_USER_LIST_SUCCEEDED,
  SUBMIT_USER_SUCCEEDED,
  REMOVE_SELECTED_USER,
  UPDATE_USER_SUCCEEDED,
  SET_SELECTED_USER,
  DELETE_USER_SUCCEEDED,
  FETCH_USER_BY_ID_SUCCEEDED,
  FETCH_CURRENT_USER_BY_ID_SUCCEEDED,
  SUBMIT_USERSTATUS_SUCCEEDED,
  RESET_USER,
  UPDATE_PIN_SUCCEEDED
} from "../../types/actions/Users.action";

const initialState: User = {
  userList: [],
  currentUser: null,
  selectedUser: null,
  systemRoles: [],
  belongsTo: [],
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0
}

export default (state = initialState, action: UserActionsTypes | FetchKVPSuccededAction ): User => {
  switch (action.type) {
    case FETCH_USER_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      let userList = append ? [...state.userList, ...lists] : lists;

      // NOTE - Temp change for live domain
      const domain = window.location.host;
      if(domain === 'react-as.azurewebsites.net') {
      // if(domain === 'localhost:3000') {
        userList = userList.filter(({email}) => !email.includes('tsyntro+'))
      }
      
      return {
        ...state,
        ...rest,
        userList
      }
    }
    case FETCH_SYSTEM_ROLES_SUCCEEDED: {
      const systemRoles = action.payload;
      return {
        ...state,
        systemRoles
      }
    }
    case SUBMIT_USER_SUCCEEDED: {
      const { payload } = action
      const userList = [...state.userList, payload]
      return {
        ...state,
        userList
      }
    }
    case UPDATE_USER_SUCCEEDED: {
      const { payload } = action;
      const selectedUser = payload;
      return {
        ...state,
        selectedUser
      }
    }
    case DELETE_USER_SUCCEEDED: {
      const { id } = action;
      const userList = [...state.userList].filter(list => list.id !== id);
      return {
        ...state,
        userList
      }
    }
    case SUBMIT_USERSTATUS_SUCCEEDED:
    case FETCH_USER_BY_ID_SUCCEEDED: {
      const { payload } = action;
      const selectedUser = payload;
      return{
        ...state,
        selectedUser
      }
    }
    case FETCH_CURRENT_USER_BY_ID_SUCCEEDED: {
      const { payload } = action;
      const currentUser = payload;
      const organisationId = currentUser?.appMetadata?.organisationId;
      const _authUser = localStorage.getItem('auth-user');

      if(_authUser) {
        const authUser = {...JSON.parse(_authUser), organisationId};
        localStorage.setItem('auth-user', JSON.stringify(authUser))
      }
      return{
        ...state,
        currentUser
      }
    }
    case SET_SELECTED_USER: {
      const { id } =  action;
      const selectedUser = state.userList[id];
      return{
        ...state,
        selectedUser
      }
    }
    case REMOVE_SELECTED_USER: {
      return {
        ...state,
        selectedUser: null
      }
    }
    case FETCH_KVP_FROM_USERS_SUCCEEDED: {
      const { payload: belongsTo } = action
      return {
        ...state,
        belongsTo
      }
    }
    case UPDATE_PIN_SUCCEEDED: {
      // const { payload: belongsTo } = action
      return {
        ...state,
        // belongsTo
      }
    }
    case RESET_USER: {
      return {
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}