import { UserList, SystemRole } from "../../types/models/Users";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_USER_LIST,
  FETCH_USER_BY_ID,
  FETCH_CURRENT_USER_BY_ID,
  SUBMIT_USER,
  UPDATE_USER,
  DELETE_USER,
  FETCH_USER_LIST_SUCCEEDED,
  FETCH_USER_BY_ID_SUCCEEDED,
  FETCH_CURRENT_USER_BY_ID_SUCCEEDED,
  SUBMIT_USER_SUCCEEDED,
  UPDATE_USER_SUCCEEDED,
  DELETE_USER_SUCCEEDED,
  SET_SELECTED_USER,
  REMOVE_SELECTED_USER,
  SUBMIT_USERSTATUS,
  SUBMIT_FORGOTPASSWORD,
  SUBMIT_ACCOUNT_CONFIMRATION,
  SUBMIT_FORGOTPASSWORD_SUCCEEDED,
  SUBMIT_ACCOUNT_CONFIMRATION_SUCCEEDED,
  SUBMIT_USERSTATUS_SUCCEEDED,
  FETCH_SYSTEM_ROLES,
  FETCH_SYSTEM_ROLES_SUCCEEDED,
  RESET_USER,
  UserActionsTypes,
  UPDATE_PIN,
  UPDATE_PIN_SUCCEEDED,
  SEND_MAIL,
  SEND_MAIL_SUCCEEDED
} from "../../types/actions/Users.action";

export const fetchSystemRoles = ():UserActionsTypes => ({
  type: FETCH_SYSTEM_ROLES
});

export const fetchUserList = (payload: GetQueryParams) => ({
  type: FETCH_USER_LIST,
  payload
});

export const submitUser = (payload:UserList):UserActionsTypes => ({
  type: SUBMIT_USER,
  payload
});

export const updateUser = (id: any, payload:UserList):UserActionsTypes => ({
  type: UPDATE_USER,
  id,
  payload
});

export const deleteUser = (id: number):UserActionsTypes => ({
  type: DELETE_USER,
  id
});

export const fetchUserById = (id:string):UserActionsTypes => ({
  type: FETCH_USER_BY_ID,
  id
});

export const fetchCurrentUserById = (id:any):UserActionsTypes => ({
  type: FETCH_CURRENT_USER_BY_ID,
  id
});

export const setSelectedUser = (id:number) => ({
  type: SET_SELECTED_USER,
  id
});

export const removeSelectedUser = () => ({
  type: REMOVE_SELECTED_USER
});

export const submitForgotPassword = (payload: {email:string}) => ({
  type: SUBMIT_FORGOTPASSWORD,
  payload
});

export const submitAccountConfirmation = (payload: {userId: string}) => ({
  type: SUBMIT_ACCOUNT_CONFIMRATION,
  payload
});

export const submitUserStatus = (payload: {id:string, status:boolean}) => ({
  type: SUBMIT_USERSTATUS,
  payload
});

export const fetchSystemRolesSucceeded = (payload: SystemRole[]):UserActionsTypes => ({
  type: FETCH_SYSTEM_ROLES_SUCCEEDED,
  payload
});


export const fetchUserListSucceeded = (payload:any, append: boolean): UserActionsTypes=> ({
  type: FETCH_USER_LIST_SUCCEEDED,
  payload,
  append
});

export const submitUserSucceeded = (payload:UserList):UserActionsTypes => ({
  type: SUBMIT_USER_SUCCEEDED,
  payload
});

export const updateUserSucceeded = (payload:UserList):UserActionsTypes => ({
  type: UPDATE_USER_SUCCEEDED,
  payload
});

export const deleteUserSucceeded = (id: number):UserActionsTypes => ({
  type: DELETE_USER_SUCCEEDED,
  id
});

export const fetchUserByIdSucceeded = (payload:UserList):UserActionsTypes => ({
  type: FETCH_USER_BY_ID_SUCCEEDED,
  payload
});

export const fetchCurrentUserByIdSucceeded = (payload:UserList):UserActionsTypes => ({
  type: FETCH_CURRENT_USER_BY_ID_SUCCEEDED,
  payload
});

export const submitForgotPasswordSucceeded = () => ({
  type: SUBMIT_FORGOTPASSWORD_SUCCEEDED
});

export const submitAccountConfirmationSucceeded = (): UserActionsTypes => ({
  type: SUBMIT_ACCOUNT_CONFIMRATION_SUCCEEDED
});

export const submitUserStatusSucceeded = (payload: UserList): UserActionsTypes => ({
  type: SUBMIT_USERSTATUS_SUCCEEDED,
  payload
});

export const resetUser = (): UserActionsTypes => ({
  type: RESET_USER
});

export const updatePin = (payload:any): UserActionsTypes => ({
  type: UPDATE_PIN,
  payload
});

export const updatePinSucceeded = (payload:any): UserActionsTypes => ({
  type: UPDATE_PIN_SUCCEEDED,
  payload
});

export const sendMail = (payload:any) => ({
  type: SEND_MAIL,
  payload
})

export const sendMailSucceeded = (payload:any) => ({
  type: SEND_MAIL_SUCCEEDED,
  payload
})
