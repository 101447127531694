import createAuth0Client from "@auth0/auth0-spa-js";

const Auth0Config = async (client_id: string, domain: string, redirect_uri: string, audience: string ) => {
  return await createAuth0Client({
    client_id,
    domain,
    redirect_uri,
    audience,
    cacheLocation: 'localstorage',
    // useRefreshTokens: true
  });
};

export default Auth0Config;

/* {
  client_id: '3xeQEuRScslee1qEreEH9wH3MjAbz0wH',
  domain: 'syntro.au.auth0.com',
  redirect_uri: 'http://localhost:3000',
  audience : 'http://syntro-dev-api.azurewebsites.net/'
}*/