import { Payload } from "../models/Notifications";
import { GetQueryParams } from "../models/Common";

export const FETCH_PENDING_REQUESTS = "FETCH_PENDING_REQUESTS";
export const FETCH_PENDING_REQUESTS_SUCCEEDED = "FETCH_PENDING_REQUESTS_SUCCEEDED";
export const FETCH_REQUEST_HISTORY = "FETCH_REQUEST_HISTORY";
export const FETCH_REQUEST_HISTORY_SUCCEEDED = "FETCH_REQUEST_HISTORY_SUCCEEDED";
export const APPROVE_QA_REQUEST = "APPROVE_QA_REQUEST";
export const APPROVE_QA_REQUEST_SUCCEEDED = "APPROVE_QA_REQUEST_SUCCEEDED";
export const REJECT_QA_REQUEST = "REJECT_QA_REQUEST";
export const REJECT_QA_REQUEST_SUCCEEDED = "REJECT_QA_REQUEST_SUCCEEDED";
export const UPDATE_QA_REQUEST = "UPDATE_QA_REQUEST";
export const UPDATE_QA_REQUEST_SUCCEEDED = "UPDATE_QA_REQUEST_SUCCEEDED";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const FETCH_REQUEST_BY_ID = "FETCH_REQUEST_BY_ID";
export const FETCH_REQUEST_BY_ID_SUCCEEDED = "FETCH_REQUEST_BY_ID_SUCCEEDED";
export const REMOVE_SELECTED_REQUEST = "REMOVE_SELECTED_REQUEST";
export const FETCH_INVENTORY_FROM_QA = "FETCH_INVENTORY_FROM_QA";
export const FETCH_INVENTORY_FROM_QA_SUCCEEDED = "FETCH_INVENTORY_FROM_QA_SUCCEEDED";

export interface FetchPendingRequestActions {
  type: typeof FETCH_PENDING_REQUESTS;
  payload: GetQueryParams;
}

export interface FetchPendingRequestSucceededActions {
  type: typeof FETCH_PENDING_REQUESTS_SUCCEEDED,
  payload: Payload;
  append: boolean;
}

export interface FetchRequestHistoryActions {
  type: typeof FETCH_REQUEST_HISTORY;
  payload: GetQueryParams;
}

export interface FetchRequestHistorySucceededActions {
  type: typeof FETCH_REQUEST_HISTORY_SUCCEEDED,
  payload: Payload;
  append: boolean;
}

export interface ApproveRequestActions {
  type: typeof APPROVE_QA_REQUEST;
  id: string;
}

export interface ApproveRequestSucceededActions {
  type: typeof APPROVE_QA_REQUEST_SUCCEEDED;
  payload: any;
}

export interface RejectRequestActions {
  type: typeof REJECT_QA_REQUEST;
  payload: GetQueryParams;
}

export interface RejectRequestSucceededActions {
  type: typeof REJECT_QA_REQUEST_SUCCEEDED;
  payload: any;
}

export interface UpdateRequestActions {
  type: typeof UPDATE_QA_REQUEST;
  payload: GetQueryParams;
}

export interface UpdateRequestSucceededActions {
  type: typeof UPDATE_QA_REQUEST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchRequestByIdActions {
  type: typeof FETCH_REQUEST_BY_ID;
  id: string;
}

export interface FetchRequestByIdSucceededActions {
  type: typeof FETCH_REQUEST_BY_ID_SUCCEEDED;
  payload: any;
}

export interface RemoveSelectedRequestActions {
  type: typeof REMOVE_SELECTED_REQUEST
}

export interface ResetNotification {
  type: typeof RESET_NOTIFICATION
}

export interface fetchInventoryFromQAActions {
  type: typeof FETCH_INVENTORY_FROM_QA;
  payload:any;
}

export interface FetchInventoryFromQASucceededActions {
  type: typeof FETCH_INVENTORY_FROM_QA_SUCCEEDED;
  payload:any;
  append: boolean;
}

export type NotificationActionsTypes = 
| FetchPendingRequestActions
| FetchPendingRequestSucceededActions
| FetchRequestHistoryActions
| FetchRequestHistorySucceededActions
| ApproveRequestActions
| ApproveRequestSucceededActions
| RejectRequestActions
| RejectRequestSucceededActions
| UpdateRequestActions
| UpdateRequestSucceededActions
| FetchRequestByIdActions
| FetchRequestByIdSucceededActions
| ResetNotification
| RemoveSelectedRequestActions
| fetchInventoryFromQAActions
| FetchInventoryFromQASucceededActions
;