import { ConsultationList, Payload } from "../models/Consultations";
import { GetQueryParams } from "../models/Common";

export const FETCH_CONSULTATION_LIST = "FETCH_CONSULTATION_LIST";
export const SUBMIT_CONSULTATION = "SUBMIT_CONSULTATION";
export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION";
export const DELETE_CONSULTATION = "DELETE_CONSULTATION";
export const FETCH_CONSULTATION_BY_ID = "FETCH_CONSULTATION_BY_ID";
export const SET_SELECTED_CONSULTATION = "SET_SELECTED_CONSULTATION"
export const REMOVE_SELECTED_CONSULTATION = "REMOVE_SELECTED_CONSULTATION"
export const RESET_CONSULTATION = "RESET_CONSULTATION";
export const FETCH_CONSULTATION_LIST_SUCCEEDED = "FETCH_CONSULTATION_LIST_SUCCEEDED";
export const FETCH_CONSULTATION_BY_ID_SUCCEEDED = "FETCH_CONSULTATION_BY_ID_SUCCEEDED";
export const SUBMIT_CONSULTATION_SUCCEEDED = "SUBMIT_CONSULTATION_SUCCEEDED";
export const UPDATE_CONSULTATION_SUCCEEDED = "UPDATE_CONSULTATION_SUCCEEDED";
export const DELETE_CONSULTATION_SUCCEEDED = "DELETE_CONSULTATION_SUCCEEDED";

// Fetch consulation detail by Counselling Id and Prescription Id actions
export const FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID = "FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID";
export const FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID_SUCCEEDED = "FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID_SUCCEEDED";

// Update Consultation Stock status actions
export const UPDATE_CONSULTATION_STOCK_STATUS = "UPDATE_CONSULTATION_STOCK_STATUS";
export const UPDATE_CONSULTATION_STOCK_STATUS_SUCCEEDED = "UPDATE_CONSULTATION_STOCK_STATUS_SUCCEEDED";

// Follow up again actions types
export const FOLLOW_UP_AGAIN = "FOLLOW_UP_AGAIN";
export const FOLLOW_UP_AGAIN_SUCCEEDED = "FOLLOW_UP_AGAIN_SUCCEEDED";

// Mark as done actions types
export const MARK_AS_DONE = "MARK_AS_DONE";
export const MARK_AS_DONE_SUCCEEDED = "MARK_AS_DONE_SUCCEEDED";

// Comment actions types
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const SUBMIT_COMMENT_SUCCEEDED = "SUBMIT_COMMENT_SUCCEEDED";

// Comment history actions types
export const FETCH_COMMENTS_LIST = "FETCH_COMMENTS_LIST";
export const FETCH_COMMENTS_LIST_SUCCEEDED = "FETCH_COMMENTS_LIST_SUCCEEDED";

export interface FetchConsultationListActions {
  type: typeof FETCH_CONSULTATION_LIST,
  payload: GetQueryParams
}

export interface FetchConsultationListSucceededActions {
  type: typeof FETCH_CONSULTATION_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchConsultationByIdActions {
  type: typeof FETCH_CONSULTATION_BY_ID;
  id: string;
  counsellingId: string;
}

export interface SetSelectedConsultationActions {
  type: typeof SET_SELECTED_CONSULTATION;
  id: string
}

export interface FetchConsultationByIdSucceededActions {
  type: typeof FETCH_CONSULTATION_BY_ID_SUCCEEDED;
  payload: ConsultationList
}

export interface RemoveSelectedConsultationActions {
  type: typeof REMOVE_SELECTED_CONSULTATION
}

export interface SubmitConsultationActions {
  type: typeof SUBMIT_CONSULTATION;
  payload: ConsultationList | null;
}

export interface SubmitConsultationSucceededActions {
  type: typeof SUBMIT_CONSULTATION_SUCCEEDED;
  payload: ConsultationList;
}

export interface UpdateConsultationActions {
  type: typeof UPDATE_CONSULTATION;
  id: string;
  payload: ConsultationList;
}

export interface UpdateConsultationSucceededActions {
  type: typeof UPDATE_CONSULTATION_SUCCEEDED;
  id: string;
  payload: ConsultationList;
}

export interface DeleteConsultationActions {
  type: typeof DELETE_CONSULTATION;
  id: string
}

export interface DeleteConsultationSuceededActions {
  type: typeof DELETE_CONSULTATION_SUCCEEDED;
  id: string
}

export interface ResetConsultationActions {
  type: typeof RESET_CONSULTATION
}

// Fetch consulation detail by Counselling Id and Prescription Id actions
export interface FetchConsultationByIdAndPrescriptionIdActions {
  type: typeof FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID;
  counsellingId: string;
  prescriptionId: string;
}

export interface FetchConsultationByIdAndPrescriptionIdSucceededActions {
  type: typeof FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID_SUCCEEDED;
  payload: any;
}

// Update Consultation Stock status actions
export interface UpdateConsultationStockStatusActions {
  type: typeof UPDATE_CONSULTATION_STOCK_STATUS;
  prescriptionId: string;
  status: boolean;
}

export interface UpdateConsultationStockStatusSucceededActions {
  type: typeof UPDATE_CONSULTATION_STOCK_STATUS_SUCCEEDED;
  payload: any;
}

// Follow up again consultation actions
export interface FollowUpAgainActions {
  type: typeof FOLLOW_UP_AGAIN;
  prescriptionId: string;
  counsellingId: string;
  payload: {
    scheduledOn: string
  };
}

export interface FollowUpAgainSucceededActions {
  type: typeof FOLLOW_UP_AGAIN_SUCCEEDED;
  payload: any;
}

// Mark consultation as done actions
export interface MarkAsDoneActions {
  type: typeof MARK_AS_DONE;
  prescriptionId: string;
  counsellingId: string;
}

export interface MarkAsDoneSucceededActions {
  type: typeof MARK_AS_DONE_SUCCEEDED;
  payload: any;
}

// Comments actions
export interface SubmitCommentActions {
  type: typeof SUBMIT_COMMENT;
  prescriptionId: string;
  payload: {
    targetObjectId: string,
    comment: string
  };
}

export interface SubmitCommentSucceededActions {
  type: typeof SUBMIT_COMMENT_SUCCEEDED;
  payload: any;
}

export type ConsultationActionsTypes =
  | FetchConsultationListActions
  | SubmitConsultationActions
  | UpdateConsultationActions
  | DeleteConsultationActions
  | FetchConsultationListSucceededActions
  | FetchConsultationByIdSucceededActions
  | SubmitConsultationSucceededActions
  | UpdateConsultationSucceededActions
  | DeleteConsultationSuceededActions
  | FetchConsultationByIdActions
  | SetSelectedConsultationActions
  | RemoveSelectedConsultationActions
  | ResetConsultationActions
  | FetchConsultationByIdAndPrescriptionIdActions
  | FetchConsultationByIdAndPrescriptionIdSucceededActions
  | UpdateConsultationStockStatusActions
  | UpdateConsultationStockStatusSucceededActions
  | FollowUpAgainActions
  | FollowUpAgainSucceededActions
  | MarkAsDoneActions
  | MarkAsDoneSucceededActions
  | SubmitCommentActions
  | SubmitCommentSucceededActions
  ;