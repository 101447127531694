import React from 'react';

export const siteShippingRouteConfig = [
  {
    auth: ['Admin excluded', "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/site-shipping',
        exact: true,
        component: React.lazy(() => import('./SiteShipping')),
      },
      {
        path: '/site-shipping/:id',
        exact: true,
        component: React.lazy(() => import('./SiteShippingDetailList')),
      }
    ],
  }
];