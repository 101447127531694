import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError} from '../actions/Common';
import { Api, getGetParams} from "../../@crema/utility/Utils";
import {setSetting} from "../actions/Settings";

function getSettings() {
  return Api.fetchWithoutBase("/assets/appSettings.php?csrf=a193f0af-efa2-431b-b895-e693afa4fa86", getGetParams());
}

export function* getSettingsSaga() {
  try {
    const response  = yield call(getSettings);
    // TODO Check the response in the server later
    if(response) {
      yield put(setSetting(response));
    } else {
      yield put(fetchError(response.message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('INIT_SETTING' ,getSettingsSaga),
]