import {Dashboard} from "../../types/models/Dashboard";
import { 
  FETCH_ACTIVE_TRIAL_SUCCEEDED,
  FETCH_REPORT_FOR_TRIAL_SUCCEEDED,
  FETCH_STATS_SUCCEEDED,
  FETCH_DAILY_DISPENSE_PER_TRIAL_SUCCEEDED,
  RESET_DASHBOARD,
  DashboardActionTypes
} from "../../types/actions/Dashboard.action";

const initialState: Dashboard = {
  activeTrials: [],
  activeTrialReports: [],
  dispenseReport: [],
  stats: null
}

export default (state = initialState, action: DashboardActionTypes): any => {
  switch (action.type) {
    case FETCH_ACTIVE_TRIAL_SUCCEEDED: {
      return {
        ...state,
        activeTrials: action.payload
      }
    }
    case FETCH_REPORT_FOR_TRIAL_SUCCEEDED: {
      const activeTrialReports = action.payload
      return {
        ...state,
        activeTrialReports
      }
    }
    case FETCH_STATS_SUCCEEDED: {
      const stats = action.payload;
      return {
        ...state,
        stats
      }
    }
    case FETCH_DAILY_DISPENSE_PER_TRIAL_SUCCEEDED: {
      const dispenseReport = action.payload
      return {
        ...state,
        dispenseReport
      }
    }
    case RESET_DASHBOARD: {
      return {
        ...state,
        ...initialState
      }
    }
    default: {
      return state;
    } 
  }
}