import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams } from "../../@crema/utility/Utils";
import { fetchKVPSucceeded } from "../actions/Common";
import { 
  fetchSystemRolesSucceeded,
  fetchUserListSucceeded, 
  submitUserSucceeded, 
  updateUserSucceeded, 
  fetchUserByIdSucceeded,
  submitUserStatusSucceeded,
  submitForgotPasswordSucceeded,
  submitAccountConfirmationSucceeded,
  updatePinSucceeded,
  fetchCurrentUserByIdSucceeded,
  sendMailSucceeded
} from "../actions/Users";

const baseUrlv1 = `api/v1`;

function fetchSystemRolesApi() {
  const url = `${baseUrlv1}/roles`;
  return Api.fetch(url, getGetParams());
}

function fetchUserListByIdApi(id:number) {
  const url = `${baseUrlv1}/user/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchUserListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, Roles } = payload;
  const roles = Roles.map((role:string) => `Roles=${role}`);
  const url = `${baseUrlv1}/user/ListUsers?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${encodeURIComponent(SearchTerm)}&${roles.join('&')}`;
  return Api.fetch(url, getGetParams());
}

function submitUserApi (payload:any) {
  const url = `${baseUrlv1}/user`;
  return Api.fetch(url, getPostParams(payload));
}

function updateUserApi(payload:any) {
  const url = `${baseUrlv1}/user`;
  return Api.fetch(url, getPutParams(payload))
} 

function submitForgotPasswordApi(payload:any) {
  const url = `${baseUrlv1}/Auth/forgotPassword`;
  return Api.fetch(url, getPostParams(payload));
}

function submitAccountConfirmationApi(payload:any) {
  const url = `${baseUrlv1}/Auth/confirmAccount`;
  return Api.fetch(url, getPostParams(payload));
} 

function submitUserStatusApi(payload:any) {
  const url = `${baseUrlv1}/user/status`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchKVPApi(typeofKVP:string, searchValue:string) {
  const url = `${baseUrlv1}/${typeofKVP}/kvp?searchTerm=${searchValue}`;
  return Api.fetch(url, getGetParams());
}

function updatePinApi(payload: any) {
  const url = `${baseUrlv1}/user/updatePin`;
  return Api.fetch(url, getPostParams(payload));
}

function sendMailApi(payload: any) {
  const formUpload = true;
  const url = `${baseUrlv1}/Sponsor/sponsorcontact`;
  return Api.fetch(url, getPostParams(payload, formUpload));
}

export function* fetchSystemRoles() {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchSystemRolesApi);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchSystemRolesSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchUserById({id}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchUserListByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchUserByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No User found"
      } else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* fetchCurrentUserById({id}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchUserListByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchCurrentUserByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No User found"
      } else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchUserList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchUserListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchUserListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitUser({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitUserApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitUserSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateUser({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateUserApi, {...payload, id});
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateUserSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitForgotPassword({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitForgotPasswordApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitForgotPasswordSucceeded())
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitAccountConfirmation({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitAccountConfirmationApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitAccountConfirmationSucceeded())
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitUserStatus({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitUserStatusApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitUserStatusSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchKVP({ typeofKVP, searchValue, component }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchKVPApi, typeofKVP, searchValue);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchKVPSucceeded(response, typeofKVP, component))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updatePin({ payload }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(updatePinApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updatePinSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* sendMail({ payload }:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(sendMailApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(sendMailSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export default [
  takeLatest('FETCH_SYSTEM_ROLES', fetchSystemRoles),
  takeLatest('FETCH_USER_BY_ID' ,fetchUserById),
  takeLatest('FETCH_CURRENT_USER_BY_ID' ,fetchCurrentUserById),
  takeLatest('FETCH_USER_LIST' ,fetchUserList),
  takeLatest('SUBMIT_USER' ,submitUser),
  takeLatest('UPDATE_USER' ,updateUser),
  takeLatest('SUBMIT_FORGOTPASSWORD' ,submitForgotPassword),
  takeLatest('SUBMIT_ACCOUNT_CONFIMRATION' ,submitAccountConfirmation),
  takeLatest('SUBMIT_USERSTATUS' ,submitUserStatus),
  takeLatest('FETCH_KVP' ,fetchKVP),
  takeLatest('UPDATE_PIN' ,updatePin),
  takeLatest('SEND_MAIL' ,sendMail)
]