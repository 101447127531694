import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';

interface HorizontalNavProps {
  filterRoutesConfig?:any;
  qaPendingTotal?: any
}
// NOTE Menus are loaded from here
const HorizontalNav: React.FC<HorizontalNavProps> = ({filterRoutesConfig, qaPendingTotal}) => {

  return (
    <List className='navbarNav'>
      {filterRoutesConfig.map((item:any) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <HorizontalGroup item={item} nestedLevel={0} />
          )}

          {item.type === 'collapse' && (
            <HorizontalCollapse item={item} nestedLevel={0} />
          )}

          {item.type === 'item' && (
            <HorizontalItem item={item} nestedLevel={0} qaPendingTotal={qaPendingTotal}/>
          )}

          {item.type === 'divider' && (
            <Box my={5} clone>
              <Divider />
            </Box>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default HorizontalNav;
