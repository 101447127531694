import React, { useState, useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
// import {orange} from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { AppState } from 'redux/store';
import IntlMessages from '../../utility/IntlMessages';
// @ts-ignore
import {dropdownRouteConfig} from '../../../modules/routesConfig';
import routes from "../../../modules";
import Auth0Config from "../../services/auth/auth0/auth0Config";
import AppContext from '../../utility/AppContext';
import { useAuthUser } from '../../utility/AppHooks';
import {Fonts} from '../../../shared/constants/AppEnums';
import AppContextPropsType, { CremaTheme } from '../../../types/AppContextPropsType';
import { onSignOutAuth0User, fetchStart } from '../../../redux/actions'; // resetSettings
import { localStorageByQuery } from './../../utility/Utils';
import Alert from "../../../shared/components/Alert";

interface UserInfoProps {
  props?: any;
}

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const AUTH0_ClientId = useSelector((state: AppState) => state.settings.AUTH0_ClientId);
  const AUTH0_DOMAIN = useSelector((state: AppState) => state.settings.AUTH0_DOMAIN);
  const AUTH0_audience = useSelector((state: AppState) => state.settings.AUTH0_audience);
  const AUTH0_redirect_uri = useSelector((state: AppState) => state.settings.AUTH0_redirect_uri);

  const useStyles = makeStyles((theme: CremaTheme) => {
    return {
      avatar: {
        height: 40,
        width: 40,
        fontSize: 14,
        fontFamily: Fonts.MEDIUM,
        backgroundColor: '#76CFE7',
        [theme.breakpoints.up('xl')]: {
          height: 55,
          width: 55,
        },
      },
      userInfo: {
        width: 'calc(100% - 75px)',
      },
      userName: {
        color: themeMode === 'light' ? '#313541' : 'white',
      },
      pointer: {
        cursor: 'pointer',
      },
      userDetail: {
        color: '#000000de'
      },
      userRole: {
        fontSize: 12,
        color: '#9b9b9b'
      },
      userFullName: {
        fontFamily: Fonts.MEDIUM,
        width: 120,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    };
  });

  const classes = useStyles(props);
  const user = useAuthUser();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [filterRoutesConfig, setFilterRoutesConfig] = useState([]);

  const role = useSelector((state:any) => state.auth.user?.roles[0]);
  const currentUser = useSelector((state:any) => state.users.currentUser);

  useEffect(() => {
    if(role) {
      // const userType = currentUser.appMetadata.userType;
      const filterRoutesConfig:any = dropdownRouteConfig.filter((config:any) => {
        const route = routes.find((route):any => route.path === config.url);
    
        if(route) {
          // userType = 3 //Site  "Principal Investigators", "Sponsor", "Study Coordinators"
          // return route.auth.includes(role) || (userType === 3 && (route.auth.includes('Principal Investigators') || route.auth.includes('Study Coordinators')));
          return route.auth.includes(role)
        }
        return false;
      });

      setFilterRoutesConfig(filterRoutesConfig);
    }
  }, [role]);

  useEffect(() => {
    if(sessionExpired()) {
      console.log('session expired')
      setOpenAlert(true);
      setTimeout(() => {dispatch(fetchStart(true));signOut();}, 2000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
/*     if (user && user.displayName) {
      console.log(user.displayName)
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    } */

    if(currentUser) {
      const firstLetter = currentUser?.firstName ? currentUser?.firstName?.charAt(0).toUpperCase() : currentUser?.email?.charAt(0).toUpperCase();
      const secondLetter = currentUser?.lastName ? currentUser?.lastName?.charAt(0).toUpperCase() : currentUser?.firstName?.charAt(1).toUpperCase()
      return `${firstLetter}${secondLetter}`;
    }
  };
  const handleAlertClose = (state:boolean) => {
    setOpenAlert(state);
  }
  
  const signOut = async () => {
    dispatch(fetchStart(true));
    const auth0 = await Auth0Config(AUTH0_ClientId, AUTH0_DOMAIN, AUTH0_redirect_uri, AUTH0_audience);
    await auth0.logout({ returnTo:  AUTH0_redirect_uri});
    dispatch(onSignOutAuth0User());

    // await auth0.logout({ returnTo:  `${window.location.origin}/signout`});
    // const logoutUrl = `https://${AUTH0_DOMAIN}/v2/logout/?returnTo=${AUTH0_redirect_uri}&client_id=${AUTH0_ClientId}`;
    // window.location.replace(logoutUrl);
    // dispatch(resetSettings());
  }

  const sessionExpired = () => {
    const auth0Storage = localStorageByQuery(/^@@auth/)[0]?.value;
    const expiry = auth0Storage?.expiresAt;

    const currentTime = Date.now() / 1000;
    return currentTime > expiry;
  }

  return (
    <Box py={2} pl={{xs: 2, sm: 6}}>
      <Box className={classes.pointer} onClick={handleClick} display="flex" justifyContent="space-between" alignItems="center">
        {/* {user && user.photoURL ? (
          <Avatar className={classes.avatar} src={user.photoURL} />
        ) : 
        (
          <Avatar className={classes.avatar}>{getUserAvatar()}</Avatar>
        )} */}

        {currentUser && <Avatar className={classes.avatar}>{getUserAvatar()}</Avatar>}
        <Box className={classes.userDetail} mx={2}>
          <Box className={classes.userFullName}>{currentUser?.firstName} {currentUser?.lastName}</Box>
          {currentUser && (
            <Box className={classes.userRole}>
              {role.includes('Quality')
                ? 'Quality' 
                : role.includes('Warehouse') ? 'Logistic Specialist' : role
                // : isWarehouseUser ? 'Logistic Specialist' : role
              }
            </Box>
          )}
          
        </Box>
        <ExpandMoreIcon style={{color: '#76CFE7'}}/>
          
      </Box>
      <Box className={classes.userInfo}>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 45,
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
        >
          <MenuItem component={Link} to="/myaccount" onClick={handleClose}>My account</MenuItem>
          {filterRoutesConfig.map((item:any, index:any) => (
            <MenuItem key={index} component={Link} to={item.url} onClick={handleClose}><IntlMessages id={item.messageId} /></MenuItem>
          ))}
          <MenuItem
            onClick={() => {
              if (user) {
                signOut()
              }
            }}>
            Logout
          </MenuItem>
        </Menu>
      </Box>

      {openAlert && 
        <Alert 
          open={openAlert} 
          title="Information"
          message="Session has expired. Pleae login again."
          type="info"
          duration={4000}
          onClose={handleAlertClose}
        />
      }

    </Box>
  );
};

export default UserInfo;
