import {LayoutType} from '../../../shared/constants/AppEnums';

export const layoutTypes = [
  {
    id: 1,
    alias: LayoutType.FULL_WIDTH,
    image: require('assets/images/layouts/full width.png'),
  },
  {
    id: 2,
    alias: LayoutType.BOXED,
    image: require('assets/images/layouts/boxed.png'),
  },
];
