import { PatientList, Payload } from "../models/Patients";
import { GetQueryParams } from "../models/Common";

export const FETCH_PATIENT_LIST = "FETCH_PATIENT_LIST";
export const SUBMIT_PATIENT = "SUBMIT_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const FETCH_PATIENT_BY_ID = "FETCH_PATIENT_BY_ID";
export const SET_SELECTED_PATIENT = "SET_SELECTED_PATIENT"
export const REMOVE_SELECTED_PATIENT = "REMOVE_SELECTED_PATIENT"
export const RESET_PATIENT = "RESET_PATIENT";
export const FETCH_PATIENT_LIST_SUCCEEDED = "FETCH_PATIENT_LIST_SUCCEEDED";
export const FETCH_PATIENT_BY_ID_SUCCEEDED = "FETCH_PATIENT_BY_ID_SUCCEEDED";
export const SUBMIT_PATIENT_SUCCEEDED = "SUBMIT_PATIENT_SUCCEEDED";
export const UPDATE_PATIENT_SUCCEEDED = "UPDATE_PATIENT_SUCCEEDED";
export const DELETE_PATIENT_SUCCEEDED = "DELETE_PATIENT_SUCCEEDED";
export const FETCH_PATIENT_SCRIPT_HISTORY = "FETCH_PATIENT_SCRIPT_HISTORY";
export const FETCH_PATIENT_SCRIPT_HISTORY_SUCCEEDED = "FETCH_PATIENT_SCRIPT_HISTORY_SUCCEEDED";
export const SUBMIT_PRESCRIPTION = "SUBMIT_PRESCRIPTION";
export const SUBMIT_PRESCRIPTION_SUCCEEDED = "SUBMIT_PRESCRIPTION_SUCCEEDED";
export const FETCH_PRESCRIPTION_SUMMARY = "FETCH_PRESCRIPTION_SUMMARY";
export const FETCH_PRESCRIPTION_SUMMARY_SUCCEEDED = "FETCH_PRESCRIPTION_SUMMARY_SUCCEEDED";
export const SUBMIT_PIN_GENERATION = "SUBMIT_PIN_GENERATION";
export const SUBMIT_PIN_GENERATION_SUCCEEDED = "SUBMIT_PIN_GENERATION_SUCCEEDED";
export const FETCH_UNBLINDED = "FETCH_UNBLINDED";
export const FETCH_UNBLINDED_SUCCEEDED = "FETCH_UNBLINDED_SUCCEEDED";
export const FETCH_UNBLINDED_NAMES = "FETCH_UNBLINDED_NAMES";
export const FETCH_UNBLINDED_NAMES_SUCCEEDED = "FETCH_UNBLINDED_NAMES_SUCCEEDED";
export const RESET_UNBLIND = "RESET_UNBLIND";
export const STOCK_AVAILABILITY = "STOCK_AVAILABILITY";
export const STOCK_AVAILABILITY_SUCCEEDED = "STOCK_AVAILABILITY_SUCCEEDED";
export const CREATE_REPEAT = "CREATE_REPEAT";
export const CREATE_REPEAT_SUCCEEDED = "CREATE_REPEAT_SUCCEEDED";
export interface FetchPatientListActions {
  type: typeof FETCH_PATIENT_LIST,
  payload: GetQueryParams
}

export interface FetchPatientListSucceededActions {
  type: typeof FETCH_PATIENT_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchPatientByIdActions {
  type: typeof FETCH_PATIENT_BY_ID;
  trialId: string;
  id: string
}

export interface SetSelectedPatientActions {
  type: typeof SET_SELECTED_PATIENT;
  id: string
}

export interface FetchPatientByIdSucceededActions {
  type: typeof FETCH_PATIENT_BY_ID_SUCCEEDED;
  payload: PatientList
}

export interface RemoveSelectedPatientActions {
  type: typeof REMOVE_SELECTED_PATIENT
}

export interface SubmitPatientActions {
  type: typeof SUBMIT_PATIENT;
  payload: PatientList | null;
}

export interface SubmitPatientSucceededActions {
  type: typeof SUBMIT_PATIENT_SUCCEEDED;
  payload: PatientList;
}

export interface UpdatePatientActions {
  type: typeof UPDATE_PATIENT;
  id: string;
  payload: PatientList;
}

export interface UpdatePatientSucceededActions {
  type: typeof UPDATE_PATIENT_SUCCEEDED;
  id: string;
  payload: PatientList;
}

export interface DeletePatientActions {
  type: typeof DELETE_PATIENT;
  trialId: string;
  id: string
}

export interface DeletePatientSuceededActions {
  type: typeof DELETE_PATIENT_SUCCEEDED;
  id: string
}

export interface ResetPatientActions {
  type: typeof RESET_PATIENT
}

export interface FetchPatientScriptHistoryActions {
  type: typeof FETCH_PATIENT_SCRIPT_HISTORY;
  id: string;
  patientId: string;
}

export interface FetchPatientScriptHistorySucceededActions {
  type: typeof FETCH_PATIENT_SCRIPT_HISTORY_SUCCEEDED;
  payload:any
}

export interface SubmitPrescriptionActions {
  type: typeof SUBMIT_PRESCRIPTION;
  payload: any;
}

export interface SubmitPrescriptionSucceededActions {
  type: typeof SUBMIT_PRESCRIPTION_SUCCEEDED;
  payload: any;
}

export interface FetchPrescriptionSummaryActions {
  type: typeof FETCH_PRESCRIPTION_SUMMARY;
  id: string;
}

export interface FetchPrescriptionSummarySucceededActions {
  type: typeof FETCH_PRESCRIPTION_SUMMARY_SUCCEEDED;
  payload: any;
}

export interface SubmitPinGenerationActions{
  type: typeof SUBMIT_PIN_GENERATION;
  payload:any
}

export interface SubmitPinGenerationSucceededActions{
  type: typeof SUBMIT_PIN_GENERATION_SUCCEEDED;
  payload:any
}

export interface FetchUnblindedActions {
  type: typeof FETCH_UNBLINDED;
  payload: any;
}

export interface FetchUnblindedSucceededActions {
  type: typeof FETCH_UNBLINDED_SUCCEEDED;
  payload: any;
}

export interface fetchUnblindedNamesActions {
  type: typeof FETCH_UNBLINDED_NAMES;
  payload:any
}

export interface fetchUnblindedNamesSucceededActions {
  type: typeof FETCH_UNBLINDED_NAMES_SUCCEEDED;
  payload:any
}

export interface ResetUnblindActions {
  type: typeof RESET_UNBLIND
}

export interface StockAvailabilityActions {
  type: typeof STOCK_AVAILABILITY;
  trialId: string;
  patientId: string;
}

export interface StockAvailabilitySucceededActions {
  type: typeof STOCK_AVAILABILITY_SUCCEEDED;
  payload:any;
}

export interface CreateRepeatActions {
  type: typeof CREATE_REPEAT;
  trialId: string;
  patientId: string;
}

export interface CreateRepeatSucceededActions {
  type: typeof CREATE_REPEAT_SUCCEEDED;
  payload:any;
}


export type PatientActionsTypes =
  | FetchPatientListActions
  | SubmitPatientActions
  | UpdatePatientActions
  | DeletePatientActions
  | FetchPatientListSucceededActions
  | FetchPatientByIdSucceededActions
  | SubmitPatientSucceededActions
  | UpdatePatientSucceededActions
  | DeletePatientSuceededActions
  | FetchPatientByIdActions
  | SetSelectedPatientActions
  | RemoveSelectedPatientActions
  | ResetPatientActions
  | FetchPatientScriptHistoryActions
  | FetchPatientScriptHistorySucceededActions
  | SubmitPrescriptionActions
  | SubmitPrescriptionSucceededActions
  | FetchPrescriptionSummaryActions
  | FetchPrescriptionSummarySucceededActions
  | SubmitPinGenerationActions
  | SubmitPinGenerationSucceededActions
  | FetchUnblindedActions
  | FetchUnblindedSucceededActions
  | fetchUnblindedNamesActions
  | fetchUnblindedNamesSucceededActions
  | ResetUnblindActions
  | StockAvailabilityActions
  | StockAvailabilitySucceededActions
  | CreateRepeatActions
  | CreateRepeatSucceededActions
  ;