import React from 'react';

export const userRouteConfig = [
  {
    // auth: ["Admin", "Monitor", "Patient", "Pharmacists", "Pharmacy Assistants", "Principal Investigators", "Quality Associate QA", "Sponsor", "Study Coordinators"],
    auth: ["Admin"],
    routes: [
      {
        path: '/users',
        exact: true,
        component: React.lazy(() => import('./Users')),
      },
      {
        path: '/user/updatePin',
        exact: true,
        component: React.lazy(() => import('./PinUpdate')),
        auth: ["Admin excluded", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"]
      },
      {
        path: '/users/:id',
        exact: true,
        component: React.lazy(() => import('./UserDetails'))
      },
      {
        path: '/myaccount',
        exact: true,
        component: React.lazy(() => import('./Account')),
        auth: ["Admin", "Monitor", "Patient", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant", "Site", "Quality Associate QA", "Sponsor", "Sponsor Representative", "Patient"],
      },
      {
        path: '/contactus',
        exact: true,
        component: React.lazy(() => import('./ContactUs')),
        auth: ["Sponsor","Sponsor Representative"],
      }
    ],
  },
];
