import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles"
import Snackbar from "@material-ui/core/Snackbar";
import {Alert as AlertBar, AlertTitle as AlertBarTitle} from "@material-ui/lab";

import {
  resetError
 } from "../../../redux/actions/Common"

const useStyles = makeStyles(() => ({
  message: {
    width: '300px',
    // whiteSpace: 'pre'
  }
}));

interface AlertProps {
  open: boolean;
  duration?: number;
  title: string;
  message: string;
  type: "success" | "info" | "warning" | "error" | undefined;
  onClose: (state:boolean) => void;
};

const Alert: React.FC<AlertProps> = ({open, duration, title, message, type, onClose}) => {
  const dispatch = useDispatch();

  const [openAlert, setOpenAlert] = useState(open);
  const [msgInfo, setMsgInfo] = useState(message);
  const [alertType, setAlertType] = useState(type);

  const handleCloseAlert = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
    onClose(false);
    setMsgInfo('');
    setAlertType(undefined);
    /* istanbul ignore next */
    dispatch(resetError());
  };

  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={openAlert}
      autoHideDuration={duration ? duration : 2500}
      onClose={handleCloseAlert}
    >
      <AlertBar variant="filled" severity={alertType} onClose={handleCloseAlert}>
        <AlertBarTitle>{title}</AlertBarTitle>
        <div className={classes.message}>{msgInfo}</div>
      </AlertBar>
    </Snackbar>
  );
}

export default Alert;