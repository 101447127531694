import { Patient } from '../../types/models/Patients'; 
import { 
  PatientActionsTypes,
  FETCH_PATIENT_LIST_SUCCEEDED,
  SUBMIT_PATIENT_SUCCEEDED,
  REMOVE_SELECTED_PATIENT,
  UPDATE_PATIENT_SUCCEEDED,
  SET_SELECTED_PATIENT,
  DELETE_PATIENT_SUCCEEDED,
  FETCH_PATIENT_BY_ID_SUCCEEDED,
  FETCH_PATIENT_SCRIPT_HISTORY_SUCCEEDED,
  RESET_UNBLIND,
  RESET_PATIENT,
  SUBMIT_PRESCRIPTION_SUCCEEDED,
  FETCH_PRESCRIPTION_SUMMARY_SUCCEEDED,
  SUBMIT_PIN_GENERATION_SUCCEEDED,
  FETCH_UNBLINDED_SUCCEEDED,
  FETCH_UNBLINDED_NAMES_SUCCEEDED,
  STOCK_AVAILABILITY_SUCCEEDED,
  CREATE_REPEAT_SUCCEEDED
} from "../../types/actions/Patients.action";

const initialState: Patient = {
  patientList: [],
  selectedPatient: null,
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  scriptsHistory: [],
  secretNumber: '',
  drugsForPatient: '',
  prescriptionSummary: null,
  stockAvailable: true
}

export default (state = initialState, action: PatientActionsTypes): any => { // Patient
  switch (action.type) {
    case FETCH_PATIENT_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const patientList = append ? [...state.patientList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        patientList
      }
    }
    case SUBMIT_PATIENT_SUCCEEDED: {
      let { payload } = action;
      payload = {...payload, postcode: payload.postCode}
      const patientList = [...state.patientList, payload];
      const selectedPatient = payload;
      const totalResults:number = state.totalResults + 1;
      return {
        ...state,
        patientList,
        selectedPatient,
        totalResults
      }
    }
    case UPDATE_PATIENT_SUCCEEDED: {
      const {id, payload } = action;
      const patientList = [...state.patientList].map((list:any) => {
        if(list.id === id) {
          list = {...list, ...payload}
        }
        return list
      })
      return {
        ...state,
        patientList,
        selectedPatient: payload
      }
    }
    case DELETE_PATIENT_SUCCEEDED: {
      const { id } = action;
      const patientList = [...state.patientList].filter((list:any) => list.id !== id);
      return {
        ...state,
        patientList
      }
    }
    case FETCH_PATIENT_BY_ID_SUCCEEDED: {
      const selectedPatient = action.payload
      return{
        ...state,
        selectedPatient
      }
    }
    case FETCH_PATIENT_SCRIPT_HISTORY_SUCCEEDED: 
    case CREATE_REPEAT_SUCCEEDED:
    {
      const { payload: scriptsHistory } = action
      let selectedPatient:any = {...state.selectedPatient};
      if(action.type === CREATE_REPEAT_SUCCEEDED) {
        selectedPatient = {...selectedPatient, repeatsRemaining: Number(selectedPatient.repeatsRemaining) - 1}
      }
      return {
        ...state,
        scriptsHistory,
        selectedPatient
      }
    }
    case SUBMIT_PRESCRIPTION_SUCCEEDED: {
      // const { payload } = action;
      return {
        ...state,

      }
    }
    case FETCH_PRESCRIPTION_SUMMARY_SUCCEEDED: {
      const {payload: prescriptionSummary} = action
      return {
        ...state,
        prescriptionSummary
      }
    }
    case SET_SELECTED_PATIENT: {
      const { id } =  action;
      const selectedPatient = [...state.patientList].find((list:any) => list.id === id) || null;
      return{
        ...state,
        selectedPatient
      }
    }
    case SUBMIT_PIN_GENERATION_SUCCEEDED: {
      const secretNumber = action.payload;
      return {
        ...state,
        secretNumber
      }
    }
    case FETCH_UNBLINDED_SUCCEEDED: {
      return {
        ...state
      }
    }
    case FETCH_UNBLINDED_NAMES_SUCCEEDED: {
      const drugsForPatient = action.payload;
      return {
        ...state,
        drugsForPatient
      }
    }
    case STOCK_AVAILABILITY_SUCCEEDED: {
      const stockAvailable = action.payload
      return {
        ...state,
        stockAvailable
      }
    }
    case REMOVE_SELECTED_PATIENT: {
      return {
        ...state,
        selectedPatient: null
      }
    }
    case RESET_UNBLIND: {
      return {
        ...state,
        secretNumber: '',
        drugsForPatient: ''
      }
    }
    case RESET_PATIENT: {
      return {
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}