import { Setting } from "types/models/Setting";

export const TOGGLE_NAV_COLLAPSED = 'TOGGLE_NAV_COLLAPSED';
export const SET_INITIAL_PATH = 'SET_INITIAL_PATH';
export const ROUTE_CHANGE = '@@router/LOCATION_CHANGE';
export const INIT_SETTING = 'INIT_SETTING';
export const SET_SETTINGS = 'SET_SETTINGS';
export const RESET_SETTINGS = "RESET_SETTINGS";
export interface ToggleNavCollapsedAction {
  type: typeof TOGGLE_NAV_COLLAPSED;
}

export interface SetInitialPathAction {
  type: typeof SET_INITIAL_PATH;
  initialPath: string;
}

export interface InitSettingsAction {
  type: typeof INIT_SETTING;
}

export interface SetSettingsAction {
  type: typeof SET_SETTINGS;
  payload: Setting;
}

export interface ResetSettingsAction {
  type: typeof RESET_SETTINGS
}
export interface RouteChangeAction {
  type: typeof ROUTE_CHANGE;
}


export type SettingsActionTypes =
  | ToggleNavCollapsedAction
  | SetInitialPathAction
  | RouteChangeAction
  | SetSettingsAction
  | ResetSettingsAction
  | InitSettingsAction;
