import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from "../actions/Common";
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
// import { BaseUrl } from "../../shared/constants/AppEnums";
// import { GetQueryParams } from "../../types/models/Common";
import { 
  fetchCourierListSucceeded, 
  submitCourierSucceeded, 
  updateCourierSucceeded, 
  deleteCourierSucceeded,
  fetchAvailableRoutesSucceeded,
  fetchPharmacyAddressSucceeded,
  createApiConsignmentSucceeded,
  saveManualConsignmentSucceeded,
  fetchCourierKvpListSucceeded,
} from "../actions/Couriers";

const baseUrlv1 = 'api/v1';

function fetchCourierListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/Couriers?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchCourierKvpListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/Couriers/kvp?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function submitCourierApi (payload:any) {
  const url = `${baseUrlv1}/Couriers`;
  return Api.fetch(url, getPostParams(payload))
}

function updateCourierApi(payload:any) {
  const url = `${baseUrlv1}/Couriers`;
  return Api.fetch(url, getPutParams(payload))
} 

function deleteCourierApi(id:number) {
  const url = `${baseUrlv1}/Couriers/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchPharmacyAddressApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/Pharmacy/getAddresses?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchAvailableRoutesApi(payload:any) {
  // const {height, width, length, weight, quantity = 1, courierId, palletSpaces = 1, siteShippingId = '', prescriptionId ='', bulkOrderId = ''} = payload
  // const url = `${baseUrlv1}/shipping?PrescriptionId=32B0F41B-C425-4DAF-9E3B-1BD3878D2E2A&Quantity=1&Height=1&Width=1&Length=1&Weight=1`;
  const params= Object.keys(payload).map((key) => `${key}=${payload[key]}`);
  const url = `${baseUrlv1}/shipping?${params.join('&')}`;
  return Api.fetch(url, getGetParams());
}

function createApiConsignmentApi(payload:any) {
  const url = `${baseUrlv1}/shipping/consignment`;
  return Api.fetch(url, getPostParams(payload));
}

function saveManualConsignmentApi(payload:any) {
  const url = `${baseUrlv1}/shipping/shipping/manualconsignment`;
  return Api.fetch(url, getPostParams(payload));
}


export function* fetchCourierList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response = yield call(fetchCourierListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchCourierListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* fetchCourierKvpList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response = yield call(fetchCourierKvpListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchCourierKvpListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitCourier({payload}:any) {
  yield put(fetchStart(true)); // backdrop enable
  try {
    const response  = yield call(submitCourierApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitCourierSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateCourier({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateCourierApi, {...payload, id});
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateCourierSucceeded(id, payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteCourier({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deleteCourierApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteCourierSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchPharmacyAddress({payload}: any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchPharmacyAddressApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchPharmacyAddressSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchAvailableRoutes({payload}: any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchAvailableRoutesApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchAvailableRoutesSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* createApiConsignment({payload}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(createApiConsignmentApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(createApiConsignmentSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* saveManualConsignment({payload}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(saveManualConsignmentApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(saveManualConsignmentSucceeded(payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest("FETCH_COURIER_LIST" ,fetchCourierList),
  takeLatest("FETCH_COURIER_KVP_LIST" ,fetchCourierKvpList),
  takeLatest("SUBMIT_COURIER" ,submitCourier),
  takeLatest("UPDATE_COURIER" ,updateCourier),
  takeLatest("DELETE_COURIER" ,deleteCourier),
  takeLatest("FETCH_PHARMACY_ADDRESS" ,fetchPharmacyAddress),
  takeLatest("FETCH_AVAILABLE_ROUTES" ,fetchAvailableRoutes),
  takeLatest("CREATE_API_CONSIGNMENT" ,createApiConsignment),
  takeLatest("SAVE_MANUAL_CONSIGNMENT" ,saveManualConsignment)
]