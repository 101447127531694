
export const FETCH_ACTIVE_TRIAL = "FETCH_ACTIVE_TRIAL";
export const FETCH_ACTIVE_TRIAL_SUCCEEDED = "FETCH_ACTIVE_TRIAL_SUCCEEDED";
export const FETCH_REPORT_FOR_TRIAL = "FETCH_REPORT_FOR_TRIAL";
export const FETCH_REPORT_FOR_TRIAL_SUCCEEDED = "FETCH_REPORT_FOR_TRIAL_SUCCEEDED";
export const DOWNLOAD_REPORT_FILE = "DOWNLOAD_REPORT_FILE"; 
export const DOWNLOAD_REPORT_FILE_SUCCEEDED = "DOWNLOAD_REPORT_FILE_SUCCEEDED"; 
export const FETCH_DAILY_DISPENSE_PER_TRIAL = "FETCH_DAILY_DISPENSE_PER_TRIAL";
export const FETCH_DAILY_DISPENSE_PER_TRIAL_SUCCEEDED = "FETCH_DAILY_DISPENSE_PER_TRIAL_SUCCEEDED";
export const FETCH_STATS = "FETCH_STATS";
export const FETCH_STATS_SUCCEEDED = "FETCH_STATS_SUCCEEDED";
export const RESET_DASHBOARD = "RESET_DASHBOARD";
export interface FetchActiveTrialActions {
  type: typeof FETCH_ACTIVE_TRIAL
}
export interface FetchActiveTrialSucceededActions {
  type: typeof FETCH_ACTIVE_TRIAL_SUCCEEDED,
  payload: any;
}

export interface FetchReportForTrialActions {
  type: typeof FETCH_REPORT_FOR_TRIAL,
  payload: any;
}
export interface FetchReportForTrialSucceededActions {
  type: typeof FETCH_REPORT_FOR_TRIAL_SUCCEEDED
  payload: any;
  append: boolean;
}

export interface DownloadReportFileActions {
  type: typeof DOWNLOAD_REPORT_FILE;
  fileType: string;
  trialId: string;
}

export interface FetchStatsActions {
  type: typeof FETCH_STATS
}

export interface FetchStatsSucceededActions {
  type: typeof FETCH_STATS_SUCCEEDED;
  payload:any
}

export interface FetchDailyDispensePerTrialActions {
  type: typeof FETCH_DAILY_DISPENSE_PER_TRIAL;
  trialId: string;
}

export interface FetchDailyDispensePerTrialSucceededActions {
  type: typeof FETCH_DAILY_DISPENSE_PER_TRIAL_SUCCEEDED;
  payload: any;
}

export interface ResetDashboadActions {
  type: typeof RESET_DASHBOARD
}

export type DashboardActionTypes = 
  | FetchActiveTrialActions
  | FetchActiveTrialSucceededActions
  | FetchReportForTrialActions
  | FetchReportForTrialSucceededActions
  | DownloadReportFileActions
  | FetchStatsActions
  | FetchStatsSucceededActions
  | FetchDailyDispensePerTrialActions
  | FetchDailyDispensePerTrialSucceededActions
  | ResetDashboadActions
  ;