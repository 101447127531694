import { InventoryList, Payload, TrialTreatment } from "../models/Inventory";
import { GetQueryParams, KVP } from "../models/Common";

export const FETCH_INVENTORY_LIST = "FETCH_INVENTORY_LIST";
export const FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR = "FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR";
export const FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR_SUCCEEDED = "FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR_SUCCEEDED";
export const SUBMIT_INVENTORY = "SUBMIT_INVENTORY";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const DELETE_INVENTORY = "DELETE_INVENTORY";
export const FETCH_INVENTORY_BY_ID = "FETCH_INVENTORY_BY_ID";
export const SET_SELECTED_INVENTORY = "SET_SELECTED_INVENTORY"
export const REMOVE_SELECTED_INVENTORY = "REMOVE_SELECTED_INVENTORY"
export const RESET_INVENTORY = "RESET_INVENTORY";
export const FETCH_INVENTORY_LIST_SUCCEEDED = "FETCH_INVENTORY_LIST_SUCCEEDED";
export const FETCH_INVENTORY_BY_ID_SUCCEEDED = "FETCH_INVENTORY_BY_ID_SUCCEEDED";
export const SUBMIT_INVENTORY_SUCCEEDED = "SUBMIT_INVENTORY_SUCCEEDED";
export const UPDATE_INVENTORY_SUCCEEDED = "UPDATE_INVENTORY_SUCCEEDED";
export const DELETE_INVENTORY_SUCCEEDED = "DELETE_INVENTORY_SUCCEEDED";
export const FETCH_KVP_TRIAL = "FETCH_KVP_TRIAL";
export const FETCH_KVP_TRIAL_SUCCEEDED = "FETCH_KVP_TRIAL_SUCCEEDED";
export const FETCH_TRIAL_TREATMENT = "FETCH_TRIAL_TREATMENT";
export const FETCH_TRIAL_TREATMENT_SUCCEEDED = "FETCH_TRIAL_TREATMENT_SUCCEEDED";
export const SUBMIT_PRINT_INVENTORY = "SUBMIT_PRINT_INVENTORY";
export const SUBMIT_PRINT_INVENTORY_SUCCEEDED = "SUBMIT_PRINT_INVENTORY_SUCCEEDED";
export const RESET_PRINT_INVENTORY = "RESET_PRINT_INVENTORY";
export const FETCH_INVENTORY_DETAILS = "FETCH_INVENTORY_DETAILS";
export const FETCH_INVENTORY_DETAILS_SUCCEEDED = "FETCH_INVENTORY_DETAILS_SUCCEEDED";

export const FETCH_BATCH_BY_TRIALID = "FETCH_BATCH_BY_TRIALID";
export const FETCH_BATCH_BY_TRIALID_SUCCEEDED = "FETCH_BATCH_BY_TRIALID_SUCCEEDED";
export const FETCH_PRODUCTCODE_BY_TRIALID = "FETCH_PRODUCTCODE_BY_TRIALID";
export const FETCH_PRODUCTCODE_BY_TRIALID_SUCCEEDED = "FETCH_PRODUCTCODE_BY_TRIALID_SUCCEEDED";
export const FETCH_UPLOADED_FILES = "FETCH_UPLOADED_FILES"
export const FETCH_UPLOADED_FILES_SUCCEEDED = "FETCH_UPLOADED_FILES_SUCCEEDED"
export const RESET_UPLOADEDFILES = "RESET_UPLOADEDFILES"

export interface FetchInventoryListActions {
  type: typeof FETCH_INVENTORY_LIST,
  payload: GetQueryParams
}

export interface FetchInventoryListSucceededActions {
  type: typeof FETCH_INVENTORY_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchInventoryListForSiteAndSponsorActions {
  type: typeof FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR,
  payload: GetQueryParams
}

export interface FetchInventoryListForSiteAndSponsorSucceededActions {
  type: typeof FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchInventoryByIdActions {
  type: typeof FETCH_INVENTORY_BY_ID;
  id: string
}

export interface SetSelectedInventoryActions {
  type: typeof SET_SELECTED_INVENTORY;
  id: string
}

export interface FetchInventoryByIdSucceededActions {
  type: typeof FETCH_INVENTORY_BY_ID_SUCCEEDED;
  payload: InventoryList
}

export interface RemoveSelectedInventoryActions {
  type: typeof REMOVE_SELECTED_INVENTORY
}

export interface SubmitInventoryActions {
  type: typeof SUBMIT_INVENTORY;
  payload: InventoryList | null;
}

export interface SubmitInventorySucceededActions {
  type: typeof SUBMIT_INVENTORY_SUCCEEDED;
  payload: InventoryList;
}

export interface UpdateInventoryActions {
  type: typeof UPDATE_INVENTORY;
  id: string;
  payload: InventoryList;
}

export interface UpdateInventorySucceededActions {
  type: typeof UPDATE_INVENTORY_SUCCEEDED;
  id: string;
  payload: InventoryList;
}

export interface DeleteInventoryActions {
  type: typeof DELETE_INVENTORY;
  id: string
}

export interface DeleteInventorySucceededActions {
  type: typeof DELETE_INVENTORY_SUCCEEDED;
  id: string
}

export interface ResetInventoryActions {
  type: typeof RESET_INVENTORY
}

export interface FetchKVPTrialActions{
  type: typeof FETCH_KVP_TRIAL,
  searchValue: string
}

export interface FetchKVPTrialSucceededActions{
  type: typeof FETCH_KVP_TRIAL_SUCCEEDED,
  payload: KVP[]
};

export interface FetchTrialTreatmentActions {
  type: typeof FETCH_TRIAL_TREATMENT;
  id: string;
}
export interface FetchTrialTreatmentSucceededActions {
  type: typeof FETCH_TRIAL_TREATMENT_SUCCEEDED;
  payload: TrialTreatment[];
}

export interface SubmitPrintInventoryActions {
  type: typeof SUBMIT_PRINT_INVENTORY;
  inventoryId: string;
}

export interface SubmitPrintInventorySucceededActions {
  type: typeof SUBMIT_PRINT_INVENTORY_SUCCEEDED;
  payload: any;
}

export interface ResetPrintInventoryActions {
  type: typeof RESET_PRINT_INVENTORY;
}

export interface FetchInventoryDetailsActions {
  type: typeof FETCH_INVENTORY_DETAILS,
  trialId: string;
  treatmentId: string
}
export interface FetchInventoryDetailsSucceededActions {
  type: typeof FETCH_INVENTORY_DETAILS_SUCCEEDED,
  payload:any
}

export interface FetchBatchByTrialIdActions {
  type: typeof FETCH_BATCH_BY_TRIALID
  id: string
}

export interface FetchBatchByTrialIdSucceededActions {
  type: typeof FETCH_BATCH_BY_TRIALID_SUCCEEDED;
  payload: any
}

export interface FetchProductCodeByTrialIdActions {
  type: typeof FETCH_PRODUCTCODE_BY_TRIALID
  id: string
}

export interface FetchProductCodeByTrialIdSucceededActions {
  type: typeof FETCH_PRODUCTCODE_BY_TRIALID_SUCCEEDED;
  payload: any
}


export type InventoryActionsTypes =
  | FetchInventoryListActions
  | FetchInventoryListForSiteAndSponsorActions
  | SubmitInventoryActions
  | UpdateInventoryActions
  | DeleteInventoryActions
  | FetchInventoryListSucceededActions
  | FetchInventoryListForSiteAndSponsorSucceededActions
  | FetchInventoryByIdSucceededActions
  | SubmitInventorySucceededActions
  | UpdateInventorySucceededActions
  | DeleteInventorySucceededActions
  | FetchInventoryByIdActions
  | SetSelectedInventoryActions
  | RemoveSelectedInventoryActions
  | ResetInventoryActions
  | FetchKVPTrialActions
  | FetchKVPTrialSucceededActions
  | FetchTrialTreatmentActions
  | FetchTrialTreatmentSucceededActions
  | SubmitPrintInventoryActions
  | SubmitPrintInventorySucceededActions
  | ResetPrintInventoryActions
  | FetchInventoryDetailsActions
  | FetchInventoryDetailsSucceededActions
  | FetchBatchByTrialIdActions
  | FetchBatchByTrialIdSucceededActions
  | FetchProductCodeByTrialIdActions
  | FetchProductCodeByTrialIdSucceededActions
  ;