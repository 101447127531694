import { Stocktake } from "../../types/models/Stocktake";
import { 
  FETCH_TRIAL_STOCKTAKE_LIST_SUCCEEDED,
  FETCH_STOCKTAKE_LIST_SUCCEEDED,
  // UPDATE_INVENTORY_LIST,
  // UPDATE_STOCKTAKE_SUCCEEDED,
  FETCH_INVENTORY_RANDOMISATION_SUCCEEDED,
  SET_SELECTED_STOCKTAKE,
  UPDATED_SELECTED_INVENTORY,
  RESET_STOCKTAKE,
  RESET_STOCKTAKE_INVENTORY,
  StocktakeActionTypes
} from "../../types/actions/Stocktake.action";

const initialState: Stocktake = {
  trialStocktakeList: [],
  // selectedTrialStocktake: null,
  stocktake: null,
  inventoryList: [],
  inventoryUpdatedList: [],
  selectedInventory: null,
  currentPage: 0,
  pageSize: 5,
  totalPages: 0,
  totalResults: 0,
  inventoryRandomisationList: {
    onSelfRandomisations: [],
    dispensedRandomisations: []
  }
}

export default (state = initialState, action: StocktakeActionTypes): any => {
  switch (action.type) {
    case FETCH_TRIAL_STOCKTAKE_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const trialStocktakeList = append ? [...state.trialStocktakeList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        trialStocktakeList
      }
    }
    case FETCH_STOCKTAKE_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const { inventories, ...stocktake } = payload
      const {pagedData, ...rest} = inventories;
      const lists = pagedData;
      let inventoryList = append ? [...state.inventoryList, ...lists] : lists;
      // inventoryList = inventoryList.map((list:any) => {
      //   if(state.selectedInventory?.id === list.id) {
      //     list.actualQty = state.selectedInventory?.actualQty
      //   }
      //   return list
      // })
      return {
        ...state,
        ...rest,
        stocktake,
        inventoryList
      }
    }
    // case UPDATE_INVENTORY_LIST: {
    //   return {
    //     ...state
    //   }
    // }
    case FETCH_INVENTORY_RANDOMISATION_SUCCEEDED: {
      const { payload:inventoryRandomisationList } = action;
      return {
        ...state,
        inventoryRandomisationList
      }
    }
    case SET_SELECTED_STOCKTAKE: { // Selected Inventory
      const selectedInventory = action.payload;
      return {
        ...state,
        selectedInventory
      }
    }
    case UPDATED_SELECTED_INVENTORY: {
      let inventoryUpdatedList:any = [...state.inventoryUpdatedList];
      const { id, value } = action;

      if(inventoryUpdatedList.findIndex((list:any) => list.id === id) > -1) {
        inventoryUpdatedList = inventoryUpdatedList.map((list:any) => {
          if(list.id === id) list.actualQty = value;
          return list;
        })
      } else {
        const list = [...state.inventoryList].find((list:any) => list.id === id);
        inventoryUpdatedList = [...inventoryUpdatedList, list]
      }

      const inventoryList = [...state.inventoryList].map((list:any) => {
          if(list.id === id) {
            list.actualQty = value;
          }
          return list
        })
      
      return {
        ...state,
        inventoryList,
        inventoryUpdatedList
      }
    }
    case RESET_STOCKTAKE: {
      return {
        ...state,
        ...initialState
      }
    }
    case RESET_STOCKTAKE_INVENTORY: {
      return {
        ...state,
        inventoryList: []
      }
    }
    default:{
      return state;
    }
  }
} 