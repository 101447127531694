import { ParcelInfo } from "../models/SiteShipping";
import { GetQueryParams } from "../models/Common";

export const FETCH_SITE_SHIPPING_LIST = "FETCH_SITE_SHIPPING_LIST";
export const FETCH_SITE_SHIPPING_LIST_SUCCEEDED = "FETCH_SITE_SHIPPING_LIST_SUCCEEDED";
export const FETCH_SITE_SHIPPED_LIST = "FETCH_SITE_SHIPPED_LIST";
export const FETCH_SITE_SHIPPED_LIST_SUCCEEDED = "FETCH_SITE_SHIPPED_LIST_SUCCEEDED";

export const FETCH_SITE_TO_SHIP_DETAIL_LIST = "FETCH_SITE_TO_SHIP_DETAIL_LIST"
export const FETCH_SITE_TO_SHIP_DETAIL_LIST_SUCCEEDED = "FETCH_SITE_TO_SHIP_DETAIL_LIST_SUCCEEDED"

export const FETCH_SITE_SHIPPING_DETAIL_LIST = "FETCH_SITE_SHIPPING_DETAIL_LIST";
export const FETCH_SITE_SHIPPING_DETAIL_LIST_SUCCEEDED = "FETCH_SITE_SHIPPING_DETAIL_LIST_SUCCEEDED";
export const ADD_PARCEL_INFO = "ADD_PARCEL_INFO";
export const ADD_PARCEL_INFO_SUCCEEDED = "ADD_PARCEL_INFO_SUCCEEDED";
export const UPDATE_SHIPPING_INFO = "UPDATE_SHIPPING_INFO";
export const UPDATE_SHIPPING_INFO_SUCCEEDED = "UPDATE_SHIPPING_INFO_SUCCEEDED";
export const FETCH_SITE_SHIPPING_PICKING_SLIP = "FETCH_SITE_SHIPPING_PICKING_SLIP";
export const FETCH_SITE_SHIPPING_PICKING_SLIP_SUCCEEDED = "FETCH_SITE_SHIPPING_PICKING_SLIP_SUCCEEDED";
export const RESET_SITE_SHIPPING = "RESET_SITE_SHIPPING";
export const RESET_SITE_SHIPPING_DETAILS = "RESET_SITE_SHIPPING_DETAILS";
export const RESET_SITE_SHIPPING_SLIP = "RESET_SITE_SHIPPING_SLIP";
export const FETCH_SITE_RECEIVED_LIST = "FETCH_SITE_RECEIVED_LIST";
export const FETCH_SITE_RECEIVED_LIST_SUCCEEDED = "FETCH_SITE_RECEIVED_LIST_SUCCEEDED";
export const UPDATE_RECEIVED = "UPDATE_RECEIVED";
export const UPDATE_RECEIVED_SUCCEEDED = "UPDATE_RECEIVED_SUCCEEDED";
export const UPDATE_PRICE = "UPDATE_PRICE";
export const UPDATE_PRICE_SUCCEEDED = "UPDATE_PRICE_SUCCEEDED";
export const RESET_RECEIVED = "RESET_RECEIVED";
export const FETCH_SHIPPING_HISTORY = "FETCH_SHIPPING_HISTORY";
export const FETCH_SHIPPING_HISTORY_SUCCEEDED = "FETCH_SHIPPING_HISTORY_SUCCEEDED";
export const RESET_SHIPPING_HISTORY = "RESET_SHIPPING_HISTORY";

export interface FetchSiteShippingListActions {
  type: typeof FETCH_SITE_SHIPPING_LIST,
  payload: GetQueryParams
}

export interface FetchSiteShippingListSucceededActions {
  type: typeof FETCH_SITE_SHIPPING_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface FetchSiteShippedListActions {
  type: typeof FETCH_SITE_SHIPPED_LIST,
  payload: GetQueryParams
}

export interface FetchSiteShippedListSucceededActions {
  type: typeof FETCH_SITE_SHIPPED_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface FetchSiteToShipListActions {
  type: typeof FETCH_SITE_TO_SHIP_DETAIL_LIST,
  payload: GetQueryParams
}

export interface FetchSiteToShipListSucceededActions {
  type: typeof FETCH_SITE_TO_SHIP_DETAIL_LIST_SUCCEEDED,
  payload: any;
  append: boolean;
}

export interface FetchSiteShippingDetailListActions {
  type: typeof FETCH_SITE_SHIPPING_DETAIL_LIST,
  payload: GetQueryParams
}

export interface FetchSiteShippingDetailListSucceededActions {
  type: typeof FETCH_SITE_SHIPPING_DETAIL_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface AddParcelInfoActions {
  type: typeof ADD_PARCEL_INFO;
  id: string;
  payload: ParcelInfo;
}

export interface AddParcelInfoSucceededActions {
  type: typeof ADD_PARCEL_INFO_SUCCEEDED;
  payload: any;
}

export interface UpdateShippingInfoActions {
  type: typeof UPDATE_SHIPPING_INFO;
  id: string;
  payload: ParcelInfo;
}

export interface UpdateShippingInfoSucceededActions {
  type: typeof UPDATE_SHIPPING_INFO_SUCCEEDED;
  payload: any;
}

export interface FetchSiteShippingPickingSlipActions {
  type: typeof FETCH_SITE_SHIPPING_PICKING_SLIP,
  payload: any
}

export interface FetchSiteShippingPickingSlipSucceededActions {
  type: typeof FETCH_SITE_SHIPPING_PICKING_SLIP_SUCCEEDED,
  payload: any
}

export interface ResetSiteShippingSlipActions {
  type: typeof RESET_SITE_SHIPPING_SLIP;
}
export interface ResetSiteShippingActions {
  type: typeof RESET_SITE_SHIPPING;
}
export interface ResetSiteShippingDetailsActions {
  type: typeof RESET_SITE_SHIPPING_DETAILS;
}

export interface FetchSiteReceivedListActions {
  type: typeof FETCH_SITE_RECEIVED_LIST,
  payload: GetQueryParams
}

export interface FetchSiteReceivedListSucceededActions {
  type: typeof FETCH_SITE_RECEIVED_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface UpdateReceivedActions {
  type: typeof UPDATE_RECEIVED,
  id: string;
}

export interface UpdateReceivedSucceededActions {
  type: typeof UPDATE_RECEIVED_SUCCEEDED;
  payload: any
}

export interface UpdatePriceActions {
  type: typeof UPDATE_PRICE;
  id: string;
  price: number;
}

export interface UpdatePriceSucceededActions {
  type: typeof UPDATE_PRICE_SUCCEEDED;
  payload:any;
}

export interface ResetReceivedActions {
  type: typeof RESET_RECEIVED
}

export interface FetchShippingHistoryActions {
  type: typeof FETCH_SHIPPING_HISTORY,
  payload: any
}

export interface FetchShippingHistorySucceededActions {
  type: typeof FETCH_SHIPPING_HISTORY_SUCCEEDED,
  payload: any;
  append: boolean;
}
export interface ResetShippingHistoryActions {
  type: typeof RESET_SHIPPING_HISTORY
}


export type SiteShippingActionsTypes =
  | FetchSiteShippingListActions
  | FetchSiteShippingListSucceededActions
  | FetchSiteShippedListActions
  | FetchSiteShippedListSucceededActions
  | FetchSiteToShipListActions
  | FetchSiteToShipListSucceededActions
  | FetchSiteShippingDetailListActions
  | FetchSiteShippingDetailListSucceededActions
  | AddParcelInfoActions
  | AddParcelInfoSucceededActions
  | UpdateShippingInfoActions
  | UpdateShippingInfoSucceededActions
  | FetchSiteShippingPickingSlipActions
  | FetchSiteShippingPickingSlipSucceededActions
  | ResetSiteShippingSlipActions
  | ResetSiteShippingActions
  | ResetSiteShippingDetailsActions
  | FetchSiteReceivedListActions
  | FetchSiteReceivedListSucceededActions
  | UpdateReceivedActions
  | UpdateReceivedSucceededActions
  | UpdatePriceActions
  | UpdatePriceSucceededActions
  | ResetReceivedActions
  | FetchShippingHistoryActions
  | FetchShippingHistorySucceededActions
  | ResetShippingHistoryActions
  ;