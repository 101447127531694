import {AuthUser} from '../models/AuthUser';

//AUTH0
export const UPDATE_AUTH0_USER = 'UPDATE_AUTH0_USER';
export const SET_AUTH0_TOKEN_SET = 'SET_AUTH0_TOKEN_SET';
export const SIGNOUT_AUTH0_SUCCESS = 'SIGNOUT_AUTH0_SUCCESS';

export interface UpdateAuthUserActions {
  type: typeof UPDATE_AUTH0_USER;
  payload: AuthUser | null;
}


export interface SetAuthUserActions {
  type: typeof SET_AUTH0_TOKEN_SET;
  payload: AuthUser | null;
}

export interface SignOutAuthUserActions {
  type: typeof SIGNOUT_AUTH0_SUCCESS;
}

export type AuthActions = UpdateAuthUserActions | SetAuthUserActions | SignOutAuthUserActions;
