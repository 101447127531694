import { ReturnInventoryList } from "../../types/models/ReturnInventory";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_RETURN_INVENTORY_LIST,
  FETCH_RETURN_INVENTORY_BY_ID,
  SUBMIT_RETURN_INVENTORY,
  UPDATE_RETURN_INVENTORY,
  DELETE_RETURN_INVENTORY,
  FETCH_RETURN_INVENTORY_LIST_SUCCEEDED,
  FETCH_RETURN_INVENTORY_BY_ID_SUCCEEDED,
  SUBMIT_RETURN_INVENTORY_SUCCEEDED,
  UPDATE_RETURN_INVENTORY_SUCCEEDED,
  DELETE_RETURN_INVENTORY_SUCCEEDED,
  SET_SELECTED_RETURN_INVENTORY,
  REMOVE_SELECTED_RETURN_INVENTORY,
  RESET_RETURN_INVENTORY,
  ReturnInventoryActionsTypes,
  FETCH_ACTIVE_RETURNED_LIST,
  FETCH_ACTIVE_RETURNED_LIST_SUCCEEDED,
  FETCH_DESTROYED_BATCH_LIST,
  FETCH_DESTROYED_BATCH_LIST_SUCCEEDED,
  FETCH_DESTROYED_STOCK_REPORT_SUCCEEDED,
  FETCH_DESTROYED_STOCK_REPORT,
  UPDATE_DESTROYED_STOCK_REPORT,
  UPDATE_DESTROYED_STOCK_REPORT_SUCCEEDED,
  MARK_STOCK_REPORT,
  MARK_STOCK_REPORT_SUCCEEDED,
  LOOKUP_PATIENT,
  LOOKUP_PATIENT_SUCCEEDED,
  RESET_LOOKUP_PATIENT,
  RESET_DESTROYED_STOCK_REPORT,
  RESET_ACTIVE_RETURNED_LIST,
  RESET_DESTROYED_BATCH_LIST
} from "../../types/actions/ReturnInventory.action";

export const fetchReturnInventoryList = (payload: GetQueryParams) => ({
  type: FETCH_RETURN_INVENTORY_LIST,
  payload
});

export const submitReturnInventory = (payload:ReturnInventoryList):ReturnInventoryActionsTypes => ({
  type: SUBMIT_RETURN_INVENTORY,
  payload
});

export const updateReturnInventory = (id: string, payload:ReturnInventoryList):ReturnInventoryActionsTypes => ({
  type: UPDATE_RETURN_INVENTORY,
  id,
  payload
});

export const deleteReturnInventory = (id: string):ReturnInventoryActionsTypes => ({
  type: DELETE_RETURN_INVENTORY,
  id
});

export const fetchReturnInventoryById = (id: string, inventoryId: string) => ({
  type: FETCH_RETURN_INVENTORY_BY_ID,
  id,
  inventoryId
});

export const setSelectedReturnInventory = (payload: any) => ({
  type: SET_SELECTED_RETURN_INVENTORY,
  payload
});

export const removeSelectedReturnInventory = () => ({
  type: REMOVE_SELECTED_RETURN_INVENTORY
});

export const resetReturnInventory = (): ReturnInventoryActionsTypes => ({
  type: RESET_RETURN_INVENTORY
});

export const fetchReturnInventoryListSucceeded = (payload:any, append:boolean): ReturnInventoryActionsTypes=> ({
  type: FETCH_RETURN_INVENTORY_LIST_SUCCEEDED,
  payload,
  append
});

export const submitReturnInventorySucceeded = (payload:ReturnInventoryList):ReturnInventoryActionsTypes => ({
  type: SUBMIT_RETURN_INVENTORY_SUCCEEDED,
  payload
});

export const updateReturnInventorySucceeded = (id: string, payload:ReturnInventoryList):ReturnInventoryActionsTypes => ({
  type: UPDATE_RETURN_INVENTORY_SUCCEEDED,
  id,
  payload
});

export const deleteReturnInventorySucceeded = (id: string):ReturnInventoryActionsTypes => ({
  type: DELETE_RETURN_INVENTORY_SUCCEEDED,
  id
});

export const fetchReturnInventoryByIdSucceeded = (payload:ReturnInventoryList):ReturnInventoryActionsTypes => ({
  type: FETCH_RETURN_INVENTORY_BY_ID_SUCCEEDED,
  payload
});

export const fetchActiveReturnedList = (payload: GetQueryParams) => ({
  type: FETCH_ACTIVE_RETURNED_LIST,
  payload
});

export const fetchDestroyedBatchList = (payload: GetQueryParams) => ({
  type: FETCH_DESTROYED_BATCH_LIST,
  payload
});

export const fetchDestroyedStockReport = (id:string, stockId:string) => ({
  type: FETCH_DESTROYED_STOCK_REPORT,
  id,
  stockId
});

export const updateDestroyedStockReport = (id: string, stockId: string, payload: any) => ({
  type: UPDATE_DESTROYED_STOCK_REPORT,
  id,
  stockId,
  payload
});

export const markStockReport = (payload: any) => ({
  type: MARK_STOCK_REPORT,
  payload
});

export const fetchActiveReturnedListSucceeded = (payload: any, append: boolean) => ({
  type: FETCH_ACTIVE_RETURNED_LIST_SUCCEEDED,
  payload,
  append
});

export const fetchDestroyedBatchListSucceeded = (payload: any, append: boolean) => ({
  type: FETCH_DESTROYED_BATCH_LIST_SUCCEEDED,
  payload,
  append
});

export const fetchDestroyedStockReportSucceeded = (payload: any) => ({
  type: FETCH_DESTROYED_STOCK_REPORT_SUCCEEDED,
  payload
});

export const updateDestroyedStockReportSucceeded = (payload: any) => ({
  type: UPDATE_DESTROYED_STOCK_REPORT_SUCCEEDED,
  payload
});

export const markStockReportSucceeded = (payload: any) => ({
  type: MARK_STOCK_REPORT_SUCCEEDED,
  payload
});

export const lookupPatient = (id: string, protocolNumber:string, treatmentType: string) => ({
  type: LOOKUP_PATIENT,
  id,
  protocolNumber,
  treatmentType
});

export const lookupPatientSucceeded = (payload: any) => ({
  type: LOOKUP_PATIENT_SUCCEEDED,
  payload
});

export const resetLookupPatient = (): ReturnInventoryActionsTypes => ({
  type: RESET_LOOKUP_PATIENT
})

export const resetDestroyedStockReport = () => ({
  type: RESET_DESTROYED_STOCK_REPORT
});

export const resetActiveReturned = () => ({
  type: RESET_ACTIVE_RETURNED_LIST
})

export const resetDestroyedBatch = () => ({
  type: RESET_DESTROYED_BATCH_LIST
})