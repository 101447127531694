import { 
  FETCH_ACTIVE_TRIAL,
  FETCH_ACTIVE_TRIAL_SUCCEEDED,
  FETCH_REPORT_FOR_TRIAL,
  FETCH_REPORT_FOR_TRIAL_SUCCEEDED,
  DOWNLOAD_REPORT_FILE,
  FETCH_STATS,
  FETCH_STATS_SUCCEEDED,
  FETCH_DAILY_DISPENSE_PER_TRIAL,
  FETCH_DAILY_DISPENSE_PER_TRIAL_SUCCEEDED,
  RESET_DASHBOARD,
  DashboardActionTypes
} from "../../types/actions/Dashboard.action";

export const fetchActiveTrial = () => ({
  type: FETCH_ACTIVE_TRIAL
});

export const fetchActiveTrialSucceeded = (payload: any):DashboardActionTypes => ({
  type: FETCH_ACTIVE_TRIAL_SUCCEEDED,
  payload
});

export const fetchReportForTrial = (payload:any):DashboardActionTypes => ({
  type: FETCH_REPORT_FOR_TRIAL,
  payload
});

export const fetchReportForTrialSucceeded = (payload: any, append:boolean):DashboardActionTypes => ({
  type: FETCH_REPORT_FOR_TRIAL_SUCCEEDED,
  payload,
  append
});

export const downloadReportFile = (fileType:string, trialId:string):DashboardActionTypes => ({
  type: DOWNLOAD_REPORT_FILE,
  fileType,
  trialId
});

export const fetchDailyDispensePerTrial = (trialId: string):DashboardActionTypes => ({
  type: FETCH_DAILY_DISPENSE_PER_TRIAL,
  trialId
});

export const fetchStats = ():DashboardActionTypes => ({
  type: FETCH_STATS
});

export const fetchStatsSucceeded = (payload:any):DashboardActionTypes => ({
  type: FETCH_STATS_SUCCEEDED,
  payload
});

export const fetchDailyDispensePerTrialSucceeded = (payload: any):DashboardActionTypes => ({
  type: FETCH_DAILY_DISPENSE_PER_TRIAL_SUCCEEDED,
  payload
});

export const resetDashboard = ():DashboardActionTypes => ({
  type: RESET_DASHBOARD
});