import { Payload, PrescriptionList, Inventory, BulkOrder } from "../models/Prescription";
import { GetQueryParams, KVP } from "../models/Common";

export const FETCH_PRESCRIPTION_LIST = "FETCH_PRESCRIPTION_LIST";
export const FETCH_PRESCRIPTION_LIST_SUCCEEDED = "FETCH_PRESCRIPTION_LIST_SUCCEEDED";
export const FETCH_ORDER_LIST = "FETCH_ORDER_LIST";
export const FETCH_ORDER_LIST_SUCCEEDED = "FETCH_ORDER_LIST_SUCCEEDED";
export const FETCH_SITE_LIST = "FETCH_SITE_LIST";
export const FETCH_SITE_LIST_SUCCEEDED = "FETCH_SITE_LIST_SUCCEEDED";
export const FETCH_KVP_TRIAL_FOR_PHARMACY = "FETCH_KVP_TRIAL_FOR_PHARMACY";
export const FETCH_KVP_TRIAL_FOR_PHARMACY_SUCCEEDED = "FETCH_KVP_TRIAL_FOR_PHARMACY_SUCCEEDED";
export const FETCH_KVP_SITES_BY_PHARMACY_ID = "FETCH_KVP_SITES_BY_PHARMACY_ID";
export const FETCH_KVP_SITES_BY_PHARMACY_ID_SUCCEEDED = "FETCH_KVP_SITES_BY_PHARMACY_ID_SUCCEEDED";
export const FETCH_KVP_SITES_BY_TRIAL_ID = "FETCH_KVP_SITES_BY_TRIAL_ID";
export const FETCH_KVP_SITES_BY_TRIAL_ID_SUCCEEDED = "FETCH_KVP_SITES_BY_TRIAL_ID_SUCCEEDED_SUCCEEDED";
export const DELETE_DISPENSE = "DELETE_DISPENSE";
export const DELETE_DISPENSE_SUCCEEDED = "DELETE_DISPENSE_SUCCEEDED";
export const REMOVE_SELECTED_PRESCRIPTION = "REMOVE_SELECTED_PRESCRIPTION";
export const RESET_PRESCRIPTION = "RESET_PRESCRIPTION";
export const FETCH_PRESCRIPTION_BY_ID = "FETCH_PRESCRIPTION_BY_ID";
export const FETCH_PRESCRIPTION_BY_ID_SUCCEEDED = "FETCH_PRESCRIPTION_BY_ID_SUCCEEDED";
export const FETCH_BULKORDER_DISPENSE_BY_ID = "FETCH_BULKORDER_DISPENSE_BY_ID";
export const FETCH_BULKORDER_DISPENSE_BY_ID_SUCCEEDED = "  FETCH_BULKORDER_DISPENSE_BY_ID_SUCCEEDED";
export const FETCH_INVENTORY_FOR_KIT_AND_BOTTLE = "FETCH_INVENTORY_FOR_KIT_AND_BOTTLE";
export const FETCH_INVENTORY_FOR_KIT_AND_BOTTLE_SUCCEEDED = "FETCH_INVENTORY_FOR_KIT_AND_BOTTLE_SUCCEEDED";
export const FETCH_BULKORDER_RANDOMISATION = "FETCH_BULKORDER_RANDOMISATION";
export const FETCH_BULKORDER_RANDOMISATION_SUCCEEDED = "FETCH_BULKORDER_RANDOMISATION_SUCCEEDED";
export const FETCH_BULKORDER_KIT_NUMBERS = "FETCH_BULKORDER_KIT_NUMBERS"
export const FETCH_BULKORDER_KIT_NUMBERS_SUCCEEDED = "FETCH_BULKORDER_KIT_NUMBERS_SUCCEEDED"
export const SUBMIT_MANUAL_ASSIGN_TO_BULKORDER = "SUBMIT_MANUAL_ASSIGN_TO_BULKORDER";
export const SUBMIT_MANUAL_ASSIGN_TO_BULKORDER_SUCCEEDED = "SUBMIT_MANUAL_ASSIGN_TO_BULKORDER_SUCCEEDED";
//Get Dispensed drugs
export const FETCH_INVENTORY_OF_DISPENSED_DRUGS = "FETCH_INVENTORY_OF_DISPENSED_DRUGS"
export const FETCH_INVENTORY_OF_DISPENSED_DRUGS_SUCCEEDED = "FETCH_INVENTORY_OF_DISPENSED_DRUGS_SUCCEEDED"
// Approve Prescription Actions
export const APPROVE_PRESCRIPTION = "APPROVE_PRESCRIPTION";
export const APPROVE_PRESCRIPTION_SUCCEEDED = "APPROVE_PRESCRIPTION_SUCCEEDED";
// Reject Prescription Actions
export const REJECT_PRESCRIPTION = "REJECT_PRESCRIPTION";
export const REJECT_PRESCRIPTION_SUCCEEDED = "REJECT_PRESCRIPTION_SUCCEEDED";
// Approve BulkOrder Actions
export const APPROVE_BULKORDER = "APPROVE_BULKORDER";
export const APPROVE_BULKORDER_SUCCEEDED = "APPROVE_BULKORDER_SUCCEEDED";
export const REJECT_BULKORDER = "REJECT_BULKORDER";
//Reject BulkOrder Actions
export const REJECT_BULKORDER_SUCCEEDED = "REJECT_BULKORDER_SUCCEEDED";
// Print Prescription Labels List by Id actions
export const PRINT_PRESCRIPTION_LABELS = "PRINT_PRESCRIPTION_LABELS";
export const PRINT_PRESCRIPTION_LABELS_SUCCEEDED = "PRINT_PRESCRIPTION_LABELS_SUCCEEDED";
// Print Prescription Packing Slips Actions
export const PRINT_PRESCRIPTION_SLIPS = "PRINT_PRESCRIPTION_SLIPS";
export const PRINT_PRESCRIPTION_SLIPS_SUCCEEDED = "PRINT_PRESCRIPTION_SLIPS_SUCCEEDED";
// Mark Prescription as Received Actions
export const MARK_PRESCRIPTION_RECEIVED = "MARK_PRESCRIPTION_RECEIVED";
export const MARK_PRESCRIPTION_RECEIVED_SUCCEEDED = "MARK_PRESCRIPTION_RECEIVED_SUCCEEDED";
//Mark BulkOrder as received
export const MARK_BULKORDER_RECEIVED = "MARK_BULKORDER_RECEIVED";
export const MARK_BULKORDER_RECEIVED_SUCCEEDED = "MARK_BULKORDER_RECEIVED_SUCCEEDED";
// Mark Prescription as Packed Actions
export const MARK_PRESCRIPTION_PACKED = "MARK_PRESCRIPTION_PACKED";
export const MARK_PRESCRIPTION_PACKED_SUCCEEDED = "MARK_PRESCRIPTION_PACKED_SUCCEEDED";
// Mark BulkOrder as Packed Actions
export const MARK_BULKORDER_PACKED =  "MARK_BULKORDER_PACKED";
export const MARK_BULKORDER_PACKED_SUCCEEDED =  "MARK_BULKORDER_PACKED_SUCCEEDED";
// Save Prescription Actions
export const SAVE_PRESCRIPTION = "SAVE_PRESCRIPTION";
export const SAVE_PRESCRIPTION_SUCCEEDED = "SAVE_PRESCRIPTION_SUCCEEDED";
// Get Trial Shipping Details Actions
export const GET_SHIPPING_DETAILS = "GET_SHIPPING_DETAILS";
export const GET_SHIPPING_DETAILS_SUCCEEDED = "GET_SHIPPING_DETAILS_SUCCEEDED";

export const DOWNLOAD_RECEIVED_STATUS_FILES = "DOWNLOAD_RECEIVED_STATUS_FILES";
export const UPLOAD_RECEIVED_STATUS_FILES = "UPLOAD_RECEIVED_STATUS_FILES";
export const UPLOAD_RECEIVED_STATUS_FILES_SUCCEEDED = "UPLOAD_RECEIVED_STATUS_FILES_SUCCEEDED";
// GET Pharmacy Address
export const FETCH_PHARMACY_ADDRESS = "FETCH_PHARMACY_ADDRESS";
export const FETCH_PHARMACY_ADDRESS_SUCCEEDED = "FETCH_PHARMACY_ADDRESS_SUCCEEDED";
//Get available routes
export const FETCH_AVAILABLE_ROUTES = "FETCH_AVAILABLE_ROUTES";
export const FETCH_AVAILABLE_ROUTES_SUCCEEDED = "FETCH_AVAILABLE_ROUTES_SUCCEEDED";
//Consignment Details
export const FETCH_CONSIGNMENT_DETAILS = "FETCH_CONSIGNMENT_DETAILS";
export const FETCH_CONSIGNMENT_DETAILS_SUCCEEDED = "FETCH_CONSIGNMENT_DETAILS_SUCCEEDED";
export const UPDATE_STATUS = "UPDATE_STATUS";

export const FETCH_USERS_TO_APPROVE = "FETCH_USERS_TO_APPROVE"
export const FETCH_USERS_TO_APPROVE_SUCCEEDED = "FETCH_USERS_TO_APPROVE_SUCCEEDED"

export const FETCH_ORDERLIST_REPORT = "FETCH_ORDERLIST_REPORT";
export const FETCH_ORDERLIST_REPORT_SUCCEEDED = "FETCH_ORDERLIST_REPORT_SUCCEEDED";
export const FETCH_ALL_ORDERLIST_REPORT = "FETCH_ALL_ORDERLIST_REPORT";
export const FETCH_ALL_ORDERLIST_REPORT_SUCCEEDED = "FETCH_ALL_ORDERLIST_REPORT_SUCCEEDED";

export interface FetchPrescriptionListActions {
  type: typeof FETCH_PRESCRIPTION_LIST;
  payload: GetQueryParams;
}

export interface FetchPrescriptionListSucceededActions {
  type: typeof FETCH_PRESCRIPTION_LIST_SUCCEEDED,
  payload: Payload;
  append: boolean;
}
export interface FetchOrderListActions {
  type: typeof FETCH_ORDER_LIST;
  payload: GetQueryParams;
}

export interface FetchOrderListSucceededActions {
  type: typeof FETCH_ORDER_LIST_SUCCEEDED,
  payload: Payload;
  append: boolean;
}

export interface FetchKVPTrialForPharmacyActions {
  type: typeof FETCH_KVP_TRIAL_FOR_PHARMACY;
}

export interface FetchKVPTrialForPharmacySucceededActions {
  type: typeof FETCH_KVP_TRIAL_FOR_PHARMACY_SUCCEEDED,
  payload: KVP[];
}

export interface FetchKVPSitesByPharmacyIdActions {
  type: typeof FETCH_KVP_SITES_BY_PHARMACY_ID;
}

export interface FetchKVPSitesByPharmacyIdSucceededActions {
  type: typeof FETCH_KVP_SITES_BY_PHARMACY_ID_SUCCEEDED,
  payload: KVP[];
}

export interface FetchKVPSitesByTrialIdActions {
  type: typeof FETCH_KVP_SITES_BY_TRIAL_ID;
  trialId: string;
}

export interface FetchKVPSitesByTrialIdSucceededActions {
  type: typeof FETCH_KVP_SITES_BY_TRIAL_ID_SUCCEEDED,
  payload: KVP[];
}

export interface DeleteDispenseActions {
  type: typeof DELETE_DISPENSE;
  id: string;
  Type: string;
  reason: string;
}
export interface DeleteDispenseSucceededActions {
  type: typeof DELETE_DISPENSE_SUCCEEDED;
  id: string;
}

export interface RemoveSelectedPrescriptionActions {
  type: typeof REMOVE_SELECTED_PRESCRIPTION
}

export interface ResetPrescriptionActions {
  type: typeof RESET_PRESCRIPTION
}

export interface FetchPrescriptionByIdActions {
  type: typeof FETCH_PRESCRIPTION_BY_ID;
  prescriptionId: string;
}

export interface FetchPrescriptionByIdSucceededActions {
  type: typeof FETCH_PRESCRIPTION_BY_ID_SUCCEEDED;
  payload: PrescriptionList
}

export interface FetchBulkOrderDispenseByIdActions {
  type: typeof FETCH_BULKORDER_DISPENSE_BY_ID;
  id: string;
}

export interface FetchBulkOrderDispenseByIdSucceededActions {
  type: typeof FETCH_BULKORDER_DISPENSE_BY_ID_SUCCEEDED;
  payload: BulkOrder
}

export interface FetchInventoryForKitAndBottleActions {
  type: typeof FETCH_INVENTORY_FOR_KIT_AND_BOTTLE;
  payload: any
}

export interface FetchInventoryForKitAndBottleSucceededActions {
  type: typeof FETCH_INVENTORY_FOR_KIT_AND_BOTTLE_SUCCEEDED;
  bottleIdentifier: number;
  inventoryId: string;
  treatmentId: string;
  payload: any;
  append: boolean;
}

export interface FetchBulkOrderRandomisationActions{
  type: typeof FETCH_BULKORDER_RANDOMISATION;
  id: string;
}

export interface FetchBulkOrderRandomisationSucceededActions{
  type: typeof FETCH_BULKORDER_RANDOMISATION_SUCCEEDED;
  payload:any;
}

export interface FetchBulkOrderKitNumbersActions{
  type: typeof FETCH_BULKORDER_KIT_NUMBERS;
  id: string;
}

export interface FetchBulkOrderKitNumbersSucceededActions{
  type: typeof FETCH_BULKORDER_KIT_NUMBERS_SUCCEEDED;
  payload:any;
}

export interface SubmitManualAssignToBulkOrderActions {
  type: typeof SUBMIT_MANUAL_ASSIGN_TO_BULKORDER;
  payload: any;
}

export interface SubmitManualAssignToBulkOrderSucceededActions {
  type: typeof SUBMIT_MANUAL_ASSIGN_TO_BULKORDER_SUCCEEDED;
  payload: any;
}

// Fetch Prescription Inventory List by Id actions
export const FETCH_PRESCRIPTION_INVENTORY_BY_ID = "FETCH_PRESCRIPTION_INVENTORY_BY_ID";
export const FETCH_PRESCRIPTION_INVENTORY_BY_ID_SUCCEEDED = "FETCH_PRESCRIPTION_INVENTORY_BY_ID_SUCCEEDED";

export interface FetchPrescriptionInventoryByIdActions {
  type: typeof FETCH_PRESCRIPTION_INVENTORY_BY_ID;
  prescriptionId: string;
}

export interface FetchPrescriptionInventoryByIdSucceededActions {
  type: typeof FETCH_PRESCRIPTION_INVENTORY_BY_ID_SUCCEEDED;
  payload: Inventory
}

//Mark BulkOrder dispensed
export const MARK_BULKORDER_DISPENSED = "MARK_BULKORDER_DISPENSED";
export const MARK_BULKORDER_DISPENSED_SUCCEEDED = "MARK_BULKORDER_DISPENSED_SUCCEEDED";

export interface MarkBulkOrderAsDispensedActions {
  type: typeof MARK_BULKORDER_DISPENSED;
  bulkOrderId: string;
  payload: any;
}

export interface MarkBulkOrderAsDispensedSucceededActions {
  type: typeof MARK_BULKORDER_DISPENSED_SUCCEEDED;
  payload: any;
}

// Maark Prescription Dispensed actions
export const MARK_PRESCRIPTION_DISPENSED = "MARK_PRESCRIPTION_DISPENSED";
export const MARK_PRESCRIPTION_DISPENSED_SUCCEEDED = "MARK_PRESCRIPTION_DISPENSED_SUCCEEDED";

export interface MarkPrescriptionDispensedActions {
  type: typeof MARK_PRESCRIPTION_DISPENSED;
  prescriptionId: string;
  payload: {
    prescriptionId: string;
    selectedInventories: string[];
  }
}

export interface MarkPrescriptionDispensedSucceededActions {
  type: typeof MARK_PRESCRIPTION_DISPENSED_SUCCEEDED;
  payload: any
}

export interface PrintPrescriptionLabelsActions {
  type: typeof PRINT_PRESCRIPTION_LABELS;
  prescriptionId: string;
  payload: any;
}

export interface PrintPrescriptionLabelsSucceededActions {
  type: typeof PRINT_PRESCRIPTION_LABELS_SUCCEEDED;
  payload: any;
}

export interface FetchInventoryofDispensedDrugsActions {
  type: typeof FETCH_INVENTORY_OF_DISPENSED_DRUGS,
  prescriptionId: string;
}

export interface FetchInventoryofDispensedDrugsSucceededActions {
  type: typeof FETCH_INVENTORY_OF_DISPENSED_DRUGS_SUCCEEDED,
  payload: Inventory
}

export interface ApprovePrescriptionAction {
  type: typeof APPROVE_PRESCRIPTION;
  prescriptionId: string;
  payload: any,
  specialApproval: boolean;
}

export interface ApprovePrescriptionSuccededAction {
  type: typeof APPROVE_PRESCRIPTION_SUCCEEDED;
  payload: any;
}

export interface RejectPrescriptionAction {
  type: typeof REJECT_PRESCRIPTION;
  prescriptionId: string;
  payload: any;
  specialApproval:boolean;
}

export interface RejectPrescriptionSuccededAction {
  type: typeof REJECT_PRESCRIPTION_SUCCEEDED;
  payload: any;
}

export interface ApproveBulkOrderActions {
  type: typeof APPROVE_BULKORDER,
  bulkOrderId: string;
  payload: any;
  specialApproval:boolean;
}

export interface ApproveBulkOrderSucceededActions {
  type: typeof APPROVE_BULKORDER_SUCCEEDED,
  payload: any;
}

export interface RejecteBulkOrderActions {
  type: typeof REJECT_BULKORDER,
  bulkOrderId: string;
  payload: any;
  specialApproval:boolean;
}

export interface RejecteBulkOrderSucceededActions {
  type: typeof REJECT_BULKORDER_SUCCEEDED,
  payload: any;
}


export interface PrintPrescriptionSlipsAction {
  type: typeof PRINT_PRESCRIPTION_SLIPS;
  prescriptionId: string;
}

export interface PrintPrescriptionSlipsSuccededAction {
  type: typeof PRINT_PRESCRIPTION_SLIPS_SUCCEEDED;
  payload: any;
}

export interface MarkPrescriptionAsReceivedAction {
  type: typeof MARK_PRESCRIPTION_RECEIVED;
  prescriptionId: string;
}

export interface MarkPrescriptionAsReceivedSuccededAction {
  type: typeof MARK_PRESCRIPTION_RECEIVED_SUCCEEDED;
  payload: any;
}

export interface MarkBulkOrderAsReceivedAction {
  type: typeof MARK_BULKORDER_RECEIVED;
  bulkOrderId: string;
}

export interface MarkBulkOrderAsReceivedSuccededAction {
  type: typeof MARK_BULKORDER_RECEIVED_SUCCEEDED;
  payload: any;
}

export interface MarkPrescriptionAsPackedAction {
  type: typeof MARK_PRESCRIPTION_PACKED;
  prescriptionId: string;
  payload: any;
}

export interface MarkPrescriptionAsPackedSuccededAction {
  type: typeof MARK_PRESCRIPTION_PACKED_SUCCEEDED;
  payload: any;
}

export interface MarkBulkOrderAsPackedAction {
  type: typeof MARK_BULKORDER_PACKED;
  bulkOrderId: string;
  payload: any;
}

export interface MarkBulkOrderAsPackedSuccededAction {
  type: typeof MARK_BULKORDER_PACKED_SUCCEEDED;
  payload: any;
}

export interface SavePrescriptionAction {
  type: typeof SAVE_PRESCRIPTION;
  prescriptionId: string;
  payload: any
}

export interface SavePrescriptionSuccededAction {
  type: typeof SAVE_PRESCRIPTION_SUCCEEDED;
  payload: any;
}

export interface TrialShippingDetailsAction {
  type: typeof GET_SHIPPING_DETAILS;
  trialId: string;
}

export interface TrialShippingDetailsSuccededAction {
  type: typeof GET_SHIPPING_DETAILS_SUCCEEDED;
  payload: any;
}

export interface DownloadReceivedStatusFilesActions {
  type: typeof DOWNLOAD_RECEIVED_STATUS_FILES;
  id:string;
  dispenseType: string;
  Type: number;
}

export interface UploadReceivedStatusFilesActions {
  type: typeof UPLOAD_RECEIVED_STATUS_FILES;
  id:string;
  dispenseType: string;
  payload: any
}
export interface UploadReceivedStatusFilesSucceededActions {
  type: typeof UPLOAD_RECEIVED_STATUS_FILES_SUCCEEDED;
  payload: any
}

export interface FetchPharmacyAddressActions {
  type: typeof FETCH_PHARMACY_ADDRESS;
  payload:any;
}

export interface FetchPharmacyAddressSucceededActions {
  type: typeof FETCH_PHARMACY_ADDRESS_SUCCEEDED;
  payload:any;
}

export interface FetchAvailableRoutesActions {
  type: typeof FETCH_AVAILABLE_ROUTES;
  payload:any;
}

export interface FetchAvailableRoutesSucceededActions {
  type: typeof FETCH_AVAILABLE_ROUTES_SUCCEEDED;
  payload:any;
}

export interface FetchConsignmentDetailsActions {
  type: typeof FETCH_CONSIGNMENT_DETAILS;
  prescriptionId:string;
}

export interface FetchConsignmentDetailsSucceededActions {
  type: typeof FETCH_CONSIGNMENT_DETAILS_SUCCEEDED;
  payload:any;
}

export interface UpdateStatusActions {
  type: typeof UPDATE_STATUS;
  status: string;
}

export interface FetchUsersToApproveActions {
  type: typeof FETCH_USERS_TO_APPROVE
  Type: string;
  id: string;
}

export interface FetchUsersToApproveSucceededActions {
  type: typeof FETCH_USERS_TO_APPROVE_SUCCEEDED;
  payload: any
}

export interface FetchOrderListReportActions{
  type: typeof FETCH_ORDERLIST_REPORT;
  payload:any;
}

export interface FetchOrderListReportSucceededActions{
  type: typeof FETCH_ORDERLIST_REPORT_SUCCEEDED;
  payload:any;
  append: boolean
}
export interface FetchAllOrderListReportActions{
  type: typeof FETCH_ALL_ORDERLIST_REPORT;
  payload:any;
}

export interface FetchAllOrderListReportSucceededActions{
  type: typeof FETCH_ALL_ORDERLIST_REPORT_SUCCEEDED;
  payload:any;
}



export type PrescriptionActionsTypes =
| FetchPrescriptionListActions
  | FetchPrescriptionListSucceededActions
  | FetchOrderListActions
  | FetchOrderListSucceededActions
  | FetchKVPTrialForPharmacyActions
  | FetchKVPTrialForPharmacySucceededActions
  | FetchKVPSitesByPharmacyIdActions
  | FetchKVPSitesByPharmacyIdSucceededActions
  | FetchKVPSitesByTrialIdActions
  | FetchKVPSitesByTrialIdSucceededActions
  | DeleteDispenseActions
  | DeleteDispenseSucceededActions
  | RemoveSelectedPrescriptionActions
  | ResetPrescriptionActions
  | FetchPrescriptionByIdActions
  | FetchPrescriptionByIdSucceededActions
  | FetchBulkOrderDispenseByIdActions
  | FetchBulkOrderDispenseByIdSucceededActions
  | FetchInventoryForKitAndBottleActions
  | FetchInventoryForKitAndBottleSucceededActions
  | FetchBulkOrderRandomisationActions
  | FetchBulkOrderRandomisationSucceededActions
  | FetchBulkOrderKitNumbersActions
  | FetchBulkOrderKitNumbersSucceededActions
  | SubmitManualAssignToBulkOrderActions
  | SubmitManualAssignToBulkOrderSucceededActions
  | FetchPrescriptionInventoryByIdActions
  | FetchPrescriptionInventoryByIdSucceededActions
  | MarkBulkOrderAsDispensedActions
  | MarkBulkOrderAsDispensedSucceededActions
  | MarkPrescriptionDispensedActions
  | MarkPrescriptionDispensedSucceededActions
  | PrintPrescriptionLabelsActions
  | PrintPrescriptionLabelsSucceededActions
  | FetchInventoryofDispensedDrugsActions
  | FetchInventoryofDispensedDrugsSucceededActions
  | ApprovePrescriptionAction
  | ApprovePrescriptionSuccededAction
  | RejectPrescriptionAction
  | RejectPrescriptionSuccededAction
  | ApproveBulkOrderActions
  | ApproveBulkOrderSucceededActions
  | RejecteBulkOrderActions
  | RejecteBulkOrderSucceededActions
  | PrintPrescriptionSlipsAction
  | PrintPrescriptionSlipsSuccededAction
  | MarkPrescriptionAsReceivedAction
  | MarkPrescriptionAsReceivedSuccededAction
  | MarkBulkOrderAsReceivedAction
  | MarkBulkOrderAsReceivedSuccededAction
  | MarkPrescriptionAsPackedAction
  | MarkPrescriptionAsPackedSuccededAction
  | MarkBulkOrderAsPackedAction
  | MarkBulkOrderAsPackedSuccededAction
  | SavePrescriptionAction
  | SavePrescriptionSuccededAction
  | TrialShippingDetailsAction
  | TrialShippingDetailsSuccededAction
  | DownloadReceivedStatusFilesActions
  | UploadReceivedStatusFilesActions
  | UploadReceivedStatusFilesSucceededActions
  | FetchPharmacyAddressActions
  | FetchPharmacyAddressSucceededActions
  | FetchAvailableRoutesActions
  | FetchAvailableRoutesSucceededActions
  | FetchConsignmentDetailsActions
  | FetchConsignmentDetailsSucceededActions
  | UpdateStatusActions
  | FetchUsersToApproveActions
  | FetchUsersToApproveSucceededActions
  | FetchOrderListReportActions
  | FetchOrderListReportSucceededActions
  | FetchAllOrderListReportActions
  | FetchAllOrderListReportSucceededActions
  ;