import React from 'react';

export const notificationRouteConfig = [
  {
    auth: ["Admin", "Site", "Principal Investigators", "Quality Associate QA", "Study Coordinators"],
    routes: [
      {
        path: '/qa',
        exact: true,
        component: React.lazy(() => import('./Notifications')),
      },
      {
        path: '/qa/:id',
        exact: true,
        component: React.lazy(() => import('./Notifications')),
      },
      {
        path: '/trials/:id/patient/:patientId/prescription/approve/:requestId',
        exact: true,
        component: React.lazy(() => import('../patients/Prescription'))
      }
    ],
  },
];
