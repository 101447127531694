import { UserList , SystemRole, Payload} from "../models/Users";

export const FETCH_SYSTEM_ROLES = "FETCH_SYSTEM_ROLES";
export const FETCH_SYSTEM_ROLES_SUCCEEDED = "FETCH_SYSTEM_ROLES_SUCCEEDED";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const SUBMIT_USER = "SUBMIT_USER";
export const SUBMIT_FORGOTPASSWORD = "SUBMIT_FORGOTPASSWORD";
export const SUBMIT_ACCOUNT_CONFIMRATION = "SUBMIT_ACCOUNT_CONFIMRATION";
export const SUBMIT_USERSTATUS = "SUBMIT_USERSTATUS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PIN = "UPDATE_PIN";
export const UPDATE_PIN_SUCCEEDED = "UPDATE_PIN_SUCCEEDED";
export const DELETE_USER = "DELETE_USER";
export const FETCH_USER_BY_ID = "FETCH_USER_BY_ID";
export const FETCH_CURRENT_USER_BY_ID = "FETCH_CURRENT_USER_BY_ID";
export const SET_SELECTED_USER = "SET_SELECTED_USER"
export const REMOVE_SELECTED_USER = "REMOVE_SELECTED_USER"
export const FETCH_USER_LIST_SUCCEEDED = "FETCH_USER_LIST_SUCCEEDED";
export const FETCH_USER_BY_ID_SUCCEEDED = "FETCH_USER_BY_ID_SUCCEEDED";
export const FETCH_CURRENT_USER_BY_ID_SUCCEEDED = "FETCH_CURRENT_USER_BY_ID_SUCCEEDED";

export const SUBMIT_USER_SUCCEEDED = "SUBMIT_USER_SUCCEEDED";
export const SUBMIT_FORGOTPASSWORD_SUCCEEDED = "SUBMIT_FORGOTPASSWORD_SUCCEEDED";
export const SUBMIT_ACCOUNT_CONFIMRATION_SUCCEEDED = "SUBMIT_ACCOUNT_CONFIMRATION_SUCCEEDED";
export const SUBMIT_USERSTATUS_SUCCEEDED = "SUBMIT_USERSTATUS_SUCCEEDED";
export const UPDATE_USER_SUCCEEDED = "UPDATE_USER_SUCCEEDED";
export const DELETE_USER_SUCCEEDED = "DELETE_USER_SUCCEEDED";
export const RESET_USER = "RESET_USER";
export const SEND_MAIL = "SEND_MAIL";
export const SEND_MAIL_SUCCEEDED = "SEND_MAIL_SUCCEEDED";

export interface FetchSystemRolesActions {
  type:  typeof FETCH_SYSTEM_ROLES
}

export interface FetchSystemRolesSuccededActions{
  type:  typeof FETCH_SYSTEM_ROLES_SUCCEEDED
  payload: SystemRole[]
}
export interface FetchUserListActions {
  type: typeof FETCH_USER_LIST;
}

export interface FetchUserListSucceededActions {
  type: typeof FETCH_USER_LIST_SUCCEEDED;
  payload: Payload;
  append: boolean;
}

export interface FetchUserByIdActions {
  type: typeof FETCH_USER_BY_ID;
  id: string
}

export interface FetchCurrentUserByIdActions {
  type: typeof FETCH_CURRENT_USER_BY_ID;
  id: string
}
export interface SetSelectedUserActions {
  type: typeof SET_SELECTED_USER;
  id: number
}

export interface FetchUserByIdSucceededActions{
  type: typeof FETCH_USER_BY_ID_SUCCEEDED;
  payload: UserList
}

export interface FetchCurrentUserByIdSucceededActions{
  type: typeof FETCH_CURRENT_USER_BY_ID_SUCCEEDED;
  payload: UserList
}

export interface RemoveSelectedUserActions {
  type: typeof REMOVE_SELECTED_USER
}

export interface SubmitUserActions {
  type: typeof SUBMIT_USER;
  payload: UserList | null;
}

export interface SubmitUserSucceededActions {
  type: typeof SUBMIT_USER_SUCCEEDED;
  payload: UserList;
}

export interface SubmitForgotPasswordActions {
  type: typeof SUBMIT_FORGOTPASSWORD
}

export interface SubmitForgotPasswordSucceededActions {
  type: typeof SUBMIT_FORGOTPASSWORD_SUCCEEDED
}

export interface SubmitAccountConfirmationActions {
  type: typeof SUBMIT_ACCOUNT_CONFIMRATION
}

export interface SubmitAccountConfirmationSucceededActions {
  type: typeof SUBMIT_ACCOUNT_CONFIMRATION_SUCCEEDED
}

export interface submitUserStatusActions {
  type: typeof SUBMIT_USERSTATUS
  status: boolean
}

export interface submitUserStatusSucceededActions {
  type: typeof SUBMIT_USERSTATUS_SUCCEEDED;
  payload: UserList;
}

export interface UpdateUserActions {
  type: typeof UPDATE_USER;
  id: number;
  payload: UserList;
}

export interface UpdateUserSucceededActions {
  type: typeof UPDATE_USER_SUCCEEDED;
  payload: UserList;
}

export interface DeleteUserActions {
  type: typeof DELETE_USER;
  id: number
}

export interface DeleteUserSuceededActions {
  type: typeof DELETE_USER_SUCCEEDED;
  id: number
}

export interface ResetUserActions {
  type: typeof RESET_USER;
}

export interface UpdatePinActions {
  type: typeof UPDATE_PIN;
  payload: any;
}

export interface UpdatePinSucceededActions {
  type: typeof UPDATE_PIN_SUCCEEDED;
  payload: any;
}

export type UserActionsTypes =
  | FetchUserListActions
  | SubmitUserActions
  | UpdateUserActions
  | UpdatePinActions
  | UpdatePinSucceededActions
  | DeleteUserActions
  | FetchUserListSucceededActions
  | FetchUserByIdSucceededActions
  | SubmitUserSucceededActions
  | UpdateUserSucceededActions
  | DeleteUserSuceededActions
  | FetchUserByIdActions
  | SetSelectedUserActions
  | RemoveSelectedUserActions
  | SubmitForgotPasswordActions
  | SubmitForgotPasswordSucceededActions
  | SubmitAccountConfirmationActions
  | SubmitAccountConfirmationSucceededActions
  | submitUserStatusActions
  | submitUserStatusSucceededActions
  | FetchSystemRolesActions
  | FetchSystemRolesSuccededActions
  | ResetUserActions
  | FetchCurrentUserByIdSucceededActions
  | FetchCurrentUserByIdActions
  ;