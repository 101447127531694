import { ReturnInventoryList } from "../models/ReturnInventory";
import { GetQueryParams } from "../models/Common";

export const FETCH_RETURN_INVENTORY_LIST = "FETCH_RETURN_INVENTORY_LIST";
export const SUBMIT_RETURN_INVENTORY = "SUBMIT_RETURN_INVENTORY";
export const UPDATE_RETURN_INVENTORY = "UPDATE_RETURN_INVENTORY";
export const DELETE_RETURN_INVENTORY = "DELETE_RETURN_INVENTORY";
export const FETCH_RETURN_INVENTORY_BY_ID = "FETCH_RETURN_INVENTORY_BY_ID";
export const SET_SELECTED_RETURN_INVENTORY = "SET_SELECTED_RETURN_INVENTORY"
export const REMOVE_SELECTED_RETURN_INVENTORY = "REMOVE_SELECTED_RETURN_INVENTORY"
export const RESET_RETURN_INVENTORY = "RESET_RETURN_INVENTORY";
export const FETCH_RETURN_INVENTORY_LIST_SUCCEEDED = "FETCH_RETURN_INVENTORY_LIST_SUCCEEDED";
export const FETCH_RETURN_INVENTORY_BY_ID_SUCCEEDED = "FETCH_RETURN_INVENTORY_BY_ID_SUCCEEDED";
export const SUBMIT_RETURN_INVENTORY_SUCCEEDED = "SUBMIT_RETURN_INVENTORY_SUCCEEDED";
export const UPDATE_RETURN_INVENTORY_SUCCEEDED = "UPDATE_RETURN_INVENTORY_SUCCEEDED";
export const DELETE_RETURN_INVENTORY_SUCCEEDED = "DELETE_RETURN_INVENTORY_SUCCEEDED";
export const FETCH_ACTIVE_RETURNED_LIST = "FETCH_ACTIVE_RETURNED_LIST";
export const FETCH_DESTROYED_BATCH_LIST = "FETCH_DESTROYED_BATCH_LIST";
export const FETCH_ACTIVE_RETURNED_LIST_SUCCEEDED = "FETCH_ACTIVE_RETURNED_LIST_SUCCEEDED";
export const FETCH_DESTROYED_BATCH_LIST_SUCCEEDED = "FETCH_DESTROYED_BATCH_LIST_SUCCEEDED";
export const FETCH_DESTROYED_STOCK_REPORT = "FETCH_DESTROYED_STOCK_REPORT";
export const FETCH_DESTROYED_STOCK_REPORT_SUCCEEDED = "FETCH_DESTROYED_STOCK_REPORT_SUCCEEDED";
export const UPDATE_DESTROYED_STOCK_REPORT = "UPDATE_DESTROYED_STOCK_REPORT";
export const UPDATE_DESTROYED_STOCK_REPORT_SUCCEEDED = "UPDATE_DESTROYED_STOCK_REPORT_SUCCEEDED";
export const MARK_STOCK_REPORT = "MARK_STOCK_REPORT";
export const MARK_STOCK_REPORT_SUCCEEDED = "MARK_STOCK_REPORT_SUCCEEDED";
export const LOOKUP_PATIENT = "LOOKUP_PATIENT";
export const LOOKUP_PATIENT_SUCCEEDED = "LOOKUP_PATIENT_SUCCEEDED";
export const RESET_LOOKUP_PATIENT = "RESET_LOOKUP_PATIENT";
export const RESET_DESTROYED_STOCK_REPORT = "RESET_DESTROYED_STOCK_REPORT";
export const RESET_ACTIVE_RETURNED_LIST = "RESET_ACTIVE_RETURNED_LIST";
export const RESET_DESTROYED_BATCH_LIST = "RESET_DESTROYED_BATCH_LIST";

export interface FetchReturnInventoryListActions {
  type: typeof FETCH_RETURN_INVENTORY_LIST,
  payload: GetQueryParams
}

export interface FetchReturnInventoryListSucceededActions {
  type: typeof FETCH_RETURN_INVENTORY_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface FetchReturnInventoryByIdActions {
  type: typeof FETCH_RETURN_INVENTORY_BY_ID;
  id: string
}

export interface SetSelectedReturnInventoryActions {
  type: typeof SET_SELECTED_RETURN_INVENTORY;
  id: string
}

export interface FetchReturnInventoryByIdSucceededActions {
  type: typeof FETCH_RETURN_INVENTORY_BY_ID_SUCCEEDED;
  payload: ReturnInventoryList
}

export interface RemoveSelectedReturnInventoryActions {
  type: typeof REMOVE_SELECTED_RETURN_INVENTORY
}

export interface SubmitReturnInventoryActions {
  type: typeof SUBMIT_RETURN_INVENTORY;
  payload: ReturnInventoryList | null;
}

export interface SubmitReturnInventorySucceededActions {
  type: typeof SUBMIT_RETURN_INVENTORY_SUCCEEDED;
  payload: ReturnInventoryList;
}

export interface UpdateReturnInventoryActions {
  type: typeof UPDATE_RETURN_INVENTORY;
  id: string;
  payload: ReturnInventoryList;
}

export interface UpdateReturnInventorySucceededActions {
  type: typeof UPDATE_RETURN_INVENTORY_SUCCEEDED;
  id: string;
  payload: ReturnInventoryList;
}

export interface DeleteReturnInventoryActions {
  type: typeof DELETE_RETURN_INVENTORY;
  id: string
}

export interface DeleteReturnInventorySuceededActions {
  type: typeof DELETE_RETURN_INVENTORY_SUCCEEDED;
  id: string
}

export interface ResetReturnInventoryActions {
  type: typeof RESET_RETURN_INVENTORY
}

export interface FetchActiveReturnedListActions {
  type: typeof FETCH_ACTIVE_RETURNED_LIST,
  payload: GetQueryParams
}

export interface FetchActiveReturnedListSucceededActions {
  type: typeof FETCH_ACTIVE_RETURNED_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface FetchDestroyedBatchListActions {
  type: typeof FETCH_DESTROYED_BATCH_LIST,
  payload: GetQueryParams
}

export interface FetchDestroyedBatchListSucceededActions {
  type: typeof FETCH_DESTROYED_BATCH_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export interface FetchDestroyedStockReportActions {
  type: typeof FETCH_DESTROYED_STOCK_REPORT;
  id: string
}

export interface FetchDestroyedStockReportSucceededActions {
  type: typeof FETCH_DESTROYED_STOCK_REPORT_SUCCEEDED;
  payload: any
}

export interface UpdateDestroyedStockReportActions {
  type: typeof UPDATE_DESTROYED_STOCK_REPORT;
  id: string
}

export interface UpdateDestroyedStockReportSucceededActions {
  type: typeof UPDATE_DESTROYED_STOCK_REPORT_SUCCEEDED;
  payload: any
}

export interface MarkStockReportActions {
  type: typeof MARK_STOCK_REPORT;
  id: string
}

export interface MarkStockReportSucceededActions {
  type: typeof MARK_STOCK_REPORT_SUCCEEDED;
  payload: any
}

export interface LookupPatientActions {
  type: typeof LOOKUP_PATIENT;
  id: string
}

export interface LookupPatientSucceededActions {
  type: typeof LOOKUP_PATIENT_SUCCEEDED;
  payload: any
}

export interface ResetLookupPatientActions {
  type: typeof RESET_LOOKUP_PATIENT
}

export interface ResetDestroyedStockReportActions {
  type: typeof RESET_DESTROYED_STOCK_REPORT;
}

export interface ResetActiveReturnedActions{
  type: typeof RESET_ACTIVE_RETURNED_LIST
}

export interface ResetDestroyedBatchActions{
  type: typeof RESET_DESTROYED_BATCH_LIST
}


export type ReturnInventoryActionsTypes =
  | FetchReturnInventoryListActions
  | SubmitReturnInventoryActions
  | UpdateReturnInventoryActions
  | DeleteReturnInventoryActions
  | FetchReturnInventoryListSucceededActions
  | FetchReturnInventoryByIdSucceededActions
  | SubmitReturnInventorySucceededActions
  | UpdateReturnInventorySucceededActions
  | DeleteReturnInventorySuceededActions
  | FetchReturnInventoryByIdActions
  | SetSelectedReturnInventoryActions
  | RemoveSelectedReturnInventoryActions
  | ResetReturnInventoryActions
  | FetchActiveReturnedListActions
  | FetchActiveReturnedListSucceededActions
  | FetchDestroyedBatchListActions
  | FetchDestroyedBatchListSucceededActions
  | FetchDestroyedStockReportActions
  | FetchDestroyedStockReportSucceededActions
  | UpdateDestroyedStockReportActions
  | UpdateDestroyedStockReportSucceededActions
  | MarkStockReportActions
  | MarkStockReportSucceededActions
  | LookupPatientActions
  | LookupPatientSucceededActions
  | ResetLookupPatientActions
  | ResetDestroyedStockReportActions
  | ResetActiveReturnedActions
  | ResetDestroyedBatchActions
  ;