
export const FETCH_CONSIGNMENT_LIST = "FETCH_CONSIGNMENT_LIST";
export const FETCH_CONSIGNMENT_LIST_SUCCEEDED = "FETCH_CONSIGNMENT_LIST_SUCCEEDED";
export const SUBMIT_MANIFEST = "SUBMIT_MANIFEST";
export const SUBMIT_MANIFEST_SUCCEEDED = "SUBMIT_MANIFEST_SUCCEEDED";
export const RESET_MANIFEST = "RESET_MANIFEST";
export interface FetchConsignmentListActions {
  type: typeof FETCH_CONSIGNMENT_LIST;
}

export interface FetchConsignmentListSucceededActions {
  type: typeof FETCH_CONSIGNMENT_LIST_SUCCEEDED;
  payload: any;
}

export interface SubmitManifestActions {
  type: typeof SUBMIT_MANIFEST;
  payload: any;
}

export interface SubmitManifestSucceededActions {
  type: typeof SUBMIT_MANIFEST_SUCCEEDED;
  payload: any;
}

export interface ResetManifestActions {
  type: typeof RESET_MANIFEST
}


export type ManifestActionsTypes =
| FetchConsignmentListActions
| FetchConsignmentListSucceededActions
| SubmitManifestActions
| SubmitManifestSucceededActions
| ResetManifestActions
;