import { Pricing } from "../../types/models/Pricing";
import { 
  FETCH_PRICING_SUCCEEDED,
  SUBMIT_PRICING_SUCCEEDED,
  PricingActionTypes
} from "../../types/actions/Pricing.action";

const initialState:Pricing = {
  pricing: {}
}

export default (state = initialState, action: PricingActionTypes): Pricing => {
  switch (action.type) {
    case FETCH_PRICING_SUCCEEDED: {
      return {
        ...state,
        pricing: action.payload
      }
    }
    case SUBMIT_PRICING_SUCCEEDED: {
      const { payload } = action;
      const pricing = {...state.pricing, ...payload };
      return {
        ...state,
        pricing
      }
    }
    default: {
      return state;
    } 
  }
}