import { Trial } from "../../types/models/Trials";
import { TrialProgress } from "../../shared/constants/AppEnums";
import { FETCH_KVP_FROM_TRIALS_SUCCEEDED } from "../../types/actions/Common.action";

import { 
  TrialActionsTypes,
  FETCH_TRIAL_LIST_SUCCEEDED,
  SUBMIT_TRIAL_SUCCEEDED,
  REMOVE_SELECTED_TRIAL,
  UPDATE_TRIAL_SUCCEEDED,
  SET_SELECTED_TRIAL,
  DELETE_TRIAL_SUCCEEDED,
  FETCH_TRIAL_BY_ID_SUCCEEDED,
  RESET_TRIAL,
  FETCH_KVP_SPONSOR_SUCCEEDED,
  FETCH_KVP_QA_SUCCEEDED,
  FETCH_KVP_MONITOR_SUCCEEDED,
  FETCH_REPEATS_SUCCEEDED,
  SUBMIT_REPEATS_SUCCEEDED,
  FETCH_CONSULTATIONS_SUCCEEDED,
  SUBMIT_CONSULTATIONS_SUCCEEDED,
  FETCH_RETURNS_INVENTORY_SUCCEEDED,
  SUBMIT_RETURNS_INVENTORY_SUCCEEDED,
  VALIDATE_EMERGENCY_UNBLINDING_SUCCEEDED,
  FETCH_EMERGENCY_UNBLINDING_SUCCEEDED,
  SUBMIT_EMERGENCY_UNBLINDING_SUCCEEDED,
  FETCH_TRIAL_PRICING_SUCCEEDED,
  SUBMIT_TRIAL_PRICING_SUCCEEDED,
  FETCH_TRIAL_SITES_SUCCEEDED,
  SUBMIT_TRIAL_SITES_SUCCEEDED,
  FETCH_DRUG_LIST_SUCCEEDED, 
  ADD_DRUG_SUCCEEDED, 
  UPDATE_DRUG_SUCCEEDED, 
  DELETE_DRUG_SUCCEEDED,
  FETCH_TREATMENTLABEL_LIST_SUCCEEDED, 
  ADD_TREATMENTLABEL_SUCCEEDED, 
  UPDATE_TREATMENTLABEL_SUCCEEDED, 
  DELETE_TREATMENTLABEL_SUCCEEDED,
  FETCH_TREATMENTARM_LIST_SUCCEEDED, 
  ADD_TREATMENTARM_SUCCEEDED, 
  UPDATE_TREATMENTARM_SUCCEEDED, 
  DELETE_TREATMENTARM_SUCCEEDED,
  FETCH_TREATMENT_ARM_SUMMARY_SUCCEEDED,
  ADD_TRIALLABEL_SUCCEEDED,
  FETCH_TRIALLABEL_SUCCEEDED,
  FETCH_TRIALLABEL_SIZE_SUCCEEDED,
  FETCH_USERS_SUCCEEDED,
  FETCH_TRIAL_USERS_LIST_SUCCEEDED,
  FETCH_TRIAL_PATIENT_USERS_LIST_SUCCEEDED,
  // SUBMIT_TRIAL_USER_SUCCEEDED,
  FETCH_TRIAL_FILE_SUCCEEDED,
  SUBMIT_TRIAL_FILE_SUCCEEDED,
  DELETE_TRIAL_FILE_SUCCEEDED,
  DOWNLOAD_TRIAL_FILE_SUCCEEDED,
  SET_TRIAL_PROGRESS,
  UPDATE_TRIAL_PROGRESS_SUCCEEDED,
  FETCH_PACKAGE_OPTIONS_SUCCEEDED,
  FETCH_SHIPPING_SUCCEEDED,
  SUBMIT_SHIPPING_SUCCEEDED,
  TOGGLE_STRATIFICATION,
  FETCH_STRATIFICATION_SUCCEEDED,
  SUBMIT_STRATIFICATION_SUCCEEDED,
  FETCH_QA_REQUEST_SUCCEEDED,
  FETCH_QA_SUCCEEDED,
  SUBMIT_QA_SUCCEEDED,
  REJECT_QA_SUCCEEDED,
  APPROVE_QA_SUCCEEDED,
  FETCH_TRIAL_SUMMARY_SUCCEEDED,
  FETCH_TRIAL_SITES_SUMMARY_SUCCEEDED,
  FETCH_RANDOMISATION_ENTRIES_SUCCEEDED,
  FETCH_ALL_RANDOMISATION_ENTRIES_SUCCEEDED,
  FETCH_RANDOMISATION_SUCCEEDED,
  SUBMIT_TRIAL_QA_SUCCEEDED,
  FETCH_TRIAL_DETAILS_FOR_BULK_ORDER_SUCCEEDED,
  FETCH_COORDINATOR_KVP_SUCCEEDED,
  FETCH_INVESTIGATOR_KVP_SUCCEEDED,
  SUBMIT_BULK_ORDER_SUCCEEDED,
  SET_SELECTED_QA_TYPE_DATA,
  REMOVE_SELECTED_QA_TYPE_DATA,
  SEARCH_TRIAL_RANDOMISATION_SUCCEEDED,
  FETCH_SITE_FOR_BULKORDER_SUCCEEDED,
  UPDATE_MULTI_TRIAL_PROGRESS_SUCCEEDED,
  FETCH_AVAILABLE_INVENTORIES_SUCCEEDED,
  RESET_AVAILABLE_INVENTORIES,
  SUBMIT_TRIAL_ARCHIVE_SUCCEEDED,
  FETCH_TRIAL_ARCHIVE_REPORTS_SUCCEEDED
} from "../../types/actions/Trials.action";

const invalidGuid = '00000000-0000-0000-0000-000000000000';

const initialState: Trial = {
  trialList: [],
  selectedTrial: null,
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  trialProgress: {},
  sponsorTypes: [],
  QATypes: [],
  monitorTypes: [],
  sites: [],
  sitesKVP: [],
  trialKVP: [],
  pharmacyKVP: [],
  couriersKVP: [],
  warehouseKVP: [],
  pricing: {},
  drugList: [],
  treatmentLabelList: [],
  treatmentArmList: [],
  treatmentArmSummary: [],
  trialLabels: {},
  trialLabelSize: [],
  users: [],
  userList: [],
  files: [],
  packageOptions: [],
  selectedStratification: null,
  stratificationList: [],
  shipping: null,
  selectedRepeats: null,
  selectedConsultations: null,
  selectedReturnsInventory: null,
  selectedEmergencyUnblinding: null,
  hasAccessToUnblinding: false,
  downloadLink: '',
  qa: null,
  qaList: [],
  trialSummary: null,
  sitesSummary: [],
  randomisation: null,
  randomisationEntries: [],
  allRandomisationEntries: [],
  trialDetails: null,
  coordinatorKVP: [],
  investigatorKVP: [],
  selectedQATypeData: [],
  randomisationForAdjustment: null,
  siteforBulkOrder: null,
  inventoryPerTreatment: {},
  archiveReports: []
}

export default (state = initialState, action: TrialActionsTypes | any): Trial => {
  switch (action.type) {
    case FETCH_TRIAL_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const trialList = append ? [...state.trialList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        trialList
      }
    }
    case SUBMIT_TRIAL_SUCCEEDED: 
    case SUBMIT_TRIAL_ARCHIVE_SUCCEEDED: {
      const { payload } = action
      const selectedTrial = payload;
      return {
        ...state,
        selectedTrial,
      }
    }
    case UPDATE_TRIAL_SUCCEEDED: {
      const { payload } = action;
      const trialProgress = {...state.trialProgress, Details: {estatus: true}}
      return {
        ...state,
        selectedTrial: payload,
        trialProgress
      }
    }
    case DELETE_TRIAL_SUCCEEDED: {
      const { id } = action;
      const trialList = [...state.trialList].filter(list => list.id !== id);
      const totalResults:number = state.totalResults - 1;
      return {
        ...state,
        trialList,
        totalResults
      }
    }
    case FETCH_TRIAL_BY_ID_SUCCEEDED: {
      const { payload } = action;
      const selectedTrial:any = payload;
      const randomisation:any = selectedTrial?.trialProgressHistory?.randomisation || false;
      let trialProgress = JSON.parse(selectedTrial.trialSetupProgress);
      trialProgress.RandomisationList.estatus = (trialProgress.RandomisationList.estatus || randomisation);

      // delete trialProgress['RandomisationList']; // NOTE removing randomisation from list
      
      return{
        ...state,
        selectedTrial,
        trialProgress
      }
    }
    case SET_SELECTED_TRIAL: {
      const { id } =  action;
      const selectedTrial = [...state.trialList].find((list:any) => list.id === id) || null;
      return{
        ...state,
        selectedTrial
      }
    }
    case REMOVE_SELECTED_TRIAL: {
      return {
        ...state,
        selectedTrial: null
      }
    }
    case RESET_TRIAL: {
      return {
        ...state,
        ...initialState
      }
    }
    case FETCH_KVP_SPONSOR_SUCCEEDED: {
      const { payload } = action;
      const sponsorTypes = payload;
      return {
        ...state,
        sponsorTypes
      }
    }
    case FETCH_KVP_QA_SUCCEEDED: {
      const { payload } = action;
      const QATypes = payload;
      return {
        ...state,
        QATypes
      }
    }
    case FETCH_KVP_MONITOR_SUCCEEDED: {
      const { payload } = action;
      const monitorTypes = payload;
      return {
        ...state,
        monitorTypes
      }
    }
    case FETCH_REPEATS_SUCCEEDED:
    case SUBMIT_REPEATS_SUCCEEDED: {
      const { payload } = action;
      const selectedRepeats = payload;

      /* let selectedTrial:any = {...state.selectedTrial};
      if(action.type === SUBMIT_REPEATS_SUCCEEDED && (state.qa?.approved === false || selectedTrial.trialStatus === 4)) {
        selectedTrial = {...selectedTrial, trialStatus: 1}
      } */
      return {
        ...state,
        selectedRepeats,
      }
    }
    case FETCH_CONSULTATIONS_SUCCEEDED:
    case SUBMIT_CONSULTATIONS_SUCCEEDED: {
      const { payload } = action;
      const selectedConsultations = payload;

      return {
        ...state,
        selectedConsultations,
      }
    }
    case FETCH_RETURNS_INVENTORY_SUCCEEDED: 
    case SUBMIT_RETURNS_INVENTORY_SUCCEEDED: {
      const { payload } = action;
      const selectedReturnsInventory = payload;

      return {
        ...state,
        selectedReturnsInventory
      }
    }
    case VALIDATE_EMERGENCY_UNBLINDING_SUCCEEDED: {
      const { payload } = action;
      const hasAccessToUnblinding = JSON.parse(payload);

      return {
        ...state,
        hasAccessToUnblinding,
      }
    }
    case FETCH_EMERGENCY_UNBLINDING_SUCCEEDED: 
    case SUBMIT_EMERGENCY_UNBLINDING_SUCCEEDED: {
      const { payload } = action;
      const selectedEmergencyUnblinding = payload;

      return {
        ...state,
        selectedEmergencyUnblinding,
      }
    } 
    case FETCH_TRIAL_PRICING_SUCCEEDED:
    case SUBMIT_TRIAL_PRICING_SUCCEEDED: {
      const { payload } = action;
      const pricing = payload;

      return {
        ...state,
        pricing,
      }
    }
    case FETCH_KVP_FROM_TRIALS_SUCCEEDED: {
      const {payload, typeofKVP} = action;

      if(typeofKVP === 'sites') return {...state, sitesKVP: payload}
      if(typeofKVP === 'pharmacy') return {...state, pharmacyKVP: payload}
      if(typeofKVP === 'couriers') return {...state, couriersKVP: payload}
      if(typeofKVP === 'warehouse') return {...state, warehouseKVP: payload}
      if(typeofKVP === 'trials') return {...state, trialKVP: payload}

      return {
        ...state
      }
    }
    case FETCH_TRIAL_SITES_SUCCEEDED:
    case SUBMIT_TRIAL_SITES_SUCCEEDED: {
      const { payload } = action;
      const sites = payload;

      return {
        ...state,
        sites,
      }
    }
    case FETCH_DRUG_LIST_SUCCEEDED: {
      const {payload} = action;
      return {
        ...state,
        drugList: payload
      }
    }
    case ADD_DRUG_SUCCEEDED: {
      const {payload} = action;
      const drugList = payload.id === invalidGuid ? [...state.drugList] : [...state.drugList, payload];
      
      return {
        ...state,
        drugList,
      }
    }
    case UPDATE_DRUG_SUCCEEDED: {
      const {payload} = action;
      const drugList = payload.id === invalidGuid 
        ? [...state.drugList] 
        : [...state.drugList].map(list => {
            if(list.id === payload.id) {
              list = {...list, ...payload}
            }
            return list
          })

      return {
        ...state,
        drugList,
      }
    }
    case DELETE_DRUG_SUCCEEDED: {
      const { id } = action;
      const drugList = [...state.drugList].filter(list => list.id !== id);

      return {
        ...state,
        drugList,
      }
    }
    case FETCH_TREATMENTLABEL_LIST_SUCCEEDED: {
      const { payload} = action;
      const {pagedData } = payload;
      const lists = pagedData;
      // const treatmentLabelList = append ? [...state.trialList, ...lists] : lists;
      return {
        ...state,
        treatmentLabelList: lists
      }
    }
    case ADD_TREATMENTLABEL_SUCCEEDED: {
      const {payload} = action;
      const treatmentLabelList = payload.id === invalidGuid ? [...state.treatmentLabelList] : [...state.treatmentLabelList, payload];
      
      return {
        ...state,
        treatmentLabelList,
      }
    }
    case UPDATE_TREATMENTLABEL_SUCCEEDED: {
      const {payload} = action;
      let selectedDrugIndex = state.treatmentLabelList.findIndex((treatmentLabel:any)=>treatmentLabel.id===payload.id);
      let allTreatmentLabels = [...state.treatmentLabelList];
      allTreatmentLabels[selectedDrugIndex] = payload;
      const treatmentLabelList =  payload.id === invalidGuid ? [...state.treatmentLabelList] : allTreatmentLabels;

      return {
        ...state,
        treatmentLabelList,
      }
    }
    case DELETE_TREATMENTLABEL_SUCCEEDED: {
      const {id} = action;
      const treatmentLabelList = [...state.treatmentLabelList].filter(list => list.id !== id);

      return {
        ...state,
        treatmentLabelList,
      }
    }
    case FETCH_TREATMENTARM_LIST_SUCCEEDED: {
      const {payload} = action;
      return {
        ...state,
        treatmentArmList: payload
      }
    }
    case ADD_TREATMENTARM_SUCCEEDED: {
      const {payload} = action;
      const treatmentArmList = payload.id === invalidGuid ? [...state.treatmentArmList] : [...state.treatmentArmList, payload];
      
      return {
        ...state,
        treatmentArmList,
      }
    }
    case UPDATE_TREATMENTARM_SUCCEEDED: {
      const {payload} = action;
      let selectedDrugIndex = state.treatmentArmList.findIndex((treatmentArm:any)=>treatmentArm.id===payload.id);
      let allTreatmentLabels = [...state.treatmentArmList];
      allTreatmentLabels[selectedDrugIndex] = payload;
      const treatmentArmList = payload.id === invalidGuid ? [...state.treatmentArmList] : allTreatmentLabels;
      
      return {
        ...state,
        treatmentArmList,
      }
    }
    case DELETE_TREATMENTARM_SUCCEEDED: {
      const {id} = action;
      const treatmentArmList = [...state.treatmentArmList].filter(list => list.id !== id);

      return {
        ...state,
        treatmentArmList,
      }
    }
    case FETCH_TREATMENT_ARM_SUMMARY_SUCCEEDED: {
      const {payload} = action;
      return {
        ...state,
        treatmentArmSummary: payload
      }
    }
    case FETCH_TRIALLABEL_SUCCEEDED: {
      const {payload} = action;
      return {
        ...state,
        trialLabels: payload
      }
    }
    case FETCH_TRIALLABEL_SIZE_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        trialLabelSize: payload
      }
    }
    case ADD_TRIALLABEL_SUCCEEDED: {
      const {payload} = action;

      return {
        ...state,
        trialLabels: payload,
      }
    }
    case FETCH_USERS_SUCCEEDED: {
      const { payload:users } = action;
      return {
        ...state,
        users
      }
    }
    case FETCH_TRIAL_USERS_LIST_SUCCEEDED:
    case FETCH_TRIAL_PATIENT_USERS_LIST_SUCCEEDED: {
    // case SUBMIT_TRIAL_USER_SUCCEEDED: {
      const { payload:userList } = action;
      return {
        ...state,
        userList
      }
    }
    case FETCH_TRIAL_FILE_SUCCEEDED: {
      const {payload} = action;
      const files = payload;
      return{
        ...state,
        files
      }
    }
    case SUBMIT_TRIAL_FILE_SUCCEEDED: {
      const {payload} = action;
      const files = payload.id === invalidGuid ? [...state.files] : [...state.files, payload];

      return{
        ...state,
        files,
      }
    }
    case DELETE_TRIAL_FILE_SUCCEEDED: {
      const { fileId } = action;
      const files = [...state.files].filter(file => file.id !== fileId);
      
      return{
        ...state,
        files,
      }
    }
    case DOWNLOAD_TRIAL_FILE_SUCCEEDED: {
      const { link:downloadLink } = action;
      return{
        ...state,
        downloadLink
      }
    }
    case SET_TRIAL_PROGRESS: {
      const { step, status } = action.payload
      const trialProgressKey = TrialProgress[step]
      const trialProgress = {...state.trialProgress, [trialProgressKey]: {estatus: status}};
      return {
        ...state,
        trialProgress
      }
    }
    case UPDATE_TRIAL_PROGRESS_SUCCEEDED: {
      const { step, status } = action.payload
      const trialProgressKey = TrialProgress[step]
      const trialProgress = {...state.trialProgress, [trialProgressKey]: {estatus: status}};
      return {
        ...state,
        trialProgress
      }
    }
    case UPDATE_MULTI_TRIAL_PROGRESS_SUCCEEDED:{
      const multiSteps = action.payload.objectTypes;
      let trialProgress:any = {...state.trialProgress};
      multiSteps.forEach(({objectType: step, enabled:status}:any) => {
        trialProgress[TrialProgress[step]] = {estatus: status}
      })

      return {
        ...state,
        trialProgress
      }
    }
    case FETCH_PACKAGE_OPTIONS_SUCCEEDED: {
      const {payload:packageOptions} = action;
      return {
        ...state,
        packageOptions
      }
    }
    case FETCH_SHIPPING_SUCCEEDED:
    case SUBMIT_SHIPPING_SUCCEEDED: {
      const { payload:shipping } = action;

      return {
        ...state,
        shipping,
      }
    }
    case TOGGLE_STRATIFICATION: {
      const {enabled} = action;
      return {
        ...state,
        selectedStratification: {
          enabled
        }
      }
    }
    case FETCH_STRATIFICATION_SUCCEEDED:
    case SUBMIT_STRATIFICATION_SUCCEEDED: {
      const {payload: stratificationList} = action;

      return {
        ...state,
        stratificationList,
      }
    }
    case FETCH_QA_SUCCEEDED: {
      return {
        ...state
      }
    }
    case FETCH_QA_REQUEST_SUCCEEDED: 
    case SUBMIT_QA_SUCCEEDED:
    case REJECT_QA_SUCCEEDED:
    case APPROVE_QA_SUCCEEDED: {
      const { payload:qa } = action;
      return {
        ...state,
        qa
      }
    }
    case FETCH_TRIAL_SUMMARY_SUCCEEDED: {
      const {payload:trialSummary} = action;
      return {
        ...state,
        trialSummary
      }
    }
    case FETCH_TRIAL_SITES_SUMMARY_SUCCEEDED: {
      const {payload:sitesSummary} = action;
      return {
        ...state,
        sitesSummary
      }
    }
    case FETCH_RANDOMISATION_ENTRIES_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const randomisationEntries = append ? [...state.randomisationEntries, ...lists] : lists;
      return {
        ...state,
        ...rest,
        randomisationEntries
      }
    }
    case FETCH_ALL_RANDOMISATION_ENTRIES_SUCCEEDED: {
      const { payload} = action;
      const allRandomisationEntries = payload;
      return {
        ...state,
        allRandomisationEntries
      }
    }
    case FETCH_RANDOMISATION_SUCCEEDED: {
      const randomisation = action.payload;
      return {
        ...state,
        randomisation
      }
    }
    case SUBMIT_TRIAL_QA_SUCCEEDED: {
      const selectedTrial = {
        ...state.selectedTrial,
        trialStatus: 2
      }
      return {
        ...state,
        selectedTrial
      }
    }
    case FETCH_TRIAL_DETAILS_FOR_BULK_ORDER_SUCCEEDED: {
      const trialDetails = action.payload;
      return {
        ...state,
        trialDetails
      }
    }
    case FETCH_COORDINATOR_KVP_SUCCEEDED: {
      const { payload:coordinatorKVP } = action;
      return {
        ...state,
        coordinatorKVP
      }
    }
    case FETCH_INVESTIGATOR_KVP_SUCCEEDED: {
      const { payload:investigatorKVP } = action;
      return {
        ...state,
        investigatorKVP
      }
    }
    case SUBMIT_BULK_ORDER_SUCCEEDED: {
      return {
        ...state
      }
    }
    case SET_SELECTED_QA_TYPE_DATA: {
      const selectedQATypeData = action.payload;
      
      let STATE = {
        ...state,
        selectedQATypeData
      }

      if(selectedQATypeData[0].trialType === 2) { // Trial
        const trialProgress = JSON.parse(selectedQATypeData[0].newValue.trialSetupProgress)
        // delete trialProgress['RandomisationList']; // NOTE removing randomisation from list
        STATE = {...STATE ,trialProgress}
      }

      return STATE;
    }
    case REMOVE_SELECTED_QA_TYPE_DATA: {
      return {
        ...state,
        selectedQATypeData: []
      }
    }
    case SEARCH_TRIAL_RANDOMISATION_SUCCEEDED: {
      const randomisationForAdjustment = action.payload;
      return {
        ...state,
        randomisationForAdjustment
      }
    }
    case FETCH_SITE_FOR_BULKORDER_SUCCEEDED: {
      const siteforBulkOrder = action.payload;
      return {
        ...state,
        siteforBulkOrder
      }
    }
    case FETCH_AVAILABLE_INVENTORIES_SUCCEEDED: {
      const {treatmentId, payload} =  action;
      const inventoryPerTreatment = {
        ...state.inventoryPerTreatment,
        [treatmentId]: payload
      }

      return {
        ...state,
        inventoryPerTreatment
      }
    }
    case RESET_AVAILABLE_INVENTORIES: {
      return {
        ...state,
        inventoryPerTreatment: {}
      }
    }
    case FETCH_TRIAL_ARCHIVE_REPORTS_SUCCEEDED: {
      return {
        ...state,
        archiveReports: action.payload
      }
    }
    default:{
      return state;
    }
  }
}