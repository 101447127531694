import { Setting } from 'types/models/Setting';
import {
  SET_INITIAL_PATH,
  SettingsActionTypes,
  TOGGLE_NAV_COLLAPSED,
  SET_SETTINGS,
  RESET_SETTINGS,
  INIT_SETTING,
} from '../../types/actions/Settings.action';

export const toggleNavCollapsed = () => ({type: TOGGLE_NAV_COLLAPSED});

export const setInitialPath = (initialPath: string): SettingsActionTypes => ({
  type: SET_INITIAL_PATH,
  initialPath,
});

export const setSetting = (payload: Setting): SettingsActionTypes => ({
  type: SET_SETTINGS,
  payload,
});

export const initSettings = (): SettingsActionTypes => ({
  type: INIT_SETTING,
});

export const resetSettings = (): SettingsActionTypes => ({
  type: RESET_SETTINGS
});