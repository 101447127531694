import { InventoryList, Payload, TrialTreatment } from "../../types/models/Inventory";
import { GetQueryParams, KVP } from "../../types/models/Common";
import { 
  FETCH_INVENTORY_LIST,
  FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR,
  FETCH_INVENTORY_BY_ID,
  SUBMIT_INVENTORY,
  UPDATE_INVENTORY,
  DELETE_INVENTORY,
  FETCH_INVENTORY_LIST_SUCCEEDED,
  FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR_SUCCEEDED,
  FETCH_INVENTORY_BY_ID_SUCCEEDED,
  SUBMIT_INVENTORY_SUCCEEDED,
  UPDATE_INVENTORY_SUCCEEDED,
  DELETE_INVENTORY_SUCCEEDED,
  SET_SELECTED_INVENTORY,
  REMOVE_SELECTED_INVENTORY,
  RESET_INVENTORY,
  FETCH_KVP_TRIAL,
  FETCH_KVP_TRIAL_SUCCEEDED,
  FETCH_TRIAL_TREATMENT,
  FETCH_TRIAL_TREATMENT_SUCCEEDED,
  SUBMIT_PRINT_INVENTORY,
  SUBMIT_PRINT_INVENTORY_SUCCEEDED,
  RESET_PRINT_INVENTORY,
  FETCH_INVENTORY_DETAILS,
  FETCH_INVENTORY_DETAILS_SUCCEEDED,
  FETCH_BATCH_BY_TRIALID,
  FETCH_BATCH_BY_TRIALID_SUCCEEDED,
  FETCH_PRODUCTCODE_BY_TRIALID,
  FETCH_PRODUCTCODE_BY_TRIALID_SUCCEEDED,
  FETCH_UPLOADED_FILES,
  FETCH_UPLOADED_FILES_SUCCEEDED,
  RESET_UPLOADEDFILES,
  InventoryActionsTypes
} from "../../types/actions/Inventory.action";

export const fetchInventoryList = (payload: GetQueryParams) => ({
  type: FETCH_INVENTORY_LIST,
  payload
});

export const fetchInventoryListForSiteAndSponsor = (payload: GetQueryParams) => ({
  type: FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR,
  payload
});

export const submitInventory = (payload:InventoryList):InventoryActionsTypes => ({
  type: SUBMIT_INVENTORY,
  payload
});

export const updateInventory = (id: string, payload:InventoryList):InventoryActionsTypes => ({
  type: UPDATE_INVENTORY,
  id,
  payload
});

export const deleteInventory = (id: string):InventoryActionsTypes => ({
  type: DELETE_INVENTORY,
  id
});

export const fetchInventoryById = (id: string) => ({
  type: FETCH_INVENTORY_BY_ID,
  id
});

export const setSelectedInventory = (id: string) => ({
  type: SET_SELECTED_INVENTORY,
  id
});

export const removeSelectedInventory = () => ({
  type: REMOVE_SELECTED_INVENTORY
});

export const resetInventory = (): InventoryActionsTypes => ({
  type: RESET_INVENTORY
});

export const fetchInventoryListSucceeded = (payload:Payload, append:boolean): InventoryActionsTypes=> ({
  type: FETCH_INVENTORY_LIST_SUCCEEDED,
  payload,
  append
});

export const fetchInventoryListForSiteAndSponsorSucceeded = (payload:Payload, append:boolean): InventoryActionsTypes=> ({
  type: FETCH_INVENTORY_LIST_FOR_SITE_AND_SPONSOR_SUCCEEDED,
  payload,
  append
});

export const submitInventorySucceeded = (payload:InventoryList):InventoryActionsTypes => ({
  type: SUBMIT_INVENTORY_SUCCEEDED,
  payload
});

export const updateInventorySucceeded = (id: string, payload:InventoryList):InventoryActionsTypes => ({
  type: UPDATE_INVENTORY_SUCCEEDED,
  id,
  payload
});

export const deleteInventorySucceeded = (id: string):InventoryActionsTypes => ({
  type: DELETE_INVENTORY_SUCCEEDED,
  id
});

export const fetchInventoryByIdSucceeded = (payload:InventoryList):InventoryActionsTypes => ({
  type: FETCH_INVENTORY_BY_ID_SUCCEEDED,
  payload
});

export const fetch_KVP_Trial = (searchValue:string): InventoryActionsTypes => ({
  type: FETCH_KVP_TRIAL,
  searchValue
})

export const fetch_KVP_Trial_Succeeded = (payload: KVP[]):InventoryActionsTypes => ({
  type: FETCH_KVP_TRIAL_SUCCEEDED,
  payload
});

export const fetchTrialTreatment = (id:string): InventoryActionsTypes => ({
  type: FETCH_TRIAL_TREATMENT,
  id
});

export const fetchTrialTreatmentSucceeded = (payload:TrialTreatment[]): InventoryActionsTypes => ({
  type: FETCH_TRIAL_TREATMENT_SUCCEEDED,
  payload
});

export const submitPrintInventory = (inventoryId: string): InventoryActionsTypes => ({
  type: SUBMIT_PRINT_INVENTORY,
  inventoryId
});

export const submitPrintInventorySucceeded = (payload:any): InventoryActionsTypes => ({
  type: SUBMIT_PRINT_INVENTORY_SUCCEEDED,
  payload
});

export const resetPrintInventory = (): InventoryActionsTypes => ({
  type: RESET_PRINT_INVENTORY
});

export const fetchInventoryDetails = (trialId:string, treatmentId:string, organisationId:string) => ({
  type: FETCH_INVENTORY_DETAILS,
  trialId,
  treatmentId,
  organisationId
})

export const fetchInventoryDetailsSucceeded = (payload:any) => ({
  type: FETCH_INVENTORY_DETAILS_SUCCEEDED,
  payload
})

export const fetchBatchByTrialId = (id: string)  => ({
  type: FETCH_BATCH_BY_TRIALID,
  id
})

export const fetchBatchByTrialIdSucceeded = (payload: any)  => ({
  type: FETCH_BATCH_BY_TRIALID_SUCCEEDED,
  payload
})

export const fetchProductCodeByTrialId = (id: string)  => ({
  type: FETCH_PRODUCTCODE_BY_TRIALID,
  id
})

export const fetchProductCodeByTrialIdSucceeded = (payload:any)  => ({
  type: FETCH_PRODUCTCODE_BY_TRIALID_SUCCEEDED,
  payload
})

export const fetchUploadedFiles = (payload:any) => ({
  type: FETCH_UPLOADED_FILES,
  payload
})

export const fetchUploadedFilesSucceeded = (payload:any) => ({
  type: FETCH_UPLOADED_FILES_SUCCEEDED,
  payload
})

export const resetUploadedFiles = () => ({
  type: RESET_UPLOADEDFILES
})