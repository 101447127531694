// import { CommentsPayload } from "../models/Comment";

// Comment actions types
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const SUBMIT_COMMENT_SUCCEEDED = "SUBMIT_COMMENT_SUCCEEDED";

// Comment history actions types
export const FETCH_COMMENTS_LIST = "FETCH_COMMENTS_LIST";
export const FETCH_COMMENTS_LIST_SUCCEEDED = "FETCH_COMMENTS_LIST_SUCCEEDED";

// Comments actions
export interface SubmitCommentActions {
  type: typeof SUBMIT_COMMENT;
  prescriptionId: string;
  payload: {
    targetObjectId: string,
    comment: string,
    targetObjectType: number;
  };
}

export interface SubmitCommentSucceededActions {
  type: typeof SUBMIT_COMMENT_SUCCEEDED;
  payload: any;
}

// Comment History actions
export interface FetchCommentHistoryActions {
  type: typeof FETCH_COMMENTS_LIST;
  prescriptionId: string;
  payload: any
}

export interface FetchCommentHistorySucceededActions {
  type: typeof FETCH_COMMENTS_LIST_SUCCEEDED;
  payload: any;
  append: boolean;
}

export type CommentActionsTypes =
  | SubmitCommentActions
  | SubmitCommentSucceededActions
  | FetchCommentHistoryActions
  | FetchCommentHistorySucceededActions
  ;