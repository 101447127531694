import React from 'react';

export const stocktakeRouteConfig = [
  {
    auth: ["Admin", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/stocktake',
        exact: true,
        // component: React.lazy(() => import('./Stocktake')),
      },
      {
        path: '/stocktake/:id',
        exact: true,
        component: React.lazy(() => import('./Stocktake')),
      },
      {
        path: '/fixissues',
        exact: true,
        component: React.lazy(() => import('./FixStocktake')),
      }
    ]
  }
]