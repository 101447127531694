import { all } from "redux-saga/effects";
import CourierSagas  from "../redux/sagas/Couriers"
import SitesSagas  from "../redux/sagas/Sites"
import PricingSagas  from "../redux/sagas/Pricing"
import SponsorsSagas  from "../redux/sagas/Sponsors"
import UsersSagas  from "../redux/sagas/Users"
import SettingsSagas  from "../redux/sagas/Settings"
import TrialsSagas  from "../redux/sagas/Trials";
import NotificationsSagas from "../redux/sagas/Notifications";
import PatientsSagas  from "../redux/sagas/Patients";
import ConsultationsSagas  from "../redux/sagas/Consultations";
import ReportSagas from "../redux/sagas/Reports";
import CommentSagas  from "../redux/sagas/Comments";
import PrescriptionSagas from "../redux/sagas/Prescriptions";
import StocktakeSagas from "../redux/sagas/Stocktake";
import InventorySagas  from "../redux/sagas/Inventory";
import ReturnInventorySagas from "../redux/sagas/ReturnInventory";
import DashboardSagas from "../redux/sagas/Dashboard";
import ManifestSagas  from "../redux/sagas/Manifest";
import SiteShippingSagas from "../redux/sagas/SIteShipping";

function* rootSaga() {
  yield all([
    ...CourierSagas,
    ...PricingSagas,
    ...SponsorsSagas,
    ...SitesSagas,
    ...UsersSagas,
    ...SettingsSagas,
    ...NotificationsSagas,
    ...TrialsSagas,
    ...PatientsSagas,
    ...ConsultationsSagas,
    ...ReportSagas,
    ...CommentSagas,
    ...PrescriptionSagas,
    ...StocktakeSagas,
    ...InventorySagas,
    ...ReturnInventorySagas,
    ...DashboardSagas,
    ...ManifestSagas,
    ...SiteShippingSagas
  ]);
}

export default rootSaga;
