import "whatwg-fetch";
import { takeLatest, put, call } from "redux-saga/effects";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams } from "../../@crema/utility/Utils";
import { 
  addCommentToConsultationSucceeded,
  listPrescriptionCommentsSucceeded
} from "../actions/Comments";

const baseUrlv1 = 'api/v1';

function addCommentApi(prescriptionId:string, payload:any) {
  const url = `${baseUrlv1}/comments/${prescriptionId}`;
  return Api.fetch(url, getPostParams(payload));
}

function listCommentHistory(prescriptionId:string, payload:any) {
  const { PageSize, PageNumber, objectType } = payload;
  const url = `${baseUrlv1}/comments/${prescriptionId}?PageSize=${PageSize}&PageNumber=${PageNumber}&objectType=${objectType}`;
  return Api.fetch(url, getGetParams());
}

export function* submitConsultationComment({prescriptionId, payload}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(addCommentApi, prescriptionId, payload);

    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(addCommentToConsultationSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* listConsultationComments({prescriptionId, payload}:any) {
  yield put(fetchStart());
  try {
    const { append } = payload;
    const response = yield call(listCommentHistory, prescriptionId, payload);

    if(!response.error) {
      yield put(fetchSuccess());
      yield put(listPrescriptionCommentsSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('SUBMIT_COMMENT',submitConsultationComment),
  takeLatest('FETCH_COMMENTS_LIST',listConsultationComments)
];
