import React from 'react';

export const siteRouteConfig = [
  {
    auth: ['Admin'],
    routes: [
      {
        path: '/sites',
        exact: true,
        component: React.lazy(() => import('./Sites')),
      },
      {
        path: '/sites/:id',
        exact: true,
        component: React.lazy(() => import('./SiteDetails')),
      }
    ],
  }
];