import { Sponsor } from "../../types/models/Sponsors";
import { 
  SponsorActionsTypes,
  FETCH_SPONSOR_LIST_SUCCEEDED,
  SUBMIT_SPONSOR_SUCCEEDED,
  REMOVE_SELECTED_SPONSOR,
  UPDATE_SPONSOR_SUCCEEDED,
  SET_SELECTED_SPONSOR,
  DELETE_SPONSOR_SUCCEEDED,
  RESTORE_SPONSOR_SUCCEEDED,
  FETCH_SPONSOR_BY_ID_SUCCEEDED,
  RESET_SPONSOR,
  RESET_FETCHCOMPLETE
} from "../../types/actions/Sponsors.action";

const initialState: Sponsor = {
  sponsorList: [],
  selectedSponsor: null,
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  fetchComplete: false
}

export default (state = initialState, action: SponsorActionsTypes): Sponsor => {
  switch (action.type) {
    case FETCH_SPONSOR_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const sponsorList = append ? [...state.sponsorList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        sponsorList
      }
    }
    case SUBMIT_SPONSOR_SUCCEEDED: {
      const { payload } = action
      const sponsorList = [...state.sponsorList, payload];
      const totalResults:number = state.totalResults + 1;
      return {
        ...state,
        sponsorList,
        fetchComplete: true,
        totalResults
      }
    }
    case UPDATE_SPONSOR_SUCCEEDED: {
      const {id, payload} = action;
      const sponsorList = [...state.sponsorList].map(list => {
        if(list.id === id) {
          list = {...list, ...payload}
        }
        return list
      })
      return {
        ...state,
        sponsorList,
        selectedSponsor: payload,
        fetchComplete: true
      }
    }
    case DELETE_SPONSOR_SUCCEEDED: {
      const { id } = action;
      const sponsorList = [...state.sponsorList].filter(list => list.id !== id);
      const totalResults:number = state.totalResults - 1;
      return {
        ...state,
        sponsorList,
        fetchComplete: true,
        totalResults
      }
    }
    case RESTORE_SPONSOR_SUCCEEDED: {
      const { id } = action;
      const sponsorList = [...state.sponsorList].filter(list => list.id !== id);
      const totalResults:number = state.totalResults - 1;
      return {
        ...state,
        sponsorList,
        fetchComplete: true,
        totalResults
      }
    }
    case FETCH_SPONSOR_BY_ID_SUCCEEDED: {
      const { payload } = action;
      const selectedSponsor = payload;
      return{
        ...state,
        selectedSponsor
      }
    }
    case SET_SELECTED_SPONSOR: {
      const { id } =  action;
      const selectedSponsor = [...state.sponsorList].find((list:any) => list.id === id) || null;
      return{
        ...state,
        selectedSponsor
      }
    }
    case REMOVE_SELECTED_SPONSOR: {
      return {
        ...state,
        selectedSponsor: null
      }
    }
    case RESET_FETCHCOMPLETE: {
      return {
        ...state,
        fetchComplete: false
      }
    }
    case RESET_SPONSOR: {
      return {
        ...state,
        ...initialState
      }
    }
    default:{
      return state;
    }
  }
}