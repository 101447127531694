import { Payload, PrescriptionList, Inventory, BulkOrder } from "types/models/Prescription";
import { GetQueryParams, KVP } from "../../types/models/Common";
import { 
  FETCH_PRESCRIPTION_LIST,
  FETCH_PRESCRIPTION_LIST_SUCCEEDED,
  FETCH_ORDER_LIST,
  FETCH_ORDER_LIST_SUCCEEDED,
  FETCH_KVP_TRIAL_FOR_PHARMACY,
  FETCH_KVP_TRIAL_FOR_PHARMACY_SUCCEEDED,
  FETCH_KVP_SITES_BY_PHARMACY_ID,
  FETCH_KVP_SITES_BY_PHARMACY_ID_SUCCEEDED,
  FETCH_KVP_SITES_BY_TRIAL_ID,
  FETCH_KVP_SITES_BY_TRIAL_ID_SUCCEEDED,
  DELETE_DISPENSE,
  DELETE_DISPENSE_SUCCEEDED,
  REMOVE_SELECTED_PRESCRIPTION,
  RESET_PRESCRIPTION,
  FETCH_PRESCRIPTION_BY_ID,
  FETCH_PRESCRIPTION_BY_ID_SUCCEEDED,
  FETCH_BULKORDER_DISPENSE_BY_ID,
  FETCH_BULKORDER_DISPENSE_BY_ID_SUCCEEDED,
  FETCH_INVENTORY_FOR_KIT_AND_BOTTLE,
  FETCH_INVENTORY_FOR_KIT_AND_BOTTLE_SUCCEEDED,
  FETCH_BULKORDER_RANDOMISATION,
  FETCH_BULKORDER_RANDOMISATION_SUCCEEDED,
  FETCH_BULKORDER_KIT_NUMBERS,
  FETCH_BULKORDER_KIT_NUMBERS_SUCCEEDED,
  SUBMIT_MANUAL_ASSIGN_TO_BULKORDER,
  SUBMIT_MANUAL_ASSIGN_TO_BULKORDER_SUCCEEDED,
  FETCH_PRESCRIPTION_INVENTORY_BY_ID,
  FETCH_PRESCRIPTION_INVENTORY_BY_ID_SUCCEEDED,
  MARK_BULKORDER_DISPENSED,
  MARK_BULKORDER_DISPENSED_SUCCEEDED,
  MARK_PRESCRIPTION_DISPENSED,
  MARK_PRESCRIPTION_DISPENSED_SUCCEEDED,
  PRINT_PRESCRIPTION_LABELS,
  PRINT_PRESCRIPTION_LABELS_SUCCEEDED,
  FETCH_INVENTORY_OF_DISPENSED_DRUGS,
  FETCH_INVENTORY_OF_DISPENSED_DRUGS_SUCCEEDED,
  APPROVE_PRESCRIPTION,
  APPROVE_PRESCRIPTION_SUCCEEDED,
  REJECT_PRESCRIPTION,
  REJECT_PRESCRIPTION_SUCCEEDED,
  APPROVE_BULKORDER,
  APPROVE_BULKORDER_SUCCEEDED,
  REJECT_BULKORDER,
  REJECT_BULKORDER_SUCCEEDED,
  PRINT_PRESCRIPTION_SLIPS,
  PRINT_PRESCRIPTION_SLIPS_SUCCEEDED,
  MARK_PRESCRIPTION_RECEIVED,
  MARK_PRESCRIPTION_RECEIVED_SUCCEEDED,
  MARK_BULKORDER_RECEIVED,
  MARK_BULKORDER_RECEIVED_SUCCEEDED,
  MARK_PRESCRIPTION_PACKED,
  MARK_PRESCRIPTION_PACKED_SUCCEEDED,
  MARK_BULKORDER_PACKED,
  MARK_BULKORDER_PACKED_SUCCEEDED,
  SAVE_PRESCRIPTION,
  SAVE_PRESCRIPTION_SUCCEEDED,
  GET_SHIPPING_DETAILS,
  GET_SHIPPING_DETAILS_SUCCEEDED,
  DOWNLOAD_RECEIVED_STATUS_FILES,
  UPLOAD_RECEIVED_STATUS_FILES,
  UPLOAD_RECEIVED_STATUS_FILES_SUCCEEDED,
  FETCH_CONSIGNMENT_DETAILS,
  FETCH_CONSIGNMENT_DETAILS_SUCCEEDED,
  UPDATE_STATUS,
  FETCH_USERS_TO_APPROVE,
  FETCH_USERS_TO_APPROVE_SUCCEEDED,
  FETCH_ORDERLIST_REPORT,
  FETCH_ORDERLIST_REPORT_SUCCEEDED,
  FETCH_ALL_ORDERLIST_REPORT,
  FETCH_ALL_ORDERLIST_REPORT_SUCCEEDED,
  PrescriptionActionsTypes,
} from "types/actions/Prescription.action";

export const fetchPrescriptionList = (payload: GetQueryParams) => ({
  type: FETCH_PRESCRIPTION_LIST,
  payload
});

export const fetchPrescriptionListSucceeded = (payload:Payload, append:boolean): PrescriptionActionsTypes=> ({
  type: FETCH_PRESCRIPTION_LIST_SUCCEEDED,
  payload,
  append
});

export const fetchOrderList = (payload: GetQueryParams) => ({
  type: FETCH_ORDER_LIST,
  payload
});

export const fetchOrderListSucceeded = (payload:Payload, append:boolean): PrescriptionActionsTypes=> ({
  type: FETCH_ORDER_LIST_SUCCEEDED,
  payload,
  append
});

export const fetch_KVP_TrialForPharmacy = (): PrescriptionActionsTypes => ({
  type: FETCH_KVP_TRIAL_FOR_PHARMACY,
});

export const fetch_KVP_TrialForPharmacySucceeded = (payload: KVP[]): PrescriptionActionsTypes=> ({
  type: FETCH_KVP_TRIAL_FOR_PHARMACY_SUCCEEDED,
  payload
});

export const fetch_KVP_SitesByPharmacyId = (): PrescriptionActionsTypes => ({
  type: FETCH_KVP_SITES_BY_PHARMACY_ID,
  
});

export const fetch_KVP_SitesByPharmacyIdSucceeded = (payload: KVP[]): PrescriptionActionsTypes=> ({
  type: FETCH_KVP_SITES_BY_PHARMACY_ID_SUCCEEDED,
  payload
});

export const fetch_KVP_SitesByTrialId = (trialId: string): PrescriptionActionsTypes => ({
  type: FETCH_KVP_SITES_BY_TRIAL_ID,
  trialId
});

export const fetch_KVP_SitesByTrialIdSucceeded = (payload: KVP[]): PrescriptionActionsTypes=> ({
  type: FETCH_KVP_SITES_BY_TRIAL_ID_SUCCEEDED,
  payload
});

export const deleteDispense = (id: string, Type: string, reason: string): PrescriptionActionsTypes => ({
  type: DELETE_DISPENSE,
  id,
  Type,
  reason
});

export const deleteDispenseSucceeded = (id:string): PrescriptionActionsTypes => ({
  type: DELETE_DISPENSE_SUCCEEDED,
  id
});

export const removeSelectedPrescription = () => ({
  type: REMOVE_SELECTED_PRESCRIPTION
});

export const resetPrescription = (): PrescriptionActionsTypes => ({
  type: RESET_PRESCRIPTION
});

// Fetch Prescription details
export const fetchPrescriptionById = (prescriptionId: string):PrescriptionActionsTypes => ({
  type: FETCH_PRESCRIPTION_BY_ID,
  prescriptionId
});

export const fetchPrescriptionByIdSucceeded = (payload:PrescriptionList):PrescriptionActionsTypes => ({
  type: FETCH_PRESCRIPTION_BY_ID_SUCCEEDED,
  payload
});

// Fetch Bulk order details
export const fetchBulkOrderDispenseById = (id: string):PrescriptionActionsTypes => ({
  type: FETCH_BULKORDER_DISPENSE_BY_ID,
  id
});

export const fetchBulkOrderDispenseByIdSucceeded = (payload:BulkOrder):PrescriptionActionsTypes => ({
  type: FETCH_BULKORDER_DISPENSE_BY_ID_SUCCEEDED,
  payload
});

export const fetchInventoryForKitAndBottle = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_INVENTORY_FOR_KIT_AND_BOTTLE,
  payload
});

export const fetchInventoryForKitAndBottleSucceeded = (bottleIdentifier: number, inventoryId: string, treatmentId: string, payload:any, append: boolean):PrescriptionActionsTypes => ({
  type: FETCH_INVENTORY_FOR_KIT_AND_BOTTLE_SUCCEEDED,
  bottleIdentifier,
  inventoryId,
  treatmentId,
  payload,
  append
});

export const fetchBulkOrderRandomisation = (id: string): PrescriptionActionsTypes => ({
  type: FETCH_BULKORDER_RANDOMISATION,
  id
});

export const fetchBulkOrderRandomisationSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_BULKORDER_RANDOMISATION_SUCCEEDED,
  payload
});

export const fetchBulkOrderKitNumbers = (id: string): PrescriptionActionsTypes => ({
  type: FETCH_BULKORDER_KIT_NUMBERS,
  id
});

export const fetchBulkOrderKitNumbersSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_BULKORDER_KIT_NUMBERS_SUCCEEDED,
  payload
});

export const submitManualAssignToBulkOrder = (payload:any): PrescriptionActionsTypes => ({
  type: SUBMIT_MANUAL_ASSIGN_TO_BULKORDER,
  payload
});

export const submitManualAssignToBulkOrderSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: SUBMIT_MANUAL_ASSIGN_TO_BULKORDER_SUCCEEDED,
  payload
});

// Fetch Prescription Inventories by Id
export const fetchPrescriptionInventoryById = (prescriptionId: string):PrescriptionActionsTypes => ({
  type: FETCH_PRESCRIPTION_INVENTORY_BY_ID,
  prescriptionId
});

export const fetchPrescriptionInventoryByIdSucceeded = (payload:Inventory):PrescriptionActionsTypes => ({
  type: FETCH_PRESCRIPTION_INVENTORY_BY_ID_SUCCEEDED,
  payload
});

//Mark BulkOrder as Dispensed
export const markBulkOrderAsDispensed = (bulkOrderId:string, payload:any):PrescriptionActionsTypes => ({
  type: MARK_BULKORDER_DISPENSED,
  bulkOrderId,
  payload
});

export const markBulkOrderAsDispensedSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: MARK_BULKORDER_DISPENSED_SUCCEEDED,
  payload
});

// MARK Prescription As Dispensed by Id
export const markPrescriptionAsDispensed = (prescriptionId: string, payload: any):PrescriptionActionsTypes => ({
  type: MARK_PRESCRIPTION_DISPENSED,
  prescriptionId,
  payload
});

export const markPrescriptionAsDispensedSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: MARK_PRESCRIPTION_DISPENSED_SUCCEEDED,
  payload
});

// Print Prescription Labels
export const printPrescriptionLabels = (prescriptionId: string, payload: any):PrescriptionActionsTypes => ({
  type: PRINT_PRESCRIPTION_LABELS,
  prescriptionId,
  payload
});

export const printPrescriptionLabelsSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: PRINT_PRESCRIPTION_LABELS_SUCCEEDED,
  payload
});

export const fetchInventoryofDispensedDrugs = (prescriptionId: string):PrescriptionActionsTypes => ({
  type: FETCH_INVENTORY_OF_DISPENSED_DRUGS,
  prescriptionId
});

export const fetchInventoryofDispensedDrugsSucceeded = (payload: any):PrescriptionActionsTypes => ({
  type: FETCH_INVENTORY_OF_DISPENSED_DRUGS_SUCCEEDED,
  payload
});

// Approve Prescription
export const approvePrescription = (prescriptionId:string, payload:any, specialApproval:boolean):PrescriptionActionsTypes => ({
  type: APPROVE_PRESCRIPTION,
  prescriptionId,
  payload,
  specialApproval
});

export const approvePrescriptionSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: APPROVE_PRESCRIPTION_SUCCEEDED,
  payload
});

// Reject Prescription
export const rejectPrescription = (prescriptionId:string, payload:any, specialApproval:boolean):PrescriptionActionsTypes => ({
  type: REJECT_PRESCRIPTION,
  prescriptionId,
  payload,
  specialApproval
});

export const rejectPrescriptionSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: REJECT_PRESCRIPTION_SUCCEEDED,
  payload
});

// Approve BulkOrder
export const approveBulkOrder = (bulkOrderId:string, payload:any, specialApproval:boolean):PrescriptionActionsTypes => ({
  type: APPROVE_BULKORDER,
  bulkOrderId,
  payload,
  specialApproval
});

export const approveBulkOrderSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: APPROVE_BULKORDER_SUCCEEDED,
  payload
});

// Reject BulkOrder
export const rejectBulkOrder = (bulkOrderId:string, payload:any, specialApproval:boolean):PrescriptionActionsTypes => ({
  type: REJECT_BULKORDER,
  bulkOrderId,
  payload,
  specialApproval
});

export const rejectBulkOrderSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: REJECT_BULKORDER_SUCCEEDED,
  payload
});

// Reject Prescription Packing Slips
export const printPrescriptionPackingSlips = (prescriptionId:string):PrescriptionActionsTypes => ({
  type: PRINT_PRESCRIPTION_SLIPS,
  prescriptionId
});

export const printPrescriptionPackingSlipsSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: PRINT_PRESCRIPTION_SLIPS_SUCCEEDED,
  payload
});

// Mark Prescription as Received
export const markPrescriptionAsReceived = (prescriptionId:string):PrescriptionActionsTypes => ({
  type: MARK_PRESCRIPTION_RECEIVED,
  prescriptionId
});

export const markPrescriptionAsReceivedSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: MARK_PRESCRIPTION_RECEIVED_SUCCEEDED,
  payload
});

// Mark BulkOrder as Received
export const markBulkOrderAsReceived = (bulkOrderId:string):PrescriptionActionsTypes => ({
  type: MARK_BULKORDER_RECEIVED,
  bulkOrderId
});

export const markBulkOrderAsReceivedSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: MARK_BULKORDER_RECEIVED_SUCCEEDED,
  payload
});


// Mark Prescription as Packed
export const markPrescriptionAsPacked= (prescriptionId:string, payload:any):PrescriptionActionsTypes => ({
  type: MARK_PRESCRIPTION_PACKED,
  prescriptionId,
  payload
});

export const markPrescriptionAsPackedSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: MARK_PRESCRIPTION_PACKED_SUCCEEDED,
  payload
});

// Mark Prescription as Packed
export const markBulkOrderAsPacked= (bulkOrderId:string, payload:any):PrescriptionActionsTypes => ({
  type: MARK_BULKORDER_PACKED,
  bulkOrderId,
  payload
});

export const markBulkOrderAsPackedSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: MARK_BULKORDER_PACKED_SUCCEEDED,
  payload
});

// Save Prescription
export const savePrescription= (prescriptionId:string, payload:any):PrescriptionActionsTypes => ({
  type: SAVE_PRESCRIPTION,
  prescriptionId,
  payload
});

export const savePrescriptionSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: SAVE_PRESCRIPTION_SUCCEEDED,
  payload
});

// Get Trial Shipping Details
export const getShippingDetails = (trialId:string):PrescriptionActionsTypes => ({
  type: GET_SHIPPING_DETAILS,
  trialId
});

export const getShippingDetailsSucceeded = (payload:any):PrescriptionActionsTypes => ({
  type: GET_SHIPPING_DETAILS_SUCCEEDED,
  payload
});

export const downloadReceivedStatusFiles = (id:string, dispenseType: string, Type: number): PrescriptionActionsTypes => ({
  type: DOWNLOAD_RECEIVED_STATUS_FILES,
  id,
  dispenseType,
  Type
});

export const uploadReceivedStatusFiles = (id: string, dispenseType: string,  payload:any): PrescriptionActionsTypes => ({
  type: UPLOAD_RECEIVED_STATUS_FILES,
  id,
  dispenseType,
  payload
});

export const uploadReceivedStatusFilesSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: UPLOAD_RECEIVED_STATUS_FILES_SUCCEEDED,
  payload
});


export const fetchConsignmentDetails = (prescriptionId:string): PrescriptionActionsTypes => ({
  type: FETCH_CONSIGNMENT_DETAILS,
  prescriptionId,
});

export const fetchConsignmentDetailsSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_CONSIGNMENT_DETAILS_SUCCEEDED,
  payload,
});

export const updateStatus = (status: number) => ({
  type: UPDATE_STATUS,
  status
});

export const fetchUsersToApprove = (Type:string, id: string): PrescriptionActionsTypes => ({
  type: FETCH_USERS_TO_APPROVE,
  Type,
  id
}); 

export const fetchUsersToApproveSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_USERS_TO_APPROVE_SUCCEEDED,
  payload
}); 

export const fetchOrderListReport = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_ORDERLIST_REPORT,
  payload
})

export const fetchOrderListReportSucceeded = (payload:any, append: boolean): PrescriptionActionsTypes => ({
  type: FETCH_ORDERLIST_REPORT_SUCCEEDED,
  payload,
  append
})

export const fetchAllOrderListReport = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_ALL_ORDERLIST_REPORT,
  payload
})

export const fetchAllOrderListReportSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_ALL_ORDERLIST_REPORT_SUCCEEDED,
  payload,
})

/*export const fetchPharmacyAddressSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_PHARMACY_ADDRESS_SUCCEEDED,
  payload
}); 

export const fetchAvailableRoutes = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_AVAILABLE_ROUTES,
  payload
});

export const fetchAvailableRoutesSucceeded = (payload:any): PrescriptionActionsTypes => ({
  type: FETCH_AVAILABLE_ROUTES_SUCCEEDED,
  payload
}); */

