import React from 'react';

export const reportsRouteConfig = [
  {
    // auth: ['Admin'],
    auth: ["Admin"],
    routes: [
      {
        path: '/billingReports',
        component: React.lazy(() => import('./BillingReport')),
      },
    ],
  },
];
