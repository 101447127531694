import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { 
  fetchConsultationListSucceeded, 
  submitConsultationSucceeded, 
  updateConsultationSucceeded, 
  deleteConsultationSucceeded,
  fetchConsultationByIdSucceeded,
  updateConsultationStockStatusSucceeded,
  followUpAgainConsultationSucceeded,
  fetchConsultationByIdAndPrescriptionIdSucceeded
} from "../actions/Consultations";

const baseUrlv1 = 'api/v1';

function fetchConsultationListByIdApi(id:string, counsellingId: string) {
  const url = `${baseUrlv1}/prescriptions/${id}/counselling/${counsellingId}`;
  return Api.fetch(url, getGetParams());
}

function fetchConsultationByIdAndPrescriptionIdApi(counsellingId:string, prescriptionId:string) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/counselling/${counsellingId}`;
  return Api.fetch(url, getGetParams());
}

function fetchConsultationListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/consultations?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function markConsultationStockStatusApi(prescriptionId:string, status: boolean) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/stockRecieved`;
  return Api.fetch(url, getPostParams({
    prescriptionId,
    received: status
  }));
}

function followUpAgainApi(prescriptionId:string, counsellingId:string, payload: any) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/counselling/${counsellingId}/reschedule`;
  return Api.fetch(url, getPostParams(payload));
}

function markConsultationAsDoneApi(prescriptionId:string, counsellingId:string) {
  const url = `${baseUrlv1}/prescriptions/${prescriptionId}/counselling/${counsellingId}/complete`;
  return Api.fetch(url, getPostParams({
    prescriptionId: prescriptionId,
    counsellingId: counsellingId,
  })); 
}

function submitConsultationApi (payload:any) {
  const url = `${baseUrlv1}/consultations`;
  return Api.fetch(url, getPostParams(payload))
}

function updateConsultationApi(payload:any) {
  const url = `${baseUrlv1}/consultations`;
  return Api.fetch(url, getPutParams(payload))
} 

function deleteConsultationApi(id:number) {
  const url = `${baseUrlv1}/consultations/${id}`;
  return Api.fetch(url, getDeleteParams())
}

export function* fetchConsultationById({id, counsellingId}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchConsultationListByIdApi, id, counsellingId);

    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchConsultationByIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchConsultationList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchConsultationListApi, payload);
    
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchConsultationListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitConsultation({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitConsultationApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitConsultationSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateConsultation({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateConsultationApi, {...payload, id});
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateConsultationSucceeded(id, payload))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteConsultation({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deleteConsultationApi, id);
    if(response) {
      yield put(fetchSuccess(true));
      yield put(deleteConsultationSucceeded(id))
    } else {
      yield put(fetchError(response.message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

/**
 * Fetch Consultation details using Counseling Id and Prescription Id
 * @param Object 
 */
export function* fetchConsultationByIdAndPrescriptionId({counsellingId, prescriptionId}:any) {
  yield put(fetchStart());

  try {
    const response = yield call(fetchConsultationByIdAndPrescriptionIdApi, counsellingId, prescriptionId);

    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchConsultationByIdAndPrescriptionIdSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

/**
 * Updates Consultaion Stock Received Status
 * @param Object
 */
export function* updateConsultationStockReceivedStatus({prescriptionId, status}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(markConsultationStockStatusApi, prescriptionId, status);

    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateConsultationStockStatusSucceeded({ stockReceived: status }))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

/**
 * 
 * @param param0 
 */
export function* followUpAgain({counsellingId, prescriptionId, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response:any = yield call(followUpAgainApi, prescriptionId, counsellingId, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(followUpAgainConsultationSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* markConsultationAsDone({counsellingId, prescriptionId}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(markConsultationAsDoneApi, prescriptionId, counsellingId);

    if(!response.error) {
      yield put(fetchSuccess(true));
      // yield put(markConsultationAsDoneSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_CONSULTATION_BY_ID',fetchConsultationById),
  takeLatest('FETCH_CONSULTATION_BY_ID_AND_PRESCRIPTION_ID',fetchConsultationByIdAndPrescriptionId),
  takeLatest('UPDATE_CONSULTATION_STOCK_STATUS', updateConsultationStockReceivedStatus),
  takeLatest('FETCH_CONSULTATION_LIST',fetchConsultationList),
  takeLatest('SUBMIT_CONSULTATION',submitConsultation),
  takeLatest('UPDATE_CONSULTATION',updateConsultation),
  takeLatest('DELETE_CONSULTATION',deleteConsultation),
  takeLatest('FOLLOW_UP_AGAIN', followUpAgain),
  takeLatest('MARK_AS_DONE',markConsultationAsDone)
];
