import React  from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import { errorPagesConfigs } from './errorPages';
import { authRouteConfig } from './auth';
// import { initialUrl } from '../shared/constants/AppConst';
import { dashboardRouteConfig } from './dashboard';
import { notificationRouteConfig } from './notifications';
import { pharmacyRouteConfig } from './pharmacies';
import { siteRouteConfig } from './sites';
import { sponsorRouteConfig } from './sponsors';
import { trialRouteConfig } from './trials';
import { courierRouteConfig } from './couriers';
import { pricingRouteConfig } from "./pricing";
import { userRouteConfig } from "./users";
import { consultationRouteConfig } from "./consultations";
import { reportsRouteConfig } from "./reports";
import { despensingRouteConfig } from "./dispensing";
import { prescriptionRouteConfig} from "./prescriptions";
import { stocktakeRouteConfig } from "./stocktake";
import { inventoryRouteConfig } from "./inventory";
import { returnInventoryRouteConfig } from "./returnInventory";
import { manifestRouteConfig } from "./manifest";
import { siteShippingRouteConfig } from './siteShipping';

const routeConfigs = [
  ...authRouteConfig,
  ...errorPagesConfigs,
  ...dashboardRouteConfig,
  ...notificationRouteConfig,
  ...pharmacyRouteConfig,
  ...siteRouteConfig,
  ...sponsorRouteConfig,
  ...trialRouteConfig,
  ...courierRouteConfig,
  ...pricingRouteConfig,
  ...userRouteConfig,
  ...consultationRouteConfig,
  ...reportsRouteConfig,
  ...despensingRouteConfig,
  ...prescriptionRouteConfig,
  ...stocktakeRouteConfig,
  ...inventoryRouteConfig,
  ...returnInventoryRouteConfig,
  ...manifestRouteConfig,
  ...siteShippingRouteConfig
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    // component: () => <Redirect to={initialUrl} />,
    component: () => {
      let url = '/'
      const auth = localStorage.getItem('auth-user');
      const role = auth ? JSON.parse(auth)?.roles[0] : '';

      if(role){
        if(role === 'Admin' || role === 'Sponsor' || role === "Sponsor Representative") url = '/dashboard'
        else if(role === 'Site') url = '/dashboard'
        else if(role === 'Pharmacists' || role === 'Pharmacy Assistants' || role === 'Warehouse' || role === 'Warehouse Assistant') url = '/trials'
        else if(role === 'Quality Associate QA' || role === 'Monitor') url = '/trials'
      }
      // console.log(url)
      return <Redirect to={url} />
    },
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
