import React from "react";

export const returnInventoryRouteConfig = [
  {
    auth: ["Admin excluded Pharmacy only", "Pharmacists", "Pharmacy Assistants", "Warehouse", "Warehouse Assistant"],
    routes: [
      {
        path: '/returnsInventory',
        exact: true,
        component: React.lazy(() => import('./ReturnInventoryComponent')),
      },
      {
        path: '/returnsInventory/:id',
        exact: true,
        component: React.lazy(() => import('./ReturnInventoryDetails')),
      },
    ],
  },
];
