import { KVP } from "../models/Common";

// action strings
export const FETCH_START = 'FETCH_START';
export const FETCH_STOP = 'FETCH_STOP';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const RESET_PROMISE_COMPLETE = 'RESET_PROMISE_COMPLETE';
export const FETCH_KVP = 'FETCH_KVP';
export const FETCH_KVP_SUCCEEDED = 'FETCH_KVP_FROM_USERS_SUCCEEDED' || 'FETCH_KVP_FROM_TRIALS_SUCCEEDED';
export const FETCH_KVP_FROM_USERS_SUCCEEDED = 'FETCH_KVP_FROM_USERS_SUCCEEDED';
export const FETCH_KVP_FROM_TRIALS_SUCCEEDED = 'FETCH_KVP_FROM_TRIALS_SUCCEEDED';
export const FETCH_KVP_FROM_INVENTORY_SUCCEEDED = 'FETCH_KVP_FROM_INVENTORY_SUCCEEDED';
export const SET_CRUD = 'SET_CRUD';
export const RESET_CRUD = 'RESET_CRUD';
export const RESET_ERROR = 'RESET_ERROR';

export interface FetchStartAction {
  type: typeof FETCH_START;
  backdropLoader: boolean;
}
export interface FetchStopAction {
  type: typeof FETCH_STOP;
}

export interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
  promiseComplete: boolean;
}

export interface FetchErrorAction {
  type: typeof FETCH_ERROR;
  error: string;
}

export interface ShowMessageAction {
  type: typeof SHOW_MESSAGE;
  message: string;
}

export interface ToggleDrawerAction {
  type: typeof TOGGLE_APP_DRAWER;
}

export interface HideMessageAction {
  type: typeof HIDE_MESSAGE;
}

export interface ResetPromiseCompleteAction {
  type: typeof RESET_PROMISE_COMPLETE
}

export interface FetchKVPAction {
  type: typeof FETCH_KVP,
  typeofKVP: string,
  searchValue: string
  component: string;
}

export interface FetchKVPSuccededAction {
  type: typeof FETCH_KVP_SUCCEEDED
  payload: KVP[],
  typeofKVP: string;
  component: string;
}

export interface SetCrudAction {
  type: typeof SET_CRUD,
  crudAction: string;
}

export interface ResetCrudAction {
  type: typeof RESET_CRUD
}

export interface ResetErrorAction {
  type: typeof RESET_ERROR
}

export type CommonActionTypes =
  | FetchStartAction
  | FetchStopAction
  | FetchSuccessAction
  | FetchErrorAction
  | ShowMessageAction
  | ToggleDrawerAction
  | HideMessageAction
  | ResetPromiseCompleteAction
  | FetchKVPAction
  | FetchKVPSuccededAction
  | SetCrudAction
  | ResetCrudAction
  | ResetErrorAction
  ;

