import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import AppHeader from './AppHeader';
import {ContentView} from '../../../index';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppFooter from './AppFooter';
import AppFixedFooter from './AppFixedFooter';
import AppContext from '../../../utility/AppContext';
// import AppSidebar from './AppSidebar';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';

interface HorDarkLayoutProps {
  props?: any;
}

const HorDarkLayout: React.FC<HorDarkLayoutProps> = (props) => {
  const classes = useStyles(props);
  const location:any = useLocation();
  const {footer, layoutType, footerType} = useContext<AppContextPropsType>(
    AppContext,
  );
  // NOTE Current header used

  let nospacing = false;
  const paths = location.pathname.split('/');

  if(paths.includes('trials') && paths.includes('create')) {
    nospacing = true;
  } else {
    // const state = ['trialName', 'activeTab'];
    nospacing = (location.state && 
      (Object.keys(location.state).includes('trialName') ||  Object.keys(location.state).includes('activeTab')))
      || false;
  }


  return (
    <Box
      className={clsx(
        classes.appMain,
        'appMainHor',
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppHeader />
      <Box className={classes.mainContent}>
        {/* <AppSidebar /> */}
        <Box className={clsx(classes.mainContainer, nospacing ? 'nospacing' : '')}>
          <ContentView>{props.children}</ContentView>
        </Box>
      </Box>
      <AppFooter />
      <AppFixedFooter />
    </Box>
  );
};

export default HorDarkLayout;
