import { Courier } from "../../types/models/Couriers";
import { 
  CourierActionsTypes,
  FETCH_COURIER_LIST_SUCCEEDED,
  SUBMIT_COURIER_SUCCEEDED,
  SET_SELECTED_COURIER,
  REMOVE_SELECTED_COURIER,
  UPDATE_COURIER_SUCCEEDED,
  DELETE_COURIER_SUCCEEDED,
  RESET_COURIER,
  FETCH_AVAILABLE_ROUTES,
  FETCH_AVAILABLE_ROUTES_SUCCEEDED,
  FETCH_PHARMACY_ADDRESS_SUCCEEDED,
  CREATE_API_CONSIGNMENT_SUCCEEDED,
  SAVE_MANUAL_CONSIGNMENT_SUCCEEDED,
  FETCH_COURIER_KVP_LIST_SUCCEEDED,
  CONSIGNMENT_COMPLETED,
  RESET_MANUAL_CONSIGNMENT
} from "../../types/actions/Couriers.action";

const initialState: Courier = {
  courierList: [],
  selectedCourier: null,
  currentPage: 0,
  pageSize: 25,
  totalPages: 0,
  totalResults: 0,
  fetchingRoutes: false,
  availableAddresses: [],
  availableRoutesList: [],
  courierKvpList: [],
  consignmentDetails: null,
  consignmentCompleted: false
}

export default (state = initialState, action: CourierActionsTypes): Courier => {
  switch (action.type) {
    case FETCH_COURIER_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const courierList = append ? [...state.courierList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        courierList
      }
    }
    case SUBMIT_COURIER_SUCCEEDED: {
      const { payload } = action
      const courierList = [...state.courierList, payload];
      const totalResults:number = state.totalResults + 1;
      return {
        ...state,
        courierList,
        totalResults
      }
    }
    case UPDATE_COURIER_SUCCEEDED: {
      const {id, payload} = action;
      const courierList = [...state.courierList].map(list => {
        if(list.id === id) {
          list = {...list, ...payload}
        }
        return list
      })
      return {
        ...state,
        courierList
      }
    }
    case DELETE_COURIER_SUCCEEDED: {
      const { id } = action;
      const courierList = [...state.courierList].filter(list => list.id !== id);
      const totalResults:number = state.totalResults - 1;
      return {
        ...state,
        courierList,
        totalResults
      }
    }
    case SET_SELECTED_COURIER: {
      const { id } =  action;
      const selectedCourier = [...state.courierList].find((list:any) => list.id === id) || null;
      return{
        ...state,
        selectedCourier
      }
    }
    case REMOVE_SELECTED_COURIER: {
      return {
        ...state,
        selectedCourier: null
      }
    }
    case RESET_COURIER: {
      return {
        ...state,
        ...initialState
      }
    }
    case FETCH_PHARMACY_ADDRESS_SUCCEEDED: {
      const { payload} = action;
      const {pagedData, ...rest} = payload;
      const availableAddresses = pagedData;
      return {
        ...state,
        availableAddresses,
        ...rest,
      }
    }
    case FETCH_AVAILABLE_ROUTES:{
      return {
        ...state,
        fetchingRoutes: true
      }
    }
    case FETCH_AVAILABLE_ROUTES_SUCCEEDED: {
      const { payload} = action;
      const availableRoutesList = payload;
      return {
        ...state,
        availableRoutesList,
        fetchingRoutes: false
      }
    }
    case FETCH_COURIER_KVP_LIST_SUCCEEDED: {
      const { append, payload} = action;
      const {pagedData, ...rest} = payload;
      const lists = pagedData;
      const courierKvpList = append ? [...state.courierKvpList, ...lists] : lists;
      return {
        ...state,
        ...rest,
        courierKvpList
      }
    }
    case CREATE_API_CONSIGNMENT_SUCCEEDED: {
      const { payload} = action;
      return {
        ...state,
        consignmentDetails: payload
      }
    }
    case CONSIGNMENT_COMPLETED:
    case SAVE_MANUAL_CONSIGNMENT_SUCCEEDED: {
      // const { payload} = action;
      // console.log(payload)
      return {
        ...state,
        consignmentCompleted: true
      }
    }
    case RESET_MANUAL_CONSIGNMENT: {
      return {
        ...state,
        consignmentCompleted: false
      }
    }
    default:{
      return state;
    }
  }
}