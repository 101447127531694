import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { 
  fetchTrialListSucceeded, 
  submitTrialSucceeded, 
  updateTrialSucceeded, 
  deleteTrialSucceeded, 
  fetchTrialByIdSucceeded,
  fetch_KVP_Sponsor_Succeeded,
  fetch_KVP_QA_Succeeded,
  fetch_KVP_Monitor_Succeeded,
  fetchRepeatsSucceeded,
  submitRepeatsSucceeded,
  fetchConsultationsSucceeded,
  submitConsultationsSucceeded,
  fetchReturnsInventorySucceeded,
  submitReturnsInventorySucceeded,
  validateEmergencyUnblindingSucceeded,
  fetchEmergencyUnblindingSucceeded,
  submitEmergencyUnblindingSucceeded,
  fetchTrialPricingSucceeded,
  submitTrialPricingSucceeded,
  fetchTrialSitesSucceeded,
  submitTrialSitesSucceeded,
  fetchTrialSitesSummarySucceeded,
  fetchDrugListSucceeded,
  addDrugSucceeded,
  updateDrugSucceeded,
  deleteDrugSucceeded,
  fetchTreatmentLabelListSucceeded,
  addTreatmentLabelSucceeded,
  updateTreatmentLabelSucceeded,
  deleteTreatmentLabelSucceeded,
  fetchTreatmentArmListSucceeded,
  addTreatmentArmSucceeded,
  updateTreatmentArmSucceeded,
  deleteTreatmentArmSucceeded,
  fetchTreatmentArmSummarySucceeded,
  fetchTrialLabelSucceeded,
  fetchTrialLabelSizeSucceeded,
  addTrialLabelSucceeded,
  // fetchUserListSucceeded,
  // updateUserListSucceeded,
  fetchUsersSucceeded,
  fetchTrialUserListSucceeded,
  fetchTrialPatientUserListSucceeded,
  submitTrialUserSucceeded,
  fetchTrialFileSucceeded,
  submitTrialFileSucceeded,
  deleteTrialFileSucceeded,
  // downloadTrialFileSucceeded,
  updateTrialProgressSucceeded,
  fetchPackageOptionsSucceeded,
  fetchShippingSucceeded,
  submitShippingSucceeded,
  fetchStratificationSucceeded,
  submitStratificationSucceeded,
  fetchRandomisationEntriesSucceeded,
  fetchAllRandomisationEntriesSucceeded,
  fetchRandomisationSucceeded,
  submitRandomisationSucceeded,
  fetchQARequestSucceeded,
  fetchQASucceeded,
  submitQASucceeded,
  rejectQASucceeded,
  approveQASucceeded,
  fetchTrialSummarySucceeded,
  submitTrialQASucceeded,
  fetchCoordinatorKvpSucceeded,
  fetchInvestigatorKvpSucceeded,
  submitBulkOrderSucceeded,
  searchTrialRandomisationSucceeded,
  updateTrialRandomisationSucceeded,
  fetchSiteforBulkOrderSucceeded,
  updateMultiTrialProgressSucceeded,
  fetchAvailableInventoriesSucceeded,
  submitTrialArchiveSucceeded,
  fetchTrialArchiveReportsSucceeded
} from "../actions/Trials";

const baseUrlv1 = `api/v1`;

function fetchTrialListByIdApi(id:number) {
  const url = `${baseUrlv1}/Trials/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchTrialListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, primarySponsor, secondarySponsor } = payload;
  let url = `${baseUrlv1}/Trials?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;

  if(primarySponsor) url = `${url}&PrimarySponsorId=${primarySponsor}`
  if(secondarySponsor) url = `${url}&SecondarySponsorId=${secondarySponsor}`
  
  return Api.fetch(url, getGetParams());
}

function submitTrialApi (payload:any) {
  const url = `${baseUrlv1}/Trials`;
  return Api.fetch(url, getPostParams(payload))
}

function updateTrialApi(payload:any) {
  const url = `${baseUrlv1}/Trials`;
  return Api.fetch(url, getPutParams(payload))
} 

function deleteTrialApi(id:number) {
  const url = `${baseUrlv1}/Trials/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchKVPSponsorApi(searchValue:string) {
  const url = `${baseUrlv1}/Sponsor/kvp?searchTerm=${searchValue}`;
  return Api.fetch(url, getGetParams());
}

function fetchKVPQAApi(searchValue:string) {
  const url = `${baseUrlv1}/QualityAssociates/kvp?searchTerm=${searchValue}`;
  return Api.fetch(url, getGetParams());
}

function fetchKVPMonitorApi(searchValue:string) {
  const url = `${baseUrlv1}/TrialMonitors/kvp?searchTerm=${searchValue}`;
  return Api.fetch(url, getGetParams());
}

function fetchRepeatsApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/repeats`;
  return Api.fetch(url, getGetParams());
}

function submitRepeatsApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/repeats`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchConsultationsApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/consultation`;
  return Api.fetch(url, getGetParams());
}

function submitConsultationsApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/consultation`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchReturnsInventoryApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/returnsInventory`;
  return Api.fetch(url, getGetParams());
}

function submitReturnsInventoryApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/returnsInventory`;
  return Api.fetch(url, getPostParams(payload));
}

function validateEmergencyUnblindingApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/emergencyUnblinding/hasAccess`;
  return Api.fetch(url, getGetParams(), 'text');
}

function fetchEmergencyUnblindingApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/emergencyUnblinding`;
  return Api.fetch(url, getGetParams());
}

function submitEmergencyUnblindingApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/emergencyUnblinding`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchTrialPricingApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/pricing`;
  return Api.fetch(url, getGetParams());
}

function submitTrialPricingApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/pricing`;
  return Api.fetch(url,  getPostParams(payload));
}

function fetchTrialSitesApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/sites`;
  return Api.fetch(url, getGetParams());
}

function submitTrialSitesApi(id: string, payload:any) {
  const url = `${baseUrlv1}/trials/${id}/sites`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchTrialSitesSummaryApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/sites/summary`;
  return Api.fetch(url, getGetParams());
}

function fetchDrugListApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/drugs`;
  return Api.fetch(url, getGetParams());
}

function addDrugApi (payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/drugs`;
  return Api.fetch(url, getPostParams(payload))
}

function updateDrugApi (payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/drugs`;
  return Api.fetch(url, getPutParams(payload))
}

function deleteDrugApi (trialId:string, id:string) {
  const url = `${baseUrlv1}/trials/${trialId}/drugs/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchTreatmentLabelListApi(trialId:string) {
  const url = `${baseUrlv1}/trials/${trialId}/treatments`;
  return Api.fetch(url, getGetParams());
}

function addTreatmentLabelApi (payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/treatments`;
  return Api.fetch(url, getPostParams(payload))
}

function updateTreatmentLabelApi (payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/treatments`;
  return Api.fetch(url, getPutParams(payload))
}

function deleteTreatmentLabelApi (trialId: string, id:string) {
  const url = `${baseUrlv1}/trials/${trialId}/treatments/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchTreatmentArmListApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/treatmentArms`;
  return Api.fetch(url, getGetParams());
}

function addTreatmentArmApi (payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/treatmentArms`;
  return Api.fetch(url, getPostParams(payload))
}

function updateTreatmentArmApi (payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/treatmentArms`;
  return Api.fetch(url, getPutParams(payload))
}

function deleteTreatmentArmApi (trialId:string, id:string) {
  const url = `${baseUrlv1}/trials/${trialId}/treatmentArms/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchTreatmentArmSummaryApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/treatmentArms/summary`;
  return Api.fetch(url, getGetParams());
}

function fetchTrialLabelApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/labels`;
  return Api.fetch(url, getGetParams());
}

function fetchTrialLabelSizeApi() {
  const url = `${baseUrlv1}/trials/labelsizes`;
  return Api.fetch(url, getGetParams());
}

function addTrialLabelApi (id:string, payload:any) {
  const url = `${baseUrlv1}/trials/${id}/labels`;
  return Api.fetch(url, getPostParams(payload))
}

function fetchUsersApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/users/getAssignableUsers`;
  return Api.fetch(url, getGetParams());
}

function fetchTrialUserListApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/users`;
  return Api.fetch(url, getGetParams());
}

function fetchTrialPatientUserListApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/getpatientusers`;
  return Api.fetch(url, getGetParams());
}

function submitTrialUsertApi (trialId:string, payload:any) {
  const url = `${baseUrlv1}/trials/${trialId}/users`;
  return Api.fetch(url, getPostParams(payload))
}

function fetchTrialFileApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/files`;
  return Api.fetch(url, getGetParams());
}

function submitTrialFileApi(id:string, payload:any) {
  const formUpload = true;
  const url = `${baseUrlv1}/trials/${id}/files`;
  return Api.fetch(url, getPostParams(payload, formUpload))
}

function deleteTrialFileApi(id:string, fileId:string) {
  const url = `${baseUrlv1}/trials/${id}/files/delete/${fileId}`;
  return Api.fetch(url, getDeleteParams());
}

function downloadTrialFileApi(id:string, fileId:string) {
  const url = `${baseUrlv1}/trials/${id}/files/download/${fileId}`;
  return Api.fetch(url, getGetParams(), 'text');
}

function updateTrialProgressApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.id}/progress`;
  return Api.fetch(url, getPostParams(payload))
}

function fetchPackageOptionsApi(courierId:string) {
  const url = `${baseUrlv1}/shipping/packageOptions?courierId=${courierId}`;
  return Api.fetch(url, getGetParams());
}

function fetchShippingApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/shipping`;
  return Api.fetch(url, getGetParams());
}

function submitShippingApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/shipping`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchStratificationApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/stratifications`;
  return Api.fetch(url, getGetParams());
}

function submitStratificationApi(id:string, payload:any) {
  const url = `${baseUrlv1}/trials/${id}/stratifications`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchRandomisationEntriesApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm, id } = payload;
  const url = `${baseUrlv1}/trials/${id}/randomisationNumbers?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function fetchAllRandomisationEntriesApi(payload:any) {
  const { id } = payload;
  const url = `${baseUrlv1}/trials/${id}/downloadrandomisationNumbers`;
  return Api.fetch(url, getGetParams());
}

function fetchRandomisationApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/randomisation`;
  return Api.fetch(url, getGetParams());
}

function submitRandomisationApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/randomisationEntries`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchQARequestApi(objectType: number, trialId: string) {
  const url = `${baseUrlv1}/qa/${objectType}/${trialId}`;
  return Api.fetch(url, getGetParams());
}

function fetchQAApi(payload:any) {
  const url = `${baseUrlv1}/qa/`;
  return Api.fetch(url, getGetParams());
}

function submitQAApi(id:string, payload:any) {
  const url = `${baseUrlv1}/trials/${id}/qa`;
  return Api.fetch(url, getPostParams(payload));
}

export function rejectQAApi(payload:any) {
  const url = `${baseUrlv1}/qa/reject`;
  return Api.fetch(url, getPostParams(payload));
}

export function approveQAApi(id:string) {
  const url = `${baseUrlv1}/qa/approve/${id}`;
  return Api.fetch(url, getPostParams({id}));
}

function fetchTrialSummaryApi(id:string) {
  const url = `${baseUrlv1}/trials/${id}/summary`;
  return Api.fetch(url, getGetParams());
}

function submitTrialQAApi(payload: any){
  const url = `${baseUrlv1}/trials/${payload.id}/qa/submit`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchCoordinatorKvpApi(siteId: string, trialId: string) {
  const url = `${baseUrlv1}/user/coordinatorkvp?siteId=${siteId}&trialId=${trialId}`;
  return Api.fetch(url, getGetParams());
}

function fetchInvestigatorKvpApi(siteId: string, trialId: string) {
  const url = `${baseUrlv1}/user/investigatorkvp?siteId=${siteId}&trialId=${trialId}`;
  return Api.fetch(url, getGetParams());
}

function submitBulkOrderApi(payload:any) {
  const url = `${baseUrlv1}/bulkorders`;
  return Api.fetch(url, getPostParams(payload));
}

function searchTrialRandomisationApi(payload:any) {
  const { trialId, searchTerm } = payload;
  let url = `${baseUrlv1}/randomisation/search/${trialId}`;
  if(searchTerm.length > 0) {
    url = `${url}/${searchTerm}`
  }
  return Api.fetch(url, getGetParams());
}

function updateTrialRandomisationApi(id:string, payload:any) {
  const url = `${baseUrlv1}/randomisation/put/${id}`;
  return Api.fetch(url, getPutParams(payload));
}

function fetchSiteforBulkOrderApi(id:string){
  const url = `${baseUrlv1}/trials/${id}/pharmacysites`;
  return Api.fetch(url, getGetParams());
}

function updateMultiTrialProgressApi(id:string, payload:any) {
  const url = `${baseUrlv1}/trials/${id}/progresses`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchAvailableInventoriesApi(trialId:string, treatmentId:string, siteId: string, stratificationId:string) {
  const url = `${baseUrlv1}/inventory/GetAvailabelInventories?trialId=${trialId}&treatmentId=${treatmentId}&siteId=${siteId}&stratificationId=${stratificationId}`;
  return Api.fetch(url, getGetParams());
}

function fetchTrialArchiveReportsApi(id: string) {
  const url = `${baseUrlv1}/trials/reports/archivalReports/${id}`;
  return Api.fetch(url, getGetParams());
}

function submitTrialArchiveApi(id: string) {
  const url = `${baseUrlv1}/trials/${id}/archive`;
  return Api.fetch(url, getPutParams({trialId: id}), 'text');
}


export function* fetchTrialById({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialListByIdApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No Trial found"
      } else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchTrialList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchTrialListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchTrialListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitTrial({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitTrialApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitTrialSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateTrial({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    let response  = yield call(updateTrialApi, {...payload, id});
    if(payload.trialStatus === 3) response = {...response, trialStatus: 3}
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateTrialSucceeded(id, response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteTrial({id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deleteTrialApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteTrialSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchKVPSponsor({ searchValue }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchKVPSponsorApi, searchValue);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetch_KVP_Sponsor_Succeeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchKVPQA({ searchValue }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchKVPQAApi, searchValue);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetch_KVP_QA_Succeeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchKVPMonitor({ searchValue }:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchKVPMonitorApi, searchValue);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetch_KVP_Monitor_Succeeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchRepeats({id}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(fetchRepeatsApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchRepeatsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitRepeats({payload}:any) {
  yield put(fetchStart());
  try {
    const response = yield call(submitRepeatsApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitRepeatsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchConsultations({id}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(fetchConsultationsApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchConsultationsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitConsultations({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitConsultationsApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitConsultationsSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchReturnsInventory({id}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(fetchReturnsInventoryApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchReturnsInventorySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitReturnsInventory({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitReturnsInventoryApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitReturnsInventorySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchEmergencyUnblinding({id}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(fetchEmergencyUnblindingApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchEmergencyUnblindingSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* validateEmergencyUnblinding({id}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(validateEmergencyUnblindingApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(validateEmergencyUnblindingSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitEmergencyUnblinding({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitEmergencyUnblindingApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitEmergencyUnblindingSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchTrialPricing({id}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(fetchTrialPricingApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialPricingSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitTrialPricing({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitTrialPricingApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitTrialPricingSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchTrialSites({id}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(fetchTrialSitesApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialSitesSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* submitTrialSites({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(submitTrialSitesApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitTrialSitesSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchTrialSitesSummary({id}:any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(fetchTrialSitesSummaryApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialSitesSummarySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}


export function* fetchDrugList({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchDrugListApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchDrugListSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* addDrug({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(addDrugApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(addDrugSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateDrug({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateDrugApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateDrugSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* deleteDrug({trialId, id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteDrugApi, trialId, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteDrugSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchTreatmentLabelList({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTreatmentLabelListApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTreatmentLabelListSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* addTreatmentLabel({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(addTreatmentLabelApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(addTreatmentLabelSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* updateTreatmentLabel({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateTreatmentLabelApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateTreatmentLabelSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* deleteTreatmentLabel({trialId, id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteTreatmentLabelApi, trialId, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteTreatmentLabelSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchTreatmentArmList({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTreatmentArmListApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTreatmentArmListSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* addTreatmentArm({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(addTreatmentArmApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(addTreatmentArmSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* updateTreatmentArm({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateTreatmentArmApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateTreatmentArmSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* deleteTreatmentArm({trialId, id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteTreatmentArmApi, trialId, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteTreatmentArmSucceeded(id))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchTreatmentArmSummary({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTreatmentArmSummaryApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchTreatmentArmSummarySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchTrialLabel({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialLabelApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialLabelSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchTrialLabelSize() {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialLabelSizeApi);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialLabelSizeSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}



export function* addTrialLabel({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(addTrialLabelApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(addTrialLabelSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchUsers({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchUsersApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchUsersSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchTrialUserList({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialUserListApi, id); 
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialUserListSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchTrialPatientUserList({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialPatientUserListApi, id); 
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialPatientUserListSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitTrialUser({trialId, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitTrialUsertApi, trialId, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitTrialUserSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message));
  }
}

export function* fetchTrialFile({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialFileApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchTrialFileSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitTrialFile({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitTrialFileApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitTrialFileSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deleteTrialFile({id, fileId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(deleteTrialFileApi, id, fileId);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(deleteTrialFileSucceeded(fileId))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* downloadTrialFile({id, fileId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(downloadTrialFileApi, id, fileId);
    if(!response.error) {
      yield put(fetchSuccess());
      // yield put(downloadTrialFileSucceeded(response));
      window.open(response, "_blank");
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateTrialProgress({payload}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(updateTrialProgressApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(updateTrialProgressSucceeded(payload));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchPackageOptions({courierId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchPackageOptionsApi, courierId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchPackageOptionsSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchShipping({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchShippingApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchShippingSucceeded(response)); // TODO verify it later
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitShipping({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitShippingApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitShippingSucceeded(response)); // TODO verify it later
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchStratification({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchStratificationApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchStratificationSucceeded(Object.keys(response).length === 0 ? [] : response)); // TODO verify it later
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitStratification({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitStratificationApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitStratificationSucceeded(response)); // TODO verify it later
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchRandomisationEntries({payload}:any) {
  yield put(fetchStart(true));
  try {
    const { append} = payload;
    const response  = yield call(fetchRandomisationEntriesApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchRandomisationEntriesSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  }
  catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchAllRandomisationEntries({payload}:any) {
  yield put(fetchStart(true));
  try {
    const { append} = payload;
    const response  = yield call(fetchAllRandomisationEntriesApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchAllRandomisationEntriesSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  }
  catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchRandomisation({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchRandomisationApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchRandomisationSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitRandomisation({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitRandomisationApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitRandomisationSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchQARequest({objectType, trialId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchQARequestApi, objectType, trialId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchQARequestSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchQA({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchQAApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchQASucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitQA({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitQAApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(submitQASucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* rejectQA({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(rejectQAApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(rejectQASucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* approveQA({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(approveQAApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(approveQASucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchTrialSummary({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialSummaryApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialSummarySucceeded(response));
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No Trial found"
      } else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitTrialQA({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitTrialQAApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(submitTrialQASucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchCoordinatorKvp({siteId, trialId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchCoordinatorKvpApi, siteId, trialId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchCoordinatorKvpSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchInvestigatorKvp({siteId, trialId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchInvestigatorKvpApi, siteId, trialId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchInvestigatorKvpSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitBulkOrder({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitBulkOrderApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitBulkOrderSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* searchTrialRandomisation({payload}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(searchTrialRandomisationApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(searchTrialRandomisationSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateTrialRandomisation({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateTrialRandomisationApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateTrialRandomisationSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchSiteforBulkOrder({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchSiteforBulkOrderApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchSiteforBulkOrderSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updateMultiTrialProgress({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updateMultiTrialProgressApi, id, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updateMultiTrialProgressSucceeded(payload));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchAvailableInventories({trialId, treatmentId, siteId, stratificationId}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchAvailableInventoriesApi, trialId, treatmentId, siteId, stratificationId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchAvailableInventoriesSucceeded(treatmentId, response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}


export function* fetchTrialArchiveReports({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchTrialArchiveReportsApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchTrialArchiveReportsSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitTrialArchive({id}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitTrialArchiveApi, id);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitTrialArchiveSucceeded(response));
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_TRIAL_BY_ID' ,fetchTrialById),
  takeLatest('FETCH_TRIAL_LIST' ,fetchTrialList),
  takeLatest('SUBMIT_TRIAL' ,submitTrial),
  takeLatest('UPDATE_TRIAL' ,updateTrial),
  takeLatest('DELETE_TRIAL' ,deleteTrial),
  takeLatest('FETCH_KVP_SPONSOR' ,fetchKVPSponsor),
  takeLatest('FETCH_KVP_QA' ,fetchKVPQA),
  takeLatest('FETCH_KVP_MONITOR' ,fetchKVPMonitor),
  takeLatest('FETCH_REPEATS', fetchRepeats),
  takeLatest('SUBMIT_REPEATS', submitRepeats),
  takeLatest('FETCH_CONSULTATIONS', fetchConsultations),
  takeLatest('SUBMIT_CONSULTATIONS', submitConsultations),
  takeLatest('FETCH_RETURNS_INVENTORY', fetchReturnsInventory),
  takeLatest('SUBMIT_RETURNS_INVENTORY', submitReturnsInventory),
  takeLatest('VALIDATE_EMERGENCY_UNBLINDING', validateEmergencyUnblinding),
  takeLatest('FETCH_EMERGENCY_UNBLINDING', fetchEmergencyUnblinding),
  takeLatest('SUBMIT_EMERGENCY_UNBLINDING', submitEmergencyUnblinding),
  takeLatest('FETCH_TRIAL_PRICING', fetchTrialPricing),
  takeLatest('SUBMIT_TRIAL_PRICING', submitTrialPricing),
  takeLatest('FETCH_TRIAL_SITES', fetchTrialSites),
  takeLatest('SUBMIT_TRIAL_SITES', submitTrialSites),
  takeLatest('FETCH_TRIAL_SITES_SUMMARY', fetchTrialSitesSummary),
  takeLatest('FETCH_DRUG_LIST', fetchDrugList),
  takeLatest('ADD_DRUG', addDrug),
  takeLatest('UPDATE_DRUG', updateDrug),
  takeLatest('DELETE_DRUG', deleteDrug),
  takeLatest('FETCH_TREATMENTLABEL_LIST', fetchTreatmentLabelList),
  takeLatest('ADD_TREATMENTLABEL', addTreatmentLabel),
  takeLatest('UPDATE_TREATMENTLABEL', updateTreatmentLabel),
  takeLatest('DELETE_TREATMENTLABEL', deleteTreatmentLabel),
  takeLatest('FETCH_TREATMENTARM_LIST', fetchTreatmentArmList),
  takeLatest('ADD_TREATMENTARM', addTreatmentArm),
  takeLatest('UPDATE_TREATMENTARM', updateTreatmentArm),
  takeLatest('DELETE_TREATMENTARM', deleteTreatmentArm),
  takeLatest('FETCH_TREATMENT_ARM_SUMMARY', fetchTreatmentArmSummary),
  takeLatest('FETCH_TRIALLABEL', fetchTrialLabel),
  takeLatest('FETCH_TRIALLABEL_SIZE', fetchTrialLabelSize),
  takeLatest('ADD_TRIALLABEL', addTrialLabel),
  takeLatest('FETCH_USERS', fetchUsers),
  takeLatest('FETCH_TRIAL_USERS_LIST', fetchTrialUserList),
  takeLatest('FETCH_TRIAL_PATIENT_USERS_LIST', fetchTrialPatientUserList),
  takeLatest('SUBMIT_TRIAL_USER', submitTrialUser),
  takeLatest('FETCH_TRIAL_FILE', fetchTrialFile),
  takeLatest('SUBMIT_TRIAL_FILE', submitTrialFile),
  takeLatest('DELETE_TRIAL_FILE', deleteTrialFile),
  takeLatest('DOWNLOAD_TRIAL_FILE', downloadTrialFile),
  takeLatest('UPDATE_TRIAL_PROGRESS', updateTrialProgress),
  takeLatest('FETCH_PACKAGE_OPTIONS', fetchPackageOptions),
  takeLatest('FETCH_SHIPPING', fetchShipping),
  takeLatest('SUBMIT_SHIPPING', submitShipping),
  takeLatest('FETCH_STRATIFICATION', fetchStratification),
  takeLatest('SUBMIT_STRATIFICATION', submitStratification),
  takeLatest('FETCH_RANDOMISATION_ENTRIES', fetchRandomisationEntries),
  takeLatest('FETCH_ALL_RANDOMISATION_ENTRIES', fetchAllRandomisationEntries),
  takeLatest('FETCH_RANDOMISATION', fetchRandomisation),
  takeLatest('SUBMIT_RANDOMISATION', submitRandomisation),
  takeLatest('FETCH_QA_REQUEST', fetchQARequest),
  takeLatest('FETCH_QA', fetchQA),
  takeLatest('SUBMIT_QA', submitQA),
  takeLatest('REJECT_QA', rejectQA),
  takeLatest('APPROVE_QA', approveQA),
  takeLatest('FETCH_TRIAL_SUMMARY', fetchTrialSummary),
  takeLatest('SUBMIT_TRIAL_QA', submitTrialQA),
  takeLatest('FETCH_COORDINATOR_KVP', fetchCoordinatorKvp),
  takeLatest('FETCH_INVESTIGATOR_KVP', fetchInvestigatorKvp),
  takeLatest('SUBMIT_BULK_ORDER', submitBulkOrder),
  takeLatest('SEARCH_TRIAL_RANDOMISATION', searchTrialRandomisation),
  takeLatest('UPDATE_TRIAL_RANDOMISATION', updateTrialRandomisation),
  takeLatest('FETCH_SITE_FOR_BULKORDER', fetchSiteforBulkOrder),
  takeLatest('UPDATE_MULTI_TRIAL_PROGRESS', updateMultiTrialProgress),
  takeLatest('FETCH_AVAILABLE_INVENTORIES', fetchAvailableInventories),
  takeLatest('FETCH_TRIAL_ARCHIVE_REPORTS', fetchTrialArchiveReports),
  takeLatest('SUBMIT_TRIAL_ARCHIVE', submitTrialArchive),
]