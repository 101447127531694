import { DefaultPricing } from "../models/Pricing";

export const FETCH_PRICING = "FETCH_PRICING";
export const SUBMIT_PRICING = "SUBMIT_PRICING";
export const FETCH_PRICING_SUCCEEDED = "FETCH_PRICING_SUCCEEDED";
export const SUBMIT_PRICING_SUCCEEDED = "SUBMIT_PRICING_SUCCEEDED";

export interface FetchPricingActions {
  type: typeof FETCH_PRICING
}
export interface FetchPricingSucceededActions {
  type: typeof FETCH_PRICING_SUCCEEDED,
  payload: DefaultPricing;
}

export interface SubmitPricingActions {
  type: typeof SUBMIT_PRICING,
  payload: DefaultPricing
}

export interface SubmitPricingSucceededActions {
  type: typeof SUBMIT_PRICING_SUCCEEDED,
  payload: DefaultPricing
}

export type PricingActionTypes = 
  | FetchPricingActions
  | SubmitPricingActions
  | FetchPricingSucceededActions
  | SubmitPricingSucceededActions
  ;