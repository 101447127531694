import { SponsorList, Payload } from "../models/Sponsors";
import { GetQueryParams } from "../models/Common";

export const FETCH_SPONSOR_LIST = "FETCH_SPONSOR_LIST";
export const SUBMIT_SPONSOR = "SUBMIT_SPONSOR";
export const UPDATE_SPONSOR = "UPDATE_SPONSOR";
export const DELETE_SPONSOR = "DELETE_SPONSOR";
export const RESTORE_SPONSOR = "RESTORE_SPONSOR";
export const FETCH_SPONSOR_BY_ID = "FETCH_SPONSOR_BY_ID";
export const SET_SELECTED_SPONSOR = "SET_SELECTED_SPONSOR"
export const REMOVE_SELECTED_SPONSOR = "REMOVE_SELECTED_SPONSOR";
export const RESET_SPONSOR = "RESET_SPONSOR";
export const FETCH_SPONSOR_LIST_SUCCEEDED = "FETCH_SPONSOR_LIST_SUCCEEDED";
export const FETCH_SPONSOR_BY_ID_SUCCEEDED = "FETCH_SPONSOR_BY_ID_SUCCEEDED";
export const SUBMIT_SPONSOR_SUCCEEDED = "SUBMIT_SPONSOR_SUCCEEDED";
export const UPDATE_SPONSOR_SUCCEEDED = "UPDATE_SPONSOR_SUCCEEDED";
export const DELETE_SPONSOR_SUCCEEDED = "DELETE_SPONSOR_SUCCEEDED";
export const RESTORE_SPONSOR_SUCCEEDED = "RESTORE_SPONSOR_SUCCEEDED";
export const RESET_FETCHCOMPLETE = "RESET_FETCHCOMPLETE";

export interface FetchSponsorListActions {
  type: typeof FETCH_SPONSOR_LIST;
  payload: GetQueryParams;
}

export interface FetchSponsorListSucceededActions {
  type: typeof FETCH_SPONSOR_LIST_SUCCEEDED,
  payload: Payload;
  append: boolean;
}

export interface FetchSponsorByIdActions {
  type: typeof FETCH_SPONSOR_BY_ID;
  id: string
}

export interface SetSelectedSponsorActions {
  type: typeof SET_SELECTED_SPONSOR;
  id: string
}

export interface FetchSponsorByIdSucceededActions {
  type: typeof FETCH_SPONSOR_BY_ID_SUCCEEDED;
  payload: SponsorList
}

export interface RemoveSelectedSponsorActions {
  type: typeof REMOVE_SELECTED_SPONSOR
}

export interface SubmitSponsorActions {
  type: typeof SUBMIT_SPONSOR;
  payload: SponsorList | null;
}

export interface SubmitSponsorSucceededActions {
  type: typeof SUBMIT_SPONSOR_SUCCEEDED;
  payload: SponsorList;
}

export interface UpdateSponsorActions {
  type: typeof UPDATE_SPONSOR;
  id: string;
  payload: SponsorList;
}

export interface UpdateSponsorSucceededActions {
  type: typeof UPDATE_SPONSOR_SUCCEEDED;
  id: string;
  payload: SponsorList;
}

export interface DeleteSponsorActions {
  type: typeof DELETE_SPONSOR;
  id: string;
}

export interface DeleteSponsorSuceededActions {
  type: typeof DELETE_SPONSOR_SUCCEEDED;
  id: string;
}

export interface RestoreSponsorActions {
  type: typeof RESTORE_SPONSOR;
  id: string;
}

export interface RestoreSponsorSuceededActions {
  type: typeof RESTORE_SPONSOR_SUCCEEDED;
  id: string;
}

export interface ResetSponsorActions {
  type: typeof RESET_SPONSOR;
}

export interface  ResetFetchCompleteActions {
  type: typeof RESET_FETCHCOMPLETE
}

export type SponsorActionsTypes =
  | FetchSponsorListActions
  | SubmitSponsorActions
  | UpdateSponsorActions
  | DeleteSponsorActions
  | RestoreSponsorActions
  | FetchSponsorListSucceededActions
  | FetchSponsorByIdSucceededActions
  | SubmitSponsorSucceededActions
  | UpdateSponsorSucceededActions
  | DeleteSponsorSuceededActions
  | RestoreSponsorSuceededActions
  | FetchSponsorByIdActions
  | SetSelectedSponsorActions
  | RemoveSelectedSponsorActions
  | ResetSponsorActions
  | ResetFetchCompleteActions
  ;