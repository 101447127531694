import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import {fetchError, fetchStart, fetchSuccess} from '../actions/Common';
import { Api, getGetParams, getPostParams, getPutParams, getDeleteParams } from "../../@crema/utility/Utils";
import { setCRUD } from "../actions/Common"
import { 
  fetchPatientListSucceeded, 
  submitPatientSucceeded, 
  updatePatientSucceeded, 
  deletePatientSucceeded, 
  fetchPatientByIdSucceeded,
  fetchPatientScriptHistorySucceeded,
  submitPrescriptionSucceeded,
  fetchPrescriptonSummarySucceeded,
  submitPinGenerationSucceeded,
  fetchUnblindedSucceeded,
  fetchUnblindedNamesSucceeded,
  stockAvailabilitySucceeded,
  createRepeatSucceeded
} from "../actions/Patients";

const baseUrlv1 = 'api/v1';

function fetchPatientListByIdApi(trialId:string, id:string) {
  const url = `${baseUrlv1}/trials/${trialId}/patients/${id}`;
  return Api.fetch(url, getGetParams());
}

function fetchPatientListApi(payload:any) {
  const { PageSize, PageNumber, SearchTerm  } = payload
  const url = `${baseUrlv1}/trials/${payload.trialId}/patients?PageSize=${PageSize}&PageNumber=${PageNumber}&SearchTerm=${SearchTerm}`;
  return Api.fetch(url, getGetParams());
}

function submitPatientApi (payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/patients`;
  return Api.fetch(url, getPostParams(payload))
}

function updatePatientApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/patients`;
  return Api.fetch(url, getPutParams(payload))
} 

function deletePatientApi(trialId:string, id:string) {
  const url = `${baseUrlv1}/trials/${trialId}/patients/${id}`;
  return Api.fetch(url, getDeleteParams())
}

function fetchPatientScriptHistoryApi(id:string, patientId:string) {
  const url = `${baseUrlv1}/trials/${id}/patients/${patientId}/scripts`;
  return Api.fetch(url, getGetParams());
}

function submitPrescriptionApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/patient/${payload.patientId}/prescription/create`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchPrescriptonSummaryApi(id: string) {
  const url = `${baseUrlv1}/prescriptions/getsummary/${id}`;
  return Api.fetch(url, getGetParams());
}

function submitPinGenerationApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/unblinding`;
  return Api.fetch(url, getPostParams(payload), 'text');
}

function fetchUnblindedApi(payload:any) {
  const url = `${baseUrlv1}/trials/${payload.trialId}/unblinded`;
  return Api.fetch(url, getPostParams(payload));
}

function fetchUnblindedNamesApi(payload:any) {
  const url = `${baseUrlv1}/trials/getUnblindedData`;
  return Api.fetch(url, getPostParams(payload), 'text');
}

function stockAvailabilityApi(trialId: string, patientId:string) {
  const url = `${baseUrlv1}/trials/${trialId}/patients/${patientId}/repeat/checkStock`;
  return Api.fetch(url, getGetParams());
}

function createRepeatApi(trialId: string, patientId:string) {
  const url = `${baseUrlv1}/trials/${trialId}/patient/${patientId}/repeat/create`;
  return Api.fetch(url, getPostParams({}));
}

export function* fetchPatientById({trialId, id}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchPatientListByIdApi, trialId, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchPatientByIdSucceeded(response))
    } else {
      let message: any;
      const { code} = response.error;
      
      if(code === 400 || code === 404) {
        message = "No Patient found"
      }else {
        ({ message } = response.error);
      }
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchPatientList({payload}:any) {
  yield put(fetchStart());
  try {
    const { append} = payload;
    const response  = yield call(fetchPatientListApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchPatientListSucceeded(response, append))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitPatient({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitPatientApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(submitPatientSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* updatePatient({id, payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(updatePatientApi, {...payload, id});
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(updatePatientSucceeded(id, response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* deletePatient({trialId, id}: any) {
  yield put(fetchStart(true));
  try {
    const response = yield call(deletePatientApi, trialId, id);
    if(response) {
      yield put(fetchSuccess(true));
      yield put(deletePatientSucceeded(id))
    } else {
      yield put(fetchError(response.message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchPatientScriptHistory({id, patientId}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchPatientScriptHistoryApi, id, patientId);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(fetchPatientScriptHistorySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitPrescription({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitPrescriptionApi, payload);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(setCRUD('create'));
      yield put(submitPrescriptionSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchPrescriptonSummary({id}:any) {
  yield put(fetchStart());
  try {
    const response  = yield call(fetchPrescriptonSummaryApi, id);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchPrescriptonSummarySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* submitPinGeneration({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(submitPinGenerationApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(submitPinGenerationSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchUnblinded({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchUnblindedApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchUnblindedSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* fetchUnblindedNames({payload}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(fetchUnblindedNamesApi, payload);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchUnblindedNamesSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* stockAvailability({trialId, patientId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(stockAvailabilityApi, trialId, patientId);
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(stockAvailabilitySucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export function* createRepeat({trialId, patientId}:any) {
  yield put(fetchStart(true));
  try {
    const response  = yield call(createRepeatApi, trialId, patientId);
    if(!response.error) {
      yield put(fetchSuccess(true));
      yield put(createRepeatSucceeded(response))
    } else {
      const { message } = response.error;
      yield put(fetchError(message));
    }
  } catch(error) {
    yield put(fetchError(error.message))
  }
}

export default [
  takeLatest('FETCH_PATIENT_BY_ID', fetchPatientById),
  takeLatest('FETCH_PATIENT_LIST', fetchPatientList),
  takeLatest('SUBMIT_PATIENT', submitPatient),
  takeLatest('UPDATE_PATIENT', updatePatient),
  takeLatest('DELETE_PATIENT', deletePatient),
  takeLatest('FETCH_PATIENT_SCRIPT_HISTORY', fetchPatientScriptHistory),
  takeLatest('SUBMIT_PRESCRIPTION', submitPrescription),
  takeLatest('FETCH_PRESCRIPTION_SUMMARY', fetchPrescriptonSummary),
  takeLatest('SUBMIT_PIN_GENERATION', submitPinGeneration),
  takeLatest('FETCH_UNBLINDED', fetchUnblinded),
  takeLatest('FETCH_UNBLINDED_NAMES', fetchUnblindedNames),
  takeLatest('STOCK_AVAILABILITY', stockAvailability),
  takeLatest('CREATE_REPEAT', createRepeat)
]