import { SponsorList, Payload } from "../../types/models/Sponsors";
import { GetQueryParams } from "../../types/models/Common";
import { 
  FETCH_SPONSOR_LIST,
  FETCH_SPONSOR_BY_ID,
  SUBMIT_SPONSOR,
  UPDATE_SPONSOR,
  DELETE_SPONSOR,
  RESTORE_SPONSOR,
  FETCH_SPONSOR_LIST_SUCCEEDED,
  FETCH_SPONSOR_BY_ID_SUCCEEDED,
  SUBMIT_SPONSOR_SUCCEEDED,
  UPDATE_SPONSOR_SUCCEEDED,
  DELETE_SPONSOR_SUCCEEDED,
  RESTORE_SPONSOR_SUCCEEDED,
  SET_SELECTED_SPONSOR,
  REMOVE_SELECTED_SPONSOR,
  RESET_SPONSOR,
  RESET_FETCHCOMPLETE,
  SponsorActionsTypes
} from "../../types/actions/Sponsors.action";

export const fetchSponsorList = (payload: GetQueryParams) => ({
  type: FETCH_SPONSOR_LIST,
  payload
});

export const submitSponsor = (payload:SponsorList):SponsorActionsTypes => ({
  type: SUBMIT_SPONSOR,
  payload
});

export const updateSponsor = (id: string, payload:SponsorList):SponsorActionsTypes => ({
  type: UPDATE_SPONSOR,
  id,
  payload
});

export const deleteSponsor = (id: string):SponsorActionsTypes => ({
  type: DELETE_SPONSOR,
  id
});

export const restoreSponsor = (id: string):SponsorActionsTypes => ({
  type: RESTORE_SPONSOR,
  id
});


export const fetchSponsorById = (id:string) => ({
  type: FETCH_SPONSOR_BY_ID,
  id
});

export const setSelectedSponsor = (id:string) => ({
  type: SET_SELECTED_SPONSOR,
  id
});

export const removeSelectedSponsor = () => ({
  type: REMOVE_SELECTED_SPONSOR
});

export const fetchSponsorListSucceeded = (payload:Payload, append:boolean): SponsorActionsTypes=> ({
  type: FETCH_SPONSOR_LIST_SUCCEEDED,
  payload,
  append
});

export const submitSponsorSucceeded = (payload:SponsorList):SponsorActionsTypes => ({
  type: SUBMIT_SPONSOR_SUCCEEDED,
  payload
});

export const updateSponsorSucceeded = (id: string, payload:SponsorList):SponsorActionsTypes => ({
  type: UPDATE_SPONSOR_SUCCEEDED,
  id,
  payload
});

export const deleteSponsorSucceeded = (id: string):SponsorActionsTypes => ({
  type: DELETE_SPONSOR_SUCCEEDED,
  id
});

export const restoreSponsorSucceeded = (id: string):SponsorActionsTypes => ({
  type: RESTORE_SPONSOR_SUCCEEDED,
  id
});

export const fetchSponsorByIdSucceeded = (payload:SponsorList):SponsorActionsTypes => ({
  type: FETCH_SPONSOR_BY_ID_SUCCEEDED,
  payload
});

export const resetSponsor = ():SponsorActionsTypes => ({
  type: RESET_SPONSOR
});

export const resetFetchComplete = ():SponsorActionsTypes => ({
  type: RESET_FETCHCOMPLETE
});