import React from 'react';

export const pricingRouteConfig = [
  {
    auth: ['Admin'],
    routes: [
      {
        path: '/pricing',
        component: React.lazy(() => import('./Pricing')),
      },
    ],
  },
];
